import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const ConfirmProfileModal = ({
                                      ok,
                                      cancel,
                                    }) => {
  const [visible, setVisible] = useState(false)
  const [nextPath, setNextPath] = useState('')
  const history = useHistory()

  useEffect(() => {
    window.confirmProfile = (url) => {
      setVisible(() => true)
      setNextPath(url)
    }
    return () => {
      delete window.confirmProfile
    }
  }, [])

  const onOk = async () => {
    await ok()
    history.push(nextPath)
    setNextPath('')
    setVisible(() => false)
  }

  const onLeave = async () => {
    await cancel()
    history.push(nextPath)
    setNextPath('')
    setVisible(() => false)
  }

  const onCancel = () => {
    setVisible(() => false)
  }
  return (<Modal visible={visible}
                 onOk={onOk}
                 onCancel={onCancel}
                 title={'Save changes profile template?'}
                 footer={[
                   <Button key="submit" onClick={onOk}>
                     Save and continue
                   </Button>, <Button key="leave" onClick={onLeave}>
                     Leave without saving
                   </Button>, <Button key="cancel" onClick={onCancel}>
                     Cancel and stay
                   </Button>]} />)
}

