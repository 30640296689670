import React from 'react'
import App from './app'
import { useRecoilValue } from 'recoil'
import {
  CurrentMemberSelector,
} from '../state'

import NavigationMembers from '../navigationMembers'

const Apps = () => {
  const CurrentMember = useRecoilValue(CurrentMemberSelector)
  return (<div className={'row'}>
      <NavigationMembers />
      {CurrentMember && (<React.Suspense fallback={<div>Loading...</div>}>
        <App />
      </React.Suspense>)}
    </div>
  )
}

export default Apps
