import { QueryClient } from 'react-query'
import axios from 'axios'
import getBaseURL from './baseURL'

export const GA = 'UA-166899409-1'

export const TEXT = 'text'
export const NUMBER = 'number'
export const DATE = 'date'
export const TRUEFALSE = 'true/false'
export const SELECTION = 'selection'
export const OBJECT = 'object'
export const GLOBAL = 'global'
export const ID = 'id'
export const TABLE = 'table'
export const QUERY = 'query'
export const COLLECTION = 'collection'
export const DOCX = 'docx'
export const FILE = 'file'
export const PDFF = 'pdff'

export const VARIABLETYPES = [
  TEXT,
  NUMBER,
  DATE,
  TRUEFALSE,
  SELECTION,
  OBJECT,
  FILE]

export const VARIABLE_TYPES_CATALOG = [
  TEXT,
  NUMBER,
  DATE,
  TRUEFALSE,
  SELECTION,
  OBJECT,
  GLOBAL,
  FILE]

export const LISTVARIABLETYPES = [TEXT, SELECTION, OBJECT, FILE]
export const LIST_VARIABLE_TYPES_CATALOG = [
  TEXT,
  SELECTION,
  OBJECT,
  GLOBAL,
  FILE]

export const TEXTBOX = 'textbox'
export const SPINBOX = 'spinbox'
export const DATEPICKER = 'datepicker'
export const CHECKBOX = 'checkbox'
export const SWITCH = 'switch'
export const RADIOBUTTONS = 'radiobuttons'
export const RADIOBUTTONS_GRID = 'radiobutton columns'
export const CHECKBOXES = 'checkboxes'
export const CHECKBOXES_GRID = 'checkbox columns'
export const DROPDOWN = 'dropdown'
export const HYBRID = 'hybrid'
export const LISTBOX = 'listbox'
export const POPUP = 'popup'
export const HIDDEN = 'hidden'

export const QUESTION = 'question'
export const STATIC = 'static'

export const ANSWERED = {
  NONE: 0,
  PART: 1,
  FULL: 2,
}
export const DND = {
  TYPES: {
    DNDITEMROOT: 'DNDITEMROOT',
  },
  FORMULAS: {
    DNDFORMULAS: 'DNDITEMFORMULAS',
  },
  TEXT: {
    DNDSUGGESTIONS: 'DNDSUGGESTIONS',
  },
}

const URI = getBaseURL()
axios.defaults.baseURL = URI

export { URI }

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
})

export function canBeKey (def) {
  return def && !def.isList && [ID, NUMBER, SELECTION, TEXT].includes(def.type)
}
