import {
  ADD_NEW_COLLECTION,
  CHANGE_COLLECTIONS,
  EDIT_COLLECTION,
  ERROR_COLLECTIONS,
  PUSH_COLLECTIONS,
  REMOVE_COLLECTION,
  REQUEST_COLLECTIONS,
  SAVE_COLLECTION
} from '../../action/collections'
import { produce } from 'immer'

function collections (state = [], action) {
  switch (action.type) {
    case ADD_NEW_COLLECTION: {
      return [...state, action.obj]
    }
    case PUSH_COLLECTIONS: {
      return [...action.data]
    }
    case CHANGE_COLLECTIONS: {
      let i = state.findIndex(s => s._id === action.collection._id)
      if (i > -1) {
        state[i].apps = action.collection.apps
      }

      return [...state]
    }
    case SAVE_COLLECTION: {
      state[action.index] = action.collection
      return [...state]
    }
    case EDIT_COLLECTION: {
      state[action.index][action.key] = action.val
      return [...state]
    }
    case REQUEST_COLLECTIONS: {
      return []
    }
    case REMOVE_COLLECTION: {
      return produce(state, draft => {
        return draft.filter(d => d._id !== action._id)
      })
    }
    case ERROR_COLLECTIONS: {
      return []
    }
    default: {
      return state
    }
  }
}

export default collections