import styled from 'styled-components'

export const Row = styled.div`
 background-color: ${props => props.theme.colorTable.plain};
 &:nth-child(even) {
   background-color: ${props => props.theme.colorTable.backLight};
 } 
`
export const LabelForAddButton = styled.label`
  color:${props => props.theme.colorTable.backText}
`
