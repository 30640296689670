import React, { Fragment } from 'react'
import _ from 'lodash'
import {
  Ace,
  RadioInputs,
  Select,
  SingleAce,
  Table,
} from '../../../../components'
import { QUERY, TABLE } from '../../../../config'
import { Engine } from 'yatte'
import { GetDefaultSelectionStyle } from '../../../../utils'
import RequiredRow from './parameters/RequiredRow'
import ModalParameters from './parameters/ModalParameters'
import useParseQuery from './parameters/useParseQuery'
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'

import {
  CurrentMemberSelector,
  CurrentTypeSelector,
  editMemberProperty,
  editMemberPropertyByPath,
  ElementsChangesAtom,
  getKind,
  getMemberKey,
  TypesSelector,
} from '../../state'

import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'
import { produce } from 'immer'

const { ParseQueryExpr } = require('../../../../utils/parseQueryExpr')

const SELECTION = 'selection'

const LocalOptions = () => {
  const [data, onChange] = useRecoilState(editMemberProperty('options'))
  let preData
  let opts
  try {
    opts = Engine.compileExpr(data).ast
  } catch (e) {
    opts = {
      type: 'Error',
      message: e.toString(),
    }
  }
  if (opts.type === Engine.AST.ArrayExpression) {
    preData = JSON.parse(data)
  }
  if (!preData || !preData.length) {
    preData = [{ Name: '' }]
  }
  const createProps = (d) => {
    if (d.length) {
      d = d[0]
      const keys = _.keys(d)
      if (keys.length) {
        return keys.map(k => {
          return {
            label: k,
            name: k,
            path: k,
          }
        })
      }
    } else {
      return [
        {
          label: 'Name',
          name: 'Name',
          path: 'Name',
        }]
    }
  }

  const preProps = createProps(preData)
  const formatOptions = (newData) => {
    newData = JSON.stringify(newData)
    if (newData.localeCompare(data) !== 0) {
      onChange(newData)
    }
  }
  return (
    <Table preData={preData}
           columns={preProps}
           implicitKey={'Name'}
           getTable={({ data }) => formatOptions(data)}
           activeConfirm={() => 0}/>
  )
}

const AsyncOnChangeOptions = ({ set, snapshot }) => async (val) => {
  const types = await snapshot.getPromise(TypesSelector)
  const member = await snapshot.getPromise(CurrentMemberSelector)
  const type = await snapshot.getPromise(CurrentTypeSelector)

  const tables = types.filter(
    ({ type }) => type === TABLE || type === QUERY) || []

  let isQuery = false
  const nextType = tables.find(type => type.name === val)
  if (nextType) {
    if (nextType.type === QUERY) {
      isQuery = true
    }
  }
  window.typesWereChanged = true

  set(ElementsChangesAtom, produce((draft) => {
    const currentElement = draft.findIndex(el => el._id === member._id)
    if (currentElement === -1) {
      draft.push({
        action: 'update',
        _id: member._id,
        kind: getKind(getMemberKey()),
        parent: type._id,
        parentKind: type.kind,
        options: isQuery ? val + '()' : val,
      })
      return
    }
    _.set(draft[currentElement], 'options', isQuery ? val + '()' : val)
  }))
}

const TableOptions = () => {
  const value = useRecoilValue(editMemberProperty('options'))

  const Types = useRecoilValue(TypesSelector)
  const Tables = Types.filter(({ type }) => type === TABLE || type === QUERY).map(type => type.name) || []

  const onChange = useRecoilCallback(AsyncOnChangeOptions, [])

  const parseQuery = ParseQueryExpr(value)

  return <Select label={'OPTION SOURCE'} id={'selectionStyle'}
                 value={parseQuery.queryName || value}
                 values={['', ...Tables]}
                 onChange={onChange}/>
}

const ExpressionOptions = () => {
  const value = useRecoilValue(editMemberProperty('options'))

  const onChange = useRecoilCallback(AsyncOnChangeOptions, [])

  return (<div
    className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>
    <Ace
      label={'OPTION EXPRESSION'}
      mode={'kexpr'}
      onChange={onChange}
      className={'border-0'}
      value={value}
      isExpression={true}
      type={'text'}
      maxLines={Infinity}/>
  </div>)
}

const selectFromChoices = [
  {
    value: 'anOptionListedBelow',
    label: 'Options defined below',
  },
  {
    value: 'aTable',
    formatting: () => `A table${window.knacklyAddons &&
    window.knacklyAddons.queries ? ' or query' : ''}`,
  },
  {
    value: 'userData',
    label: 'User data (list expression)',
  },
]

const typeLabel = (param) => {
  return param.type + (param.isList ? ' list' : '')
}

const QueryEditor = () => {
  const [query, onChange] = useRecoilState(editMemberProperty('options'))
  const types = useRecoilValue(TypesSelector)

  const {
    requiredParams,
    optionalParams,
    onUpdateOptionalParams,
  } = useParseQuery(query,
    types,
    (value) => onChange(value))

  const parseQuery = ParseQueryExpr(query)

  if (!parseQuery.queryName) {
    return null
  }
  return <Fragment>
    {(!!optionalParams.length || !!requiredParams.length) &&
      (<div className={'col-6'}>
        <div className={'form-group mb-2'}>
          <label>{'PARAMETERS'}</label>
          {requiredParams.map(param => (
            <RequiredRow name={param.name} key={param.name}
                         type={typeLabel(param)}
                         value={param.value}
                         onChange={param.onChange}/>))}
        </div>
        {(!!optionalParams.length) && (<ModalParameters params={optionalParams}
                                                        onUpdate={onUpdateOptionalParams}/>)}
      </div>)}
  </Fragment>
}

function Selection () {

  const typeOfVariable = useRecoilValue(editMemberProperty('typeOfVariable'))
  const setProperty = useSetRecoilState(editMemberPropertyByPath)
  const isList = useRecoilValue(editMemberProperty('isList'))

  // Reminder: if you extend this list, you must also extend the DB schema:
  //   server/db/types/variables/selection.js
  const styles = isList
    ? [
      'checkboxes',
      'checkbox columns',
      'dropdown',
      'listbox',
      'hybrid',
    ] : [
      'checkboxes',
      'checkbox columns',
      'dropdown',
      'listbox',
      'radiobuttons',
      'radiobutton columns',
      'hybrid',
    ]

  return (
    <Fragment>
      <div className={'col-4'}>
        <RadioInputs label={'SELECT FROM'} name={'typeSelection'}
                     values={selectFromChoices}
                     active={typeOfVariable}
                     handelChange={val => {
                       setProperty({
                         path: 'typeOfVariable',
                         value: val,
                       })
                     }}
        />
      </div>
      <div className={'col-4'}>
        <EditMemberPropertyComponent path={'style'}>
          {({
              value,
              onChange,
            }) => (
            <Select label={'STYLE'}
                    id={'selectionStyle'}
                    value={value || GetDefaultSelectionStyle(isList)}
                    values={styles}
                    onChange={onChange}/>)}
        </EditMemberPropertyComponent>

      </div>
      <div className={'col-4 form-group'}>
        <label>OPTION TEMPLATE</label>
        <EditMemberPropertyComponent path={'optionTemplate'}>
          {({
              value,
              onChange,
            }) => (
            <SingleAce onChange={onChange}
                       value={value || ''}
                       name={'optionTemplate'}
                       placeholder={''}/>)}
        </EditMemberPropertyComponent>
      </div>
      <div className={typeOfVariable === 'aTable' ? 'col-6' : 'col-12'}>
        {typeOfVariable === 'anOptionListedBelow' &&
          <LocalOptions/>}
        {typeOfVariable === 'aTable' &&
          <TableOptions/>}
        {typeOfVariable === 'userData' &&
          <ExpressionOptions/>}
      </div>
      <QueryEditor/>
    </Fragment>
  )
}

export { SELECTION, Selection }
