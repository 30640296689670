const { Engine } = require('yatte')
const AST = Engine.AST

function ParseQueryExpr (expr) {
  let ast, parsedExpr, callExpr, queryName, mapTo, mapBack, args, reqArgs, optArgs
  if (expr) {
    try {
      ast = parsedExpr = Engine.compileExpr(expr).ast
      if (parsedExpr.type === AST.ListFilterExpression && parsedExpr.filter.name === 'map') {
        mapTo = AST.serialize(parsedExpr.arguments[0], true)
        parsedExpr = parsedExpr.input
      }
      if (parsedExpr.type === AST.ListFilterExpression && parsedExpr.filter.name === 'map') {
        mapBack = mapTo
        mapTo = AST.serialize(parsedExpr.arguments[0], true)
        parsedExpr = parsedExpr.input
      }
      if (parsedExpr.type === AST.CallExpression) {
        callExpr = parsedExpr
        const argAsts = parsedExpr.arguments
        args = argAsts.map(argAst => AST.serialize(argAst, true))
        if (argAsts.length > 0) {
          if (argAsts[argAsts.length - 1].type === AST.ObjectExpression) {
            optArgs = argAsts[argAsts.length - 1].properties
            reqArgs = args.slice(0, -1)
          } else {
            reqArgs = args
          }
        }
        parsedExpr = parsedExpr.callee
      }
      if (parsedExpr.type === AST.Identifier) {
        queryName = parsedExpr.name
      }
    } catch (e) {}
  }
  return {
    ast,
    callExpr,
    queryName,
    mapTo,
    mapBack,
    args,
    reqArgs,
    optArgs,
  }
}

exports.ParseQueryExpr = ParseQueryExpr
