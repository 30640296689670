import React, { memo } from 'react'
import { Ace, AddButton, Icon } from '../../../components'
import {
  selector,
  selectorFamily,
  useRecoilValue,
  useRecoilCallback,
} from 'recoil'

import produce from 'immer'
import { areEqual } from 'react-window'

import {
  ReactComponent as CloseIcon,
} from '../../../images/icons/times-pro.svg'
import { editMemberProperty } from '../state'

const ExpressionsSelector = selector({
  key: 'ExpressionsFormulasSelector',
  get: ({ get }) => {
    const expression = get(editMemberProperty('expression'))
    console.log('expression', expression)
    if (!expression) {
      return []
    }
    try {
      return JSON.parse(expression)
    } catch (e) {

    }
  },
  set: ({
          get,
          set,
        }, value) => {
    set(editMemberProperty('expression'), JSON.stringify(value))
  },
})

const ExpressionStateSelection = selectorFamily({
  key: 'ExpressionStateSelection',
  get: (id) => ({ get }) => {
    const Expressions = get(ExpressionsSelector)
    return Expressions[id]
  },
})

const ExpressionComponent = memo(({
                                    id,
                                    position,
                                  }) => {
  const expr = useRecoilValue(ExpressionStateSelection(id))

  const onRemove = useRecoilCallback(({ set }) => () => {
    set(ExpressionsSelector, produce(draft => {
      return draft.filter((item, index) => index !== id)
    }))
  }, [id])

  const onChange = useRecoilCallback(({ set }) => (value = '') => {
    set(ExpressionsSelector, produce((draft) => {
      draft[id] = value
    }))
  }, [id])

  const onUp = useRecoilCallback(({ set }) => () => {
    set(ExpressionsSelector, produce(draft => {
      const item = draft[id]
      draft.splice(id, 1)
      draft.splice(id - 1, 0, item)
    }))
  }, [id])

  const onDown = useRecoilCallback(({ set }) => () => {
    set(ExpressionsSelector, produce(draft => {
      const item = draft[id]
      draft.splice(id, 1)
      draft.splice(id + 1, 0, item)
    }))
  }, [id])

  return <div className={'d-flex mb-2'}>
    <div className={'pr-2 prevent-select'}
         style={{ minWidth: '1.5rem' }}>{position}</div>
    <div
      className={'knackly-plain rounded-0 p-2 form-control TemplateWrapper'}>
      <Ace name={id.toString()}
           value={expr || ''}
           mode={'kexpr'}
           className={'border-0'}
           onChange={onChange}
           maxLines={Infinity} />
    </div>
    <div className={'d-flex'}>
      <div onClick={onUp} className={'mx-1 cursorPointer'}>
        <Icon type={'icon-arrow-up'} className={'align-middle'} />
      </div>
      <div onClick={onDown} className={'mx-1 cursorPointer'}>
        <Icon type={'icon-arrow-down'} className={'align-middle'} />
      </div>
      <div onClick={onRemove} className={'mx-1 cursorPointer'}>
        <CloseIcon className={'timesBtn'} style={{
          width: '1rem',
          height: '1rem',
        }} />
      </div>
    </div>
  </div>
}, areEqual)

const ExpressionsComponents = () => {
  const Expressions = useRecoilValue(ExpressionsSelector)
  return (<div className={'d-flex flex-column m-1'}>
    {Expressions.map(
      (expression, index) => <ExpressionComponent
        key={index}
        id={index}
        position={index + 1} />)}
  </div>)
}

const ExpressionsProviderComponents = () => {

  const onAdd = useRecoilCallback(({
                                     set,
                                   }) => () => {
    set(ExpressionsSelector, produce(draft => {
      draft.push('')
    }))
  }, [])

  return (<div className={'col-12 form-group'}>
    <label>EXPRESSIONS</label>
    <ExpressionsComponents />
    <AddButton id={'addExpression'}
               label={'Add expression'}
               onClick={onAdd} />
  </div>)
}
export default ExpressionsProviderComponents
