import React from 'react'
import { Ace } from '../../../../components'
import '../../../../components/Ace/mode/knackly'
import '../../../../components/Ace/theme/knackly'
import 'brace/ext/language_tools'
import 'brace/ext/searchbox'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'

const Text = () => {
  return (<div className={'col-12 form-group'}>
    <div className={'d-flex justify-content-between'}>
      <label>TEMPLATE TEXT</label>
      <div className="form-check">
        <EditMemberPropertyComponent path={'contentType'}>
          {({
              value,
              onChange,
            }) => (
            <input className="form-check-input"
                   type="checkbox"
                   id="markdownCheck"
                   onChange={() => onChange(
                     value === 'markdown' ? 'text' : 'markdown')}
                   checked={value === 'markdown'} />)}
        </EditMemberPropertyComponent>
        <label className="form-check-label" htmlFor="markdownCheck">
          Allow formatting (markdown)
        </label>
      </div>
    </div>

    <EditMemberPropertyComponent path={'text'}
                                 defaultValue={''}>
      {({
          value,
          onChange,
        }) => (<Ace value={value}
             maxLines={null}
             height={300}
             onChange={onChange} />)
      }
    </EditMemberPropertyComponent>
  </div>)
}

export default Text
