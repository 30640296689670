const PdfUtils = {
  FlagsToText,
  FlattenFieldMap,
  MergeFieldMaps,
  ParseNameIndex,
  UpdateFieldMap,
}
module.exports = PdfUtils

const FIELDFLAGS = ['ReadOnly', 'Required', 'NoExport', '','','','','','','','','', 'Multiline',
  'Password', 'NoToggleToOff', 'Radio', 'Pushbutton', 'Combo', 'Edit', 'Sort', 'FileSelect', 'MultiSelect',
  'DoNotSpellCheck', 'DoNotScroll']

function FlagsToText (fieldObj) {
  if (fieldObj.FieldFlags === -1) {
    return 'OBSOLETE'
  }
  let result
  switch (fieldObj.FieldJustification.toLowerCase()) {
    case 'left': result = "LeftJust"; break
    case 'right': result = "RightJust"; break
    case 'center': result = "Centered"; break
    default: result = ''
  }
  let flags = fieldObj.FieldFlags || 0
  let count = 0
  while (flags) {
    if (flags & 1) {
      if (result) result += ', '
      result += FIELDFLAGS[count] || (count + 1)
    }
    flags >>= 1
    count++
  }
  return result
}

function FlattenFieldMap (fieldObj) {
  if (!fieldObj) {
    return []
  }
  let result
  if (Array.isArray(fieldObj)) {
    result = fieldObj.map(fo => FlattenFieldMap(fo))
  } else {
    result = Object.entries(fieldObj).map(entry => (entry[0] === '__props' ? entry[1] : FlattenFieldMap(entry[1])))
  }
  return [].concat.apply([], result)
}

function ParseNameIndex (fieldName) {
  const match = fieldName.match(/^(.*)\[(\d+)\]$/)
  return match ? {
    name: match[1],
    index: parseInt(match[2]),
  } : false
}

function UpdateFieldMap (existing, id, value) {
  if (!id) {
    let newObj = Object.assign({}, existing)
    let newProps = Object.assign({}, existing.__props)
    newProps.Expression = value
    newObj.__props = newProps
    return newObj // { ...existing, __props: { ...existing.__props, Expression: value } }
  }
  let isArray = Array.isArray(existing)
  if (isArray && !id.startsWith('[')) throw Error('oops')
  let child, index
  let period = id.indexOf('.', 0)
  if (period >= 0) {
    child = id.slice(0, period)
  } else {
    child = id
  }
  const parsed = ParseNameIndex(child)
  if (parsed) {
    index = parsed.index
    child = parsed.name
  } else {
    index = -1
  }
  if (isArray) {
    if (child || index < 0) throw Error('oops2')
    // else
    return existing.slice(0, index)
      .concat(UpdateFieldMap(existing[index], id.slice(period >= 0 ? period + 1 : id.length), value))
      .concat(existing.slice(index + 1))
  } // else
  let newObj = existing
  newObj[child] = UpdateFieldMap(existing[child], id.slice(index >= 0 ? child.length : period >= 0 ? period + 1 : id.length), value)
  return newObj
  // {
  //   ...existing,
  //   [child]: UpdateFieldMap(existing[child], id.slice(index >= 0 ? child.length : period >= 0 ? period + 1 : id.length), value)
  // }
}

function MergeFieldMaps (existingMap, newMap) {
  // if !existingMap, this basically creates and returns a deep copy of newMap
  // if existingMap, this creates a new, merged map without throwing away any data that is still important
  const resultMap = {}
  if (!newMap) {
    // return only that data in existingMap where mapped Expressions have been set
    if (existingMap) {
      if (Array.isArray(existingMap)) {
        const resultArray = existingMap.map(obj => MergeFieldMaps(obj, null))
        return resultArray.filter(Boolean)
      } // else it's an object
      let subMap
      for (const entryName of Object.keys(existingMap).filter(k => !k.startsWith('_'))) {
        subMap = MergeFieldMaps(existingMap[entryName], null)
        if (subMap) {
          resultMap[entryName] = subMap
        }
      }
      if (Object.keys(resultMap).length > 0 || (existingMap.__props && existingMap.__props.Expression)) {
        if (existingMap.__props) {
          resultMap.__props = Object.assign({}, existingMap.__props) //{ ...existingMap.__props, FieldFlags: -1 }
          resultMap.__props.FieldFlags = -1
        }
        return resultMap
      }
    }
    return undefined
  } // else there is a newMap (there usually will be)
  if (Array.isArray(newMap)) {
    if (existingMap) {
      // make sure existingMap is also an array
      if (!Array.isArray(existingMap)) {
        existingMap = [existingMap]
      }
      if (newMap.length === existingMap.length) {
        return newMap.map((obj, idx) => MergeFieldMaps(existingMap[idx], obj))
      } // else match based on field names, not index
      const remainingExisting = existingMap.slice() // [...existingMap]
      const resultArray = []
      for (const newItem of newMap) {
        if (newItem.__props && newItem.__props.FieldName) {
          let idx = remainingExisting.findIndex(
            item => item.__props && (item.__props.FieldName === newItem.__props.FieldName))
          if (idx >= 0) {
            const matched = remainingExisting.splice(idx, 1)[0]
            resultArray.push(MergeFieldMaps(matched, newItem))
          } else {
            resultArray.push(MergeFieldMaps(null, newItem))
          }
        } else {
          resultArray.push(Object.assign({}, newItem)/*{ ...newItem }*/)
        }
      }
      if (remainingExisting.length > 0) {
        resultArray.concat(remainingExisting.map(ex => MergeFieldMaps(ex, null)).filter(Boolean))
      }
      return resultArray
    } // else
    return newMap.slice() // [...newMap]
  } // else newMap is an object
  // make sure existingMap is also an object
  if (Array.isArray(existingMap) && existingMap.length > 0) {
    let existingArray = MergeFieldMaps(existingMap, null).filter(Boolean)
    if (existingArray.length > 0) {
      existingMap = existingArray[0]
      if (existingArray.length > 1) {
        resultMap.__old = existingArray
      }
    } else {
      existingMap = undefined
    }
  }
  let subMap
  for (const entryName of Object.keys(newMap).filter(k => !k.startsWith('_'))) {
    subMap = MergeFieldMaps(existingMap && existingMap[entryName], newMap[entryName])
    if (subMap) {
      resultMap[entryName] = subMap
    }
  }
  if (existingMap) {
    for (const entryName of Object.keys(existingMap).filter(k => !k.startsWith('_') && !(k in newMap))) {
      subMap = MergeFieldMaps(existingMap[entryName], null)
      if (subMap) {
        resultMap[entryName] = subMap
      }
    }
  }
  let existingExpr = existingMap && existingMap.__props && existingMap.__props.Expression
  if ('__props' in newMap) {
    resultMap.__props = Object.assign({}, newMap.__props) // { ...newMap.__props }
    if (existingExpr) {
      resultMap.__props.Expression = existingExpr
    }
  } else if (existingExpr) {
    resultMap.__props = Object.assign({}, existingMap.__props) // { ...existingMap.__props, FieldFlags: -1 }
    resultMap.__props.FieldFlags = -1
  }
  return resultMap
}
