import localforage from 'localforage'

const getMembersIndexDB = (workspace, typeId) => {
  return localforage.createInstance(
    {
      driver: localforage.INDEXEDDB,
      name: 'members-' + workspace,
      storeName: typeId,
    })
}
export default getMembersIndexDB
