import React, { Fragment } from 'react'
import HeaderDnd from './dnd/header'

const Header = ({
                  header,
                  handleDelete,
                  handleKey,
                  handleRename,
                  handleRelabel,
                  moveHeader,
                  handleSortASC,
                  handleSortDESC,
                }) => {
  return (
    <Fragment>
      {header.map((h, index) => (
        <HeaderDnd key={'HeaderDnd-' + index}
                   header={h}
                   index={index}
                   handleDelete={handleDelete}
                   handleKey={handleKey}
                   handleRename={handleRename}
                   handleRelabel={handleRelabel}
                   moveHeader={moveHeader}
                   handleSortASC={handleSortASC}
                   handleSortDESC={handleSortDESC} />))}
    </Fragment>
  )
}

export default Header
