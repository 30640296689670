import React, { useRef } from 'react'
import { ReactComponent as Edit } from '../../../../../images/icons/edit.svg'
import { ReactComponent as CloseIcon } from '../../../../../images/icons/times-pro.svg'
import { useDrag, useDrop } from 'react-dnd'
import { DND } from '../../../../../config'

const OptionCard = ({ d, i, setEdit, setOptionEdit, removeItem, moveCard }) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: DND.TEXT.DNDSUGGESTIONS,
    hover (item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = i
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [, drag] = useDrag({
    item: { type: DND.TEXT.DNDSUGGESTIONS, index: i },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <div key={d + '-' + i} className={'d-flex'} ref={ref}>
      <div className={'mx-2 my-1 text-black-50'}>{i + 1}</div>
      <div className={'mx-2 my-1'}>{d}</div>
      <div className={'ml-auto d-flex'}>
        <div onClick={() => {
          setEdit(i)
          setOptionEdit(d)
        }}>
          <Edit className={'timesBtn'} style={{
            width: '1rem',
            height: '1rem',
            marginRight: '.5rem'
          }}/>
        </div>
        <div onClick={() => removeItem(d)}>
          <CloseIcon className={'timesBtn'} style={{
            width: '1rem',
            height: '1rem'
          }}/>
        </div>
      </div>
    </div>
  )
}

export default OptionCard
