import React from 'react'
import PropTypes from 'prop-types'
import Markdown from '../Markdown'
import { InferLabelFromName } from '../../utils'
import Help from '../Help'

Label.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  forId: PropTypes.string,
  className: PropTypes.string
}

function Label ({ label, name, forId, className, helpText }) {
  return label !== null ? (
      <label htmlFor={forId} className={className || 'd-inline'}>{label ? (
        <Markdown>{label.valueOf()}</Markdown>) : InferLabelFromName(name)}
        {helpText && <Help text={helpText}/>}
      </label>
  ) : null
}

export default Label
