import React, { Fragment } from 'react'
import { Select } from '../../../../components'
import useQuerySelect from './parameters/useQuerySelect'
import RequiredRow from './parameters/RequiredRow'
import ModalParameters from './parameters/ModalParameters'
import BuildLinkExpr from '../../../../utils/BuildLinkExpr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  CurrentMemberSelector, editMemberProperty,
  editMemberPropertyByPath,
  TypesSelector,
} from '../../state'
import { QUESTION, TABLE } from '../../../../config'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'
import { useQuery } from 'react-query'
import axios from 'axios'
import { GLOBAL } from '../../../../config'

const NESTEDOBJECT = 'object'

const typeLabel = (param) => {
  return param.type + (param.isList ? ' list' : '')
}

function NestedObject () {
  const Member = useRecoilValue(CurrentMemberSelector)
  const setProperty = useSetRecoilState(editMemberPropertyByPath)
  const Types = useRecoilValue(TypesSelector)
  const selectFrom = useRecoilValue(editMemberProperty('selectFrom'))
  const linkedQuery = useRecoilValue(editMemberProperty('linkedQuery'))
  const TableOrQuestion = Types.filter(
    ({ type }) => type !== TABLE && type !== QUESTION)

  const LinkedQuery = useQuerySelect(
    Member._id, linkedQuery, Member.isLink, Member.name, Member.typeName,
    TableOrQuestion,
    value => setProperty({
      path: 'linkedQuery',
      value,
    }),
  )
  const {
    data: globalsQuery,
  } = useQuery(['type', Member._id, Member.typeDef, 'globals'],
    async () => {
      if (!Member.typeDef) {
        return []
      }
      if (Member.type === GLOBAL) {
        return []
      }
      const { data } = await axios.get(
        `/api/v2/types/model/${Member.typeDef}/globals`)
      return data
    },
    {
      retry: false,
      refetchOnWindowFocus: true,
    },
  )
  const globals = globalsQuery || []

  const getValuesSelectFrom = () => {
    let selectFromValues = ['']

    if (SelectFromQuery.availableOptions.length) {
      selectFromValues = SelectFromQuery.availableOptions
    }
    if (globals.length) {
      selectFromValues = [...new Set(selectFromValues.concat(globals))]
    }
    return selectFromValues
  }

  const SelectFromQuery = useQuerySelect(
    Member._id,
    globals.includes(selectFrom) ? undefined : selectFrom,
    false,
    Member.name,
    Member.typeName,
    TableOrQuestion,
    value => setProperty({
      path: 'selectFrom',
      value,
    }),
  )

  const showSelectFrom = typeof selectFrom === 'string'
  const showLinkedQuery = typeof LinkedQuery.selectedQuery === 'string'

  return (
    <Fragment>
      <div className={'col-12'}>
        <hr/>
      </div>
      <div className={'col-3'}>
        <EditMemberPropertyComponent path={'style'} defaultValue={''}>
          {({
            value,
            onChange,
          }) => (
            <Select label={'Style'.toUpperCase()} id={'objectStyle'}
                    value={value}
                    values={[
                      'popup',
                      'inline',
                      'accordion',
                      'hidden']}
                    onChange={onChange}/>)}
        </EditMemberPropertyComponent>
      </div>
      {(SelectFromQuery.queryVisible || LinkedQuery.queryVisible ||
        !!globals.length) && (
        <div className={'col'}>
          <div className={'row'}>
            {(SelectFromQuery.queryVisible || !!globals.length) && (
              <Fragment>
                <div className={'col-5'}>
                  <div className={'form-group mb-2'}>
                    <div className="form-check">
                      <input className="form-check-input cursorPointer"
                             type="checkbox"
                             name="selectFrom"
                             id="useSelectFrom"
                             value=""
                             checked={showSelectFrom}
                             onChange={() => {
                               setProperty({
                                 path: 'selectFrom',
                                 value: showSelectFrom ? null : '',
                               })
                             }}/>
                      <label htmlFor={'useSelectFrom'}
                             className={'cursorPointer'}>SELECT data
                        FROM...</label>
                    </div>
                    {showSelectFrom && (
                      <Select id={Member._id + '_selectFrom'}
                              value={SelectFromQuery.selectedQuery}
                              values={getValuesSelectFrom()}
                              className={'mb-3 ml-3'}
                              errElement={SelectFromQuery.queryInvalid &&
                                !globals.includes(selectFrom) &&
                                <span
                                  className={'text-danger'}>(ERROR - change!)</span>}
                              onChange={val => {
                                if (val === '') {
                                  setProperty({ path: 'selectFrom', value: '' })
                                  return
                                }
                                if (globals.includes(val)) {
                                  setProperty(
                                    { path: 'selectFrom', value: val })
                                  return
                                }
                                setProperty({
                                  path: 'selectFrom',
                                  value: BuildLinkExpr(val,
                                    SelectFromQuery.requiredParams,
                                    SelectFromQuery.optionalParams),
                                })
                              }}/>
                    )}
                  </div>
                </div>
                <div className={'col-7'}>
                  {(!!SelectFromQuery.optionalParams.length ||
                      !!SelectFromQuery.requiredParams.length) &&
                    showSelectFrom &&
                    (<Fragment>
                      <div className={'form-group mb-2'}>
                        <label>{'PARAMETERS'}</label>
                        {SelectFromQuery.requiredParams.map(param => (
                          <RequiredRow name={param.name}
                                       key={Member.id + 'SELECT' + param.name}
                                       type={typeLabel(param)}
                                       value={param.value}
                                       onChange={param.onChange}/>))}
                      </div>
                      {(!!SelectFromQuery.optionalParams.length) &&
                        (<ModalParameters
                          params={SelectFromQuery.optionalParams}
                          onUpdate={SelectFromQuery.onUpdateOptionalParams}/>)}
                    </Fragment>)}
                </div>
              </Fragment>
            )}
            {LinkedQuery.queryVisible && (
              <div className={'col-5'}>
                <div className={'form-group mb-2'}>
                  <div className="form-check">
                    <input className="form-check-input cursorPointer"
                           type="checkbox"
                           name="selectFrom"
                           id="useLinkedQuery"
                           value=""
                           checked={showLinkedQuery}
                           onChange={() => {
                             setProperty({
                               path: 'linkedQuery',
                               value: showLinkedQuery ? null : '',
                             })
                           }}/>
                    <label htmlFor={'useLinkedQuery'}
                           className={'cursorPointer'}>LINK object
                      TO...</label>
                  </div>
                  {showLinkedQuery &&
                    (<Select id={Member._id + '_linkTo'}
                             className={'mt-2 ml-3'}
                             value={LinkedQuery.selectedQuery}
                             values={LinkedQuery.availableOptions}
                             errElement={LinkedQuery.queryInvalid &&
                               <span className={'text-danger'}> (ERROR - change!)</span>}
                             onChange={val =>
                               setProperty({
                                 path: 'linkedQuery',
                                 value: BuildLinkExpr(
                                   val,
                                   LinkedQuery.requiredParams,
                                   LinkedQuery.optionalParams),
                               })}/>)}
                </div>
                {showLinkedQuery && (<div className="form-check ml-3">
                  <EditMemberPropertyComponent path={'isLink'}>
                    {({
                      value,
                      onChange,
                    }) => (<input className="form-check-input"
                                  type="checkbox"
                                  name="exampleRadios"
                                  id="bidirectional"
                                  value=""
                                  disabled={!LinkedQuery.canChangeLinkType}
                                  checked={!!value}
                                  onChange={() => onChange(!value)}/>)}
                  </EditMemberPropertyComponent>
                  <label className="form-check-label" htmlFor="bidirectional">
                    Bidirectional link
                  </label>
                </div>)}
              </div>
            )}
            {(!!LinkedQuery.optionalParams.length ||
                !!LinkedQuery.requiredParams.length) && showLinkedQuery &&
              (<div className={'col-7'}>
                <div className={'form-group mb-2'}>
                  <label>{'PARAMETERS'}</label>
                  {LinkedQuery.requiredParams.map(param => (
                    <RequiredRow name={param.name}
                                 key={Member.id + param.name}
                                 type={typeLabel(param)}
                                 value={param.value}
                                 onChange={param.onChange}/>))}
                </div>
                {(!!LinkedQuery.optionalParams.length) &&
                  (<ModalParameters params={LinkedQuery.optionalParams}
                                    onUpdate={LinkedQuery.onUpdateOptionalParams}/>)}
              </div>)}
          </div>
        </div>
      )}
      {Member.isList && (
        <div className={'col-3'}>
          <div className={'d-flex align-items-center h-100'}>
            <div className="form-check" style={{ marginTop: 21 }}>
              <EditMemberPropertyComponent path={'useImport'}>
                {({
                  value,
                  onChange,
                }) => (
                  <input className="form-check-input" type="checkbox"
                         id="useImport"
                         onChange={() => onChange(!Member.useImport)}
                         checked={!!value}/>
                )}
              </EditMemberPropertyComponent>
              <label className="form-check-label" htmlFor="defaultCheck1">
                Allow import
              </label>
            </div>
          </div>
        </div>)}
    </Fragment>
  )
}

export { NESTEDOBJECT, NestedObject }
