import React, { useState, useEffect, memo, Fragment, useRef } from 'react'
import { Icon, Dropdown } from 'antd'

import { OBJECT, SELECTION, GLOBAL } from '../../config'
import KnacklyIcon from '../../components/Icon'
import { Engine } from 'yatte'
import { areEqual, VariableSizeList as List } from 'react-window'
import className from 'classnames'
import useDebounce from '../../utils/useDebounce'

const getIcon = member => {
  if (member.isTemplates) {
    return KnacklyIcon.fromTemplateType(member.type)
  } else if (member.isFormulas) {
    return KnacklyIcon.fromFormulaType(member.type)
  } else {
    if (member.type === GLOBAL) {
      return ['icon-global', KnacklyIcon.fromValueType(member.type)]
    }
    return KnacklyIcon.fromValueType(member.type)
  }
}

function useListOptions (activeType, types, filters, loadingType) {
  const [options, setOptions] = useState([])
  const getOptions = async () => {
    let Members = []

    const fillMembers = async (
      def, parentPath = '', parentItems = [], maxLevels = 5) => {
      if (maxLevels === 0) {
        return
      }
      if (def.neededLoading) {
        def = await loadingType(def._id)
      }
      if ((filters.isVariables || filters.isGlobal) && def.properties) {
        await Promise.all(def.properties.map(async member => {
          if (!filters.isGlobal && member.type === GLOBAL) {
            return
          }
          let items = [
            ...parentItems, {
              name: member.name,
              icon: getIcon(member),
            }]
          const path = parentPath ? parentPath + '.' + member.name : member.name

          Members.push({
            keySearch: member.name.toUpperCase(),
            path,
            items,
          })

          if (member.type === OBJECT || member.type === GLOBAL) {
            let _def = types.find(
              type => type._id === member.typeDef || type.name ===
                member.typeName)
            if (_def) {
              await fillMembers(_def, path, items, maxLevels - 1)
              return
            }
          }

          if (member.type === SELECTION) {
            if (!member.options) {
              return
            }

            if (member.typeOfVariable === 'anOptionListedBelow' ||
              member.typeOfVariable === 'userData') {

              try {
                const expr = Engine.compileExpr(member.options)
                if (expr.ast.type === Engine.AST.CallExpression) {
                  if (expr.ast.callee.type === Engine.AST.Identifier) {
                    const queryName = expr.ast.callee.name
                    const findTypeByName = typeName => types.find(
                      t => t.name === typeName)
                    let query = findTypeByName(queryName)
                    if (query) {
                      if (query.extModelName) {
                        let _def = findTypeByName(query.extModelName)
                        if (_def) {
                          await fillMembers(_def, path, items, maxLevels - 1)
                          return
                        }
                      }
                    }
                  }
                }
                if (expr.ast.type === Engine.AST.Identifier) {
                  const Formula = activeType.formulas.find(
                    formula => formula.name === expr.ast.name)
                  if (Formula) {
                    if (Formula.type === OBJECT) {
                      let _def = types.find(type => type.name === Formula.ref)
                      if (_def) {
                        await fillMembers(_def, path, items, maxLevels - 1)
                        return
                      }
                    }
                  }
                }
                const resultExpression = expr()
                if (resultExpression) {
                  Object.keys(
                    resultExpression.reduce((
                        previousValue,
                        currentValue) => ({ ...previousValue, ...currentValue }),
                      {}) ||
                    {}).forEach(variable => {
                    Members.push({
                      keySearch: variable.toUpperCase(),
                      path: path + '.' + variable,
                      items: [
                        ...items, {
                          name: variable,
                          icon: 'icon-font',
                        }],
                    })
                  })
                  return
                }
              } catch (e) {
                return []
              }
            }

            if (member.typeOfVariable === 'aTable') {
              let defName = ''
              const expr = Engine.compileExpr(member.options)
              if (expr.ast.type === Engine.AST.CallExpression) {
                if (expr.ast.callee.type === Engine.AST.Identifier) {
                  defName = expr.ast.callee.name
                }
              }
              if (!defName) {
                defName = member.options
              }
              let _def = types.find(t => t.name === defName)
              if (_def) {
                if (_def.extModelName) {
                  const extDef = types.find(t => t.name === _def.extModelName)
                  if (extDef) {
                    _def = extDef
                  }
                }
                await fillMembers(_def, path, items, maxLevels - 1)
                return
              }
            }
          }
        }))
      }

      if (filters.isFormulas && def.formulas) {
        await Promise.all(def.formulas.map(async member => {
          let items = [
            ...parentItems, {
              name: member.name,
              icon: getIcon(member),
            }]

          const nextPath = parentPath
            ? parentPath + '.' + member.name
            : member.name

          Members.push({
            keySearch: member.name.toUpperCase(),
            path: nextPath,
            items,
          })
          if (member.type === OBJECT) {
            let _def = types.find(type => type.name === member.ref)
            if (_def) {
              await fillMembers(_def, nextPath, items, maxLevels - 1)
              return
            }
          }
        }))
      }

      if (filters.isTemplates && def.templates) {
        def.templates.forEach(member => {
          let items = [
            ...parentItems, {
              name: member.name,
              icon: getIcon(member),
            }]

          Members.push({
            keySearch: member.name.toUpperCase(),
            path: parentPath ? parentPath + '.' + member.name : member.name,
            items,
          })
        })
      }
    }
    let defaultMexLevels = 5
    if (activeType && activeType.properties && activeType.properties.length) {
      if (activeType.properties.length > 100) {
        defaultMexLevels = 2
      }
    }
    await fillMembers(activeType,
      '',
      [],
      defaultMexLevels)
    setOptions(Members)
    return
  }

  useEffect(() => {
    getOptions()
  }, [
    activeType.name,
    types.length,
    filters.isVariables,
    filters.isTemplates,
    filters.isFormulas,
    filters.isGlobal])

  return [options]
}

const OptionComponent = memo(({
  index,
  style,
  data,
}) => {

  const {
    onActive,
    options,
    goTo,
  } = data
  const option = options[index]

  return (
    <div style={style}
         onClick={() => onActive(option.path)}>
      <div className={className('cursorPointer search-option',
        { 'search-option-active': goTo === option.path })}>
        <div className={'search-option-label'}>
          <div className={'search-option-start'}>
            {option.items.map(
              (item, index, items) => items.length - 1 === index ? null : (
                <Fragment>{index > 0 &&
                  (<KnacklyIcon type={'icon-return'} className={'align-middle'}
                                style={{
                                  marginTop: -3,
                                  transform: 'rotate(180deg)',
                                  margin: '0 4px',
                                }}/>)}
                  {Array.isArray(item.icon) && (<Fragment>
                    <KnacklyIcon
                      className={'align-middle'}
                      type={item.icon[0]}
                      style={{
                        marginRight: 4,
                        marginTop: -3,
                      }}
                    />
                    <KnacklyIcon
                      className={'align-middle'}
                      type={item.icon[1]}
                      style={{
                        marginRight: 4,
                        marginTop: -3,
                      }}
                    />
                  </Fragment>)}
                  {!Array.isArray(item.icon) && (<KnacklyIcon
                    className={'align-middle'}
                    type={item.icon}
                    style={{
                      marginRight: 4,
                      marginTop: -3,
                    }}
                  />)
                  } {item.name}
                </Fragment>))}
          </div>
          <div className={'search-option-end'}>
            {option.items.length > 1 &&
              (<KnacklyIcon type={'icon-return'} className={'align-middle'}
                            style={{
                              marginTop: -3,
                              transform: 'rotate(180deg)',
                              margin: '0 4px',
                            }}/>)}

            {Array.isArray(option.items[option.items.length - 1].icon) &&
              (<Fragment>
                <KnacklyIcon
                  className={'align-middle'}
                  type={option.items[option.items.length - 1].icon[0]}
                  style={{
                    marginRight: 4,
                    marginTop: -3,
                  }}
                />
                <KnacklyIcon
                  className={'align-middle'}
                  type={option.items[option.items.length - 1].icon[1]}
                  style={{
                    marginRight: 4,
                    marginTop: -3,
                  }}
                />
              </Fragment>)}
            {!Array.isArray(option.items[option.items.length - 1].icon) &&
              (<KnacklyIcon
                className={'align-middle'}
                type={option.items[option.items.length - 1].icon}
                style={{
                  marginRight: 4,
                  marginTop: -3,
                }}
              />)
            }
            {option.items[option.items.length - 1].name}
          </div>
        </div>
      </div>
    </div>)
}, areEqual)

function SearchOptionsList ({
  activeType,
  types,
  isVariables,
  isTemplates,
  isFormulas,
  isGlobal,
  loadingType,
  goTo,
  onGoTo,
}) {
  const [visible, setVisible] = useState(false)
  const [options, setOptions] = useState([])
  const refList = useRef(null)

  const [list] = useListOptions(activeType, types, {
    isVariables,
    isTemplates,
    isFormulas,
    isGlobal,
  }, loadingType)

  const [value, setValue] = useState('')
  const [selectedValue, setSelectedValue] = useState(false)

  const debouncedValue = useDebounce(value, 400)

  const onChangeInput = (event) => {
    setSelectedValue(false)
    setValue(event.target.value)
  }

  const onActive = (path) => {
    setVisible(false)
    setSelectedValue(true)
    onGoTo(path)
  }
  useEffect(() => {
    if (debouncedValue) {
      if (!selectedValue) {
        Searching(debouncedValue)
      }
    } else {
      if (debouncedValue === '' && visible) {
        setVisible(false)
      }
    }
  }, [debouncedValue, selectedValue, visible])

  useEffect(() => {
    if (visible && goTo) {
      if (refList.current) {
        if (options.length) {
          const optionIndex = options.findIndex(member => member.path === goTo)
          if (optionIndex) {
            refList.current.scrollToItem(optionIndex, 'center')
          }
        }
      }
    }
  }, [goTo, visible])

  const Searching = async (value) => {
    setVisible(true)
    setSelectedValue(false)
    value = value.toUpperCase()
    setOptions(list.filter(member => member.keySearch.includes(value)))
  }
  const ListHeight = options.length * 35 > 350 ? 350 : options.length * 35

  const ListOptions =
    (<div style={{
      height: ListHeight,
      borderRadius: '0.25rem',
      background: '#FFFFFF',
    }} className={'shadow-lg'}>
      <List
        ref={refList}
        height={ListHeight}
        itemCount={options.length}
        itemSize={() => 35}
        itemData={{
          options,
          onActive,
          goTo,
        }}
      >
        {OptionComponent}
      </List>
    </div>)

  const onClose = () => {
    setVisible(false)
    setSelectedValue(false)
    setOptions(() => [])
    onGoTo('')
    setValue('')
  }
  const onFocus = () => {
    if (!visible && value) {
      if (options.length) {
        setSelectedValue(false)
        setVisible(true)
      }
    }
  }
  return (
    <Dropdown overlay={ListOptions} visible={visible && !selectedValue}>
      <div className="input-group mb-2">
        <input type="text"
               className="form-control rounded-0"
               autoComplete="off"
               autoCorrect="off"
               autoCapitalize="none"
               placeholder="Search..."
               value={value}
               onFocus={onFocus}
               onChange={onChangeInput}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary dart-btn rounded-0 w-100 h-100"
            type="button"
            onClick={value ? onClose : null}
          >
            {value ? <Icon
              className={'align-middle'}
              type="close"/> : <Icon
              className={'align-middle'}
              type="search"/>}

          </button>
        </div>
      </div>
    </Dropdown>
  )
}

export default SearchOptionsList
