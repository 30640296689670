const Color = require('color')

export default class ColorTable {
  constructor (accent = '#067CA4', back = '#CDD1D3', menu = '#FFFFFF') {
    // core colors
    const accentColor = Color(accent)
    const backColor = Color(back)
    const menuColor = Color(menu)
    // derived colors
    const listSelectionColor = GetLightColor(accentColor)
    const widgetColor = accentColor.lightness(accentColor.lightness() * 0.5)
    const backLightColor = GetLightColor(backColor)
    const backDarkColor = backColor.lightness(backColor.lightness() * 0.75)
    const menuSelectionColor = accentColor
    const accent2Color = accentColor.rotate(+125)
    const accent3Color = accentColor.rotate(-55)
    const complementColor = accentColor.rotate(180)

    /* ===== exported colors for use in theming HTML content ===== */

    // fixed colors (not modifiable for now, but maybe in the future)
    this.plain = '#FFFFFF'
    this.text = '#4F4F4F'
    this.textD = '#707070'
    this.textLight = '#595959'
    this.textPlaceholder = '#C0C0C0'
    this.border = '#CED4DA'
    this.hoverBorder = '#80BDFF'
    this.backBorder = '#343A40'
    // accent-derived colors
    this.accent1 = this.accent = accent
    this.accent2 = accent2Color.hex()
    this.accent3 = accent3Color.hex()
    this.complement = complementColor.hex()
    this.listSelection = listSelectionColor.hex()
    this.listSelectionText = GetTextColor(listSelectionColor).hex()
    this.widget = widgetColor.hex()
    this.widgetText = GetTextColor(widgetColor).hex()
    this.widgetHover = GetHoverColor(widgetColor).hex()
    this.menuSelection = menuSelectionColor.hex()
    this.mapSelection = menuSelectionColor.lighten(0.6).hex()
    this.menuSelectionText = GetTextColor(menuSelectionColor).hex()
    this.textHeading = accentColor.darken(0.25).hex()
    this.textHeadingDark = accentColor.darken(0.5).hex()
    // back-derived colors
    this.back = back
    this.backText = GetTextColor(backColor).hex()
    this.backTextD = GetDisabledTextColor(backColor).hex()
    this.backDark = backDarkColor.hex()
    this.backDarkText = GetTextColor(backDarkColor).hex()
    this.backDarkTextD = GetDisabledTextColor(backDarkColor).hex()
    this.backLight = backLightColor.hex()
    this.backLightText = GetTextColor(backLightColor).hex()
    this.backLightTextD = GetDisabledTextColor(backLightColor).hex()
    this.backLightHover = GetHoverColor(backLightColor).hex()
    // menu-derived colors
    this.menu = menu
    this.menuText = GetTextColor(menuColor).hex()
    this.menuTextD = GetDisabledTextColor(menuColor).hex()
    this.menuHover = GetHoverColor(menuColor).hex()
  }

  // lighten (color, percent) {
  //   return Color(color).lighten(percent).hex()
  // }

  // darken (color, percent) {
  //   return Color(color).darken(percent).hex()
  // }
}

const GetTextColor = (baseColor) => FindContrastingShade(baseColor, 10)
const GetDisabledTextColor = (baseColor) => FindContrastingShade(baseColor, 3)

const GetHoverColor = (baseColor) => (
  baseColor.isDark()
    ? baseColor.lighten(0.2).saturate(0.2)
    : baseColor.darken(0.1).saturate(0.1))

const GetLightColor = (baseColor) => {
  let result = baseColor.lighten(0.2)
  if (result.lightness() === 100) {
    result = baseColor.darken(0.07)
  }
  return result
}

const FindContrastingShade = (baseColor, targetContrast = 10, maxIter = 5) => {
  const reversed = baseColor.isDark()
  const color = reversed ? Color('#FFFFFF') : Color('#000000') // max contrast
  let contrast = baseColor.contrast(color)
  if (contrast <= targetContrast) return color // targetContrast unreachable, give up now

  let ceiling, floor
  if (reversed) {
    ceiling = color
    floor = baseColor
  } else {
    ceiling = baseColor
    floor = color
  }
  let mid = ceiling.mix(floor)
  contrast = baseColor.contrast(mid)
  let iter = 0
  while (targetContrast !== contrast && iter++ < maxIter) {
    if (reversed) {
      floor = (contrast < targetContrast) ? mid : floor
      ceiling = (contrast < targetContrast) ? ceiling : mid
    } else { // !reversed
      floor = (contrast < targetContrast) ? floor : mid
      ceiling = (contrast < targetContrast) ? mid : ceiling
    }
    mid = ceiling.mix(floor)
    contrast = baseColor.contrast(mid)
  }
  return mid
}
