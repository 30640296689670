import React from 'react'
import { Modal } from 'antd'
import { ReactComponent as Note } from '../../../images/icons/sticky-note.svg'
import { ReactComponent as Eye } from '../../../images/icons/eye.svg'
import { ReactComponent as Question } from '../../../images/icons/question-circle.svg'
import { ReactComponent as ListOl } from '../../../images/icons/list-ol.svg'

const ModalInfo = ({ isVisible, onClose }) => {
  const styleForIcons = {
    width: '1.0rem',
    marginBottom: 1,
  }

  return (
    <Modal
      title="Editing the Default Layout"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <button key="back" className={'btn btn-outline-secondary dart-btn-outline'} onClick={onClose}>OK</button>,
      ]}
    >
      <div className={'row'}>
        <div className={'col'}>
          <p className={'my-2'}>
            The <em>default layout</em> for a model contains a <strong>question cell</strong> for
            each variable in that model.
          </p>
          <ul>
            <li><em>Single questions</em> are indicated by <Question style={styleForIcons}/></li>
            <li><em>Question lists</em> are indicated by <ListOl style={styleForIcons}/></li>
          </ul>
          <p className={'my-2'}>
            The visibility of each question is linked to the relevance of its corresponding variable.
          </p>
          <p className={'my-2'}>
            You can add <strong>informational cells</strong> (indicated by <Note style={styleForIcons}/>)
            into the layout by dragging the rectangular example (below the help link) into the layout.
          </p>
          <p className={'my-2'}>
            Once added to the layout, visibility of these cells can be controlled using
            the <Eye style={styleForIcons}/> icon.
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default ModalInfo
