import React, { memo, useCallback, useRef } from 'react'
import { areEqual } from 'react-window'
import Row from './row'
import { InsertRow } from './insert'
import styled from 'styled-components'

const BoardDiv = styled.div`
  background-color: ${props => props.theme.colorTable.back} !important;
  color: ${props => props.theme.colorTable.text} !important;
  border-color: ${props => props.theme.colorTable.backBorder} !important;
`

const RowMemo = memo((props) => {
  let {
    row,
    index,
    addFromInsertRow,
    changeOffsetAndSpan,
    columns,
    previewRow,
    position,
    closePreviewRow,
    variables,
    scrolling,
    removeSelected,
    setSelected,
    changeContent,
    changeVisibility,
    removeCard,
    moveRowTo,
    countRows,
  } = props
  return (
    <Row
      key={'row' + index}
      rowIndex={index}
      row={row}
      countRows={countRows}
      add={addFromInsertRow}
      changeOffsetAndSpan={changeOffsetAndSpan}
      countColumns={columns}
      previewRow={previewRow}
      position={position}
      closePreviewRow={closePreviewRow}
      variables={variables}
      scrolling={scrolling}
      removeSelected={removeSelected}
      setSelected={setSelected}
      changeContent={changeContent}
      changeVisibility={changeVisibility}
      removeCard={removeCard}
      moveRowTo={moveRowTo}
    />
  )

}, areEqual)
const Board = ({
  rows,
  countRows,
  addFromInsertRow,
  changeOffsetAndSpan,
  columns,
  setSelected,
  removeSelected,
  previewRow,
  position,
  closePreviewRow,
  variables,
  changeContent,
  changeVisibility,
  removeCard,
  moveRowTo,
}) => {

  const refBoard = useRef(null)
  let isFlag = true
  const scrolling = useCallback((positioningElementByY) => {
    if (refBoard.current) {
      let {
        top,
        bottom,
      } = refBoard.current.getBoundingClientRect()
      if (positioningElementByY < top) {
        if (isFlag) {
          isFlag = false
          setTimeout(() => {
            refBoard.current.scrollTop = refBoard.current.scrollTop - 5
            isFlag = true
          }, 25)
        }
      }
      if (positioningElementByY > bottom) {
        if (isFlag) {
          isFlag = false
          setTimeout(() => {
            refBoard.current.scrollTop = refBoard.current.scrollTop + 5
            isFlag = true
          }, 25)
        }
      }
    }
  }, [refBoard])
  return (
    <div ref={refBoard} className={'d-flex flex-column overflow-auto rounded'}
         style={{
           height: '50vh',
         }}
         onMouseLeave={removeSelected}>
      <BoardDiv className={'w-100 d-flex flex-column rounded col '}
                style={{ flex: 1 }}>
        {rows.map((row, index) => <RowMemo
          index={index}
          row={row}
          countRows={countRows}
          addFromInsertRow={addFromInsertRow}
          changeOffsetAndSpan={changeOffsetAndSpan}
          columns={columns}
          previewRow={previewRow}
          position={position}
          closePreviewRow={closePreviewRow}
          variables={variables}
          scrolling={scrolling}
          removeSelected={removeSelected}
          setSelected={setSelected}
          changeContent={changeContent}
          changeVisibility={changeVisibility}
          removeCard={removeCard}
          moveRowTo={moveRowTo}
        />)}
        <InsertRow countColumn={columns} add={addFromInsertRow}
                   indexRow={rows.length}/>
      </BoardDiv>
    </div>
  )
}

export default Board

