import React, { useCallback, useEffect, useState } from 'react'

import update from 'immutability-helper'
import uuidv1 from 'uuid/v1'
import AddStaticBlock from './AddStaticBlock'
import { AddButton, MemberList, Search } from '../../../components'
import ModalInfo from './modalInfo'
import Board from './Board'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import {
  CurrentMembersByKeySelector,
  CurrentMemberSelector,
  CurrentTypeSelector,
  editMemberProperty,
  editTypeProperty,
  ElementsChangesAtom,
} from '../state'
import produce from 'immer'
import { ObjectID } from 'bson'
import { GLOBAL } from '../../../config'

const { getDefaultLayoutForType } = require('./getdefault')

const PREVIEWSTATIC = 'PreviewStatic'
const DEFAULT_COLUMNS = 1
const MAX_COLUMNS = 6

const Layouts = () => {
  const type = useRecoilValue(CurrentTypeSelector)
  const layout = useRecoilValue(CurrentMemberSelector)
  const variables = useRecoilValue(CurrentMembersByKeySelector('properties'))
  const typeName = useRecoilValue(editTypeProperty('name'))
  const columnCountFromState = useRecoilValue(editMemberProperty('columnCount'))
  const rowsFromState = useRecoilValue(editMemberProperty('rows'))
  const [contents, setContents] = useState([])
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState(DEFAULT_COLUMNS)
  const [count, setCount] = useState(
    new Array(MAX_COLUMNS).fill('').map((a, index) => index + 1))
  const [position, setPosition] = useState({
    position: -1,
    row: -1,
  })
  const [infoVisible, setInfoVisible] = useState(false)

  const onChangeRows = useRecoilCallback(({
    set,
    snapshot,
  }) => async (rows, count) => {
    const member = await snapshot.getPromise(CurrentMemberSelector)
    if (!member) {
      set(ElementsChangesAtom, (preElements) => {
        return produce(preElements, draft => {
          draft.push({
            _id: new ObjectID().toString(),
            action: 'add',
            kind: 'layout',
            parent: type._id,
            parentKind: type.kind,
            name: '(default)',
            columnCount: count,
            rows,
          })
        })
      })
      return
    }
    set(editMemberProperty('columnCount'), count)
    set(editMemberProperty('rows'), rows)
  }, [])

  const previewRow = useCallback((nextPosition, nextRow) => {
    if (columns === 1) {
      return
    }
    if (nextPosition !== position.position || nextRow !== position.row) {
      setPosition({
        position: nextPosition,
        row: nextRow,
      })
    }
  }, [columns, position])
  const closePreviewRow = useCallback(() => {
    if (position.position > -1 || position.row > -1) {
      setPosition({
        position: -1,
        row: -1,
      })
    }
  }, [position])
  useEffect(() => {

    if (layout && layout.name === '(default)') {
      if (columnCountFromState) {
        setColumns(columnCountFromState)
      }
      if (rowsFromState) {
        if (rowsFromState[0]) {
          let rows = rowsFromState
          const columns = rows.reduce((acc, val) => acc.concat(val), [])

          const questionColumns = columns.filter(
            column => column.class === 'question').map(column => column.content)

          variables.forEach(property => {
            if (property.type !== GLOBAL) {
              if (!questionColumns.includes(property.name)) {
                rows = [
                  ...rows, [
                    {
                      content: property.name,
                      class: 'question',
                      list: [],
                      expr: '',
                    }]]
              }
            }
          })
          const nextRows = rows.map(r => r.filter(c => {
            if (c.class === 'question') {
              return variables.find(p => p.name === c.content)
            }
            return true
          }).map(column => ({
            ...column,
            id: column.id ? column.id : uuidv1(),
          })))
          setRows(nextRows)
        } else {
          const layout = getDefaultLayoutForType({
            name: typeName,
            layouts: [
              {
                name: '(default)',
                columnCount: columnCountFromState || DEFAULT_COLUMNS,
                rows: rowsFromState || [],
              }],
            variables,
          }, DEFAULT_COLUMNS)
          if (layout) {
            if (layout.columnCount) {
              setColumns(layout.columnCount)
            }
            if (layout.rows) {
              if (layout.rows[0]) {
                const nextRows = layout.rows.map(row => row.map(column => ({
                  ...column,
                  id: uuidv1(),
                })))
                setRows(nextRows)
              } else {
                setRows([])
              }
            }
          }
        }
      }
    } else {
      const layout = getDefaultLayoutForType({
        name: typeName,
        layouts: [
          {
            name: '(default)',
            columnCount: columnCountFromState || DEFAULT_COLUMNS,
            rows: rowsFromState || [],
          }],
        variables,
      }, DEFAULT_COLUMNS)
      if (layout) {
        if (layout.columnCount) {
          setColumns(layout.columnCount)
        }
        if (layout.rows) {
          if (layout.rows[0]) {
            const nextRows = layout.rows.map(row => row.map(column => ({
              ...column,
              id: uuidv1(),
            })))
            setRows(nextRows)
          } else {
            setRows([])
          }
        }
      }
    }
  }, [typeName, rowsFromState, columnCountFromState])

  useEffect(() => {
    let maxIndex = -1
    rows.forEach(row => {
      let localIndex = -1
      row.forEach((c, index) => {
        if ('offset' in c) {
          if ('span' in c) {
            if (localIndex < c.offset + c.span) {
              localIndex = c.offset
            }
          }
        }
      })
      if (maxIndex < localIndex) {
        maxIndex = localIndex
      }
    })
    if (maxIndex !== (count.length - 1)) {
      setCount(() => {
        return new Array(MAX_COLUMNS).fill('').
          map((a, index) => index + 1).
          filter((i, index) => index >= maxIndex)
      })
    }
  }, [rows])

  const removeCard = useCallback((row, id) => {
    let thisRow = rows[row]
    if (!id) {
      return
    }
    thisRow = thisRow.filter(c => c.id !== id)
    let nextRows
    if (thisRow.length === 0) {
      nextRows = update(rows, {
        $splice: [[row, 1]],
      })
    } else {
      rows[row] = [...thisRow]
      nextRows = rows
    }
    nextRows = nextRows.filter(row => row.length > 0)
    onChangeRows(nextRows, columns)
  }, [rows])
  const clearPreview = useCallback(() => {
    const nextContents = contents.filter(
      content => content.class !== PREVIEWSTATIC)
    setContents([...nextContents])
  }, [contents])

  const changeContent = useCallback(({
    row,
    column,
    id,
  }, content) => {
    const thisRow = rows[row]
    const thisColumnIndex = thisRow.findIndex(c => c.id === id)
    if (thisColumnIndex > -1) {
      rows[row][thisColumnIndex].content = content
      onChangeRows(rows, columns)
    }
  }, [rows])

  const changeVisibility = ({
    row,
    id,
  }) => (key, val) => {
    const thisRow = rows[row]
    const thisColumnIndex = thisRow.findIndex(c => c.id === id)
    if (thisColumnIndex > -1) {
      thisRow[thisColumnIndex][key] = val
      rows[row] = [...thisRow]
      const nextRows = [...rows]
      onChangeRows(nextRows, columns)
    }
  }
  const addFromInsertRow = useCallback(({
    row,
    column,
    type,
  }, oldCard) => {
    const selectedRows = JSON.parse(JSON.stringify(rows)).
      filter(r => r.filter(c => c.isSelected).length).
      map(r => r.map(c => {
        delete c.isSelected
        return c
      }))
    if (oldCard) {
      if (oldCard.content.id !== 'add') {
        let oldRow = rows[oldCard.row]
        oldRow = oldRow.filter(c => c.id !== oldCard.content.id)
        rows[oldCard.row] = oldRow
      }
    }
    if (type === 'NEW_ROW') {
      if (column === 0) {
        let nextRows
        if (selectedRows.length) {
          nextRows = update([...rows], {
            $splice: [
              [
                row, 0, ...selectedRows]],
          })
          nextRows = nextRows.filter(r => !r.filter(c => c.isSelected).length)
        } else {
          nextRows = update(rows, {
            $splice: [
              [
                row, 0, [
                {
                  id: uuidv1(),
                  content: oldCard ? oldCard.content ? oldCard.content.content
                    ? oldCard.content.content
                    : '' : '' : '',
                  list: oldCard ? oldCard.content ? oldCard.content.list
                    ? oldCard.content.list
                    : [] : [] : [],
                  class: oldCard ? oldCard.content ? oldCard.content.class
                    ? oldCard.content.class
                    : 'static' : 'static' : 'static',
                  expr: oldCard ? oldCard.content ? oldCard.content.expr
                    ? oldCard.content.expr
                    : '' : '' : '',
                }]]],
          })
        }

        nextRows = nextRows.filter(row => row.length > 0)
        onChangeRows(nextRows, columns)
      } else {
        let thisRow = []
        if (oldCard) {

        }
        const newStatic = {
          id: uuidv1(),
          content: oldCard
            ? oldCard.content ? oldCard.content.content : ''
            : '',
          list: oldCard ? oldCard.content ? oldCard.content.list : [] : [],
          class: oldCard
            ? oldCard.content ? oldCard.content.class : 'static'
            : 'static',
          expr: oldCard ? oldCard.content ? oldCard.content.expr : '' : '',
          offset: column,
          span: 1,
        }
        thisRow = update(thisRow, {
          $splice: [[column, 0, newStatic]],
        })
        let nextRows = update(rows, {
          $splice: [
            [
              row, 0, thisRow]],
        })
        nextRows = nextRows.filter(row => row.length > 0)
        onChangeRows(nextRows, columns)
      }
    }
    if (type === 'INSERT_TO_ROW') {
      const newStatic = {
        id: uuidv1(),
        content: (oldCard && oldCard.content && oldCard.content.content) || '',
        list: (oldCard && oldCard.content && oldCard.content.list) || [],
        class: (oldCard && oldCard.content && oldCard.content.class) ||
          'static',
        expr: (oldCard && oldCard.content && oldCard.content.expr) || '',
        offset: column,
        span: 1,
      }
      let thisRow = rows[row]
      if (thisRow.length === 1) {
        if (thisRow[0]) {
          if (!thisRow[0].offset) {
            thisRow[0].offset = 0
            if (!thisRow[0].span) {
              thisRow[0].span = column
            }
          }
        }
      }
      thisRow = [...thisRow, newStatic]
      thisRow = thisRow.sort((preColumn, nextColumn) => {
        if (preColumn.offset < nextColumn.offset) {
          return -1
        }
        return 1
      })
      let nextRows
      if (selectedRows.length) {
        nextRows = update([...rows], {
          $splice: [
            [
              row, 0, ...selectedRows]],
        })
        nextRows = nextRows.filter(r => !r.filter(c => c.isSelected).length)
      } else {
        nextRows = update(rows, {
          $splice: [
            [
              row, 1, thisRow]],
        })
        nextRows = nextRows.filter(row => row.length > 0)
      }
      closePreviewRow()
      setPosition({
        position: -1,
        row: -1,
      })
      onChangeRows(nextRows, columns)
    }
  }, [columns, rows])

  const changeCountColumns = useCallback(event => {
    const newCount = parseInt(event.target.value)
    onChangeRows(rows, newCount)
  }, [count, rows])

  const changeOffsetAndSpan = useCallback(({
    offset,
    span,
  }, row, id) => {
    const thisRow = rows[row]
    const thisColumnIndex = thisRow.findIndex(c => c.id === id)
    if (thisColumnIndex > -1) {
      thisRow[thisColumnIndex].offset = offset
      if (span === 0) {
        delete thisRow[thisColumnIndex].span
        delete thisRow[thisColumnIndex].offset
      } else {
        thisRow[thisColumnIndex].span = span
      }
      rows[row] = [...thisRow]
      const nextRows = [...rows]
      onChangeRows(nextRows, columns)
    }
  }, [rows])

  const onCloseModal = useCallback(() => setInfoVisible(false), [infoVisible])

  const setSelected = useCallback((rowIndex, columnIndex) => {
    if (rows[rowIndex]) {
      if (rows[rowIndex][columnIndex]) {
        rows[rowIndex][columnIndex].isSelected = !rows[rowIndex][columnIndex].isSelected
        onChangeRows([...rows], columns)
      }
    }
  }, [rows])

  const removeSelected = useCallback(() => {
    const selectedRows = rows.filter(r => r.filter(c => c.isSelected).length)
    if (selectedRows.length) {
      onChangeRows(rows.map(row => row.map(column => {
        delete column.isSelected
        return column
      })), columns)
    }
  }, [rows])

  const moveRowTo = useCallback(
    ({
      action,
      positionByRow,
      currentCell,
      currentPosition,
      currentColumn,
    }) => {
      onChangeRows(produce(rows, draft => {
        if (currentPosition !== positionByRow) {
          const isDown = positionByRow > currentPosition

          const row = draft[currentPosition]
          if (row) {
            if (columns === 1) {
              if (isDown) {
                positionByRow--
              }

              draft.splice(currentPosition, 1)
              if (action === 'AboveRow') {
                draft.splice(positionByRow, 0, row)
                return
              }
              if (action === 'BelowRow') {
                draft.splice(positionByRow + 1, 0, row)
                return
              }
            }
            const columnIndex = row.findIndex(
              col => col.id === currentColumn.id)
            if (columnIndex !== -1) {
              const item = row[columnIndex]
              if (row.length === 1) {
                if (isDown) {
                  positionByRow--
                }
                draft.splice(currentPosition, 1)

                if (action === 'AboveRow') {
                  draft.splice(positionByRow, 0, [item])
                }
                if (action === 'BelowRow') {
                  draft.splice(positionByRow + 1, 0, [item])
                }
                if (action === 'OnRow') {

                  const current = draft[positionByRow]
                  const minefield = Array(columns).fill(0)

                  current.forEach(cell => {
                    minefield[cell.offset] = 1
                    if (cell.span) {
                      if (cell.span > 0) {
                        for (let i = cell.offset; i < cell.offset +
                        cell.span; i++) {
                          minefield[i] = 1
                        }
                      }
                    }
                  })

                  const offset = item.offset || 0
                  const span = item.span || 0

                  let check = false

                  for (let i = offset; i < offset + span; i++) {
                    if (minefield[i]) {
                      if (minefield[i] === 1) {
                        check = true
                      }
                    }
                  }

                  if (!check) {
                    draft[positionByRow].push(item)
                  } else {
                    draft.splice(positionByRow + 1, 0, [item])
                  }

                }

              } else {
                draft[currentPosition].splice(columnIndex, 1)
                if (action === 'AboveRow') {
                  draft.splice(positionByRow, 0, [item])
                }
                if (action === 'BelowRow') {
                  draft.splice(positionByRow + 1, 0, [item])
                }
                if (action === 'OnRow') {

                  const current = draft[positionByRow]
                  const minefield = Array(columns).fill(0)

                  current.forEach(cell => {
                    minefield[cell.offset] = 1
                    if (cell.span) {
                      if (cell.span > 0) {
                        for (let i = cell.offset; i < cell.offset +
                        cell.span; i++) {
                          minefield[i] = 1
                        }
                      }
                    }
                  })

                  const offset = item.offset || 0
                  const span = item.span || 0

                  let check = false

                  for (let i = offset; i < offset + span; i++) {
                    if (minefield[i]) {
                      if (minefield[i] === 1) {
                        check = true
                      }
                    }
                  }

                  if (!check) {
                    draft[positionByRow].push(item)
                  } else {
                    draft.splice(positionByRow + 1, 0, [item])
                  }
                }
              }
            }
          }
        }
      }))
    }, [rows])

  return (<div>
    <div className={'row'}>
      <div className="col-3">
        <Search onSubmit={() => null} disabled={true}/>
        <MemberList members={[
          {
            id: 1,
            name: `(${typeName})`,
            type: 'default',
          }]}
                    changeActive={() => null}
        />
        <AddButton id={'addVar'} label={'Add a custom layout'}
                   disabled={true}/>
      </div>
      <div className="col-9 px-3 py-2 knackly-plain">
        <div className={'row'}>
          <div className="col-9">
            <div className="form-group m-0 p-0">
              <label onClick={() => { setInfoVisible(true) }}
                     className={'cursorPointer'}>CLICK FOR
                HELP ON LAYOUTS</label>
              <AddStaticBlock clearPreview={clearPreview}/>
            </div>
          </div>
          <div className={'col-3'}>
            <div className="form-group m-0 p-0">
              <label>GRID COLUMNS</label>
              <select className={'form-control'} value={columns}
                      onChange={changeCountColumns}>
                {count.map(c => (<option key={'count-' + c}>{c}</option>))}
              </select>
            </div>
          </div>
        </div>
        <div className={'row pt-2'}>
          <div className={'col'}>
            <label>LAYOUT</label>
          </div>
        </div>
        <Board rows={rows}
               countRows={rows.length}
               addFromInsertRow={addFromInsertRow}
               changeOffsetAndSpan={changeOffsetAndSpan}
               columns={columns}
               activeTypeName={typeName || ''}
               previewRow={previewRow}
               position={position}
               setSelected={setSelected}
               removeSelected={removeSelected}
               closePreviewRow={closePreviewRow}
               variables={variables}
               changeContent={changeContent}
               changeVisibility={changeVisibility}
               moveRowTo={moveRowTo}
               removeCard={removeCard}
        />
      </div>
    </div>
    <ModalInfo isVisible={infoVisible} onClose={onCloseModal}/>
  </div>)
}

export default Layouts
