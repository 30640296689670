import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { ReactComponent as ArrowBack } from '../../images/icons/ArrowBack.svg'
import { Link } from 'react-router-dom'
import validateEmail from '../../utils/validateEmail'
import validatePassword from '../../utils/validatePassword'
import { toast } from 'react-toastify'
import Register from '../register'
import { URI } from '../../config'

const LabelSignIn = styled.h1`
  color: ${props => props.theme.colorTable.text}
`

const BlockLogo = styled.div`
  background-color: ${props => props.theme.colorTable.menu};
  color: ${props => props.theme.colorTable.menuText}
`
const ButtonLogin = styled.button`
  background: white !important;
  border-color: ${props => props.theme.colorTable.widget} !important;
  color: ${props => props.theme.colorTable.text} !important;
  font-weight: 500 !important;
  padding: 12px !important;
`
const ButtonLoginDark = styled.button`
  background: ${props => props.theme.colorTable.widget} !important;
  border-color: ${props => props.theme.colorTable.widget} !important;
  color: ${props => props.theme.colorTable.widgetText} !important;
  font-weight: 500 !important;
  padding: 12px !important;
`
const CustumLink = styled(Link)`
  color: ${props => props.theme.colorTable.text}
`
const LogonForExternal = ({
                            settings,
                            goBack,
                            login,
                            registration,
                          }) => {
  const [isNewUser, setIsNewUser] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)

  const submit = () => {
    let instanceEmail = email.toLowerCase()
    if (validateEmail(email) && password.length > 5) {
      setInvalidEmail(false)
      setInvalidPassword(false)
      login({
        email: instanceEmail,
        password,
      })
    } else {
      if (!validateEmail(email) && !validatePassword(password)) {
        toast.error(
          'The email or password does not match the user account, or the account does not exist. Please verify both the user name and password and try again.',
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          })
        setInvalidEmail(true)
        setInvalidPassword(true)
      } else {
        if (!validateEmail(email)) {
          toast.error(
            'The email does not match the user account, or the account does not exist. Please verify both the user name and password and try again.',
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
            })
          setInvalidEmail(true)
        } else {
          setInvalidEmail(false)
        }
        if (!validatePassword(password)) {
          toast.error(
            'The password does not match the user account, or the account does not exist. Please verify both the user name and password and try again.',
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
            })
          setInvalidPassword(true)
        } else {
          validatePassword(false)
        }
      }
    }
  }

  return (
    <Fragment>
      {settings.logo && <BlockLogo className={'w-100 text-center py-3'}>
        <img src={`${URI}/${window.tenancy}/` + settings.logo} style={{
          maxWidth: '50%',
          maxHeight: 100,
        }} alt={settings.label ? settings.label : settings.name} />
      </BlockLogo>}

      <div className={settings.logo ? 'pt-5' : ''}
           style={!settings.logo ? { paddingTop: '10%' } : {}}>
        <div className={'mx-auto signin text-center d-flex flex-column p-5 '}>
          <LabelSignIn
            className={'mb-5 font-weight-bold'}>{settings.label
            ? settings.label
            : settings.name}</LabelSignIn>
          <p className={'mb-5'}>Please sign in to access the {settings.label
            ? settings.label
            : settings.name}</p>
          <div className="form-group text-left">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio"
                     name="inlineRadioOptions" id={'RadioNewUser'}
                     checked={isNewUser} onChange={() => setIsNewUser(true)} />
              <label className="form-check-label text-uppercase"
                     htmlFor="RadioNewUser">New user</label>
            </div>
            <div className="form-check form-check-inline ml-3">
              <input className="form-check-input"
                     type="radio"
                     name="inlineRadioOptions"
                     id={'RadioReturningUser'}
                     checked={!isNewUser}
                     onChange={() => setIsNewUser(false)}
              />
              <label className="form-check-label text-uppercase"
                     htmlFor="RadioReturningUser">Returning user</label>
            </div>
          </div>
          {isNewUser ? (
            <Register registration={registration} goBack={goBack} />) : (
            <Fragment>
              <form className={'text-left'} onSubmit={event => {
                event.preventDefault()
                submit()
              }}>
                <div className="form-group">
                  <label htmlFor="emailForReturningUser">Email</label>
                  <input
                    className={classNames('form-control rounded-0',
                      { 'is-invalid': invalidEmail })}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    id={'emailForReturningUser'} type="email"
                    placeholder="Email" />
                </div>
                <div className="form-group mb-1">
                  <label htmlFor="PasswordForReturningUser">Password</label>
                  <input className={classNames('form-control rounded-0',
                    { 'is-invalid': invalidPassword })}
                         value={password}
                         id={'PasswordForReturningUser'}
                         onChange={e => setPassword(e.target.value)}
                         type={'password'}
                         placeholder="Password" />
                </div>
                <div className={'text-right w-100 mb-2'}>
                  <CustumLink to={`/${window.tenancy}/resetPassword`}>Forgot
                    your password?</CustumLink>
                </div>
                <input className={'position-absolute'} style={{
                  left: -2000,
                  zIndex: -1,
                }} type={'submit'} />
              </form>
              <div className={'row'}>
                {!window.knacklyAddons.no3p_auth_ext &&
                  (<div className={'col-4'}><ButtonLogin className={'my-2' +
                    ' btn-block d-flex rounded'}><ArrowBack
                    style={{ width: '1rem' }} /> BACK</ButtonLogin>
                  </div>)}
                <div className={!window.knacklyAddons.no3p_auth_ext
                  ? 'col-8'
                  : 'col-12'}><ButtonLoginDark
                  className={'my-2  btn-block rounded'}
                  onClick={() => submit()}>SIGN IN
                  TO {settings.label
                    ? settings.label
                    : settings.name} </ButtonLoginDark></div>
              </div>
            </Fragment>)}
        </div>
      </div>

    </Fragment>
  )
}

export default LogonForExternal
