import React, { Fragment, useState, useMemo } from 'react'
import { Modal } from 'antd'
import { AddButton, Input, RadioInputs, Select } from '../../../components'

import { validateTypeMember } from '../../../utils'
import {
  atom,
  selector,
  selectorFamily, useRecoilCallback,
  useRecoilState,
  useRecoilValue, useSetRecoilState,
} from 'recoil'
import produce from 'immer'
import _ from 'lodash'
import {
  CurrentMembersByKeySelector,
  CurrentTypeSelector,
  ElementsChangesAtom, getCurrentTab,
  TypesSelector,
} from '../state'
import { useHistory } from 'react-router-dom'
import {
  DATE,
  NUMBER,
  OBJECT,
  QUERY,
  TABLE,
  TEXT,
  TRUEFALSE,
} from '../../../config'
import { ObjectID } from 'bson'

const DEFAULT_NEW_FORMULA_STATE = {
  name: '',
  ref: '',
  isList: false,
  nameTouched: false,
  showErrors: false,
  type: 'text',
}

const NewFormulaState = atom({
  key: 'NewFormulaState',
  default: DEFAULT_NEW_FORMULA_STATE,
})

const EditorNewFormula = selectorFamily({
  key: 'EditorNewFormula',
  get: path => ({ get }) => {
    return _.get(get(NewFormulaState), path)
  },
  set: path => ({
                  set,
                  get,
                }, newValue) => {
    set(NewFormulaState, produce(get(NewFormulaState), draft => {
      _.set(draft, path, newValue)
      if (path === 'name') {
        _.set(draft, 'showErrors', true)
      }
    }))
  },
})

const NewFormulaErrors = selector({
  key: 'NewFormulaErrors',
  get: ({ get }) => {
    const {
      type,
      ref,
      name,
    } = get(NewFormulaState)

    const variables = get(CurrentMembersByKeySelector('properties'))
    const templates = get(CurrentMembersByKeySelector('templates'))
    const formulas = get(CurrentMembersByKeySelector('formulas'))

    return validateTypeMember('formula',
      type,
      ref,
      name,
      variables,
      templates,
      formulas)
  },
})
const ErrorAlert = () => {
  const errors = useRecoilValue(NewFormulaErrors)
  const nameTouched = useRecoilValue(EditorNewFormula('nameTouched'))
  const showErrors = useRecoilValue(EditorNewFormula('showErrors'))
  if (errors.name && showErrors && (nameTouched || errors.nameForce)) {
    return (<p className={'text-danger'}>
      {errors.name}
    </p>)
  }
  return null
}
const NameProperty = ({ visible }) => {
  const [name, setName] = useRecoilState(EditorNewFormula('name'))
  const setNameTouched = useSetRecoilState(EditorNewFormula('nameTouched'))
  return (<Input type={'text'} value={name}
                 onChange={setName}
                 label={'FORMULA NAME'}
                 id={'modalNewTypeName'}
                 onBlur={() => {if (visible) setNameTouched(true)}}/>)
}

const RefProperty = () => {
  const type = useRecoilValue(EditorNewFormula('type'))
  const [ref, setRef] = useRecoilState(EditorNewFormula('ref'))
  const types = useRecoilValue(TypesSelector)
  if (type === OBJECT) {
    return (<div className={'col-12'}>
      <Select id={'newObjectType'}
              label={'OBJECT TYPE'}
              values={[
                '',
                ...types.filter(t => t.type !== TABLE && t.type !== QUERY).map(t => t.name)]}
              value={ref}
              onChange={setRef}/>
    </div>)
  }
  return null
}

const TypeProperty = () => {
  const [type, setType] = useRecoilState(EditorNewFormula('type'))
  return (<Select values={[TEXT, NUMBER, DATE, TRUEFALSE, OBJECT]}
                  id={'newFormulaType'}
                  value={type}
                  onChange={setType}/>)
}

const SwitchIsListProperty = () => {
  const [isList, setIsList] = useRecoilState(EditorNewFormula('isList'))
  return (<RadioInputs label={'TYPE'}
                       values={['single', 'listOf']}
                       active={isList
                         ? 'listOf'
                         : 'single'}
                       handelChange={val => {
                         setIsList(val === 'listOf')
                       }}
                       name={'newFormulaIsList'}/>)
}

const ModalAdd = () => {
  let [visible, setVisible] = useState(false)
  const history = useHistory()

  const onOpen = () => setVisible(true)

  const onSubmit = useRecoilCallback(({
                                        snapshot,
                                        set,
                                      }) => async () => {
    const formula = await snapshot.getPromise(NewFormulaState)
    const errors = await snapshot.getPromise(NewFormulaErrors)
    const type = await snapshot.getPromise(CurrentTypeSelector)
    if (!errors.name && !errors.type) {
      set(ElementsChangesAtom, (preElements) => {
        return produce(preElements, draft => {
          draft.push({
            _id: new ObjectID().toString(),
            action: 'add',
            kind: 'formula',
            parent: type._id,
            parentKind: 'model',
            name: formula.name,
            isList: formula.isList,
            type: formula.type,
            ref: formula.ref,
          })
        })
      })

      history.push(
        `/${window.tenancy}/types/${type.name}/${getCurrentTab()}/${formula.name}`)

      onCancel()
    } else {
      set(EditorNewFormula('showErrors'), true)
    }
  }, [])

  const onCancel = useRecoilCallback(({ set }) => () => {
    setVisible(false)
    set(NewFormulaState, DEFAULT_NEW_FORMULA_STATE)
  }, [])

  const MemoAddButton = useMemo(() => (
      <AddButton id={'addFormula'} onClick={onOpen}
                 label={'Add a new formula'}/>),
    [])

  return (
    <Fragment>
      <Modal
        title="New Formula"
        visible={visible}
        onCancel={onCancel}
        maskClosable={false}
        closable={false}
        width={410}
        footer={[
          <button key="submit" className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }} onClick={onSubmit}>
            Ok
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  style={{ width: 125 }}
                  onClick={onCancel}>Cancel</button>,
        ]}
      >
        <form onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}>
          <div className={'row'}>
            <div className={'col-3'}>
              <SwitchIsListProperty/>
            </div>
            <div className={'col-6'}>
              <TypeProperty/>
            </div>
            <RefProperty/>
            <div className={'col-12'}>
              <NameProperty/>
              <ErrorAlert/>
            </div>
          </div>
        </form>
      </Modal>
      {MemoAddButton}
    </Fragment>
  )
}

export default ModalAdd
