import React from 'react'
import { Modal } from 'antd'
import axios from 'axios'

const ImportGlobal = ({
  catalogName,
  elementName,
  appName,
  visible,
  onCancel,
}) => {

  const onSubmit = async () => {
    try {
      await axios.post(
        `/api/collections/${catalogName}/globals/remove`,
        { elementName, appName })
    } catch (e) {
      console.error(e)
    } finally {
      onCancel()
    }
  }

  return (
    <Modal
      title={`Are you sure you want to permanently delete the \'${elementName}\' global data?`}
      visible={visible}
      onCancel={onCancel}
      closable={false}
      footer={[
        <button key="submit" className={'btn btn-secondary dart-btn'}
                onClick={onSubmit}>
          Ok
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                onClick={onCancel}>Cancel</button>,
      ]}
    >

    </Modal>
  )
}

export default ImportGlobal
