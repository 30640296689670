import React from 'react'
import { Menu } from 'antd'
import classNames from 'classnames'

const key = ({ index, handleKey, isKey }) => (<Menu>
  <Menu.Item disabled={!handleKey}
             onClick={() => handleKey(index)}>
    <button type="button"
            disabled={!handleKey}
            style={handleKey ? null : {cursor:'not-allowed'}}
            className={classNames(
              'btn',
              { 'btn-link': handleKey },
              'btn-sm',
              'ant-dropdown-menu-item',
            )}
    >{isKey ? 'Reset key' : 'Set key'}</button>
  </Menu.Item>
</Menu>)

export default key
