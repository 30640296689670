import React from 'react'
import PropTypes from 'prop-types'
import { NavigationBar } from '../'

function Container ({ children }) {
  return (
      <div className="App">
        <NavigationBar/>
        <div className="container-fluid mt-2 knackly-back">
          {children}
        </div>
      </div>
  )
}

Container.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Container