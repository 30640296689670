/* eslint-disable quote-props */
/* eslint-disable strict */
/* eslint no-use-before-define: 0 */
/* eslint-disable max-len */
import ace from 'brace'

ace.define('ace/mode/doc_comment_highlight_rules', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/text_highlight_rules'], function (acequire, exports, module) {
  'use strict'

  var oop = acequire('../lib/oop')
  var TextHighlightRules = acequire('./text_highlight_rules').TextHighlightRules

  var DocCommentHighlightRules = function () {
    this.$rules = {
      'start': [
        {
          token: 'comment.doc.tag',
          regex: '@[\\w\\d_]+' // TODO: fix email addresses
        },
        DocCommentHighlightRules.getTagRule(),
        {
          defaultToken: 'comment.doc',
          caseInsensitive: true
        }
      ]
    }
  }

  oop.inherits(DocCommentHighlightRules, TextHighlightRules)

  DocCommentHighlightRules.getTagRule = function (start) {
    return {
      token: 'comment.doc.tag.storage.type',
      regex: '\\b(?:TODO|FIXME|XXX|HACK)\\b'
    }
  }

  DocCommentHighlightRules.getStartRule = function (start) {
    return {
      token: 'comment.doc', // doc comment
      regex: '\\/\\*(?=\\*)',
      next: start
    }
  }

  DocCommentHighlightRules.getEndRule = function (start) {
    return {
      token: 'comment.doc', // closing comment
      regex: '\\*\\/',
      next: start
    }
  }

  exports.DocCommentHighlightRules = DocCommentHighlightRules
})

ace.define('ace/mode/expression_highlight_rules', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/doc_comment_highlight_rules', 'ace/mode/text_highlight_rules'], function (acequire, exports, module) {
  'use strict'

  var oop = acequire('../lib/oop')
  var DocCommentHighlightRules = acequire('./doc_comment_highlight_rules').DocCommentHighlightRules
  var TextHighlightRules = acequire('./text_highlight_rules').TextHighlightRules
  var identifierRe = '[a-zA-Z\\$_\u00a1-\uffff][a-zA-Z\\d\\$_\u00a1-\uffff]*'

  var ExpressionHighlightRules = function (options) {
    var keywordMapper = this.createKeywordMapper(
      {
        'variable.language':
          'isFinite|isNaN|parseFloat|parseInt|peek|appLink|' + // Non-constructor functions
          'math|date|finance|text|number|' + // Global objects
          'this', // Pseudo
        'keyword': 'in|instanceof|new|typeof',
        'constant.language': 'null|Infinity|NaN|undefined',
        'constant.language.boolean': 'true|false'
      },
      'identifier'
    )
    var kwBeforeRe = 'in|instanceof|typeof|void'

    var escapedRe = '\\\\(?:x[0-9a-fA-F]{2}|' + // hex
      'u[0-9a-fA-F]{4}|' + // unicode
      'u{[0-9a-fA-F]{1,6}}|' + // es6 unicode
      '[0-2][0-7]{0,2}|' + // oct
      '3[0-7][0-7]?|' + // oct
      '[4-7][0-7]?|' + // oct
      '.)'

    this.$rules = {
      'no_regex': [
        DocCommentHighlightRules.getStartRule('doc-start'),
        comments('no_regex'),
        {
          token: 'string',
          regex: '\'(?=.)',
          next: 'qstring'
        }, {
          token: 'string',
          regex: '"(?=.)',
          next: 'qqstring'
        }, {
          token: 'constant.numeric', // hexadecimal, octal and binary
          regex: /0(?:[xX][0-9a-fA-F]+|[oO][0-7]+|[bB][01]+)\b/
        }, {
          token: 'constant.numeric', // decimal integers and floats
          regex: /(?:\d\d*(?:\.\d*)?|\.\d+)(?:[eE][+-]?\d+\b)?/
        }, {
          token: 'keyword',
          regex: 'from(?=\\s*(\'|"))'
        }, {
          token: 'keyword',
          regex: '(?:' + kwBeforeRe + ')\\b',
          next: 'start'
        }, {
          token: keywordMapper,
          regex: identifierRe
        }, {
          token: 'punctuation.operator',
          regex: /[.](?![.])/,
          next: 'property'
        }, {
          token: 'keyword.operator',
          regex: /===|==|!=|!==|<=?|>=?|!|&&|\|\||[!%&*+\-~/^]/,
          next: 'start'
        }, {
          token: 'punctuation.operator',
          regex: /[?:,;.]/,
          next: 'start'
        }, {
          token: 'paren.lparen',
          regex: /[[({]/,
          next: 'start'
        }, {
          token: 'paren.rparen',
          regex: /[\])}]/
        }, {
          token: 'comment',
          regex: /^#!.*$/
        }
      ],
      property: [{
        token: 'text',
        regex: '\\s+'
      }, {
        token: 'punctuation.operator',
        regex: /[.](?![.])/
      }, {
        token: 'support.function',
        regex: /(s(?:h(?:ift|ow(?:Mod(?:elessDialog|alDialog)|Help))|croll(?:X|By(?:Pages|Lines)?|Y|To)?|t(?:op|rike)|i(?:n|zeToContent|debar|gnText)|ort|u(?:p|b(?:str(?:ing)?)?)|pli(?:ce|t)|e(?:nd|t(?:Re(?:sizable|questHeader)|M(?:i(?:nutes|lliseconds)|onth)|Seconds|Ho(?:tKeys|urs)|Year|Cursor|Time(?:out)?|Interval|ZOptions|Date|UTC(?:M(?:i(?:nutes|lliseconds)|onth)|Seconds|Hours|Date|FullYear)|FullYear|Active)|arch)|qrt|lice|avePreferences|mall)|h(?:ome|andleEvent)|navigate|c(?:har(?:CodeAt|At)|o(?:s|n(?:cat|textual|firm)|mpile)|eil|lear(?:Timeout|Interval)?|a(?:ptureEvents|ll)|reate(?:StyleSheet|Popup|EventObject))|t(?:o(?:GMTString|S(?:tring|ource)|U(?:TCString|pperCase)|Lo(?:caleString|werCase))|est|a(?:n|int(?:Enabled)?))|i(?:s(?:NaN|Finite)|ndexOf|talics)|d(?:isableExternalCapture|ump|etachEvent)|u(?:n(?:shift|taint|escape|watch)|pdateCommands)|j(?:oin|avaEnabled)|p(?:o(?:p|w)|ush|lugins.refresh|a(?:ddings|rse(?:Int|Float)?)|r(?:int|ompt|eference))|e(?:scape|nableExternalCapture|val|lementFromPoint|x(?:p|ec(?:Script|Command)?))|valueOf|UTC|queryCommand(?:State|Indeterm|Enabled|Value)|f(?:i(?:nd|le(?:ModifiedDate|Size|CreatedDate|UpdatedDate)|xed)|o(?:nt(?:size|color)|rward)|loor|romCharCode)|watch|l(?:ink|o(?:ad|g)|astIndexOf)|a(?:sin|nchor|cos|t(?:tachEvent|ob|an(?:2)?)|pply|lert|b(?:s|ort))|r(?:ou(?:nd|teEvents)|e(?:size(?:By|To)|calc|turnValue|place|verse|l(?:oad|ease(?:Capture|Events)))|andom)|g(?:o|et(?:ResponseHeader|M(?:i(?:nutes|lliseconds)|onth)|Se(?:conds|lection)|Hours|Year|Time(?:zoneOffset)?|Da(?:y|te)|UTC(?:M(?:i(?:nutes|lliseconds)|onth)|Seconds|Hours|Da(?:y|te)|FullYear)|FullYear|A(?:ttention|llResponseHeaders)))|m(?:in|ove(?:B(?:y|elow)|To(?:Absolute)?|Above)|ergeAttributes|a(?:tch|rgins|x))|b(?:toa|ig|o(?:ld|rderWidths)|link|ack))\b(?=\()/
      }, {
        token: 'identifier',
        regex: identifierRe
      }, {
        regex: '',
        token: 'empty',
        next: 'no_regex'
      }
      ],
      'start': [
        DocCommentHighlightRules.getStartRule('doc-start'),
        comments('start'),
        {
          token: 'string.regexp',
          regex: '\\/',
          next: 'regex'
        }, {
          token: 'text',
          regex: '\\s+|^$',
          next: 'start'
        }, {
          token: 'empty',
          regex: '',
          next: 'no_regex'
        }
      ],
      'regex': [
        {
          token: 'regexp.keyword.operator',
          regex: '\\\\(?:u[\\da-fA-F]{4}|x[\\da-fA-F]{2}|.)'
        }, {
          token: 'string.regexp',
          regex: '/[sxngimy]*',
          next: 'no_regex'
        }, {
          token: 'invalid',
          regex: /\{\d+\b,?\d*\}[+*]|[+*$^?][+*]|[$^][?]|\?{3,}/
        }, {
          token: 'constant.language.escape',
          regex: /\(\?[:=!]|\)|\{\d+\b,?\d*\}|[+*]\?|[()$^+*?.]/
        }, {
          token: 'constant.language.delimiter',
          regex: /\|/
        }, {
          token: 'constant.language.escape',
          regex: /\[\^?/,
          next: 'regex_character_class'
        }, {
          token: 'empty',
          regex: '$',
          next: 'no_regex'
        }, {
          defaultToken: 'string.regexp'
        }
      ],
      'regex_character_class': [
        {
          token: 'regexp.charclass.keyword.operator',
          regex: '\\\\(?:u[\\da-fA-F]{4}|x[\\da-fA-F]{2}|.)'
        }, {
          token: 'constant.language.escape',
          regex: ']',
          next: 'regex'
        }, {
          token: 'constant.language.escape',
          regex: '-'
        }, {
          token: 'empty',
          regex: '$',
          next: 'no_regex'
        }, {
          defaultToken: 'string.regexp.charachterclass'
        }
      ],
      'qqstring': [
        {
          token: 'constant.language.escape',
          regex: escapedRe
        }, {
          token: 'string',
          regex: '\\\\$',
          consumeLineEnd: true
        }, {
          token: 'string',
          regex: '"|$',
          next: 'no_regex'
        }, {
          defaultToken: 'string'
        }
      ],
      'qstring': [
        {
          token: 'constant.language.escape',
          regex: escapedRe
        }, {
          token: 'string',
          regex: '\\\\$',
          consumeLineEnd: true
        }, {
          token: 'string',
          regex: '\'|$',
          next: 'no_regex'
        }, {
          defaultToken: 'string'
        }
      ]
    }

    if (!options || !options.noES6) {
      this.$rules.no_regex.unshift({
        regex: '[{}]',
        onMatch: function (val, state, stack) {
          this.next = val === '{' ? this.nextState : ''
          if (val === '{' && stack.length) {
            stack.unshift('start', state)
          } else if (val === '}' && stack.length) {
            stack.shift()
            this.next = stack.shift()
            if (this.next.indexOf('string') !== -1 || this.next.indexOf('jsx') !== -1) {
              return 'paren.quasi.end'
            }
          }
          return val === '{' ? 'paren.lparen' : 'paren.rparen'
        },
        nextState: 'start'
      }, {
        token: 'string.quasi.start',
        regex: /`/,
        push: [{
          token: 'constant.language.escape',
          regex: escapedRe
        }, {
          token: 'paren.quasi.start',
          regex: /\${/,
          push: 'start'
        }, {
          token: 'string.quasi.end',
          regex: /`/,
          next: 'pop'
        }, {
          defaultToken: 'string.quasi'
        }]
      })
    }

    this.embedRules(DocCommentHighlightRules, 'doc-',
      [DocCommentHighlightRules.getEndRule('no_regex')])

    this.normalizeRules()
  }

  oop.inherits(ExpressionHighlightRules, TextHighlightRules)

  function comments (next) {
    return [
      {
        token: 'comment', // multi line comment
        regex: /\/\*/,
        next: [
          DocCommentHighlightRules.getTagRule(),
          { token: 'comment', regex: '\\*\\/', next: next || 'pop' },
          { defaultToken: 'comment', caseInsensitive: true }
        ]
      }, {
        token: 'comment',
        regex: '\\/\\/',
        next: [
          DocCommentHighlightRules.getTagRule(),
          { token: 'comment', regex: '$|^', next: next || 'pop' },
          { defaultToken: 'comment', caseInsensitive: true }
        ]
      }
    ]
  }

  exports.ExpressionHighlightRules = ExpressionHighlightRules
})

ace.define('ace/mode/limited_markdown_highlight_rules', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/lib/lang', 'ace/mode/text_highlight_rules'], function (acequire, exports, module) {
  'use strict'

  var oop = acequire('../lib/oop')
  var lang = acequire('../lib/lang')
  var TextHighlightRules = acequire('./text_highlight_rules').TextHighlightRules

  var escaped = function (ch) {
    return '(?:[^' + lang.escapeRegExp(ch) + '\\\\]|\\\\.)*'
  }

  var LimitedMarkdownHighlightRules = function () {
    TextHighlightRules.call(this)

    this.$rules['start'].unshift(
      {
        token: 'empty_line',
        regex: '^$',
        next: 'allowBlock'
      }, { // h1
        token: 'markup.heading.1',
        regex: '^=+(?=\\s*$)'
      }, { // h2
        token: 'markup.heading.2',
        regex: '^\\-+(?=\\s*$)'
      }, {
        token: function (value) {
          return 'markup.heading.' + value.length
        },
        regex: /^#{1,6}(?=\s*[^ #]|\s+#.)/,
        next: 'header'
      },
      { // Github style block
        token: 'support.function',
        regex: '^\\s*```\\s*\\S*(?:{.*?\\})?\\s*$',
        next: 'githubblock'
      }, { // block quote
        token: 'string.blockquote',
        regex: '^\\s*>\\s*(?:[*+-]|\\d+\\.)?\\s+',
        next: 'blockquote'
      }, { // HR * - _
        token: 'constant',
        regex: '^ {0,2}(?:(?: ?\\* ?){3,}|(?: ?\\- ?){3,}|(?: ?\\_ ?){3,})\\s*$',
        next: 'allowBlock'
      }, { // list
        token: 'markup.list',
        regex: '^\\s{0,3}(?:[*+-]|\\d+\\.)\\s+',
        next: 'listblock-start'
      }, {
        include: 'basic'
      })

    this.addRules({
      'basic': [{
        token: 'constant.language.escape',
        regex: /\\[\\`*_{}[\]()#+\-.!]/
      }, { // code span `
        token: 'support.function',
        regex: '(`+)(.*?[^`])(\\1)'
      }, { // reference
        token: ['text', 'constant', 'text', 'url', 'string', 'text'],
        regex: '^([ ]{0,3}\\[)([^\\]]+)(\\]:\\s*)([^ ]+)(\\s*(?:["][^"]+["])?(\\s*))$'
      }, { // link by reference
        token: ['text', 'string', 'text', 'constant', 'text'],
        regex: '(\\[)(' + escaped(']') + ')(\\]\\s*\\[)(' + escaped(']') + ')(\\])'
      }, { // link by url
        token: ['text', 'string', 'text', 'markup.underline', 'string', 'text'],
        regex: '(\\[)(' +                                        // [
          escaped(']') +                                    // link text
          ')(\\]\\()' +                                      // ](
          '((?:[^\\)\\s\\\\]|\\\\.|\\s(?=[^"]))*)' +        // href
          '(\\s*"' + escaped('"') + '"\\s*)?' +            // "title"
          '(\\))'                                           // )
      }, { // strong ** __
        token: 'string.strong',
        regex: '([*]{2}|[_]{2}(?=\\S))(.*?\\S[*_]*)(\\1)'
      }, { // emphasis * _
        token: 'string.emphasis',
        regex: '([*]|[_](?=\\S))(.*?\\S[*_]*)(\\1)'
      }, { //
        token: ['text', 'url', 'text'],
        regex: '(<)(' +
          '(?:https?|ftp|dict):[^\'">\\s]+' +
          '|' +
          '(?:mailto:)?[-.\\w]+\\@[-a-z0-9]+(?:\\.[-a-z0-9]+)*\\.[a-z]+' +
          ')(>)'
      }],
      'allowBlock': [
        { token: 'support.function', regex: '^ {4}.+', next: 'allowBlock' },
        { token: 'empty_line', regex: '^$', next: 'allowBlock' },
        { token: 'empty', regex: '', next: 'start' }
      ],

      'header': [{
        regex: '$',
        next: 'start'
      }, {
        include: 'basic'
      }, {
        defaultToken: 'heading'
      }],

      'listblock-start': [{
        token: 'support.variable',
        regex: /(?:\[[ x]\])?/,
        next: 'listblock'
      }],

      'listblock': [{ // Lists only escape on completely blank lines.
        token: 'empty_line',
        regex: '^$',
        next: 'start'
      }, { // list
        token: 'markup.list',
        regex: '^\\s{0,3}(?:[*+-]|\\d+\\.)\\s+',
        next: 'listblock-start'
      }, {
        include: 'basic', noEscape: true
      }, { // Github style block
        token: 'support.function',
        regex: '^\\s*```\\s*[a-zA-Z]*(?:{.*?\\})?\\s*$',
        next: 'githubblock'
      }, {
        defaultToken: 'list' // do not use markup.list to allow stling leading `*` differntly
      }],

      'blockquote': [{ // Blockquotes only escape on blank lines.
        token: 'empty_line',
        regex: '^\\s*$',
        next: 'start'
      }, { // block quote
        token: 'string.blockquote',
        regex: '^\\s*>\\s*(?:[*+-]|\\d+\\.)?\\s+',
        next: 'blockquote'
      }, {
        include: 'basic', noEscape: true
      }, {
        defaultToken: 'string.blockquote'
      }],

      'githubblock': [{
        token: 'support.function',
        regex: '^\\s*```',
        next: 'start'
      }, {
        defaultToken: 'support.function'
      }]
    })

    this.normalizeRules()
  }
  oop.inherits(LimitedMarkdownHighlightRules, TextHighlightRules)

  exports.LimitedMarkdownHighlightRules = LimitedMarkdownHighlightRules
})

ace.define('ace/mode/kexpr', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/text', 'ace/mode/expression_highlight_rules'], function (acequire, exports, module) {
  'use strict'

  var oop = acequire('../lib/oop')
  var TextMode = acequire('./text').Mode
  var ExpressionHighlightRules = acequire('./expression_highlight_rules').ExpressionHighlightRules

  var Mode = function () {
    this.HighlightRules = ExpressionHighlightRules
  }
  oop.inherits(Mode, TextMode);

  (function () {
    this.$id = 'ace/mode/kexpr'
  }).call(Mode.prototype)

  exports.Mode = Mode
})

ace.define('ace/mode/knackly', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/text', 'ace/mode/text_highlight_rules', 'ace/mode/limited_markdown_highlight_rules', 'ace/mode/expression_highlight_rules'], function (acequire, exports, module) {
  'use strict'

  var oop = acequire('../lib/oop')
  var TextMode = acequire('./text').Mode
  var TextHighlightRules = acequire('./text_highlight_rules').TextHighlightRules
  var LimitedMarkdownHighlightRules = acequire('./limited_markdown_highlight_rules').LimitedMarkdownHighlightRules
  var ExpressionHighlightRules = acequire('./expression_highlight_rules').ExpressionHighlightRules

  var IgnoredTextHighlightRules = function () {
    this.$rules = {
      'start': [{
        defaultToken: 'comment.doc',
        caseInsensitive: true
      }]
    }
  }
  oop.inherits(IgnoredTextHighlightRules, TextHighlightRules)

  var KnacklyHighlightRules = function () {
    this.$rules = new LimitedMarkdownHighlightRules().getRules()

    for (var i in this.$rules) {
      this.$rules[i].unshift(
        {
          token: 'keyword',
          regex: '\\{\\[(?:if\\b|\\?)',
          next: 'expr-start'
        }, {
          token: 'keyword',
          regex: '\\{\\[(?:elseif\\b|\\:\\?)',
          next: 'expr-start'
        }, {
          token: 'keyword',
          regex: '\\{\\[(?:else\\b|\\:)',
          next: 'ignore-start'
        }, {
          token: 'keyword',
          regex: '\\{\\[(?:endif\\b|\\/\\?)',
          next: 'ignore-start'
        }, {
          token: 'keyword.other',
          regex: '\\{\\[(?:list\\b|\\#)',
          next: 'expr-start'
        }, {
          token: 'keyword.other',
          regex: '\\{\\[(?:endlist\\b|\\/\\#)',
          next: 'ignore-start'
        }, {
          token: 'keyword.other',
          regex: '\\{\\[',
          next: 'expr-start'
        }
      )
    }
    this.embedRules(ExpressionHighlightRules, 'expr-', [
      {
        token: 'keyword.other',
        regex: ']}',
        next: 'start'
      }
    ])
    this.embedRules(IgnoredTextHighlightRules, 'ignore-', [
      {
        token: 'keyword.other',
        regex: ']}',
        next: 'start'
      }
    ])
  }
  oop.inherits(KnacklyHighlightRules, TextHighlightRules)

  var Mode = function () {
    this.HighlightRules = KnacklyHighlightRules
  }
  oop.inherits(Mode, TextMode);

  (function () {
    this.$id = 'ace/mode/knackly'
  }).call(Mode.prototype)

  exports.Mode = Mode
})
