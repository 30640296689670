import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import axios from 'axios'
import queryString from 'query-string'
import { ReactComponent as Microsoft } from '../../images/icons/microsoft.svg'
import styled from 'styled-components'

const SocialLogin = styled.button`
  background: white !important;
  border-color: ${props => props.theme.colorTable.widget} !important;
  color: ${props => props.theme.colorTable.text} !important;
  font-weight: 500 !important;
  padding: 12px !important;
`

const createLinkForLoginInternal = (type, internalEmail) => {
  return process.env.NODE_ENV === 'development'
    ? `http://localhost:5050/${window.tenancy}/api/auth/${type}?internalEmail=${internalEmail}`
    : `/${window.tenancy}/api/auth/${type}?internalEmail=${internalEmail}`
}

class Confirm extends Component {

  state = {
    token: '',
    isLoad: false,
    password: '',
    confirmPassword: '',
    invalid: false,
    type: 'default',
    internalEmail: '',
  }

  componentDidMount () {
    try {
      const getsParameters = queryString.parse(window.location.search)
      let token = getsParameters.t
      let type = getsParameters.type
      let internalEmail = getsParameters.internalEmail
      if (token) {
        console.log(token)
        this.setState({
          isLoad: true,
          token,
          type: type || 'default',
          internalEmail: internalEmail || '',
        })
      }
    } catch (e) {
      toast.error('Error: expected parameters not supplied', {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }

  }

  submit = async e => {
    e.preventDefault()
    let {
      password,
      confirmPassword,
      token,
    } = this.state
    if (password.length > 7 && confirmPassword.length > 7 && password === confirmPassword) {
      let { data } = await axios.post('/api/users/password', {
        token,
        password,
      })
      if (data) {
        const getsParameters = queryString.parse(window.location.search)
        this.props.login(data, getsParameters.backLink)
      }
    } else {
      if (password.length < 8) {
        toast.error('Choose a password that is 8 characters or longer', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      }
      if (password !== confirmPassword) {
        toast.error('Passwords did not match; try again', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      }
      this.setState({ invalid: true })
    }
  }
  setFields = key => e => this.setState({ [key]: e.target.value })

  render () {
    let {
      isLoad,
      invalid,
      password,
      confirmPassword,
      type,
      internalEmail,
    } = this.state
    if (!isLoad) {
      return null
    }
    console.log('link',createLinkForLoginInternal('microsoft', internalEmail) )
    return (
      <div className={'pt-5'}>
        <div className={'mx-auto signin text-center d-flex flex-column p-5 '}>
          {type === 'default' && (
            <Fragment>
              <h1 className={'mx-0 font-weight-bold'}>Create</h1>
              <h1 className={' mb-5 mx-0 font-weight-bold'}>your password</h1>
              <form onSubmit={this.submit}>
                <input className={classNames('form-control mb-3 bg-light rounded-0', { 'is-invalid': invalid })}
                       value={password}
                       onChange={this.setFields('password')} id={'password'} type="password"
                       placeholder="Password"/> <input
                className={classNames('form-control mb-5 bg-light rounded-0', { 'is-invalid': invalid })}
                value={confirmPassword}
                onChange={this.setFields('confirmPassword')} id={'password'} type="password"
                placeholder="Confirm Password"/>
                <button className={'btr-dark mt-2 rounded-pill'}>Save</button>
              </form>
            </Fragment>
          )}
          {type === 'microsoft' && (
            <Fragment>
              <h1 className={'mx-0 font-weight-bold'}>Welcome to Knackly</h1>
              <SocialLogin className={'mt-5 socialLogin rounded text-left'}
                           onClick={(event) => {
                             window.location.replace(createLinkForLoginInternal('microsoft', internalEmail))
                             event.stopPropagation()
                           }}
              ><Microsoft className={'iconSocial'}/>Sign in using Microsoft</SocialLogin>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(Confirm)
