import React from 'react'
import copyStr from '../../../utils/copyStrToClipboard'
import { toast } from 'react-toastify'
import Icon from '../../../components/Icon'

const copyStrToClipboard = (srt) => {
	copyStr(srt)
	toast.success('Variable reference copied to the clipboard', {
		position: toast.POSITION.TOP_CENTER,
		pauseOnFocusLoss: false,
		autoClose: 1500
	})
}

const DefaultActionComponent = ({ copyText }) => {
	return (
		<Icon type={'icon-copy'}
		      className={'align-middle pl-1'}
		      onClick={(event) => {
			      event.stopPropagation()
			      copyStrToClipboard(
				      `{[${copyText}]}`)
		      }
		      } />
	)
}

export default DefaultActionComponent
