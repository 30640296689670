import React, { Fragment, useEffect, useState } from 'react'
import { Input, Select } from '../../components'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import { Modal } from 'antd'

const ModalUser = ({
                     active,
                     user,
                     onCancel,
                     onSubmit,
                     listEmail,
                   }) => {
  let [name, setName] = useState('')
  let [mail, setMail] = useState('')
  let [errorMail, setErrorMail] = useState('')
  let [errorName, setErrorName] = useState('')
  let [role, setRole] = useState('User')
  useEffect(() => {
    if (user) {
      setName(user.name)
      setMail(user.mail)
      if (user.isAdmin) {
        setRole('Admin')
      } else {
        setRole('User')
      }
    }
  }, [user])
  let submit = () => {
    let checkEmail = isEmail(mail)
    let checkName = user.type === 'microsoft' ? false : isEmpty(name)
    let checkListEmail = true
    let isAdmin = role === 'Admin'
    if (checkEmail) {
      checkListEmail = user.type === 'microsoft' ? false : listEmail.filter(m => m !== user.mail).includes(mail)
    }

    if (!checkName && checkEmail && !checkListEmail) {
      onSubmit({
        name,
        mail,
        isAdmin,
      })
      setName('')
      setMail('')
      setRole('User')
      setErrorMail('')
    }
    if (!checkEmail) {
      setErrorMail('From is not a valid email')
    }
    if (checkListEmail) {
      setErrorMail('That email is taken. Try another.')
    }
    if (checkName) {
      setErrorName('That name is empty')
    }
  }
  return (
    <Modal
      title="Edit User"
      visible={active}
      onCancel={onCancel}
      closable={false}
      width={400}
      footer={[
        <button key="submit" className={'btn btn-secondary dart-btn'} style={{ width: 125 }} onClick={submit}>
          Ok
        </button>,
        <button key="back" className={'btn btn-outline-secondary dart-btn-outline'} style={{ width: 125 }}
                onClick={onCancel}>Cancel</button>,
      ]}
    >
      <form onSubmit={e => {
        e.preventDefault()
        submit()
      }}>
        <div className={'row'}>
          {user.type === 'default' && (
            <Fragment>
              <div className={'col-12'}>
                <Input type={'text'} value={name} onChange={setName} error={errorName} label={'Name'}
                       id={'modalNewUserName'}/>
              </div>
              <div className={'col-12'}>
                <Input type={'email'}
                       error={errorMail}
                       value={mail} onChange={val => setMail(val.replace(/\s+/g, ''))} label={'Email'}
                       id={'modalNewUserEmail'}/>
              </div>
            </Fragment>
          )}
          {!user.isExternal && (<div className={'col-12'}>
            <Select label={'Assign role'} value={role} onChange={setRole} values={['User', 'Admin']}/>
          </div>)}
        </div>
      </form>
    </Modal>
  )
}

export default ModalUser
