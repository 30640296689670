// eslint-disable-next-line
import React from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import {
  CurrentMemberSelector,
  CurrentTypeSelector,
  editMemberProperty, ElementsChangesAtom, getKind, getMemberKey,
} from './state'
import { produce } from 'immer'
import _ from 'lodash'

const EditMemberPropertyComponent = ({
                                       path,
                                       defaultValue,
                                       children,
                                     }) => {
  const value = useRecoilValue(editMemberProperty(path))
  const onChange = useRecoilCallback(
    ({ set, snapshot }) => async (nextValue) => {

      const member = await snapshot.getPromise(CurrentMemberSelector)
      const type = await snapshot.getPromise(CurrentTypeSelector)

      window.typesWereChanged = true

      set(ElementsChangesAtom, produce((draft) => {
        const currentElement = draft.findIndex(el => el._id === member._id)
        if (currentElement === -1) {
          if (path === 'typeOfVariable') {
            draft.push({
              action: 'update',
              _id: member._id,
              kind: getKind(getMemberKey()),
              parent: type._id,
              originalType: member.type,
              parentKind: type.kind,
              [path]: nextValue,
              options: '[]',
            })
            return
          }
          if (path === 'suggestion') {
            draft.push({
              action: 'update',
              _id: member._id,
              kind: getKind(getMemberKey()),
              parent: type._id,
              originalType: member.type,
              parentKind: type.kind,
              [path]: nextValue,
              _options: '[]',
            })
            return
          }
          draft.push({
            action: 'update',
            _id: member._id,
            kind: getKind(getMemberKey()),
            parent: type._id,
            originalType: member.type,
            parentKind: type.kind,
            [path]: nextValue,
          })
          return
        }
        _.set(draft[currentElement], path, nextValue)

        if (path === 'typeOfVariable') {
          _.set(draft[currentElement], 'options', '[]')
        }
        if (path === 'suggestion') {
          _.set(draft[currentElement], '_options', '[]')
        }
      }))
    }, [])
  return children({
    value: value ? value : defaultValue,
    onChange,
  })
}

export default EditMemberPropertyComponent
