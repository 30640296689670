import React, { useState } from 'react'
import { Modal } from 'antd'
import { Input } from '../../components'
import axios from 'axios'
import getWorkspaceURL from '../../config/workspaceURL'
import { matchPath } from 'react-router-dom'

const ClearDuplicates = ({
  visible,
  loadFirstItems,
  onClose,
}) => {
  const [step, setStep] = useState(0)
  const [count, setCount] = useState(0)
  const [key, setKey] = useState('')

  const onSubmitClear = async () => {
    try {
      if (key) {
        const match = matchPath(window.location.pathname, {
          path: '/:tenancy/:adapter',
          exact: true,
          strict: false,
        })
        const adapter = match.params.adapter

        let url = `${getWorkspaceURL()}/api/collections/${adapter}/duplicates`
        const { data } = await axios.post(url, { key })
        console.log('data', data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setKey('')
      loadFirstItems()
      onClose()
      setStep(0)
      setCount(0)
    }
  }

  const onSubmitMatch = async () => {
    try {
      if (key) {
        const match = matchPath(window.location.pathname, {
          path: '/:tenancy/:adapter',
          exact: true,
          strict: false,
        })
        const adapter = match.params.adapter

        let url = `${getWorkspaceURL()}/api/collections/${adapter}/duplicates/match`
        const { data } = await axios.post(url, { key })
        setCount(data.duplicates)
        setStep(1)
      }
    } catch (e) {
      console.error(e)
    } finally {

    }
  }

  const onCancel = () => {
    setStep(0)
    setKey('')
    setCount(0)
    onClose()
  }
  return (
    <Modal
      title="Duplicates"
      visible={visible}
      onCancel={onClose}
      closable={false}
      width={610}
      footer={step === 0 ? [
        <button key="search" className={'btn btn-secondary dart-btn'}
                style={{ width: 125 }}
                onClick={onSubmitMatch}
                disabled={!key}>
          Search
        </button>,
        <button key="cancel"
                className={'btn btn-outline-secondary dart-btn-outline'}
                style={{ width: 125 }}
                onClick={onCancel}>Cancel</button>,
      ] : count === 0 ? [
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                style={{ width: 125 }}
                onClick={() => setStep(0)}>Back</button>,
        <button key="cancel"
                className={'btn btn-outline-secondary dart-btn-outline'}
                style={{ width: 125 }}
                onClick={onCancel}>Cancel</button>] : [
        <button key="clear" className={'btn btn-secondary dart-btn'}
                style={{ width: 125 }}
                onClick={onSubmitClear}
                disabled={!key}>
          Clear
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                style={{ width: 125 }}
                onClick={() => setStep(0)}>Back</button>,
        <button key="cancel"
                className={'btn btn-outline-secondary dart-btn-outline'}
                style={{ width: 125 }}
                onClick={onCancel}>Cancel</button>,
      ]}
    >
      {step === 0 && (<form onSubmit={onSubmitMatch}>
        <Input type={'text'}
               label={'Key to identify duplicates'}
               value={key}
               onChange={value => setKey(value)}/>
      </form>)}
      {step === 1 && count === 0 &&
        (<p>No products were found matching by "{key}"</p>)}
      {step === 1 && count > 1 && (
        <p><strong>{count}</strong> records were found matching by "{key}"</p>)}
    </Modal>

  )
}

export default ClearDuplicates
