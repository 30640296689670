import React, { Fragment } from 'react'
import MappingItem from './mappingItem'
import { Engine } from 'yatte'
import { RemoveAstAstProperty, SetAstProperty, VerifyKeyProps } from './utils'
import { OBJECT } from '../../../config'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CurrentTypeSelector, editMemberProperty } from '../state'
import {
  DestModelSelector,
  ExprASTsSelector,
} from './mappingState'
import _ from 'lodash'

const Mapping = ({ showAll }) => {
  const [expression, setExpression] = useRecoilState(
    editMemberProperty('expression'))
  console.log('expression', expression)
  const sourceModel = useRecoilValue(CurrentTypeSelector)
  const destModel = useRecoilValue(DestModelSelector)
  const ExprASTs = useRecoilValue(ExprASTsSelector)
  console.log('ExprASTs', ExprASTs)
  const thisFuncName = useRecoilValue(editMemberProperty('name'))

  const sourceKey = (sourceModel && sourceModel.key) || 'id$'
  const destKey = (destModel && destModel.key) || 'id$'
  const destKeyType = (destModel && (destModel.key || destModel.key === '') &&
    destModel.keyType) || 'id'

  const destVariables = _.get(destModel, 'properties', [])

  let mappingRows = destVariables.map(varDef => ({
    name: varDef.name,
    typeName: varDef.type === OBJECT ? varDef.typeName : varDef.type,
    isList: varDef.isList,
    expression: ExprASTs[varDef.name] || null,
  }))

  if (showAll) {
    mappingRows.unshift({
      name: '_link',
      typeName: '',
      isList: false,
      expression: ExprASTs['_link'] || null,
    })
    mappingRows.unshift({
      name: 'id$',
      typeName: 'id',
      isList: false,
      expression: ExprASTs['id$'] || null,
    })
  }
  // keep leftovers
  for (const [key, ast] of Object.entries(ExprASTs)) {
    if (key !== '_link' && key !== 'id$') { // but don't show hidden keys in map view
      const alreadyExist = mappingRows.find(row => row.name === key)
      if (!alreadyExist) {
        mappingRows.push({
          name: key,
          typeName: '',
          isList: false,
          expression: ast,
        })
      }
    }
  }

  const serialize = ast => ast ? Engine.AST.serialize(ast, true) : ''

  console.log('mappingRows', mappingRows[0])
  return (<Fragment>
    <div className={'row'}>
      <div className="col-6"><p className={'pt-2'}>VARIABLE</p></div>
      <div className="col-6"><p className={'pt-2'}>EXPRESSION</p></div>
    </div>
    {mappingRows.map((row) => (
      <MappingItem name={row.name}
                   key={thisFuncName + '_' + row.name}
                   typeName={row.typeName}
                   isList={row.isList}
                   expression={serialize(row.expression)}
                   onChange={(nextValue, hasError) => {
                     const preValue = serialize(row.expression)

                     if (preValue === nextValue) {
                       return
                     }

                     try {
                       const nextExpression = Engine.compileExpr(
                         expression || `{${row.name}:1}`)
                       if (nextValue) {
                         const nextNodeExpression = Engine.compileExpr(
                           nextValue).ast

                         SetAstProperty(nextExpression.ast,
                           row.name,
                           nextNodeExpression)
                       } else {
                         RemoveAstAstProperty(nextExpression.ast, row.name)
                       }

                       // ensure required (but hidden) key mappings are present
                       VerifyKeyProps(nextExpression.ast,
                         sourceModel && sourceModel.name,
                         sourceKey,
                         destModel && destModel.name,
                         destKey,
                         destKeyType,
                         thisFuncName)

                       const nextExpressionSerialized = Engine.AST.serialize(
                         nextExpression.ast, true).toString()
                       setExpression(nextExpressionSerialized)
                       hasError('')
                     } catch (e) {
                       hasError(e.toString())
                     }
                   }
                   }/>))}
  </Fragment>)
}

export default Mapping
