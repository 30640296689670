import React, { useEffect, useState } from 'react'
import { Ace } from '../../../../components'
import { Tooltip } from 'antd'
import Icon from '../../../../components/Icon'
import changeErrorMessage from '../../Formulas/changeErrorMessage'
import classNames from 'classnames'
import LoadingType from '../../../../utils/loadingType'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { produce } from 'immer'
import { Engine } from 'yatte'
import { useRecoilValue } from 'recoil'
import { TypesSelector } from '../../state'

const Row = styled.div`
  background-color: ${props => props.theme.colorTable.plain};

  &:nth-child(even) {
    background-color: ${props => props.theme.colorTable.backLight};
  }

`

const RowMap = ({
                  name,
                  typeName,
                  isList,
                  key,
                  expression,
                  onChange,
                }) => {
  const [error, setError] = useState('')

  const hasError = nextError => {
    setError(changeErrorMessage(nextError))
  }

  useEffect(() => {
    try {
      Engine.compileExpr(expression || '{}')
      hasError('')
    } catch (e) {
      hasError(e.toString())
    }
  }, [expression])

  return (<Row className={'row'} key={name}>
    <div className="col-4">
      <div
        className={'d-flex align-items-center justify-content-between h-100'}>
        <span>{name}</span>
        <span>{typeName + (isList ? ' list' : '')}</span>
      </div>
    </div>
    <div className="col-8">
      <div className={classNames(
        'knackly-plain rounded-0 py-2 form-control TemplateWrapper my-1',
        { 'border-danger': error })}>
        <Ace mode={'kexpr'}
             key={key}
             value={expression}
             onChange={onChange}
             className={'border-0'}
             maxLines={Infinity}
             isExpression={true} />
      </div>
      {error && (
        <Tooltip title={error} key={'Tooltip' + name} placement={'bottom'}
                 overlayClassName={'warning-tooltip'}>
          <Icon type={'icon-exclamation-triangle'} style={{
            color: '#dc3545',
            fontSize: '1.2rem',
            verticalAlign: 0,
            left: 'calc(100% - 2.6rem)',
            top: '0.9rem',
            position: 'absolute',
          }} />
        </Tooltip>)}
    </div>
  </Row>)
}

const ProfileEditor = ({
                         defId,
                         profile,
                         onChange,
                       }) => {

  const {
    data: def,
    isLoading,
  } = useQuery('/api/types/' + defId, async () => await LoadingType(defId), {
    retry: false,
    refetchOnWindowFocus: false,
  })
  if (isLoading) {
    return null
  }
  const getExpression = (key) => {
    const value = profile.find(item => item.name === key)
    if (!value) {
      return ''
    }
    return value.expr || ''
  }
  if (def) {
    return (<div className={'col-12'}>
      <div className={'row'}>
        <div className="col-6"><p className={'pt-2'}>VARIABLE</p></div>
        <div className="col-6"><p className={'pt-2'}>EXPRESSION</p></div>
      </div>
      {def.properties && (def.properties.map(property => (<RowMap
        name={property.name}
        typeName={property.type}
        isList={property.isList}
        key={property.name}
        expression={getExpression(property.name)}
        onChange={(value, hasError) => {
          onChange(property.externalId, property.name, value, hasError)
        }}
      />)))}
    </div>)
  }
  return null
}

const NetDocuments = ({
                        def,
                        profile,
                        onChange,
                      }) => {

  const _onChange = (externalId, name, expr) => {
    onChange(produce(profile, draft => {
      const index = draft.findIndex(item => item.name === name)
      if (index > -1) {
        draft[index].expr = expr
      } else {
        draft.push({
          externalId,
          name,
          expr,
        })
      }
    }))
  }

  return (
    <div className={'col-12'}>
      {def && (<ProfileEditor defId={def}
                              profile={profile}
                              onChange={_onChange} />)}
    </div>
  )
}
const Provider = ({
                    def,
                    profile = '',
                    onChange,
                  }) => {

  const types = useRecoilValue(TypesSelector)
  let type = undefined
  if (types) {
    if (types.length) {
      type = types.find(type => type.name === profile)
    }
  }

  if (type) {
    return (<NetDocuments def={type._id}
                          profile={def ? def.profile ? def.profile : [] : []}
                          onChange={onChange('profile')} />)
  }
  return null
}
export default Provider
