import React from 'react'
import { Types } from './types'
import { useRecoilValue } from 'recoil'
import {
  CurrentMemberSelector,
} from '../state'

import NavigationMembers from '../navigationMembers'

const TemplatesComponent = () => {

  const CurrentMember = useRecoilValue(CurrentMemberSelector)

  return (<div className={'row'}>
      <NavigationMembers />
      <div className="col-9 px-3 py-2 knackly-plain overflow-auto"
           style={{ maxHeight: 'calc(100vh - 16rem)' }}>
        {CurrentMember && (<React.Suspense fallback={<div>Loading...</div>}>
          <Types />
        </React.Suspense>)}
      </div>
    </div>
  )
}

export default TemplatesComponent
