import React from 'react'
import classNames from 'classnames'
import { InferLabelFromName } from '../../utils'

const maskCollection = (collection) => {
  if (collection.myRecords) {
    return `/${window.tenancy}/${collection.name}?f=%7B"myrecords"%3A%7B%7D%7D`
  }
  return `/${window.tenancy}/` + collection.name
}

const Cart = ({ collection, onNextRoot, noLink, emphasis }) => {

  if (!collection) {
    return null
  }

  return (<div
    className={'d-inline font-weight-light text-uppercase'}
    key={collection.name}>
    {noLink ? (
      <span
        className={classNames('p-3', {
          activeMenuTop: emphasis,
        })}
      >{collection.label || InferLabelFromName(collection.name)}</span>
    ) : (
      <a
        className={classNames('p-3', {
          activeMenuTop: window.location.pathname === `/${window.tenancy}/` +
            collection.name,
        })}
        href={maskCollection(collection)}
        onClick={(event) => {
          event.preventDefault()
          onNextRoot(maskCollection(collection))
        }}>{collection.label || InferLabelFromName(collection.name)}</a>
    )}
  </div>)
}

export default Cart
