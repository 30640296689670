import axios from 'axios'

export default async token => {
  try {
    let { data } = await axios.post('/api/isExternalToken/', {
      token,
    })
    return data
  } catch (e) {
    return undefined
  }
}
