import React from 'react'
import styled from 'styled-components'
import MarkdownToJsx from './mdtojsx' // 'markdown-to-jsx'
import ReactPlayer from 'react-player'

const Accent1 = styled.span`
  color: ${props => props.theme.colorTable.accent1} !important;
`
const Accent2 = styled.span`
  color: ${props => props.theme.colorTable.accent2} !important;
`
const Accent3 = styled.span`
  color: ${props => props.theme.colorTable.accent3} !important;
`
const Complement = styled.span`
  color: ${props => props.theme.colorTable.complement} !important;
`
const Red = styled.span`
  color: #FF0000 !important;
`
const tag_whitelist = ['p', 'span', 'div', 'h1', 'h2', 'br']
const iframe_whitelist = ['youtube.com', 'youtu.be', 'vimeo.com']

const Markdown = (props) => {
  return (
    <MarkdownToJsx
      options={{
        disableParsingRawHTML: false, // we want this to be true so as to disable using arbitrary HTML
        // overrides: {
        //   player: { component: ResponsivePlayer },
        //   Player: { component: ResponsivePlayer }
        // },
        createElement: function (type, props, children) {
          const typelc = type && type.toLowerCase()
          const orig = React.createElement(type, props, children)
          if (tag_whitelist.includes(type)) return orig
          if (type === 'a') {
            return React.cloneElement(orig, { target: '_blank' })
          }
          if (type === 'img') {
            const newProps = { style: { maxWidth: '100%' } }
            Object.keys(props)
              .filter(n=>n.startsWith('on'))
              .forEach(n => newProps[n] = null)
            return React.cloneElement(orig, newProps)
          }
          if (type === 'center') {
            return children
              ? (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  {children}
                </div>
              )
              : null
          }
          if (type === 'accent') {
            if (props['1']) return <Accent1>{children}</Accent1>
            if (props['2']) return <Accent2>{children}</Accent2>
            if (props['3']) return <Accent3>{children}</Accent3>
            if (props['c']) return <Complement>{children}</Complement>
            if (props['red']) return <Red>{children}</Red>
            // else
            return <Accent1>{children}</Accent1>
          }
          if (typelc === 'player') {
            let { url, aspect } = props
            if (!aspect) {
              aspect = 1280 / 720
            } else if (aspect >= 100) {
              aspect /= 100
            }
            return (
              <div style={{ position: 'relative', paddingTop: `${100 / aspect}%` }}>
                <ReactPlayer
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  url={url}
                  light={true}
                  controls={true}
                  width='100%'
                  height='100%'
                />
              </div>
            )
          }
          if (typelc === 'body' || typelc === 'html') {
            return children
              ? (
                <React.Fragment>
                  {children}
                </React.Fragment>
              )
              : null
          }
          if (typelc === 'script') return null
          if (typelc === 'iframe') {
            if (props.src) {
              const url = new URL(props.src)
              // whitelist
              if (iframe_whitelist.includes(url.hostname)) {
                return orig
              }
            }
            return null
          }
          // else fallback
          return orig
        }
      }}
    >
      {props.children}
    </MarkdownToJsx>
  )
}

export default Markdown
