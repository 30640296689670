import React from 'react'

import { InferLabelFromName } from '../../utils'

function RadioInputs ({ label, name, values, active, handelChange, isListDisabled }) {
  return (
    <div className={'form-group'}>
      {label ? <label>{label}</label> : null}
      {values.map(val => {
        switch (typeof val) {
          case 'string':
            return (
              <div className="form-check" key={val}>
                <input className="form-check-input" type="radio" id={val}
                       value={val} name={name} checked={active === val} onChange={e => handelChange(e.target.value)}
                       disabled={isListDisabled && (val === 'listOf')}/>
                <label className="form-check-label" htmlFor={val}>
                  {InferLabelFromName(val)}
                </label>
              </div>
            )
          case 'object':
            return (
              <div className="form-check" key={val.value}>
                <input className="form-check-input" type="radio" id={val.value}
                       value={val.value} name={name} checked={active === val.value}
                       onChange={e => handelChange(e.target.value)}
                       disabled={isListDisabled && (val.value === 'listOf')}/>
                <label className="form-check-label" htmlFor={val.value}>
                  {val.formatting ? val.formatting() : val.label}
                </label>
              </div>
            )
          default:
            return null
        }
      })}
    </div>
  )
}

RadioInputs.defaultProps = {
  isListDisabled: false
}

export default RadioInputs
