/* eslint-disable quote-props */
const ESCAPE_CHAR = '\\'
const patternChars = {
  '9': { validate (char) { return /[0-9]/.test(char) } },
  'A': { validate (char) { return /[A-Za-z]/.test(char) } },
  'X': { validate (char) { return /\w/.test(char) } },
  'L': { validate (char) { return /[a-z]/.test(char) } },
  'U': { validate (char) { return /[A-Z]/.test(char) } },
}

function PatternHasError (pattern) {
  if (!pattern) return false
  if (typeof pattern !== 'string') return 'Pattern must be a text string.'
  if (pattern.slice(-1) === ESCAPE_CHAR) {
    if (pattern.length === 1 || pattern.slice(-2, -1) !== ESCAPE_CHAR) {
      return `Pattern ends with an unescaped ${ESCAPE_CHAR}.`
    }
  }
  let i = pattern.length
  while (i > 0) {
    i--
    if (pattern[i] in patternChars) {
      return false
    }
  }
  return `Pattern "${pattern}" does not contain any editable characters.`
}

module.exports = {
  patternChars,
  PatternHasError,
}
