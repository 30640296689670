import React, { Fragment } from 'react'
import moment from 'moment'
import Icon from '../../components/Icon'
import axios from 'axios'
import { toast } from 'react-toastify'

const Detail = ({
                  item,
                  onEdit,
                }) => {
  const onReset = async () => {
    /* let res = */
    await axios.post('/api/users/reset', { email: item.mail })
    toast.success(
      'Thank you. An email containing a link to reset the password has been sent.',
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      })
  }

  return (
    <div className="col-6">
      DETAILS
      <div className="my-2 border collectionItem knackly-plain">
        <div className="col-12 d-flex justify-content-between mt-3">
          <div>
            <h3>{item.name} {item.type === 'microsoft' && '(Microsoft)'}</h3>
          </div>
          <div onClick={() => onEdit()}>
            <Icon type="icon-pencil-alt" className={'animeteScale'}
                  style={{ fontSize: 22 }} />
          </div>
        </div>
        <div className="col-7">
          <div className="row pb-3">
            <div className="col-3 my-1">Email:</div>
            <div className="col-9 my-1">{item.mail}</div>
            <div className="col-3 my-1">Administrator:</div>
            <div className="col-9 my-1">{item.isAdmin ? 'Yes' : 'No'}</div>
            <div className="col-3 my-1">External:</div>
            <div className="col-9 my-1">{item.isExternal ? 'Yes' : 'No'}</div>
            <div className="col-3 my-1">Date created:</div>
            <div className="col-9 my-1">{moment(item.dateCreate).format('lll')}</div>
            {item.lastLogin && (<Fragment>
              <div className="col-3 my-1">Last login:</div>
              <div className="col-9 my-1">{moment(item.lastLogin).format('lll')}</div>
            </Fragment>)}
            <div className="col-6 my-1">
              <button className={'btn btn-secondary dart-btn mt-2 w-100'}
                      onClick={onReset}>RESET PASSWORD
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail
