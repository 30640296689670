import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import NewUser from './newUser'
import Login from './login'

const Links = ({
                 settings,
                 pushUser,
                 activeIsLogin,
               }) => {
  const [loading, setLoading] = useState(true)
  const [isNewUser, setNewUser] = useState(false)
  const [link, setLink] = useState({})
  const { IdLink } = useParams()
  const history = useHistory()
  useEffect(() => {
    const fetchLink = async () => {
      try {
        const { data } = await axios.get('/api/links/' + IdLink)
        console.log(data)
        if (data) {
          setLink(data.link)
          setNewUser(data.isNewUser)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    fetchLink()
  }, [IdLink])

  const onConfirm = async () => {
    setLoading(true)
    const { data: { item } } = await axios.get(`/api/collections/${link.catalogName}/items/${link.recordId}`)
    if (item) {
      activeIsLogin()
      history.push(`/${window.tenancy}/${link.catalogName}/${link.recordId}/${link.appName}?external`)
    }
  }
  if (loading) {
    return <div>Loading...</div>
  }
  if (link) {
    if (isNewUser) {
      return <NewUser settings={settings} pushUser={pushUser} email={link.userEmail} fullName={link.userName}
                      onConfirm={onConfirm}/>
    } else {
      return <Login settings={settings} pushUser={pushUser} email={link.userEmail} onConfirm={onConfirm}/>
    }
  } else {
    return <div>Sorry, the requested link could not be found.</div>
  }
}

export default Links
