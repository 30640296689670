import React, { useCallback, useState } from 'react'
import { Modal } from 'antd'
import { Input } from '../../../components'

const ModalPosition = ({ isModal, onCancel, onSubmit, span: thisSpan, offset: thisOffset, countColumns, row, id }) => {
  const [offset, setOffset] = useState(thisOffset)
  const [span, setSpan] = useState(thisSpan)
  const [error, setError] = useState('')

  const submit = useCallback((event) => {
    event.preventDefault()
    const nextOffset = parseInt(offset)
    const nextSpan = parseInt(span)

    if (isNaN(nextOffset)) {
      setError('Please provide the Offset')
      return
    }
    if (isNaN(nextSpan)) {
      setError('Please provide the Span')
      return
    }
    if (nextOffset < 0 || nextOffset > countColumns) {
      setError('Please change the Offset')
      return
    }
    console.log(nextSpan, countColumns - nextOffset)
    if (nextSpan < -1 || nextSpan > countColumns || nextSpan > (countColumns - nextOffset)) {
      setError('Please change the Span')
      return
    }
    console.log(row, row.length)
    if (nextSpan === 0 && row.length > 1) {
      setError('This position is busy')
      return
    }
    const columnsWithOutIt = row.filter(c => c.id !== id)
    const spaceThis = nextSpan + nextOffset
    let isBusy = false
    columnsWithOutIt.forEach(c => {
      if (c.offset >= nextOffset) {
        if (spaceThis >= c.offset + c.span) {
          isBusy = true
        }
      }
    })
    if (isBusy) {
      setError('This position is busy')
      isBusy = false
      return
    }
    onSubmit({
      offset: nextOffset,
      span: nextSpan,
    })

  }, [offset, span, error, thisOffset, thisSpan, row])

  return (
    <Modal
      title="Position"
      visible={isModal}
      onCancel={onCancel}
      footer={[
        <button key="submit" className={'btn btn-secondary dart-btn'} onClick={submit}>
          Ok
        </button>,
        <button key="back" className={'btn btn-outline-secondary dart-btn-outline'} onClick={onCancel}>Cancel</button>,
      ]}
    >
      <form onSubmit={submit}>
        <div className={'row'}>
          <div className={'col'}>
            <Input type={'number'} value={offset} onChange={setOffset} label={'Offset'}
                   id={'changeOffsetId'}/>
          </div>
          <div className={'col'}>
            <Input type={'number'} value={span} onChange={setSpan} label={'Span'}
                   id={'modalNewTypeName'}/>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            {error && <p className={'text-danger'}>{error}</p>}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ModalPosition
