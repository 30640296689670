/* eslint-env node, browser */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Ace } from '../../../../components'
import axios from 'axios'
import PdfUtils from '../../../../utils/pdfutil'
import moment from 'moment'
import classNames from 'classnames'

import { ReactComponent as Spinner } from '../../../../images/icons/spinner.svg'

import '../../../../components/Ace/mode/knackly'
import '../../../../components/Ace/theme/knackly'
import 'brace/ext/language_tools'
import 'brace/ext/searchbox'
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil'
import { CurrentTypeSelector, editMemberProperty, UserInfo } from '../../state'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'
import getWorkspaceURL from '../../../../config/workspaceURL'

const TableRow = styled.tr`
  background-color: ${props => props.theme.colorTable.plain};

  &:nth-child(even) {
    background-color: ${props => props.theme.colorTable.backLight};
  }
`

const InlineCheck = ({
  id,
  label,
  checked,
  onChange,
}) => {
  return (
    <span className="ml-3">
      <input id={id} type="checkbox" style={{ marginTop: '0.5rem' }}
             onChange={() => onChange(!checked)} checked={checked}/>
      <label className="form-check-label font-weight-normal ml-1"
             htmlFor={id}>{label}</label>
    </span>
  )
}

const PdfFormTemplate = () => {
  const [showFullName, setShowFullName] = useState(false)
  const {
    FlagsToText,
    FlattenFieldMap,
    MergeFieldMaps,
    ParseNameIndex,
    UpdateFieldMap,
  } = PdfUtils
  const [inProgress, setProgress] = useState(false)

  const UserName = useRecoilValue(UserInfo)
  const filename = useRecoilValue(editMemberProperty('filename'))
  const filesize = useRecoilValue(editMemberProperty('filesize'))
  const date = useRecoilValue(editMemberProperty('date'))
  const [fieldMap, setFieldMap] = useRecoilState(editMemberProperty('fieldMap'))
  const name = useRecoilValue(editMemberProperty('name'))
  const templateId = useRecoilValue(editMemberProperty('_id'))

  const type = useRecoilValue(CurrentTypeSelector)

  const setFile = useRecoilCallback(({ set }) => ({
    filename,
    filesize,
    filepath,
    user,
    date,
  }) => {
    set(editMemberProperty('filename'), filename)
    set(editMemberProperty('filesize'), filesize)
    set(editMemberProperty('filepath'), filepath)
    if (user) {
      set(editMemberProperty('user'), user)
    }
    set(editMemberProperty('date'), date)
  }, [])

  const uploadFile = async file => {
    const data = new FormData()
    data.append('file', file)
    const config = {
      baseURL: getWorkspaceURL(),
    }
    const {
      data: {
        versionId,
        ext,
      },
    } = await axios.post('/upload/' + type._id, data, config)

    const compile = () => axios.post(
      `/upload/${type._id}/compile`, {
        templateId,
        versionId,
        ext,
      }, config)

    const response = await compile()

    const retry = () => new Promise((resolve => {
      setTimeout(async () => {
        try {
          const response_ = await compile()
          if (response_.status === 202) {
            return resolve(await retry())
          }
          resolve(response_)
        } catch (e) {
          setProgress(false)
        }
      }, 1000)
    }))

    if (response.status === 202) {
      return await retry()
    }

    return response
  }

  const mappingChanged = (value, id) => {
    const newMap = UpdateFieldMap(fieldMap, id, value)
    setFieldMap(newMap)
  }

  const parseFullName = (fieldName) => {
    const idx = fieldName.lastIndexOf('.') + 1
    const name = fieldName.slice(idx)
    const parsed = ParseNameIndex(name) || { name }
    parsed.prefix = fieldName.slice(0, idx).replace(/\./g, '​.') // has ZWS before the period, to wrap if necessary
    return parsed
  }

  let Mappings = FlattenFieldMap(fieldMap)
  return (
    <div className={'col-12'}>
      <div className={'row'}>
        <div className={'col-6'}>
          <label>ASSEMBLED FILE NAME</label>
          <div
            className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>
            <EditMemberPropertyComponent path={'output'} defaultValue={''}>
              {({
                value,
                onChange,
              }) => (<Ace className={'border-0'} value={value || ''}
                          id={'templateText'}
                          placeholder={''}
                          onChange={onChange}
                          type={'text'}
                          maxLines={Infinity}/>)}</EditMemberPropertyComponent>
          </div>
        </div>
        <div className={'col-6'}>
          <div className={'form-group m-0'}>
            <label htmlFor={'fileInfo'}>TEMPLATE FILE</label>
            <div>
              <label id={'fileInfo'} className={'labelForFile m-0 text-break'}>
                {filename || null}
              </label>
              <input id={'fileTemp'} type={'file'} accept=".pdf"
                     className={'d-none'}
                     onChange={async e => {
                       setProgress(true)
                       const { data } = await uploadFile(e.target.files[0])
                       setFile({
                         filename: e.target.files[0].name,
                         filesize: e.target.files[0].size,
                         filepath: data.filepath,
                         user: data.user,
                         date: Date.now(),
                       })
                       if (data.fieldMap) {
                         // merge/reconcile new field map with existing one (if any)
                         setFieldMap(MergeFieldMaps(fieldMap, data.fieldMap))
                       }
                       setProgress(false)
                     }
                     }/>
            </div>
          </div>
        </div>
        <div className={'offset-6 col-6'}>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex justify-content-between'}>
              <label className={'my-0'}
                     style={{ paddingLeft: '0.75rem' }}>{moment(
                  date || Date.now()).format('L') + ' ' +
                moment(date || Date.now()).format('LT')}</label>
              {UserName && <label className={'my-0'}
                                  style={{ paddingRight: '0.75rem' }}>{UserName}</label>}
            </div>
            <label className={'my-0'}
                   style={{ paddingLeft: '0.75rem' }}>{filesize > 0
              ? ` ${filesize} bytes`
              : ''}</label>
          </div>
          <div className={'d-flex justify-content-end'}
               style={{ marginTop: 5 }}>
            {filename && (
              <a className={'btn dart-btn-outline'} style={{ width: 120 }}
                 href={`ms-word:nft|u|${getWorkspaceURL()}/template/${type._id}/${name}`}>
                OPEN
              </a>)}
            {filename && (
              <a className={'btn dart-btn-outline  ml-2'} style={{ width: 120 }}
                 href={`${getWorkspaceURL()}/template/${type._id}/${name}`}
                 download>
                DOWNLOAD
              </a>)}
            <label htmlFor={'fileTemp'}
                   className={classNames('btn dart-btn-outline ml-2',
                     { 'disabled': inProgress })}
                   style={{
                     width: 120,
                     cursor: 'pointer',
                   }}>
              {inProgress ? <Spinner className={'rot'} style={{
                width: 30,
                top: -4,
                position: 'relative',
              }}/> : 'UPLOAD'}
            </label>
          </div>
        </div>
        <div className={'col-12'}>
          <table>
            <TableRow>
              <th className={'pl-2'} style={{ width: '40%' }}>
                Field name
                <InlineCheck id={'showFull'} label={'show full names'}
                             onChange={setShowFullName}
                             checked={showFullName}/>
              </th>
              <th className={'pl-2'} style={{ width: '8%' }}>Input type</th>
              <th className={'pl-2'} style={{ width: '17%' }}>Flags</th>
              <th className={'pl-2'} style={{ width: '35%' }}>
                Expression
                <EditMemberPropertyComponent path={'fillable'} defaultValue={''}>
                  {({
                    value,
                    onChange,
                  }) => (<InlineCheck id={'fillable'}
                                      label={'fillable'}
                                      onChange={onChange}
                                      checked={value}/>)}
                </EditMemberPropertyComponent>
              </th>
            </TableRow>
            {Mappings.map(fieldDef => {
              const parsedName = parseFullName(fieldDef.FieldName)
              return (
                <TableRow>
                  <td className={'pl-2'} style={{ width: '40%' }}>
                    {fieldDef.FieldNameAlt &&
                      (fieldDef.FieldNameAlt !== parsedName.name) && (
                        <div>
                          <em>"{fieldDef.FieldNameAlt}"</em>
                        </div>
                      )}
                    <div style={{ overflowWrap: 'break-word' }}>
                      {showFullName && parsedName.prefix}
                      <strong>{parsedName.name}</strong>
                      {('index' in parsedName) &&
                        ('[' + parsedName.index + ']')}
                    </div>
                  </td>
                  <td className={'pl-2'} style={{ width: '8%' }}>
                    {fieldDef.FieldType}
                    {fieldDef.FieldStateOption && (
                      ' ['
                      + (Array.isArray(fieldDef.FieldStateOption)
                          ? fieldDef.FieldStateOption.join(', ')
                          : fieldDef.FieldStateOption
                      ) + ']'
                    )}
                  </td>
                  <td className={'pl-2'} style={{ width: '17%' }}>{FlagsToText(
                    fieldDef)}</td>
                  <td className={'px-2'} style={{ width: '35%' }}>
                    <div
                      className={'knackly-plain rounded-0 py-2 px-2 form-control TemplateWrapper col-12'}>
                      <Ace
                        label={'OPTION EXPRESSION'} mode={'kexpr'}
                        key={fieldDef.FieldName}
                        id={fieldDef.FieldName}
                        onChange={(value) => mappingChanged(value,
                          fieldDef.FieldName)}
                        style={{ width: '100%' }}
                        className={'border-0'}
                        value={fieldDef.Expression || ''}
                        isExpression={true}
                        type={'text'}
                        maxLines={Infinity}/></div>
                  </td>
                </TableRow>
              )
            })}
          </table>
        </div>
      </div>
    </div>
  )
}

export default PdfFormTemplate
