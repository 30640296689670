import React from 'react'
import { Ace } from '../../../../components'
import '../../../../components/Ace/mode/knackly'
import '../../../../components/Ace/theme/knackly'
import 'brace/ext/language_tools'
import 'brace/ext/searchbox'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'

const File = () => (
  <div className={'col-12'}>
    <div className={'row'}>
      <div className={'col-6'}>
        <label>ASSEMBLED FILE NAME</label>
        <div
          className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>
          <EditMemberPropertyComponent path={'output'} defaultValue={''}>
            {({
                value,
                onChange,
              }) => (
              <Ace className={'border-0'} value={value || ''}
                   id={'templateText'}
                   placeholder={''}
                   onChange={onChange}
                   type={'text'} maxLines={Infinity} />)}
          </EditMemberPropertyComponent>
        </div>
      </div>
      <div className={'col-12 form-group'}>
        <div className={'d-flex justify-content-between'}>
          <label>TEMPLATE TEXT</label>
          <div className="form-check">
            <EditMemberPropertyComponent path={'contentType'} defaultValue={''}>
              {({
                  value,
                  onChange,
                }) => (
                <input className="form-check-input" type="checkbox"
                       id="markdownCheck"
                       onChange={() => onChange(
                         value === 'markdown' ? 'text' : 'markdown')}
                       checked={value ===
                         'markdown'} />)}</EditMemberPropertyComponent>
            <label className="form-check-label" htmlFor="markdownCheck">
              Allow formatting (markdown)
            </label>
          </div>
        </div>
        <EditMemberPropertyComponent path={'text'} defaultValue={''}>
          {({
              value,
              onChange,
            }) => (
            <Ace value={value || ''} maxLines={null} height={300}
                 onChange={onChange} />)}
        </EditMemberPropertyComponent>
      </div>
    </div>
  </div>)

export default File
