import React, { Fragment, useMemo, useState } from 'react'
import { Modal } from 'antd'
import { AddButton, Input, RadioInputs, Select } from '../../../../components'
import AsyncSelect from '../../../../components/AsyncSelect'
import {
  DATE,
  LISTVARIABLETYPES,
  NUMBER,
  OBJECT,
  QUERY,
  TABLE,
  TEXT,
  TRUEFALSE,
  VARIABLETYPES,
  GLOBAL, COLLECTION, VARIABLE_TYPES_CATALOG, LIST_VARIABLE_TYPES_CATALOG,
} from '../../../../config'
import { validateTypeMember } from '../../../../utils'
import {
  atom,
  selectorFamily, useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  selector,
} from 'recoil'
import {
  CurrentMembersByKeySelector,
  CurrentTypeSelector,
  ElementsChangesAtom,
  TypesSelector,
} from '../../state'
import produce from 'immer'
import _ from 'lodash'
import { ObjectID } from 'bson'
import { useHistory } from 'react-router-dom'

const DEFAULT_NEW_VARIABLE_STATE = {
  name: '',
  ref: '',
  isList: false,
  isListDisabled: false,
  nameTouched: false,
  showErrors: false,
  type: 'text',
}
const NewVariableState = atom({
  key: 'NewVariableState',
  default: DEFAULT_NEW_VARIABLE_STATE,
  effects: [
    ({
      onSet,
      setSelf,
    }) => {
      onSet((newValue, oldValue) => {
        if (newValue.isList !== oldValue.isList || newValue.type !==
          oldValue.type) {
          if (newValue.type === NUMBER || newValue.type === TRUEFALSE ||
            newValue.type === DATE) {
            setSelf(produce(draft => {
              _.set(draft, 'isListDisabled', true)
              if (newValue.isList) {
                _.set(draft, 'type', TEXT)
              }
            }))
          } else {
            setSelf(produce(draft => {
              _.set(draft, 'isListDisabled', false)
            }))
          }
        }
      })
    }],
})

const EditorNewVariable = selectorFamily({
  key: 'EditorNewVariable',
  get: path => ({ get }) => {
    return _.get(get(NewVariableState), path)
  },
  set: path => ({
    set,
    get,
  }, newValue) => {
    set(NewVariableState, produce(draft => {
      _.set(draft, path, newValue)
      if (path === 'name') {
        _.set(draft, 'showErrors', true)
      }
    }))
  },
})

const NewVariableErrors = selector({
  key: 'NewVariableErrors',
  get: ({ get }) => {
    const {
      type,
      ref,
      name,
    } = get(NewVariableState)

    const variables = get(CurrentMembersByKeySelector('properties'))
    const templates = get(CurrentMembersByKeySelector('templates'))
    const formulas = get(CurrentMembersByKeySelector('formulas'))

    return validateTypeMember('variable',
      type,
      ref,
      name,
      variables,
      templates,
      formulas)
  },
})
const ErrorAlert = () => {
  const errors = useRecoilValue(NewVariableErrors)
  const nameTouched = useRecoilValue(EditorNewVariable('nameTouched'))
  const showErrors = useRecoilValue(EditorNewVariable('showErrors'))
  if (errors.name && showErrors && (nameTouched || errors.nameForce)) {
    return (<p className={'text-danger'}>
      {errors.name}
    </p>)
  }
  return null
}

const NameProperty = ({ visible }) => {
  const [name, setName] = useRecoilState(EditorNewVariable('name'))
  const setNameTouched = useSetRecoilState(EditorNewVariable('nameTouched'))
  return (<Input type={'text'} value={name}
                 onChange={setName}
                 label={'VARIABLE NAME'}
                 id={'modalNewTypeName'}
                 onBlur={() => {if (visible) setNameTouched(true)}}/>)
}

const RefProperty = () => {
  const type = useRecoilValue(EditorNewVariable('type'))
  const [ref, setRef] = useRecoilState(EditorNewVariable('ref'))
  const types = useRecoilValue(TypesSelector)
  if (type === OBJECT || type === GLOBAL) {
    return (<div className={'col-12'}>
      <div className={'mb-2'}>
        <AsyncSelect id={'newVarObjectType'}
                     label={'BASED ON MODEL'}
                     options={types.filter(
                       t => t.type !== TABLE && t.type !== QUERY).map(t => {
                       return {
                         value: t._id + '|' + t.name,
                         label: t.name,
                       }
                     })}
                     value={ref}
                     onChange={selected => {
                       if (selected) {
                         setRef(selected.value)
                       }
                     }
                     }
                     isInsideModal={true}
        />
      </div>
    </div>)
  }
  return null
}

const TypeProperty = () => {
  const isList = useRecoilValue(EditorNewVariable('isList'))
  const element = useRecoilValue(CurrentTypeSelector)
  const [type, setType] = useRecoilState(EditorNewVariable('type'))

  if (element.type === COLLECTION) {
    return (<Select id={'newVarType'}
                    values={(isList
                      ? LIST_VARIABLE_TYPES_CATALOG
                      : VARIABLE_TYPES_CATALOG).map(
                      item => {
                        if (item === GLOBAL) {
                          return ({ value: item, label: 'global object' })
                        }
                        return ({ value: item, label: item })
                      },
                    )}
                    value={type}
                    onChange={setType}/>)
  }

  return (<Select id={'newVarType'}
                  values={isList ? LISTVARIABLETYPES : VARIABLETYPES.map(
                    item => {
                      if (item === GLOBAL) {
                        return ({ value: item, label: 'global object' })
                      }
                      return ({ value: item, label: item })
                    },
                  )}
                  value={type}
                  onChange={setType}/>)
}

const SwitchIsListProperty = () => {
  const [isList, setIsList] = useRecoilState(EditorNewVariable('isList'))
  const isListDisabled = useRecoilValue(EditorNewVariable('isListDisabled'))
  return (<RadioInputs label={'TYPE'}
                       values={['single', 'listOf']}
                       active={isList
                         ? 'listOf'
                         : 'single'}
                       handelChange={val => {
                         setIsList(val === 'listOf')
                       }}
                       isListDisabled={isListDisabled}
                       name={'newVarIsList'}/>)
}

const NewVariable = () => {
  let [visible, setVisible] = useState(false)
  const history = useHistory()

  const onOpen = () => setVisible(true)

  const onSubmit = useRecoilCallback(({
    snapshot,
    set,
  }) => async () => {
    const variable = await snapshot.getPromise(NewVariableState)
    const errors = await snapshot.getPromise(NewVariableErrors)
    const type = await snapshot.getPromise(CurrentTypeSelector)
    if (!errors.name && !errors.type) {
      let typeName, typeDef
      if (variable.type === OBJECT || variable.type === GLOBAL) {
        [typeDef, typeName] = variable.ref.split('|')
      }

      set(ElementsChangesAtom, produce(draft => {
        draft.push({
          _id: new ObjectID().toString(),
          action: 'add',
          kind: 'variable',
          parent: type._id,
          parentKind: 'model',
          name: variable.name,
          isList: variable.isList,
          type: variable.type,
          typeName,
          typeDef,
        })
      }))

      history.push(
        `/${window.tenancy}/types/${type.name}/variables/${variable.name}`)

      onCancel()
    } else {
      set(EditorNewVariable('showErrors'), true)
    }
  }, [])

  const onCancel = useRecoilCallback(({ set }) => () => {
    setVisible(false)
    set(NewVariableState, DEFAULT_NEW_VARIABLE_STATE)
  }, [])

  const MemoAddButton = useMemo(() => (
      <AddButton id={'addVar'} onClick={onOpen} label={'Add a new variable'}/>),
    [])
  return (
    <Fragment>
      <Modal
        title="New Variable"
        visible={visible}
        onCancel={onSubmit}
        maskClosable={false}
        closable={false}
        width={410}
        footer={[
          <button key="submit"
                  className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }}
                  onClick={onSubmit}
          >
            Ok
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  style={{ width: 125 }}
                  onClick={onCancel}>Cancel</button>,
        ]}
      >
        <form onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}>
          <div className={'row'}>
            <div className={'col-3'}>
              <SwitchIsListProperty/>
            </div>
            <div className={'col-9'}>
              <p style={{ marginTop: '2rem' }}>
                <TypeProperty/>
              </p>
            </div>
            <RefProperty/>
            <div className={'col-12'}>
              <NameProperty visible={visible}/>
              <ErrorAlert/>
            </div>
          </div>
        </form>
      </Modal>
      {MemoAddButton}
    </Fragment>
  )
}

export default NewVariable
