import React, { useState } from 'react'
import classNames from 'classnames'

import { Dropdown, Menu } from 'antd'
import ModalConfirm from '../../components/ModalConfirm'
import Icon from '../../components/Icon'
import styled from 'styled-components'

const styleForIcon = {
  fontSize: 18,
  verticalAlign: 1,
  marginRight: 5,
}

const Item = styled.div`
  color: ${(props) => props.theme.colorTable.text}
  background: ${(props) => props.theme.colorTable.plain}
  border-color: ${(props) => props.theme.colorTable.backBorder}
  &:hover{
   background: ${(props) => props.theme.colorTable.backLightHover}
  }
  &.accent{
    color: ${(props) => props.theme.colorTable.listSelectionText}
    background: ${(props) => props.theme.colorTable.listSelection}
  }
`

const Card = ({ name, collection, index, active, setActive, remove, countCollections, moveUp, moveDown }) => {
  let [isHide, setIsHide] = useState(-1)
  return (
    <Item onClick={() => setActive(index)}
          onMouseOut={() => setIsHide(-1)}
          onMouseOver={() => setIsHide(index)}
          className={classNames('col-12 border p-3 d-flex justify-content-between', { 'accent': index === active },
            { 'border-top-0': index > 0 })}>
      <div>
        <Icon type={'icon-cabinet1'} style={styleForIcon}/>
        {name}
      </div>

      <div className={classNames({ 'invisible': index !== isHide }, { 'visible': index === isHide })}>
        <Dropdown overlay={
          <Menu>
            {index !== 0 && (<Menu.Item onClick={() => moveUp(index)}>
            <span><Icon type="icon-arrow-up"
                        style={{
                          fontSize: 14,
                          verticalAlign: '0.1em',
                        }}/><span> Move Up</span></span>
            </Menu.Item>)}
            {countCollections > index + 1 && (<Menu.Item onClick={() => moveDown(index)}>
            <span><Icon type="icon-arrow-down"
                        style={{
                          fontSize: 14,
                          verticalAlign: '0.1em',
                        }}/><span> Move Down</span></span>
            </Menu.Item>)}
            <Menu.Item>
              <ModalConfirm
                title={`Are you sure you want to permanently delete the '${name}' collection?`}
                submit={() => {
                  remove(collection._id)
                  setActive(-1)
                }}>
                <span><Icon type="icon-trash"
                            style={{
                              fontSize: 14,
                              verticalAlign: '0.1em',
                            }}/><span> Delete collection</span></span>
              </ModalConfirm>
            </Menu.Item>
          </Menu>}
        >
          <Icon type="icon-more" style={{
            fontSize: 18,
            verticalAlign: '0.1em',
          }}/>
        </Dropdown>
      </div>
    </Item>
  )
}

export default Card
