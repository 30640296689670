import React, { Fragment, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../images/icons/times-pro.svg'
import produce from 'immer'
import { Modal } from 'antd'
import AsyncSelect from '../../components/AsyncSelect'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { Input } from '../../components'
import moment from 'moment'
import { InferLabelFromAppName } from '../../utils'
import { useQuery } from 'react-query'
import axios from 'axios'

const FilterButton = styled.button`
  border-color: ${props => props.theme.colorTable.widget} !important;
  background: ${props => props.theme.colorTable.backLight} !important;
  color: ${props => props.theme.colorTable.textLight} !important;

  &:hover {
    background: ${props => props.theme.colorTable.backLight} !important;
    color: ${props => props.theme.colorTable.backLightText} !important;
  }
`

const MY_RECORDS = 'myrecords'
const EDITED_BY = 'edited_by'
const LAST_MODIFIED_BY = 'lastmod_by'
const CREATED = 'created'
const STATUS = 'status'
const LAST_MODIFIED = 'lastmod'
const APP = 'app'
const APP_LAST_MODIFIED = 'app_lastmod'

const OPTIONS_FIELDS = [
  {
    value: MY_RECORDS,
    label: 'My records',
  }, {
    value: EDITED_BY,
    label: 'Past editors',
  }, {
    value: LAST_MODIFIED_BY,
    label: 'Last modified by',
  }, {
    value: CREATED,
    label: 'Created',
  }, {
    value: STATUS,
    label: 'Record status',
  }, {
    value: LAST_MODIFIED,
    label: 'Last modified',
  }, {
    value: APP,
    label: 'Apps that have been run',
  }, {
    value: APP_LAST_MODIFIED,
    label: 'App last modified',
  },
]

const useFilters = () => {
  const { search } = useLocation()

  return React.useMemo(() => {
    try {
      const Params = new URLSearchParams(search)
      const strFilters = Params.get('f') || '{}'
      return JSON.parse(strFilters)
    } catch (e) {
      return {}
    }
  }, [search])
}

const AddFilter = ({ identifier, filter, apps, users, setFilter }) => {
  switch (identifier) {
    case MY_RECORDS: {
      return null
    }
    case LAST_MODIFIED_BY:
    case EDITED_BY: {
      return (<Fragment>
        <div className={'col-12 my-2'}>
          <AsyncSelect id={'field'}
                       label={'Condition'}
                       options={identifier === EDITED_BY
                         ? [
                           {
                             value: 'is',
                             label: 'include',
                           }, {
                             value: 'not',
                             label: 'do not include',
                           },
                         ]
                         : [
                           {
                             value: 'is',
                             label: 'is',
                           }, {
                             value: 'not',
                             label: 'is not',
                           },
                         ]}
                       value={_.get(filter, 'operator')}
                       onChange={selected => {
                         if (selected) {
                           setFilter(produce(draft => {
                             draft['operator'] = selected.value
                           }))
                         }
                       }
                       }
                       isInsideModal={true}
          />
        </div>
        <div className={'col-12 my-2'}>
          <AsyncSelect id={'field'}
                       label={'User'}
                       options={[
                         {
                           value: '',
                           label: '',
                         },
                         ...users.map(user => ({
                           value: user,
                           label: user,
                         }))]}
                       value={_.get(filter, 'value', '')}
                       onChange={selected => {
                         if (selected) {
                           setFilter(produce(draft => {
                             draft['value'] = selected.value
                           }))
                         }
                       }
                       }
                       isInsideModal={true}
          />
        </div>
      </Fragment>)
    }
    case STATUS: {
      return (<Fragment>
        <div className={'col-12 my-2'}>
          <AsyncSelect id={'field'}
                       label={'Condition'}
                       options={[
                         {
                           value: 'is',
                           label: 'is',
                         }, {
                           value: 'not',
                           label: 'is not',
                         }]}
                       value={_.get(filter, 'operator')}
                       onChange={selected => {
                         if (selected) {
                           setFilter(produce(draft => {
                             draft['operator'] = selected.value
                           }))
                         }
                       }
                       }
                       isInsideModal={true}
          />
        </div>
        <div className={'col-12 my-2'}>
          <AsyncSelect id={'values'}
                       label={'Values'}
                       options={[
                         {
                           value: '',
                           label: '',
                         },
                         {
                           value: 'Ok',
                           label: 'Ok',
                         }, {
                           value: 'In Progress',
                           label: 'In Progress',
                         }, {
                           value: 'Out of date',
                           label: 'Out of date',
                         }, {
                           value: 'Needs Updating',
                           label: 'Needs Updating',
                         }]}
                       value={_.get(filter, 'value', '')}
                       onChange={selected => {
                         if (selected) {
                           setFilter(produce(draft => {
                             draft['value'] = selected.value
                           }))
                         }
                       }
                       }
                       isInsideModal={true}
          />
        </div>
      </Fragment>)
    }
    case LAST_MODIFIED:
    case APP_LAST_MODIFIED:
    case CREATED: {
      return (<Fragment>
        <div className={'col-12 my-2'}>
          <AsyncSelect id={'Condition'}
                       label={'Condition'}
                       options={[
                         {
                           value: 'range',
                           label: 'in range',
                         }, {
                           value: 'before',
                           label: 'before',
                         }, {
                           value: 'after',
                           label: 'after',
                         }]}
                       value={_.get(filter, 'operator', 'range')}
                       onChange={selected => {
                         if (selected) {
                           setFilter(produce(draft => {
                             draft['operator'] = selected.value
                           }))
                         }
                       }
                       }
                       isInsideModal={true}
          />
        </div>
        {_.get(filter, 'operator', 'range') === 'range' && (
          <Fragment>
            <div className={'col-12 my-2'}>
              <Input type={'datetime-local'} label={'Start date and time'}
                     value={_.get(filter, 'dateStart', '')}
                     onChange={(value) => setFilter(produce(draft => {
                       draft['dateStart'] = value
                     }))}/>
            </div>
            <div className={'col-12 my-2'}>
              <Input type={'datetime-local'} label={'End date and time'}
                     value={_.get(filter, 'dateEnd', '')}
                     onChange={(value) => setFilter(produce(draft => {
                       draft['dateEnd'] = value
                     }))}
              />
            </div>
          </Fragment>
        )}
        {_.get(filter, 'operator', 'range') !== 'range' &&
          (<div className={'col-12 my-2'}>
            <Input type={'datetime-local'} label={'Date and time'}
                   value={_.get(filter, 'value', '')}
                   onChange={(value) => setFilter(produce(draft => {
                     delete draft['dateStart']
                     delete draft['dateEnd']
                     draft['value'] = value
                   }))}
            />
          </div>)}
      </Fragment>)
    }
    case APP: {
      return (<Fragment>
        <div className={'col-12 my-2'}>
          <AsyncSelect id={'Condition'}
                       label={'Condition'}
                       options={[
                         {
                           value: 'is',
                           label: 'include',
                         }, {
                           value: 'not',
                           label: 'do not include',
                         }]}
                       value={_.get(filter, 'operator')}
                       onChange={selected => {
                         if (selected) {
                           setFilter(produce(draft => {
                             draft['operator'] = selected.value
                           }))
                         }
                       }
                       }
                       isInsideModal={true}
          />
        </div>
        <div className={'col-12 my-2'}>
          <AsyncSelect id={'values'}
                       label={'Values'}
                       options={[
                         {
                           value: '',
                           label: '',
                         },
                         ...apps.map(app => ({
                           value: app.name,
                           label: app.label || InferLabelFromAppName(app.name),
                         }))]}
                       value={_.get(filter, 'value', '')}
                       onChange={selected => {
                         if (selected) {
                           setFilter(produce(draft => {
                             draft['value'] = selected.value
                           }))
                         }
                       }
                       }
                       isInsideModal={true}
          />
        </div>
      </Fragment>)
    }
    default: {
      return null
    }
  }
}

const AddModal = ({
  filters,
  setFilters,
  visible,
  catalogName,
  apps,
  onInvisible,
}) => {
  const [identifier, setIdentifier] = useState('')
  const [filter, setFilter] = useState({})
  const { data: users } = useQuery(['collections', catalogName, 'users'],
    async () => {
      const { data } = await axios.get(`/api/collections/${catalogName}/users`)
      return data
    }, {
      retry: false,
      refetchOnWindowFocus: false,
    })
  const onCancel = () => {
    setFilter({})
    onInvisible()
  }

  const onSubmit = () => {
    setFilters(produce(filters, draft => {
      if ((!filter.operator || filter.operator === 'range') &&
        filter.dateStart && filter.dateEnd) {
        draft[identifier] = {
          c: filter.operator || 'range',
          dateStart: filter.dateStart,
          dateEnd: filter.dateEnd,
        }
      } else if (filter.operator && filter.value) {
        draft[identifier] = {
          c: filter.operator,
          v: filter.value,

        }
      } else {
        draft[identifier] = {}
      }
    }))
    setIdentifier('')
    setFilter({})
    onInvisible()
  }

  const isDisabled = useMemo(() => {
    if (!identifier) {
      return true
    }

    if (identifier === MY_RECORDS) {
      return false
    }

    if (!_.get(filter, 'operator', false)) {
      return true
    }

    if (_.get(filter, 'operator', '') === 'range') {
      if (!_.get(filter, 'dateStart', false)) {
        return true
      }
      if (!_.get(filter, 'dateEnd', false)) {
        return true
      }
    } else {
      if (!_.get(filter, 'value', false)) {
        return true
      }
    }
    return false
  }, [identifier, filter])

  return (<Modal
    title="Filter by"
    visible={visible}
    maskClosable={false}
    closable={false}
    width={410}
    footer={[
      <button key="submit"
              className={'btn btn-secondary dart-btn'}
              style={{ width: 125 }}
              onClick={onSubmit}
              disabled={isDisabled}
      >
        Ok
      </button>,
      <button key="back"
              className={'btn btn-outline-secondary dart-btn-outline'}
              style={{ width: 125 }}
              onClick={onCancel}>Cancel</button>,
    ]}
  >
    <div className="row">
      <div className="col-12">
        <AsyncSelect id={'field'}
                     label={''}
                     options={OPTIONS_FIELDS}
                     value={identifier}
                     onChange={selected => {
                       if (selected) {
                         setIdentifier(selected.value)
                         setFilter({})
                       }
                     }
                     }
                     isInsideModal={true}
        />
      </div>
      <AddFilter identifier={identifier}
                 filter={filter}
                 apps={apps}
                 users={users}
                 setFilter={setFilter}/>
    </div>
  </Modal>)
}
const Filters = ({ loadFirstItems, catalogName, apps }) => {
  const history = useHistory()
  const filters = useFilters()
  const [visible, setVisible] = useState(false)

  const setFilters = (filters) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('f', JSON.stringify(filters))

    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    })
    setTimeout(() => {
      loadFirstItems()
    }, 0)
  }

  const onRemove = (key) => {
    setFilters(produce(filters, draft => {
      delete draft[key]
    }))
  }

  const maskFilter = (key) => {
    let condition = _.get(filters, key + '.c', '')
    let value = _.get(filters, key + '.v')

    if (value) {
      if (Array.isArray(value)) {
        value = value.join(',')
      }
    }

    if (key === LAST_MODIFIED || key === APP_LAST_MODIFIED || key === CREATED) {
      if (condition === 'range') {
        value = moment(_.get(filters, key + '.dateStart')).format('l') +
          ' - ' +
          moment(_.get(filters, key + '.dateEnd')).format('l')
      } else {
        value = condition + ' ' + moment(_.get(filters, key + '.v')).format('l')
      }
    }

    switch (key) {
      case MY_RECORDS: {
        return 'My records'
      }
      case EDITED_BY: {
        return condition === 'is'
          ? `Modified by ${value}`
          : `Not modified by ${value}`
      }
      case LAST_MODIFIED_BY: {
        return condition === 'is'
          ? `Last modified by ${value}`
          : `Not last modified by ${value}`
      }
      case CREATED: {
        return `Created ${value}`
      }
      case STATUS: {
        return `Status ${condition} ${value}`
      }
      case LAST_MODIFIED: {
        return `Last modified ${value}`
      }
      case APP: {
        return `App ${condition} ${value}`
      }
      case APP_LAST_MODIFIED: {
        return `App last modified ${value}`
      }
      default: {
        return 'Error'
      }
    }
  }

  const onVisible = () => setVisible(true)
  const onInvisible = () => setVisible(false)
  return (
    <div className={'col'}>
      {Object.keys(filters).map(
        keyFilter => <FilterButton className="btn text-truncate mr-2 my-2"
                                   type="button"
                                   key={keyFilter}
                                   onClick={() => onRemove(keyFilter)}
        > {maskFilter(keyFilter)} <CloseIcon className={'ml-2'} style={{
          width: '.75rem',
          verticalAlign: -4,
        }}
        />
        </FilterButton>)}
      <button
        className="btn btn-secondary btn-theme text-truncate my-2"
        type="button"
        onClick={onVisible}
      >
        Add filter
      </button>
      <AddModal visible={visible}
                filters={filters}
                setFilters={setFilters}
                apps={apps}
                catalogName={catalogName}
                onInvisible={onInvisible}/>
    </div>
  )
}

export default Filters
