import React, { Fragment, useEffect, useState } from 'react'
import { Dropdown, Menu } from 'antd'

import { ReactComponent as Eye } from '../../../images/icons/eye.svg'
import styled from 'styled-components'
import { useDrop } from 'react-dnd'
import { DND } from '../../../config'
import { Icon } from '../../../components'
import ModalConfirm from '../../../components/ModalConfirm'

const ALWAYS = 'always'
const CONDITION = 'when condition...'
const QUESTION = 'with questions...'

const Input = styled.input`
    border-radius: 0 0 0.25rem 0;
    border: 0;
    width: 145px;

    ::-webkit-input-placeholder {
        font-style: italic;
    }

    :-moz-placeholder {
        font-style: italic;
    }

    ::-moz-placeholder {
        font-style: italic;
    }

    :-ms-input-placeholder {
        font-style: italic;
    }
`

const Visibility = ({
  classOfLayout, variables, content, changeVisibility,
}) => {
  const [type, setType] = useState(ALWAYS)
  const [localExpression, setLocalExpression] = useState('')
  const [list, setList] = useState([])

  useEffect(() => {
    if (content.expr) {
      setType(CONDITION)
      setLocalExpression(content.expr)
    }
    if (content.list) {
      if (content.list.length > 0) {
        setList(content.list)
        setType(QUESTION)
      }
    }
  }, [content])
  const [, drop] = useDrop({
    accept: DND.TYPES.DNDITEMROOT, drop (item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      if (type === CONDITION) {
        const nextCondition = localExpression ? (localExpression +
          ` ${item.copyText}`) : item.copyText
        setLocalExpression(nextCondition)
        changeVisibility('expr', nextCondition)
      }
      if (type === QUESTION) {
        let nextList = [...list, item.copyText.split('.')[0]]
        nextList = new Set(nextList)
        nextList = [...nextList]
        setList(nextList)
        changeVisibility('list', nextList)
      }
    },
  })
  const changeType = type => {
    setType(type)
    changeVisibility('list', [])
    changeVisibility('expr', undefined)
  }
  const menu = (<Menu>
    <Menu.Item className={'p-1'} onClick={() => changeType(ALWAYS)}>
      <Eye style={{
        width: 16, height: 16,
      }}/> {ALWAYS}
    </Menu.Item>
    <Menu.Item className={'p-1'} onClick={() => changeType(CONDITION)}>
      <Eye style={{
        width: 16, height: 16,
      }}/> {CONDITION}
    </Menu.Item>
    <Menu.Item className={'p-1'} onClick={() => changeType(QUESTION)}>
      <Eye style={{
        width: 16, height: 16,
      }}/> {QUESTION}
    </Menu.Item>
  </Menu>)
  const findIcon = name => {
    const v = variables.find(variable => variable.name === name)
    if (v) {
      return Icon.fromValueType(v.type)
    }
    return ''
  }
  const removeItem = (item) => {
    changeVisibility('list', list.filter(_i => _i !== item))
  }

  return (<div className={'d-flex flex-column text-truncate'}>
    {classOfLayout === 'question' ? (
      <div className={'px-2 pb-1 text-truncate'}>
        <Fragment>
          <Eye style={{
            width: 16, height: 16,
          }}/> when relevant
        </Fragment>
      </div>) : (<Dropdown overlay={menu}>
      <div className={'px-2 pb-1'}>
        <div>
          <Eye style={{
            width: 16, height: 16,
          }}/> {type === CONDITION ? 'when:' : type === QUESTION
          ? 'with:'
          : ALWAYS}
        </div>
      </div>
    </Dropdown>)}
    {type === ALWAYS && (<div className={'mx-1 mb-1'}>&nbsp;</div>)}
    {type === CONDITION && (<div className={'mx-1 mb-1 text-dark'}>
      <Input className={'p-0 px-1 w-100'}
             ref={drop}
             value={localExpression}
             onChange={event => {
               setLocalExpression(event.target.value)
               changeVisibility('expr', event.target.value)
             }} placeholder={'expression'}/>
    </div>)}{type === QUESTION &&
    (<div className={'mx-1 mb-1 bg-white d-flex flex-column text-dark'}
          ref={drop}
          style={{ borderRadius: '0 0 0.25rem 0' }}>
      {list.length === 0 && (<span
        className={'px-1 font-italic text-black-50'}>variable(s)</span>)}
      {list.map(item => (
        <ModalConfirm
          title={`Are you sure you want to remove ${item}?`}
          submit={() => removeItem(item)}>
        <span key={item}
              className={'px-1 font-italic cursorPointer right-nav-hollow'}
        ><Icon
          type={findIcon(item)}
          className={'align-middle '}
          style={{ marginTop: -3 }}/> {item}</span>
        </ModalConfirm>
      ))}
    </div>)}
  </div>)
}

export default Visibility
