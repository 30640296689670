import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { Ace, Icon } from '../../../components'
import classNames from 'classnames'
import changeErrorMessage from './changeErrorMessage'
import styled from 'styled-components'
import { Engine } from 'yatte'

const Row = styled.div`
  background-color: ${props => props.theme.colorTable.plain};

  &:nth-child(even) {
    background-color: ${props => props.theme.colorTable.backLight};
  }
`
const MappingItem = ({
                       name,
                       typeName,
                       isList,
                       key,
                       expression,
                       onChange,
                     }) => {
  const [value, setValue] = useState(expression)
  const [error, setError] = useState('')

  const hasError = nextError => {
    setError(changeErrorMessage(nextError))
  }

  const setExpression = (value) => {
    try {
      setValue(value)
      if (value) {
        // eslint-disable-next-line
        const compiledExpr = Engine.compileExpr(value).ast
      }
      onChange(value, hasError)
    } catch (e) {
      hasError(e.toString())
    }
  }

  return (<Row className={'row'}>
    <div className="col-4">
      <div
        className={'d-flex align-items-center justify-content-between h-100'}>
        <span>{name}</span>
        <span>{typeName + (isList ? ' list' : '')}</span>
      </div>
    </div>
    <div className="col-8">
      <div className={classNames(
        'knackly-plain rounded-0 py-2 form-control TemplateWrapper my-1',
        { 'border-danger': error })}>
        <Ace mode={'kexpr'}
             key={key}
             value={value}
             onChange={setExpression}
             className={'border-0'}
             maxLines={Infinity}
             isExpression={true}/>
      </div>
      {error && (
        <Tooltip title={error} key={'Tooltip' + name} placement={'bottom'}
                 overlayClassName={'warning-tooltip'}>
          <Icon type={'icon-exclamation-triangle'} style={{
            color: '#dc3545',
            fontSize: '1.2rem',
            verticalAlign: 0,
            left: 'calc(100% - 2.6rem)',
            top: '0.9rem',
            position: 'absolute',
          }}/>
        </Tooltip>)}
    </div>
  </Row>)
}
export default MappingItem
