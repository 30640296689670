import React, { Fragment } from 'react'
import styled from 'styled-components'
import { URI } from '../../config'

const BlockLogo = styled.div`
  background-color: ${props => props.theme.colorTable.menu};
  color: ${props => props.theme.colorTable.menuText}
`
const TelLink = styled.a`
  color: #585858 !important;
`
const Inactivating = ({ settings }) => {
  return (
    <Fragment>
      {settings.logo && <BlockLogo className={'w-100 text-center py-3'}>
        <img src={`${URI}/${window.tenancy}/` + settings.logo} style={{
          maxWidth: '50%',
          maxHeight: 100,
        }} alt={settings.label ? settings.label : settings.name}/>
      </BlockLogo>}
      <div className={settings.logo ? 'pt-5' : ''}
           style={!settings.logo ? { paddingTop: '10%' } : {}}>
        <div className={'mx-auto bg-white p-4'} style={{ width: 700 }}>
          <h1 className={'mb-4'}>Your workspace has been deactivated.</h1>
          <p className={'h5'}>Please call sales at <TelLink
            href="tel:+2089512180">(208) 951-2180</TelLink> to reactivate your
            workspace.</p>
        </div>
      </div>
    </Fragment>
  )
}

export default Inactivating
