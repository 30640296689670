import React, { useEffect, useMemo, useRef } from 'react'
import { useDrop } from 'react-dnd'
import ItemTypes from '../ItemTypes'
import classNames from 'classnames'
import PreViewCard from '../previewCard'

const InsertColumn = ({
                        countColumn,
                        index,
                        value,
                        add,
                        hoverFirstItem,
                        rollbackAllItems,
                        hasInRow,
                        previewRow,
                        isHovering,
                        closePreviewRow,
                      }) => {
  const ref = useRef(null)
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop (item, monitor) {
      console.log('InsertColumn item', item)
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return null
      }
      add(item)
    },
    hover: (item, monitor) => {
      if (closePreviewRow) {
        closePreviewRow()
      }
      if (isHovering) {
        previewRow(index)
      }
      if (hasInRow) {
        return
      }
      if (index === 0) {
        hoverFirstItem()
        const hoverBoundingRect = ref.current.getBoundingClientRect()
        const clientOffset = monitor.getClientOffset()
        const widthItem = hoverBoundingRect.width / countColumn
        const positionInRootBlock = clientOffset.x - hoverBoundingRect.x

        const positionInHovering = Math.floor(positionInRootBlock / widthItem)
        if (positionInHovering !== 0) {
          rollbackAllItems()
        } else {
          hoverFirstItem()
        }
      } else {
        rollbackAllItems()
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  })
  useEffect(() => {
    if (!isOver) {
      if (!hasInRow) {
        rollbackAllItems()
      }
    }
  }, [isOver])

  drop(ref)

  const MemoInsertColumn = useMemo(() => {
    return (<div ref={ref}
                 className={classNames('col', value,
                   { 'py-2': isOver && !hasInRow })}>
      {!isOver ? (<div style={{
        minHeight: 15,
        height: '100%',
      }}/>) : (
        <PreViewCard/>)}

    </div>)
  }, [ref, value, isOver, hasInRow])
  return (
    MemoInsertColumn
  )
}

export default InsertColumn
