import { useEffect, useState } from 'react'
import { ParseQueryExpr } from '../../../../../utils/parseQueryExpr'
import { Engine } from 'yatte'
import { ERRORTOKEN } from '../../../../../utils/tokens'
import LoadingType from '../../../../../utils/loadingType'

const AST = Engine.AST

const useParseQuery = (query, types, onChange) => {
  const [queryDef, setQueryDef] = useState(() => undefined)
  const {
    ast,
    callExpr,
    queryName,
    mapTo,
    mapBack,
    reqArgs,
    optArgs,
  } = ParseQueryExpr(query)

  const fillQueryDef = async () => {
    const def = types.find(type => type.name === queryName)
    if (def) {
      if (def.neededLoading) {
        const type = await LoadingType(def._id)
        setQueryDef(() => type)
        return
      }
      setQueryDef(() => def)
      return
    }
  }

  useEffect(() => {
    fillQueryDef()
  }, [query])

  let optionalParams = queryDef ? queryDef.params ? queryDef.params.filter(
    param => param.selectorType === 'param' && !param.required) : [] : []
  let requiredParams = queryDef ? queryDef.params ? queryDef.params.filter(
    param => param.selectorType === 'param' && param.required) : [] : []

  const onChangeRequiredParams = (index, value, hasError) => {
    try {
      const valueAst = Engine.compileExpr(value || '""').ast
      if (callExpr.arguments.length === requiredParams.length) {
        callExpr.arguments = requiredParams.map((param, index) => {
          if (callExpr.arguments[index]) {
            return callExpr.arguments[index]
          }
          return {
            type: AST.Literal,
            value: `${ERRORTOKEN}`,
          }
        })
      } else {
        requiredParams.forEach((param, index) => {
          if (callExpr.arguments[index]) {
            return
          }
          callExpr.arguments[index] = ({
            type: AST.Literal,
            value: `${ERRORTOKEN}`,
          })
        })
      }
      callExpr.arguments[index] = valueAst
      onChange(AST.serialize(ast, true))
      hasError('')
    } catch (e) {
      hasError(e.toString())
    }
  }
  if (requiredParams.length) {
    requiredParams = requiredParams.map((param, index) => ({
      ...param,
      value: reqArgs ? reqArgs[index] || '' : '',
      onChange: (value, hasError) => onChangeRequiredParams(index, value, hasError),
    }))
  }
  if (optionalParams.length) {
    const getValueOptional = (keyName) => {
      if (!optArgs) {
        return ''
      }
      const Property = optArgs.find(property => property.key.name === keyName)
      if (Property) {
        return AST.serialize(Property.value, true)
      }
      return ''
    }

    optionalParams = optionalParams.map((param) => ({
      ...param,
      value: getValueOptional(param.name),
    }))
  }

  const onUpdateOptionalParams = (expression) => {
    const expressionAST = Engine.compileExpr(expression).ast
    if (callExpr.arguments.length === requiredParams.length) {
      callExpr.arguments = requiredParams.map((param, index) => {
        if (callExpr.arguments[index]) {
          return callExpr.arguments[index]
        }
        return {
          type: AST.Literal,
          value: `${ERRORTOKEN}`,
        }
      })
    } else {
      requiredParams.forEach((param, index) => {
        if (callExpr.arguments[index]) {
          return
        }
        callExpr.arguments[index] = ({
          type: AST.Literal,
          value: `${ERRORTOKEN}`,
        })
      })
    }
    if (callExpr.arguments.find(argument => argument.type === AST.ObjectExpression)) {
      callExpr.arguments = callExpr.arguments.map(
        argument => argument.type === AST.ObjectExpression ? expressionAST : argument)
    } else {
      callExpr.arguments.push(expressionAST)
    }
    onChange(AST.serialize(callExpr, true))
  }
  return {
    queryName: queryName || '',
    mapTo: mapTo || '',
    mapBack: mapBack || '',
    requiredParams,
    optionalParams,
    onUpdateOptionalParams,
  }
}
export default useParseQuery
