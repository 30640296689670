import React, { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { Input, Popover } from 'antd'
import { AddButton } from '../index'
import { IsInvalidIdentifier } from '../../utils'
import RenameModal from './modals/rename'
import RelabelModal from './modals/relabel'
import { ReactComponent as Plus } from './images/plus.svg'
import update from 'immutability-helper'
import ViewRow from './row'
import ViewHeader from './header'
import styled from 'styled-components'
import produce from 'immer'

import './index.css'

const BlockTable = styled.div`
  background-color: ${props => props.theme.colorTable.back} !important;
  border-color: ${props => props.theme.colorTable.backBorder} !important;
  color: ${props => props.theme.colorTable.backText} !important;
`

const HeaderWrap = styled.div`
  background-color: ${props => props.theme.colorTable.back} !important;
  position: sticky;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: fit-content;
`

const Board = React.forwardRef((props, ref) => (
  <BlockTable className={'rounded mb-2 pr-2 pl-1 board'}
              ref={ref}> {props.children}</BlockTable>
))
let flag = ''

const Table = ({
  preData: data,
  columns,
  keyRow,
  implicitKey,
  getTable,
  disabled,
  QueryRowKey,
  setQueryRowKey,
  isQuery = false,
}) => {
  const [flagScroll, setFlag] = useState(true)
  let [rename, setRename] = useState({
    visible: false,
    index: '',
    value: '',
  })
  let [relabel, setRelabel] = useState({
    visible: false,
    index: '',
    value: '',
  })
  const header = columns.map((col, index) => ({
    id: index,
    label: col.name,
    name: col.name,
    path: col.name,
    isKey: (keyRow || implicitKey) === col.name,
    forceKey: implicitKey,
  }))

  let [isAddRow, setIsAddRow] = useState(0)
  let [isAddColumn, setIsAddColumn] = useState(0)
  let [visible, setVisible] = useState(false)
  let refBoard = React.createRef()
  let [name, setName] = useState('')


  const disableScroll=()=>{
    if(flagScroll) {
      setFlag(false)
    }
  }
  useEffect(() => {
      if (rename.visible) {
        setTimeout(() => {
          if (document.getElementById('renameModal')) {
            document.getElementById('renameModal').focus()
          }
        }, 0)
      }
    }, [rename],
  )
  useEffect(() => {
      if (relabel.visible) {
        setTimeout(() => {
          if (document.getElementById('relabelModal')) {
            document.getElementById('relabelModal').focus()
          }
        }, 0)
      }
    }, [relabel],
  )

  useEffect(() => {
    if (refBoard.current && flagScroll) {
      refBoard.current.scrollTop = 0
      refBoard.current.scrollLeft = 0
    }
  }, [refBoard])

  let modalChange = key => value => {
    if (disabled) {
      return 0
    }
    if (key === 'rename') {
      setRename({
        ...rename,
        value,
      })
    }
    if (key === 'relabel') {
      setRelabel({
        ...relabel,
        value,
      })
    }
  }

  let hideModal = key => () => {
    if (disabled) {
      return 0
    }
    if (key === 'rename') {
      setRename({
        ...rename,
        visible: false,
      })
    }
    if (key === 'relabel') {
      setRelabel({
        ...relabel,
        visible: false,
      })
    }
  }
  const saveModal = key => () => {
    if (key === 'rename') {
      if (!IsInvalidIdentifier(rename.value)) {

        const nextData = data.map(d => {
          d = {
            ...d,
            [rename.value]: d[header[rename.index].path],
          }
          delete d[header[rename.index].path]
          return { ...d }
        })
        const nextHeader = produce(header, draft => {
          draft[rename.index].label = rename.value
          draft[rename.index].name = rename.value
          draft[rename.index].path = rename.value
        })

        setRename({
          ...rename,
          value: '',
          visible: false,
        })
        getTable({
          data: nextData,
          header: nextHeader,
        })
        return
      }
    }
    if (key === 'relabel') {
      const nextHeader = produce(header, draft => {
        draft[relabel.index].label = relabel.value
      })
      getTable({
        data,
        header: nextHeader,
      })
      setRelabel({
        ...relabel,
        value: '',
        visible: false,
      })
    }
  }

  const addRow = () => {
    if (disabled) {
      return 0
    }
    disableScroll()
    let obj = {}
    header.forEach(h => {
      obj = {
        ...obj,
        [h.path]: '',
      }
    })
    getTable({
      data: produce(data, draft => {
        draft.push(obj)
      }),
      header,
    })
    setIsAddRow(++isAddRow)
  }

  const addColumn = () => {
    if (disabled) {
      return 0
    }
    disableScroll()
    if (name &&
      !IsInvalidIdentifier(name) &&
      !header.find(h => h.path === name)) {
      const nextHeader = produce(header, draft => {
        draft.push({
          label: name,
          name,
          path: name,
          isKey: false,
          forceKey: implicitKey,
        })
      })
      const nextData = [
        ...data.map(d => ({
          ...d,
          [name]: '',
        }))]
      getTable({
        data: nextData,
        header: nextHeader,
      })
      setIsAddColumn(++isAddColumn)

      hide()
    }
  }

  const change = (index, key, value) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    let nextData

    if (data[index]) {
      nextData = produce(data, draft => {
        draft[index][key] = value
      })
    } else {
      nextData = produce(data, draft => {
        let newRow = {}
        header.forEach(h => {
          newRow = {
            ...newRow,
            [h.path]: '',
          }
        })
        newRow[key] = value
        draft.push(newRow)
      })
    }
    getTable({
      data: nextData,
      header,
    })

  }

  const changeName = e => {
    if (disabled) {
      return 0
    }
    disableScroll()
    setName(e.target.value)
  }

  useEffect(() => {
    refBoard.current.scrollTop += refBoard.current.childNodes.length * 40
  }, [isAddRow])

  useEffect(() => {
    refBoard.current.scrollLeft += refBoard.current.childNodes[0].length * 150
  }, [isAddColumn])

  useEffect(() => {
    if (flag) {
      const $el = refBoard.current.querySelector(
        `div[data-index='${data.length - 1}'][data-path='${flag}']`)
      if ($el) {
        $el.click()
        flag = ''
      }
    }
  }, [data])

  const hide = () => {
    setName('')
    setVisible(false)
  }
  const show = () => {
    if (disabled) {
      return 0
    }
    setVisible(true)
  }
  const deleteRow = (index) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    getTable({
      data: produce(data, draft => {
        draft.splice(index, 1)
      }),
      header,
    })
  }
  const deleteColumn = (index) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    const path = header[index].path

    const nextData = data.map(produce(draft => {
      delete draft[path]
      return draft
    }))

    const nextHeader = produce(header, draft => {
      draft.splice(index, 1)
    })

    getTable({
      data: nextData,
      header: nextHeader,
    })
  }
  const handleKey = (index) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    getTable({
      data,
      header: header.map((h, i) => {
        if (i === index) {
          h.isKey = true
          return h
        } else {
          h.isKey = false
          return h
        }
      }),
    })

  }
  const handleRename = (index) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    setRename({
      index,
      visible: true,
      value: header[index].path,
    })
  }
  const handleRelabel = (index) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    setRelabel({
      index,
      visible: true,
      value: header[index].label,
    })
  }
  const nextItem = e => {
    if (disabled) {
      return 0
    }
    disableScroll()
    if (e.key === 'Enter') {
      let index = Number(e.target.attributes.getNamedItem('data-index').value)
      let path = e.target.attributes.getNamedItem('data-path').value

      index++

      let el = refBoard.current.querySelector(
        `div[data-index='${index}'][data-path='${path}']`)
      if (el) {
        el.click()
      } else {
        flag = path
        addRow()
      }
    }
  }
  const moveRow = (dragIndex, hoverIndex) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    const dragCard = data[dragIndex]

    const nextData = update(data, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
    })

    getTable({
      data: nextData,
      header,
    })

  }
  const moveHeader = (dragIndex, hoverIndex) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    const dragCard = header[dragIndex]

    const nextHeader = update(header, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
    })

    getTable({
      data,
      header: nextHeader,
    })

  }
  const onPaste = (index, path) => e => {
    e.preventDefault()
    disableScroll()
    const clipText = e.clipboardData.getData('Text')
    let clipRows = clipText.split(String.fromCharCode(13))
    clipRows = clipRows.map(row => {
      return row.split(String.fromCharCode(9))
    })
    const indexCurrentlyColumn = header.findIndex(head => head.path === path)

    const createRow = () => {
      let newRow = {}
      header.forEach(h => {
        newRow = {
          ...newRow,
          [h.path]: '',
        }
      })
      return newRow
    }

    const nextData = produce(data, draft => {
      clipRows.forEach((row, indexRow) => {
        row.forEach((col, indexCol) => {
          const nextValue = col.replace(/\n|\t/g, '')
          if (nextValue) {
            if (indexCol === 0) {
              const resIndex = index + indexRow
              if (draft[resIndex]) {
                draft[resIndex][path] = nextValue
              } else {
                let newRow = createRow()
                newRow[path] = nextValue
                draft.push(newRow)
              }
            } else {
              const thisHeader = header[indexCol + indexCurrentlyColumn]
              if (thisHeader) {
                const resIndex = index + indexRow
                if (draft[resIndex]) {
                  draft[resIndex][thisHeader.path] = nextValue
                } else {
                  let newRow = createRow()
                  newRow[path] = nextValue
                  draft.push(newRow)
                }
              }
            }
          }
        })
      })
    })

    getTable({
      data: nextData,
      header,
    })

  }
  const sortASC = (index) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    const nextData = [..._.orderBy(data, [header[index].path], ['asc'])]
    getTable({
      data: nextData,
      header,
    })

  }
  const sortDESC = (index) => {
    if (disabled) {
      return 0
    }
    disableScroll()
    const nextData = _.orderBy(data, [header[index].path], ['desc'])
    getTable({
      data: nextData,
      header,
    })

  }
  const handleQueryKey = (index) => {
    if (data[index]) {
      const key = data[index].name
      if (key === QueryRowKey) {
        setQueryRowKey(implicitKey || 'id$')
      } else {
        setQueryRowKey(key)
      }
    }
  }

  return (
    <Fragment>
      <Board ref={refBoard}>
        <HeaderWrap className="d-flex flex-row">
          {header.length > 0 &&
            <div className="py-2" style={{ opacity: 0, minWidth: 30 }}>0</div>}
          <ViewHeader header={header}
                      handleDelete={deleteColumn}
                      handleKey={handleKey}
                      handleRename={handleRename}
                      handleRelabel={handleRelabel}
                      moveHeader={moveHeader}
                      handleSortASC={sortASC}
                      handleSortDESC={sortDESC}/>
          <div className={'p-1'}>
            <Popover
              content={<div
                className={classNames('ant-form-item-control', 'm-2',
                  { 'has-error': name && IsInvalidIdentifier(name) })}>
                <form onSubmit={event => {
                  event.preventDefault()
                  addColumn()
                }}>
                  <Input placeholder="Column name" value={name}
                         onChange={changeName}/>
                  <span className={'b-send'} onClick={addColumn}>
                <Plus/>
                </span>
                  <div className={'ant-form-explain has-error'}>{name &&
                    IsInvalidIdentifier(name)}</div>
                </form>
              </div>}
              title="Add column"
              trigger="hover"
              placement="bottom"
              onVisibleChange={hide}
              visible={visible}>

              <div style={{ minWidth: 150 }}> {!isQuery &&
                (<AddButton id={'Add column'}
                            label={'Add column'}
                            onClick={() => show()}/>)}
              </div>
            </Popover>
          </div>
        </HeaderWrap>
        <ViewRow data={data} header={header} change={change}
                 onPaste={onPaste}
                 handleKey={handleQueryKey}
                 QueryRowKey={QueryRowKey}
                 handleDelete={deleteRow} moveRow={moveRow}
                 isQuery={isQuery}
                 nextItem={nextItem}/>
      </Board>
      {!isQuery && (
        <AddButton
          id={'addRow'}
          label={'Add row'}
          onClick={() => {
            flag = header[0] ? header[0].path : ''
            addRow()

          }}
        />
      )}

      <RenameModal rename={rename}
                   hideModal={hideModal('rename')}
                   saveModal={saveModal('rename')}
                   modalChange={modalChange('rename')}/>
      <RelabelModal relabel={relabel}
                    hideModal={hideModal('relabel')}
                    saveModal={saveModal('relabel')}
                    modalChange={modalChange('relabel')}/>

    </Fragment>
  )
}

export default Table
