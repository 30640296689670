import { queryClient } from '../config'
import axios from 'axios'

const evaluateFormula = async (typeId, memberId) => {
  try {
    const URL = `api/v2/types/model/${typeId}/variable/${memberId}/helps/evaluate`
    const ref = await queryClient.fetchQuery(URL, async () => {
      const { data } = await axios.get(URL)
      return data
    })
    return ref
  } catch (e) {
    console.log(e)
  }
}

export default evaluateFormula
