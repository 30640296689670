import { combineReducers } from 'redux'
import modal from './modal'
import user from './user'
import colors from './colors'
import collections from './collections'
import settings from './settings'

const rootReducer = combineReducers({
  modal,
  collections,
  user,
  colors,
  settings
})

export default rootReducer
