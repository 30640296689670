/**
 * Returns the default layout for a type.
 * Includes logic to reconcile the default layout against the list of variables in that type.
 * If variables have been added or removed from the type, this will update the default layout
 * appropriately.
 *
 * @param {*} typeDef The definition of the type (including all its variables, layouts, etc.)
 */
function getDefaultLayoutForType (typeDef, defaultColumns = 1) {
  const allLayoutsForType = typeDef.layouts
  let defaultLayout = allLayoutsForType && allLayoutsForType.find(
    layout => !layout.name || layout.name.startsWith('('),
  )
  if (!defaultLayout) {
    defaultLayout = {
      name: '(default)',
      columnCount: defaultColumns,
      rows: [],
    }
  }
  const colCount = defaultLayout.columnCount
  const rows = defaultLayout.rows
  // create index of variables in default layout
  const layoutIndex = {}
  for (let rowNum = 0; rowNum < rows.length; rowNum++) {
    const cols = rows[rowNum]
    for (let colNum = 0; colNum < cols.length; colNum++) {
      const cell = cols[colNum]
      if (cell.class === 'question') {
        layoutIndex[cell.content] = {
          cell,
          rowNum,
          colNum,
        }
      }
    }
  }
  // reconcile variables against default layout
  const vars = typeDef.variables || typeDef.properties
  const cellsToInsert = []
  for (let varNum = 0; varNum < vars.length; varNum++) {
    const thisVar = vars[varNum]
    if (thisVar.type !== 'global') {
      if (thisVar.name in layoutIndex) {
        // thisVar is already somewhere in the layout; leave it there but mark it as found
        layoutIndex[thisVar.name].found = true
      } else if (thisVar.type !== 'id') {
        // add thisVar to layout
        const cell = {
          class: 'question',
          content: thisVar.name,
        }
        if (
          varNum === 0 ||
          (varNum === 1 && vars[0].type === 'id') ||
          (varNum === 2 && vars[0].type === 'id' && vars[1].type === 'id') // both internal and external ids
        ) {
          // first variable -- add cell at top of layout
          const firstRowWithQuestion = rows.findIndex(
            cols => cols.find(c => c.class === 'question'))
          if (firstRowWithQuestion >= 0) {
            const o = layoutIndex[thisVar.name] = {
              cell,
              rowNum: firstRowWithQuestion,
              colNum: 0,
              found: true,
            }
            cellsToInsert.push(o)
          } else {
            // no existing rows have questions; add at bottom
            const o = layoutIndex[thisVar.name] = {
              cell,
              rowNum: rows.length,
              colNum: 0,
              found: true,
            }
            cellsToInsert.push(o)
          }
        } else { // varNum > 0... i.e., we're not on the first variable
          if (colCount === 1) {
            const prevVar = vars[varNum - 1]
            const prevCell = layoutIndex[prevVar.name]
            if (!prevCell) {
              throw Error(
                `Problem (bug) creating default layout for model '${typeDef.name}'`)
            }
            const prevRow = prevCell.rowNum
            // add new cell to layout below the variable that precedes it
            const o = layoutIndex[thisVar.name] = {
              cell,
              rowNum: prevRow + 1,
              colNum: 0,
              found: true,
            }
            cellsToInsert.push(o)  // todo: don't use cellsToInsert if it's really just going onto the end!
          } else {
            // add new cell at the end of the layout
            const o = layoutIndex[thisVar.name] = {
              cell,
              rowNum: rows.length,
              colNum: 0,
              found: true,
            }
            cellsToInsert.push(o)
          }
        }
      }
    }
  }
  // remove cells no longer in the variable list
  for (const o of Object.values(layoutIndex).filter(item => !item.found)) {
    rows[o.rowNum][o.colNum] = undefined
  }
  // add cells queued for insertion
  cellsToInsert.sort((a, b) =>
    (a.rowNum === b.rowNum)
      ? (a.colNum - b.colNum)
      : (a.rowNum - b.rowNum),
  ).forEach((c, index) => {
    rows.splice(c.rowNum, 0, [c.cell])
  })
  // remove undefined cells and empty rows
  for (let rowNum = rows.length - 1; rowNum >= 0; rowNum--) {
    const cols = rows[rowNum]
    for (let colNum = cols.length - 1; colNum >= 0; colNum--) {
      const cell = cols[colNum]
      if (!cell) {
        cols.splice(colNum, 1)
      }
    }
    if (cols.length === 0) {
      rows.splice(rowNum, 1)
    }
  }

  return defaultLayout
}
exports.getDefaultLayoutForType = getDefaultLayoutForType

/**
 * Enumerates all the variables in the model IN LAYOUT ORDER according to the default layout.
 * If no default layout exists, the native order of the variables is not changed.
 *
 * @param {*} typeDef The definition of the type (including all its variables, layouts, etc.)
 */
function * enumVariablesInLayoutOrder (typeDef) {
  const allLayoutsForType = typeDef.layouts
  let defaultLayout = allLayoutsForType && allLayoutsForType.find(
    layout => !layout.name || layout.name.startsWith('('),
  )
  const vars = typeDef.variables || typeDef.properties || []
  if (defaultLayout) {
    const varIndex = {}
    // first return id-type variables and build index of the rest
    for (const v of vars) {
      if (v.type === 'id') {
        yield v
      } else {
        varIndex[v.name] = v
      }
    }
    // then return the rest of the variables in default layout order
    for (const row of defaultLayout.rows) {
      for (const cell of row) {
        if (cell.class === 'question') {
          const v = varIndex[cell.content]
          if (v) {
            delete varIndex[cell.content]
            yield v
          }
        }
      }
    }
    // if anything is left (i.e., was not in the layout), return that as well
    yield * Object.values(varIndex)
  } else {
    yield * vars
  }
}
exports.enumVariablesInLayoutOrder = enumVariablesInLayoutOrder
