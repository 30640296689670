import { useEffect, useState } from 'react'
import { getMappingsForModel } from '../../../../../utils/modelmaps'
import useParseQuery from './useParseQuery'

const useQuerySelect = (itemId, linkExpr, isLink, memberName, typeName, types, onChange, isExternal = false) => {
  const [suitableChecked, setSuitableChecked] = useState(false)
  const [suitableQueries, setSuitableQueries] = useState(() => [])
  
  const fillSuitableQueries = async () => {
    const nextSuitableQueries = (typeName && !isExternal)
      ? await getMappingsForModel(typeName, types, memberName)
      : []
    setSuitableChecked(true)
    setSuitableQueries(() => nextSuitableQueries)
  }
  
  useEffect(() => {
    if (suitableChecked) {
      setSuitableChecked(false)
    }
    fillSuitableQueries()
  }, [itemId, isExternal])
  
  const {
    queryName,
    mapTo,
    mapBack,
    requiredParams,
    optionalParams,
    onUpdateOptionalParams,
  } = useParseQuery(linkExpr, types, onChange)

  const queryVisible = !!linkExpr || (suitableQueries && suitableQueries.length > 0 && !isExternal)
  const selectedQuery = linkExpr && QueryMap(queryName, mapTo, mapBack)
  const availableOptions = (
    isLink
      ? (suitableQueries && suitableQueries.links && [...suitableQueries.links])
      : (suitableQueries && [...suitableQueries])
  ) || []
  const canChangeLinkType = (suitableQueries && ('links' in suitableQueries)) || isLink
  const selectedIsValid = selectedQuery && availableOptions.includes(selectedQuery)
  const queryInvalid = selectedQuery && suitableChecked && !selectedIsValid
  if (queryVisible) {
    if (selectedQuery && (queryInvalid || !suitableChecked) && !selectedIsValid) {
      availableOptions.unshift(selectedQuery)
    }
    availableOptions.unshift('')
  }
  return {
    queryVisible /* bool: whether checkbox should be visible at all */,
    selectedQuery /* string: the query expression (if checkbox checked) or undefined (if checkbox clear) */,
    queryInvalid /* bool: whether selectedQuery is an error */,
    availableOptions /* [string]: potentially valid options for selectedQuery */,
    canChangeLinkType /* bool: whether bi-directional linking is available or not */,
    requiredParams /* [object]: array of objects representing the query's required parameter values */,
    optionalParams /* [object]: array of objects representing the query's optional parameter values */,
    onUpdateOptionalParams /* function: callback for changing optional parameter values */,
  }
}
export default useQuerySelect

function QueryMap (queryName, mapTo, mapBack) {
  return `${queryName}${mapTo && '-->' + mapTo}${mapBack && '-->' + mapBack}`
}
