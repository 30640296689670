import React, { forwardRef, useImperativeHandle, useRef } from 'react'
// import PropTypes from 'prop-types'
import { Icon } from '..'
import classNames from 'classnames'

// CircleButton.propTypes = {
//   id: PropTypes.string,
//   icon: PropTypes.string,
//   label: PropTypes.string,
//   onClick: PropTypes.func,
// }

function CircleButton (props, ref) {
  const buttonRef = useRef(null)
  useImperativeHandle(ref, () => ({
    focus: () => {
      buttonRef.current.focus()
    },
  }))
  let {
    id,
    icon,
    onClick,
    label,
    activate,
    isTurn,
  } = props
  let btn = (
    <button id={id} ref={buttonRef} onClick={onClick} type="button" autoFocus={activate || null}
            className={classNames('btn dart-btn rounded-circle circle-btn ml-1 mr-2 animate__animated',
              { 'animate__rotateDown': isTurn })}>
      <Icon type={icon} className={'circle-btn-icon'}/>
    </button>
  )
  return label
    ? (
      <div className={'d-flex align-items-center'}>
        {btn} <label htmlFor={id} style={{ marginBottom: 0 }}>{label}</label>
      </div>
    )
    : btn
}

export default forwardRef(CircleButton)
