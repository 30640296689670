import React, { Fragment } from 'react'
import { Select } from '../../../../components'
import Input from '../../../../components/Input'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'

const FILE = 'file'

function File () {

  return (
    <Fragment>
      <div className={'col-12'}>
        <hr />
      </div>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'style'}>
          {({
              value,
              onChange,
            }) => (
            <Select label={'Style'.toUpperCase()} id={'fileStyle'}
                    value={value}
                    values={['upload']}
                    onChange={onChange} />)}
        </EditMemberPropertyComponent>
      </div>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'accept'} defaultValue={''}>
          {({
              value,
              onChange,
            }) => (
            <Input label={'ACCEPT'} id={'accept'} type={'text'}
                   value={value}
                   onChange={onChange} />
          )}</EditMemberPropertyComponent>
      </div>

    </Fragment>
  )
}

export { FILE, File }
