import styled from 'styled-components'

export const Card = styled.div`
  background-color: ${props => props.theme.colorTable.back}!important;
  color: ${props => props.theme.colorTable.text}!important;
  border-color:${props => props.theme.colorTable.backBorder} !important;
`
export const BodyLight = styled.div`
 background-color: ${props => props.theme.colorTable.backLight}!important;
 color:${props => props.theme.colorTable.backLightText}!important;
`
export const NavigationDark = styled.div`
  background-color: ${props => props.theme.colorTable.backDark}!important;
  color:${props => props.theme.colorTable.backDarkText};
`
export const MenuDelete = styled.div`
  color:${props => props.theme.colorTable.backText};
`
export const TextArea = styled.textarea`
  font-size: ${props => props.theme.size}px !important;
  resize: none;
`
