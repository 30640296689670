import React, { useState } from 'react'
import { Modal } from 'antd'
import { Input } from '../../../components'

import axios from 'axios'
import moment from 'moment/moment'

const Snapshot = ({
  visible,
  onCancel,
  onSave,
  catalogName,
  snapshot,
}) => {

  const [label, setLabel] = useState(snapshot.label || moment(
    snapshot.version).format('L LTS'))

  const onSubmit = async () => {
    try {
      const { data } = await axios.post(
        `/api/collections/${catalogName}/snapshots`,
        { id: snapshot._id, label })
      console.log('data', data)
    } finally {
      onSave(label)
    }
  }

  const cancel = () => {
    onCancel()
  }

  return (
    <Modal
      title="Snapshot"
      visible={visible}
      onCancel={cancel}
      closable={false}
      footer={[
        <button key="submit"
                className={'btn btn-secondary dart-btn'}
                disabled={!label}
                onClick={onSubmit}>
          Save
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                onClick={cancel}>Cancel</button>,
      ]}
    >
      <form onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}>
        <Input type={'text'}
               value={label}
               onChange={setLabel}
        />
      </form>
    </Modal>
  )
}

export default Snapshot
