import React, { Fragment, useState } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import axios from 'axios'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import getFile from './getFile'

const AppSection = styled.div`
  background: ${(props) => props.theme.colorTable.backLight || '#9B9B9B'};
  color: ${(props) => props.theme.colorTable.backLightText || '#ffffff'}
`

const Row = ({ adapter, recordId, history, fileName, onRestore }) => {

  return (<div key={history._id} className={'col-12'}>
    <div className={'row'}>
      <div className="col my-2">{history.appName}</div>
      <div className="col my-2">{history.editor}</div>
      <div className="col my-2">{moment(history.launchTime).format('lll')}</div>
      <div className="col my-2">
        <button className={'btn btn-theme btn-sm mr-2'}
                onClick={() => onRestore(history._id)}>RESTORE
        </button>
        <button className={'btn btn-theme btn-sm'} onClick={getFile(
          `/api/collections/${adapter}/items/${recordId}/histories/${history._id}/data`,
          fileName + ' - ' + moment(history.launchTime).format() +
          '.json')}>EXPORT
        </button>
      </div>

    </div>
  </div>)
}

const Histories = ({
                     visible,
                     recordId,
                     onCancel,
                     checkInProgressOfItem,
                     fileName,
                   }) => {

  const match = useRouteMatch('/:tenancy/:adapter')
  const [restoreId, setRestoreId] = useState(null)
  const { data: histories, refetch } = useQuery(
    ['match.params.adapter', 'items', recordId, 'histories'],
    async () => {
      const { data } = await axios.get(
        `/api/collections/${match.params.adapter}/items/${recordId}/histories`)
      return data || []
    }, {
      retry: false,
      refetchOnWindowFocus: false,
    })

  const onRestore = (historyId) => {
    setRestoreId(historyId)
    onCancel()
  }

  const sendRestore = async () => {
    try {
      await axios.post(
        `/api/collections/${match.params.adapter}/items/${recordId}/histories/${restoreId}/restore`)
      checkInProgressOfItem({ _id: recordId })
      refetch()
    } catch (e) {

    } finally {
      onCancelRestore()
    }
  }

  const onCancelRestore = () => {
    setRestoreId(null)
  }

  let restore

  if (histories && restoreId) {
    restore = histories.find(history => history._id === restoreId)
  }

  return (
    <Fragment>
      <Modal
        title="History"
        visible={visible}
        onCancel={onCancel}
        maskClosable={false}
        closable={false}
        width={810}
        footer={[
          <button key="submit"
                  className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }}
                  onClick={onCancel}
          >
            Ok
          </button>,
        ]}
      >
        <div className={'p-2'}>
          <div className="row">
            <div className="col mb-1">APP:</div>
            <div className="col mb-1">EDITOR:</div>
            <div className="col mb-1">LAUNCH TIME:</div>
            <div className="col mb-1">ACTIONS:</div>
          </div>
        </div>
        <AppSection className={'p-2'}>
          <div className={'row'} style={{
            maxHeight: '30vh',
            overflow: 'auto',
          }}>
            {histories && histories.map(history => (
              <Row key={history._id}
                   adapter={match.params.adapter}
                   recordId={recordId}
                   history={history}
                   fileName={fileName}
                   onRestore={onRestore}
              />))}
          </div>
        </AppSection>
      </Modal>
      {restore && (<Modal
        title={'Restore ' + moment(restore.launchTime).format('lll')}
        visible={restore}
        onCancel={onCancelRestore}
        maskClosable={false}
        closable={false}
        width={410}
        footer={[
          <button key="submit"
                  className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }}
                  onClick={sendRestore}
          >
            Restore
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  style={{ width: 125 }}
                  onClick={onCancelRestore}>Cancel</button>,
        ]}>

      </Modal>)}
    </Fragment>
  )
}

export default Histories
