import React, { Fragment } from 'react'
import { useRecoilState, useRecoilValue, useRecoilCallback } from 'recoil'
import { TypesNavigationSelector } from './state'
import { List } from '../../../components'
import { SettingsTypesSelector, TypesSelector } from '../state'
import CheckInput from '../../../components/CheckInput'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { queryClient } from '../../../config'

const filterBy3dModals = items => items.filter(t => {
  return !(t.name.includes('clio:') || t.name.includes('fv:'))
})

const ListComponent = () => {

  const [settings, setSettings] = useRecoilState(SettingsTypesSelector)
  const types = useRecoilValue(TypesNavigationSelector)
  const history = useHistory()

  const {
    isClio,
    isFilevine,
    is3dModals,
  } = settings

  const updateSettings = (key) => {
    localStorage.setItem(key, settings[key] ? '0' : '1')
    setSettings(prevSettings => {
      return {
        ...prevSettings,
        [key]: !prevSettings[key],
      }
    })
  }

  const removeType = useRecoilCallback(({ refresh }) => async (type) => {
    console.log('removeType', type)
    try {
      const { data } = await axios.post('/api/v2/types', [
        {
          action: 'remove',
          ...type,
        }])
      if (data) {
        if (data[0]) {
          queryClient.removeQueries({ queryKey: '/api/v2/types' })
          refresh(TypesNavigationSelector)
          refresh(TypesSelector)
          history.push(`/${window.tenancy}/types`)
        }
      }
    } catch (e) {

    }
  })

  let hasExternalTypes = false
  let items = []

  if (types) {
    hasExternalTypes = !!types.find(
      type => type.name.includes('clio:') || type.name.includes('fv:'))
    items = is3dModals ? types : filterBy3dModals(types)
  }

  if ((isClio || isFilevine) && hasExternalTypes) {
    return (<Fragment>
      <CheckInput id={'Show3rdPartyModels'}
                  label={'Show 3rd party models'}
                  className={'form-check mb-2'}
                  onChange={() => updateSettings('is3dModals')}
                  checked={is3dModals}/>
      <List
        items={items}
        removeType={removeType}/>
    </Fragment>)
  }
  return (<List
    items={items}
    removeType={removeType}/>)
}

export default ListComponent
