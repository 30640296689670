import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import App from './App'
import * as serviceWorker from './serviceWorker'
import preval from 'preval.macro'
import 'bootstrap/dist/css/bootstrap.css'
import 'antd/dist/antd.css'
import './index.css'
import { toast } from 'react-toastify'
import axios from 'axios'
import './utils/locales'

window.clientVersion = preval`module.exports = new Date()`

console.log('%c  Release date: ' + window.clientVersion.toLocaleString(),
  'color: red')

console.log(process.env.NODE_ENV)

if (window.location.host === 'www.actionstep-builder.com') {
  document.title = 'ActionStep'
  let link = document.querySelector('link[rel~=\'icon\']')
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.head.appendChild(link)
  }
  link.href = '/actionstep.ico'
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://69cbc191499c401b933400314cb22465@sentry.io/1775820',
  })
}
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  let errMsg

  const url = error && error.response && error.response.config &&
    error.response.config.url
  if (url && url.includes('isTenancy')) {
    return
  }

  const responseVal = error && error.request && error.request.response
  if (typeof responseVal === 'object') {
    errMsg = responseVal.toString()
    if (errMsg.startsWith('[object')) {
      errMsg = error.message
    }
    errMsg = formatErrors(errMsg)
  } else {
    errMsg = String(responseVal)
  }
  if (errMsg.length === 0) {
    return null
  }
  toast.error(
    <div>
      {formatErrors(errMsg)}
    </div>, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    })
  return Promise.reject(errMsg)
})

window.onerror = function (message, url, lineNumber) {
  if (message.includes('ResizeObserver')) {
    return true
  }
  if (message.includes('getValue')) {
    return true
  }
  if (message === 'Uncaught SyntaxError: Unexpected token <'
    || message === 'Uncaught SyntaxError: Unexpected token \'<\'') {
    return true
  }
  if (message ===
    'Uncaught Invariant Violation: Expected to find a valid target.') {
    console.log(message, url, lineNumber)
    return true
  }
  if (message ===
    'TypeError: can\'t access property "session", editor is null') {
    return true
  }
  if (message === 'SyntaxError: expected expression, got \'<\'') {
    return true
  }
  if (message.includes('session')) {
    return true
  }

  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false,
  })
  return true
}

const formatErrors = (str) => {
  let errHtml = []
  let errorArray = str.split('\n')
  if (errorArray.length === 1) {
    return str
  }
  let i = 0
  while (i < errorArray.length) {
    if ((i < errorArray.length - 1) && /^[ ^]+$/.test(errorArray[i + 1])) {
      errHtml.push(<pre style={{ color: 'white' }}>{`${errorArray[i]}
${errorArray[i + 1]}`}</pre>)
      i++
    } else {
      errHtml.push(<p>{errorArray[i]}</p>)
    }
    i++
  }
  return errHtml
}

ReactDOM.render(<App/>, document.getElementById('root'))

serviceWorker.unregister()
