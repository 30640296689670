import axios from 'axios'

const redirectNetDocuments = async (documentId) => {
  try {
    const { data } = await axios.get(
      '/api/onedrive/files/' + documentId)
    window.open(data.redirectUrl, '_blank').focus()
  } catch (e) {

  }
}

export default redirectNetDocuments
