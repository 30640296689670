import React, { useMemo, useState } from 'react'

import { ListMembers } from './knackly-panel'

import CheckInput from '../../../components/CheckInput'
import LoadingType from '../../../utils/loadingType'
import ErrorBoundary from '../../../components/ErrorBoundary'

import { useDrag } from 'react-dnd'
import SearchOptionsList from '../../../components/SearchOptionsList'
import DefaultActionComponent from './defaultActionComponent'
import { useRecoilValue } from 'recoil'
import { CurrentTypeSelector, TypesSelector } from '../state'
import evaluateFormula from '../../../utils/evaluateFormula'
import serialization from '../../../utils/panel/serialization'
import getMembers from '../../../utils/panel/getMembers'

const RightPanel = () => {
  const [goTo, setGoTo] = useState('')
  let [isVariables, setIsVariables] = useState(true)
  let [isTemplates, setIsTemplates] = useState(false)
  let [isFormulas, setIsFormulas] = useState(false)
  let [isGlobal, setIsGlobal] = useState(false)

  const activeType = useRecoilValue(CurrentTypeSelector)
  const types = useRecoilValue(TypesSelector)
  const clearGoto = () => {
    setGoTo('')
  }

  const MemoIsVariables = useMemo(
    () => (
      <div className="col">
        <CheckInput id={'variables'} label={'variables'}
                    className={'form-check'}
                    onChange={() => setIsVariables(!isVariables)}
                    checked={isVariables}/>
      </div>
    ),
    [isVariables],
  )

  const MemoIsTemplates = useMemo(
    () => (
      <div className="col">
        <CheckInput id={'templates'} label={'templates'}
                    className={'form-check'}
                    onChange={() => setIsTemplates(!isTemplates)}
                    checked={isTemplates}/>
      </div>
    ),
    [isTemplates],
  )

  const MemoIsFormulas = useMemo(
    () => (
      <div className="col">
        <CheckInput id={'formulas'} label={'formulas'} className={'form-check'}
                    onChange={() => setIsFormulas(!isFormulas)}
                    checked={isFormulas}/>
      </div>
    ),
    [isFormulas],
  )

  const MemoIsGlobal = useMemo(
    () => (
      <div className="col">
        <CheckInput id={'global'}
                    label={'global'}
                    className={'form-check'}
                    onChange={() => setIsGlobal(!isGlobal)}
                    checked={isGlobal}/>
      </div>
    ),
    [isGlobal],
  )

  if (!activeType) {
    return null
  }
  return (
    <div className={'col-3'}>
      <SearchOptionsList activeType={activeType}
                         types={types}
                         isVariables={isVariables}
                         isTemplates={isTemplates}
                         isFormulas={isFormulas}
                         isGlobal={isGlobal}
                         goTo={goTo}
                         loadingType={LoadingType}
                         onGoTo={value => setGoTo(value)}/>
      <div className={'row mb-1'}>
        <div className={'col-12'}>
          <div className={'ml-2'}>Show:</div>
        </div>
        <div className={'col-12'}>
          <div className={'mx-2'}>
            <div className={'row'}>
              {MemoIsVariables}
              {MemoIsTemplates}
              {MemoIsFormulas}
              {MemoIsGlobal}
            </div>
          </div>
        </div>
      </div>
      <div className={'d-flex overflow-auto flex-column'}
           style={{ height: 'calc(100vh - 13.25rem)' }}>
        <ErrorBoundary>
          <ListMembers
            activeType={activeType}
            types={types}
            goToSteps={goTo ? goTo.split('.') : []}
            clearGoto={clearGoto}
            isVariables={isVariables}
            isTemplates={isTemplates}
            isFormulas={isFormulas}
            isGlobal={isGlobal}
            getMembers={getMembers}
            serialization={serialization}
            useDrag={useDrag}
            evaluateFormula={evaluateFormula}
            loadingType={LoadingType}
            ActionComponent={DefaultActionComponent}
          />
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default RightPanel
