import React from 'react'
import Formula from './formula'
import {
  CurrentMemberSelector,
} from '../state'
import { useRecoilValue } from 'recoil'
import NavigationMembers from '../navigationMembers'

const FormulasComponent = () => {

  const CurrentMember = useRecoilValue(CurrentMemberSelector)

  return (<div className={'row'}>
    <NavigationMembers />
    <div className="col-9 px-3 py-2 knackly-plain overflow-auto"
         style={{ maxHeight: 'calc(100vh - 16rem)' }}>
      {CurrentMember && (<React.Suspense fallback={<div>Loading...</div>}>
        <Formula />
      </React.Suspense>)}
    </div>
  </div>)
}

export default FormulasComponent
