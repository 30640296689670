import React, { Fragment, useState } from 'react'
import { GLOBAL } from '../../config'
import { produce } from 'immer'
import Version from './version'
import { useQuery } from 'react-query'
import axios from 'axios'
import { Dropdown, Menu } from 'antd'
import Icon from '../../components/Icon'
import ImportGlobal from './modal/importGlobal'
import RemoveGlobal from './modal/removeGlobal'

const Globals = ({
  type,
  appOptions,
  catalogName,
  snapshots,
  onChange,
  updateSnapshot,
}) => {
  const {
    data: types,
  } = useQuery(['types', 'params', 'name,type,apps'], async () => {
    let params = {
      fields: 'name,type,apps',
      filter: { type: 'collection' },
    }

    const { data } = await axios.get('/api/v2/types', {
      params,
    })
    return data
  }, {
    retry: false,
    refetchOnWindowFocus: true,
  })

  let {
    data: globals,
    refetch,
  } = useQuery(['collections', 'globals', catalogName], async () => {
    try {
      const { data } = await axios.post(
        `/api/collections/${catalogName}/globals/list`)
      return data
    } catch (e) {
      console.error(e)
    }
  }, {
    retry: false,
    refetchOnWindowFocus: true,
  })

  const getType = (typeName) => {
    if (!types) {
      return null
    }
    return types.find(t => t.name === typeName)
  }

  if (!type) {
    return null
  }

  return (
    <Fragment>
      {type.properties.filter(el => el.type === GLOBAL).
        map(el => {
          const [ImportVisible, setImportVisible] = useState(false)
          const [RemoveVisible, setRemoveVisible] = useState(false)
          const onCancelImport = async () => {
            refetch()
            setImportVisible(false)
          }
          const onCancelRemove = () => {
            refetch()
            setRemoveVisible(false)
          }

          const appOpt = appOptions.find(
            ao => ao.globalTarget === el.name) || {}

          const appName = appOpt.appName || ''
          const liveVersion = appOpt.liveVersion

          const isDisabled = appOpt.isDisabled || false
          const globalType = getType(el.typeName)
          const apps = globalType ? globalType.apps || [] : []

          const onChangeAppName = event => {
            if (typeof event.target.value === 'string') {
              onChange(produce(appOpt, draft => {
                draft['appName'] = event.target.value
                draft['globalTarget'] = el.name
              }))
            }
          }

          const onChangeVersion = id => {
            onChange(produce(appOpt, draft => {
              draft['liveVersion'] = id
              draft['isDisabled'] = false
            }))
          }
          const onChangeIsDisabled = value => {
            onChange(produce(appOpt, draft => {
              draft['isDisabled'] = value
            }))
          }

          const onExport = async () => {
            try {
              const { data } = await axios.post(
                `/api/collections/${catalogName}/globals/export`,
                { elementName: el.name })
              const _url = window.URL.createObjectURL(
                new Blob([JSON.stringify(data)], { type: 'application/json' }))
              const link = document.createElement('a')
              link.href = _url
              link.setAttribute('download', el.name + '.json')

              document.body.appendChild(link)
              link.click()
            } catch (e) {

            }
          }

          const isActionDisabled = !(globals && globals[el.name])

          return (<div className={'row'} key={el._id}>
            <div className={'col-3'}>
              <div className={'row'}>
                <div className={'col-6'}><label className={'my-2'}
                                                style={{ fontWeight: 'bold' }}>{el.name}</label>
                </div>
                <div className={'col-6'}>
                  <select className="custom-select mb-3 rounded-0"
                          value={appName}
                          onChange={onChangeAppName}>
                    <option></option>
                    {apps.map(app => (<option key={app._id}
                                              value={app.name}>{app.name}</option>))}
                  </select>
                </div>
              </div>
            </div>
            <Version
              changeVersion={onChangeVersion}
              liveVersion={liveVersion}
              isDisabled={isDisabled}
              updateSnapshot={updateSnapshot}
              catalogName={catalogName}
              changeIsDisabled={onChangeIsDisabled}
              snapshots={snapshots.filter(
                snapshot => snapshot.AppID === appName).sort(
                (a, b) => new Date(b.version) - new Date(a.version))}
            />
            <div className={'col-5'}>
              {appName && (<Dropdown overlay={<Menu>
                <Menu.Item onClick={() => setImportVisible(true)}>
                <span><Icon type="icon-Import"
                            style={{
                              fontSize: 14,
                              verticalAlign: '0.1em',
                            }}/><span> Import</span>
                </span>
                </Menu.Item>
                <Menu.Item onClick={onExport}
                           disabled={isActionDisabled}>
                <span><Icon type="icon-export"
                            style={{
                              fontSize: 14,
                              verticalAlign: '0.1em',
                            }}/><span> Export</span>
                </span>
                </Menu.Item>
                <Menu.Item onClick={() => setRemoveVisible(true)}
                           disabled={isActionDisabled}>
                <span><Icon type="icon-trash"
                            style={{
                              fontSize: 14,
                              verticalAlign: '0.1em',
                            }}/><span> Delete</span>
                </span>
                </Menu.Item>
              </Menu>}
              >
                <button className={'btn'}>
                  <Icon type="icon-more" classNames={'my-2'} style={{
                    fontSize: '1.2rem',
                  }}/>
                </button>
              </Dropdown>)}
              {ImportVisible && (<ImportGlobal
                elementName={el.name}
                catalogName={catalogName}
                appName={appName}
                visible={ImportVisible}
                onCancel={onCancelImport}
              />)}
              {RemoveVisible && <RemoveGlobal
                elementName={el.name}
                catalogName={catalogName}
                appName={appName}
                visible={RemoveVisible}
                onCancel={onCancelRemove}
              />}
            </div>
          </div>)
        })
      }
    </Fragment>)
}

export default Globals
