import React, { Fragment, useState } from 'react'
import { Modal } from 'antd'

const ModalConfirm = ({ title, children, submit }) => {
  let [active, setActive] = useState(false)
  let onCancel = () => setActive(false)
  let onActive = () => setActive(true)
  let onSubmit = () => {
    onCancel()
    submit()
  }
  return (<Fragment>
      <div onClick={onActive}>
        {children}
      </div>

      <Modal title={title}
             visible={active}
             onCancel={onCancel}
             closable={false}
             width={410}
             footer={[
               <button key="submit" className={'btn btn-secondary dart-btn'} style={{ width: 125 }} onClick={onSubmit}>
                 Ok
               </button>,
               <button key="back" className={'btn btn-outline-secondary dart-btn-outline'} style={{ width: 125 }}
                       onClick={onCancel}>Cancel</button>
             ]}
      />
    </Fragment>
  )
}

export default ModalConfirm