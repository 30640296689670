const XRegExp = require('xregexp')

const identFirstChar = XRegExp('\\p{Lu}|\\p{Ll}|\\p{Lt}|\\p{Lm}|\\p{Lo}|\\p{Nl}')
const identRemaining = XRegExp('^(?:\\p{Lu}|\\p{Ll}|\\p{Lt}|\\p{Lm}|\\p{Lo}|\\p{Nl}|\\p{Mn}|\\p{Mc}|\\p{Nd}|\\p{Pc})+$')

function IsInvalidIdentifier (ident) {
  if (typeof ident !== 'string') return 'name must be a text string'
  if (ident.length < 1) return 'name cannot be empty'
  if (ident[0] === '$' || ident[0] === '_') return 'first character must be a letter'
  if (ident.indexOf(' ') >= 0) return 'name cannot contain spaces'
  if (jsReserved.includes(ident)) return `'${ident}' is a reserved word`
  if (!identFirstChar.test(ident[0])) return 'first character must be a letter'
  if (!identRemaining.test(ident)) return 'name can include only letters, numbers and underscores'
  // else
  return false
}

function validateElementName (kindOfElement, name, allElements) {
  const errors = {}
  let err, existing
  if (!name) {
    errors.name = `Please provide a name for this ${kindOfElement}.`
  } else if ((err = IsInvalidIdentifier(name))) {
    errors.name = `Invalid: ${err}.`
    errors.nameForce = true
  } else if ((existing = allElements && allElements.find(e => e.name === name))) {
    errors.name = (existing.kind
      ? 'A ' + existing.kind
      : 'An element'
    ) + ' by this name already exists.'
    errors.nameForce = true
    errors.nameConflict = true
  } else {
    errors.name = ''
  }
  return errors
}

function validateTypeMember (kindOfMember, type, typeName, name, variables, templates, formulas, apps) {
  const errors = {}
  if (!type) {
    errors.type = `You must choose a type for this ${kindOfMember}.`
  } else {
    errors.type = ''
  }
  let err
  errors.nameForce = true
  if (!name) {
    errors.name = 'Please provide a name.'
  } else if ((err = IsInvalidIdentifier(name))) {
    errors.name = `Invalid: ${err}.`
  } else if (variables && variables.find(v => v.name === name)) {
    errors.name = 'A variable by this name already exists.'
    errors.nameConflict = true
  } else if (templates && templates.find(t => t.name === name)) {
    errors.name = 'A template by this name already exists.'
    errors.nameConflict = true
  } else if (formulas && formulas.find(f => f.name === name)) {
    errors.name = 'A formula by this name already exists.'
    errors.nameConflict = true
  } else if (apps && apps.find(a => a.name === name)) {
    errors.name = 'An app by this name already exists.'
    errors.nameConflict = true
  } else if (type === 'object' && !typeName) {
    errors.name = `Choose the type (model) of object the ${kindOfMember} will ${kindOfMember === 'variable' ? 'represent' : 'produce'}.`
  } else {
    errors.nameForce = false
    errors.name = ''
  }
  return errors
}

// [Lowell] I'm not sure if we need to exclude JS reserved words, but just to be on the safe side, I'm doing it for now.
// Relaxing restrictions later is always easier than introducing them. :-)
const jsReserved = [
  'true',
  'false',
  'null',
  'NaN',
  'Infinity',
  'undefined',
  'break',
  'case',
  'catch',
  'class',
  'const',
  'continue',
  'debugger',
  'default',
  'delete',
  'do',
  'else',
  'export',
  'extends',
  'finally',
  'for',
  'function',
  'if',
  'import',
  'in',
  'instanceof',
  'new',
  'return',
  'super',
  'switch',
  'this',
  'throw',
  'try',
  'typeof',
  'var',
  'void',
  'while',
  'with',
  'yield',
  'enum',
  'implements',
  'interface',
  'let',
  'package',
  'private',
  'protected',
  'public',
  'static',
  'await',
  'abstract',
  'boolean',
  'byte',
  'char',
  'double',
  'final',
  'float',
  'goto',
  'int',
  'long',
  'native',
  'short',
  'synchronized',
  'throws',
  'transient',
  'volatile',
]

module.exports = {
  IsInvalidIdentifier,
  validateElementName,
  validateTypeMember,
}
