import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { Modal } from 'antd'
import Search from '../../components/Search'

import Icon from '../../components/Icon'
import styled from 'styled-components'
import Cart from './cart'

const styleForIcon = {
  fontSize: 18, verticalAlign: 1, marginRight: 5,
}

const ItemModal = styled.div`
    color: ${(props) => props.theme.colorTable.text};
    background: ${(props) => props.theme.colorTable.plain};
    border-color: ${(props) => props.theme.colorTable.backBorder};

    &:hover {
        background: ${(props) => props.theme.colorTable.backLightHover};
    }
;

    &.accent {
        color: ${(props) => props.theme.colorTable.listSelectionText};
        background: ${(props) => props.theme.colorTable.listSelection};
    }
`

const maskCollection = (collection) => {
  if (collection.myRecords) {
    return `/${window.tenancy}/${collection.name}?f=%7B"myrecords"%3A%7B%7D%7D`
  }
  return `/${window.tenancy}/` + collection.name
}

const isActiveCurrent = (collection) => {
  const p = `/${window.tenancy}/${collection.name}`
  return window.location.pathname === p ||
    window.location.pathname.startsWith(p + '/')
}

const CardModal = ({ collection, index, onGo }) => {
  const [isHide, setIsHide] = useState(false)
  return (
    <ItemModal onClick={(event) => {
      event.preventDefault()
      onGo(collection)
    }}
               onMouseOut={() => setIsHide(false)}
               onMouseOver={() => setIsHide(true)}
               className={classNames(
                 'col-12 border p-3 d-flex justify-content-between cursorPointer',
                 { 'accent': isHide }, { 'border-top-0': index > 0 })}>
      <div>
        <Icon type={'icon-cabinet1'} style={styleForIcon}/>
        {collection.name}
      </div>
    </ItemModal>
  )
}
const More = ({ items, onNextRoot }) => {
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')
  const onCancel = () => setVisible(false)
  const onShow = () => setVisible(true)
  const onSearch = (value) => setSearch(value)
  const onGo = (collection) => {
    onCancel()
    onNextRoot(maskCollection(collection))
  }

  if (search) {
    items = items.filter(
      item => item.name.toLowerCase().includes(search.toLowerCase()))
  }

  return (<Fragment>
    {visible && (
      <Modal
        title="CATALOGS"
        visible={visible}
        onCancel={onCancel}
        maskClosable={true}
        closable={false}
        width={410}
        footer={[]}
      >
        <Search onSubmit={onSearch}/>
        <div className={'mt-2'}/>
        <div style={{
          maxHeight: 300, overflowY: 'auto',
        }}>
          {items.map((item, index) => (<CardModal key={item.name}
                                                  collection={item}
                                                  index={index}
                                                  onGo={onGo}
            />
          ))}
        </div>
      </Modal>)}
    <div
      className={'d-inline font-weight-light text-uppercase'}
    >
      <a
        className={classNames('p-3')}
        href={'#more'}
        onClick={onShow}>
        <Icon type={'icon-more'} style={{ fontSize: 18 }}/>
      </a>
    </div>
  </Fragment>)
}
const Collections = ({ items = [], onNextRoot }) => {
  if (items.length > 6) {
    const firstFive = items.slice(0, 5)
    const isOther = !firstFive.some(isActiveCurrent)
    const other = isOther && items.find(isActiveCurrent)
    return (<Fragment>
      {firstFive.map(collection => (<Cart key={collection.name}
                                          collection={collection}
                                          onNextRoot={onNextRoot}/>))}
      {isOther && other && (<Cart key={other.name}
                                  collection={other}
                                  onNextRoot={onNextRoot}/>)}
      <More items={items} onNextRoot={onNextRoot}/>
    </Fragment>)
  }
  return (<Fragment>
    {items.map(collection => (
      <Cart key={collection.name}
            collection={collection}
            onNextRoot={onNextRoot}/>))}
  </Fragment>)
}

export default Collections
