import React, { useRef, useState } from 'react'
import { Button, Modal } from 'antd'
import { FilePond } from 'react-filepond'
import axios from 'axios'
import mime from 'mime-types'
import { ReactComponent as Spinner } from '../../images/icons/spinner.svg'
import { queryClient } from '../../config'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { TypesSelector, VisibleElementModal } from '../../views/types/state'
import getWorkspaceURL from '../../config/workspaceURL'

const ImportElement = () => {

  const visible = useRecoilValue(VisibleElementModal)
  const filePondRef = useRef(null)
  const [file, setFile] = useState(undefined)
  const [disabledOk, setDisabledOk] = useState(true)
  const [disabledCancel, setDisabledCancel] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [option, setOption] = useState('favorImported')
  const onClose = useRecoilCallback(({
    set, refresh,
  }) => (isRefresh) => {
    set(VisibleElementModal, false)
    if (isRefresh) {
      refresh(TypesSelector)
    }
  }, [])

  const onRemoveFile = async (error, removeFile) => {
    if (error) {
      console.log(error)
    }
    try {
      await axios.delete(`/upload/importModel`, {}, { url: removeFile.url })
    } catch (e) {
      console.error(e)
    } finally {
      setFile(null)
    }
  }
  const process = (fieldName, selectedFile, metadata, load, error, progress,
    abort, transfer, options) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const formData = new FormData()
    formData.append('file', selectedFile)
    setDisabledOk(true)
    const config = {
      baseURL: getWorkspaceURL(), onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
          progressEvent.target.getResponseHeader(
            'x-decompressed-content-length')
        if (totalLength !== null) {
          progress(progressEvent.lengthComputable, progressEvent.loaded,
            totalLength)
        }
      }, cancelToken: source.token,
    }

    axios.post(`/upload/importModel`, formData, config).then((response) => {
      console.log('response.data', response.data)
      load({
        _contentType: response.data.type || selectedFile.type,
        key: response.data.url,
      })

      setFile({
        source: selectedFile, url: response.data.url, options: {
          type: 'limbo',
        },
      })
    }).catch(thrown => {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message)
      } else {
        error(thrown)
      }
    }).finally(() => {
      setDisabledOk(false)
    })
    return {
      abort: () => {
        source.cancel('Operation canceled by the user.')
        abort()
      },
    }
  }

  const handleSubmit = async () => {
    setDisabledOk(true)
    setInProgress(true)
    setDisabledCancel(true)
    try {
      await axios.post('/api/types/import', {
        url: file.url,
        logic: option,
      })
      const { data: nextTypes } = await axios.get(
        '/api/types?fields=name,type,_id')
      queryClient.setQueryData('/api/types?fields=name,type,_id', nextTypes)

      onClose(true)
      setDisabledOk(true)
      setInProgress(false)
      setOption('favorImported')
      setDisabledCancel(false)
      if (filePondRef.current) {
        filePondRef.current.removeFiles()
      }
      onRemoveFile(undefined, file)

      window.location.reload()
    } catch (e) {
      console.error(e)
      setDisabledOk(false)
      setInProgress(false)
      setDisabledCancel(false)
    }
  }
  return (<Modal visible={visible}
                 title={'Import model'}
                 closable={false}
                 maskClosable={false}
                 width={500}
                 footer={[
                   <Button key="submit" className={'btn btn-secondary dart-btn'}
                           style={{ width: 125 }} onClick={handleSubmit}
                           disabled={disabledOk}>
                     {inProgress ? (<Spinner className={'rot'} style={{
                       width: 20, top: -4, position: 'relative',
                     }}/>) : 'Ok'}
                   </Button>, <Button key="back"
                                      className={'btn btn-outline-secondary dart-btn-outline'}
                                      style={{ width: 125 }}
                                      disabled={disabledCancel}
                                      onClick={() => {
                                        onClose()
                                        setOption('favorImported')
                                        setDisabledOk(true)
                                        if (file) {
                                          if (filePondRef.current) {
                                            filePondRef.current.removeFiles()
                                          }
                                          onRemoveFile(undefined, file)
                                        }
                                      }}>
                     Cancel
                   </Button>]}>
    <FilePond
      ref={filePondRef}
      allowMultiple={false}
      name={'importModel'}
      file={file ? [file] : []}
      onremovefile={onRemoveFile}
      server={{
        process,
      }}
      acceptedFileTypes={['application/json', 'application/zip']}
      fileValidateTypeLabelExpectedTypesMap={{
        'application/json': '.json', 'application/zip': '.zip',
      }}
      fileValidateTypeDetectType={async (source, type) => mime.lookup(
        source.name)}
      labelIdle='Drag &amp; Drop your file or <span class="filepond--label-action">Browse</span>'
    />
    <label>In case of conflicts, attempt to preserve functionality of</label>
    <div className="form-check">
      <input className="form-check-input"
             type="radio"
             name="favorImported"
             id="favorImported1"
             checked={option === 'existing'}
             onClick={() => setOption('existing')}
      />
      <label className="form-check-label" htmlFor="favorImported1">
        existing element
      </label>
    </div>
    <div className="form-check">
      <input className="form-check-input"
             type="radio"
             name="favorImported"
             id="favorImported2"
             checked={option === 'imported'}
             onClick={() => setOption('imported')}
      />
      <label className="form-check-label" htmlFor="favorImported2">
        imported element
      </label>
    </div>
    {window.knacklyAddons.dev && (<div className="form-check">
      <input className="form-check-input"
             type="radio"
             name="favorImported"
             id="restoreImported2"
             checked={option === 'restore'}
             onClick={() => setOption('restore')}
      />
      <label className="form-check-label" htmlFor="restoreImported2">
        replace all elements
      </label>
    </div>)}
  </Modal>)
}

export default ImportElement
