import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '..'
import styled from 'styled-components'
import classnames from 'classnames'

AddButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}
const LabelForAddButton = styled.label`
  color:${props => props.theme.colorTable.backText}
`

function AddButton (props) {
  let { id, onClick, label, disabled } = props
  const MemoAddButton = useMemo(() => {
    return (<Fragment>
      <button id={id} onClick={onClick} type="button"
              className="btn dart-btn text-white rounded-circle circle-btn ml-1 mr-2" disabled={disabled}>
        <Icon type="icon-plus2" className={'circle-btn-icon'}/>
      </button>
      <LabelForAddButton htmlFor={id}
                         className={classnames('mt-1 mb-0', { 'knackly-back-d': disabled })}
      >
        {label}
      </LabelForAddButton>
    </Fragment>)
  }, [id, onClick, label, disabled,])
  return MemoAddButton
}

AddButton.defaultProps = {
  disabled: false,
}
export default AddButton
