import React, {
  useCallback,
  useState,
  useEffect, Fragment,
} from 'react'
import { Modal } from 'antd'
import axios from 'axios'
import { FilePond } from 'react-filepond'
import styled from 'styled-components'
import getJSON from '../../utils/getJSON'
import { matchPath } from 'react-router'
import AsyncSelect from '../../components/AsyncSelect'
import { InferLabelFromAppName } from '../../utils'
import getWorkspaceURL from '../../config/workspaceURL'

const SectionBlock = styled.div`
  background: ${(props) => props.theme.colorTable.backLight || '#9B9B9B'};
  color: ${(props) => props.theme.colorTable.backLightText || '#ffffff'}
`

const Upload = ({
  files,
  setFiles,
}) => {
  return (
    <div className={'col-12'}>
      <FilePond
        allowMultiple={false}
        name={'import'}
        files={files}
        onupdatefiles={setFiles}
        acceptedFileTypes={'.csv, application/json'}
        labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
      />
    </div>)
}

const Summary = ({
  total,
  succeeded,
  failed,
}) => {
  const newTotal = total || (succeeded + failed)
  const percents = (succeeded * 100 / newTotal).toFixed()
  return (<div className="col-12">
    <h3 className={'mb-2'}>Summary</h3>
    <div className={'row'}>
      <div className={'col-4'}>TOTAL</div>
      <div className={'col-4'}>SUCCEEDED</div>
      <div className={'col-4'}>FAILED</div>
    </div>
    <SectionBlock className={'row'}>
      <div className={'col-4 py-2'}>{newTotal} elements</div>
      <div className={'col-4 py-2'}>{succeeded} elements, ({isNaN(percents)
        ? '0'
        : percents}%)
      </div>
      <div className={'col-4 py-2'}>{failed}</div>
    </SectionBlock>
  </div>)
}

const ImportItems = ({
  visible,
  onClose,
  userId,
  loadFirstItems,
  apps,
}) => {
  const [files, setFiles] = useState([])
  const [step, setStep] = useState('upload')
  const [progress, setProgress] = useState(false)
  const [succeeded, setSucceeded] = useState(0)
  const [total, setTotal] = useState(0)
  const [failed, setFailed] = useState(0)
  const [initApp, setInitApp] = useState('')

  const onCancel = useCallback(() => {
    if (!progress) {
      onClose()
      setFiles([])
      setStep('upload')
      setSucceeded(0)
      setTotal(0)
      loadFirstItems()
      setInitApp('')
    }
  }, [progress])

  useEffect(() => {
    return () => onCancel()
  }, [])

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()
    setProgress(true)

    const file = files[0].file

    let values, valueCount
    if (file.type === 'text/csv') {
      const formData = new FormData()
      formData.append('file', file)
      const { data: nextValues } = await axios.post('/upload/parse/csv',
        formData)
      values = nextValues
      valueCount = values.length
    }

    if (file.type === 'application/json') {
      let nextValues = await getJSON(file)
      if (!Array.isArray(nextValues)) {
        nextValues = [nextValues]
      }
      values = nextValues
      valueCount = values.length
    }

    const {
      data: {
        sessionId,
      },
    } = await axios.post('/upload/import/uploadValues', { values })

    setStep(prevState => {
      if (prevState === 'upload') {
        return 'summary'
      }
    })

    startImport(sessionId, valueCount)
  }, [files, initApp])

  const startImport = async (sessionId, initialCount = 0) => {
    if (!sessionId) {
      return
    }

    const match = matchPath(window.location.pathname, {
      path: '/:tenancy/:adapter',
      exact: true,
      strict: false,
    })

    const adapter = match.params.adapter

    let url = `${getWorkspaceURL()}/api/collections/${adapter}/import?sessionId=${sessionId}&userId=${userId}`
    if (initApp) {
      url += `&initApp=${initApp}`
    }
    const event = new EventSource(url)
    setProgress(true)
    if (initialCount > 0) {
      setTotal(initialCount)
    }
    event.addEventListener('compiling', function (e) {
      console.log('compiling received')
      // TODO: show somehow, in the UI, that the requested app is currently compiling
    })
    event.addEventListener('total', function (e) {
      setTotal(parseInt(e.data))
    })
    event.addEventListener('succeeded', function (e) {
      setSucceeded(prevState => prevState + 1)
    })
    event.addEventListener('failed', function (e) {
      setFailed(prevState => prevState + 1)
    })
    event.addEventListener('completed', function (e) {
      event.close()
      setProgress(false)
    })
    event.addEventListener('error', function (e) {
      event.close()
      setProgress(false)
    })
  }

  return (
    <Modal
      title="Import JSON or CSV"
      visible={visible}
      onCancel={onCancel}
      closable={false}
      width={610}
      footer={step === 'upload' ? [
        <button key="submit" className={'btn btn-secondary dart-btn'}
                style={{ width: 125 }} onClick={onSubmit}
                disabled={files.length === 0 || progress}>
          Upload
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                style={{ width: 125 }}
                disabled={progress}
                onClick={onCancel}>Cancel</button>,
      ] : [
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                style={{ width: 125 }}
                onClick={onCancel} disabled={progress}>Close</button>]}
    >
      <form onSubmit={onSubmit}>
        <div className={'row'}>
          {step === 'upload' &&
            (<Fragment>
              <Upload files={files} setFiles={setFiles}/>
              <div className={'col-12'}>
                <div className={'mb-2'}>
                  <AsyncSelect id={'newVarObjectType'}
                               label={'Prepare for use with app (optional)'}
                               options={[
                                 { value: '', label: '(none)' },
                                 ...apps.map(app => {
                                   return {
                                     value: app.name,
                                     label: app.label ||
                                       InferLabelFromAppName(app.name),
                                   }
                                 })]}
                               value={initApp}
                               onChange={selected => {
                                 if (selected) {
                                   setInitApp(selected.value)
                                 }
                               }}
                               isInsideModal={true}
                  />
                </div>
              </div>
            </Fragment>)}
          {step === 'summary' &&
            <Summary total={total} succeeded={succeeded}
                     failed={failed}/>}
        </div>
      </form>
    </Modal>
  )
}

export default ImportItems
