import axios from 'axios'

const getFile = (url, name) => async () => {
  console.log('getFile', url, name)
  try {
    const { data } = await axios.get(url, { responseType: 'blob' })
    const _url = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = _url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    console.error(e.toString())
  }
}

export default getFile
