import React, { Fragment, useCallback, useState } from 'react'
import { Ace, AddButton, Input, RadioInputs, Select } from '../../../../components'
import { InferLabelFromName } from '../../../../utils'
import { Modal } from 'antd'
import update from 'immutability-helper'
import OptionCard from './dnd/OptionCard'
import { useRecoilValue } from 'recoil'
import { editMemberProperty } from '../../state'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'

const TEXT = 'text'

const ExpressionOptions = ({
                             data,
                             change,
                           }) => {
  return (<div
    className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>
    <Ace
      label={'SUGGESTIONS EXPRESSION'}
      mode={'kexpr'}
      onChange={change}
      className={'border-0'}
      value={data}
      isExpression={true}
      type={'text'}
      maxLines={Infinity}/>
  </div>)
}
const LocalOptions = ({
                        data,
                        change,
                      }) => {

  let [isOpen, setIsOpen] = useState(false)
  let [isEdit, setEdit] = useState(-1)
  let [option, setOption] = useState('')
  let [optionEdit, setOptionEdit] = useState('')

  let preData
  try {
    preData = JSON.parse(data)
  } catch (e) { // guard against invalid stuff here crashing the UI
    preData = []
  }

  let onCancel = () => {
    setIsOpen(false)
    setEdit(-1)
    setOption('')
    setOptionEdit('')
  }
  let submit = () => {
    if (option) {
      preData.push(option)
      setOption('')
      change(JSON.stringify([...new Set(preData)]))
    }
    setIsOpen(false)
  }

  let submitEdit = () => {
    preData[isEdit] = optionEdit
    change(JSON.stringify([...new Set(preData)]))
    onCancel()
  }
  let removeItem = option => change(
    JSON.stringify(preData.filter(d => d !== option)))

  let moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = preData[dragIndex]
      const next = update(preData, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
      })
      change(JSON.stringify([...new Set(next)]))
    },
    [preData],
  )

  return (<Fragment>
      {preData.length > 0 && (<div className={'rounded border my-2 p-2'}>
        {preData.map((d, i) => <OptionCard d={d} i={i} setEdit={setEdit}
                                           setOptionEdit={setOptionEdit}
                                           removeItem={removeItem}
                                           moveCard={moveCard}/>)}
      </div>)}
      <AddButton id={'addOption'} onClick={() => {
        setIsOpen(true)
      }} label={'Add suggested answer'}/>
      <Modal
        title="Add suggested answer"
        visible={isOpen}
        onCancel={onCancel}
        closable={false}
        width={410}
        footer={[
          <button key="submit" className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }} onClick={submit}>
            Ok
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  style={{ width: 125 }}
                  onClick={onCancel}>Cancel</button>,
        ]}
      >
        <form onSubmit={e => {
          e.preventDefault()
          submit()
        }}>
          <div className={'row'}>
            <div className={'col-12'}>
              <Input type={'text'} value={option} onChange={setOption}
                     label={'Value'}/>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        title="Edit suggested answer"
        visible={isEdit > -1}
        onCancel={onCancel}
        closable={false}
        width={410}
        footer={[
          <button key="submit" className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }} onClick={submitEdit}>
            Ok
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  style={{ width: 125 }}
                  onClick={onCancel}>Cancel</button>,
        ]}
      >
        <form onSubmit={e => {
          e.preventDefault()
          submitEdit()
        }}>
          <div className={'row'}>
            <div className={'col-12'}>
              <Input type={'text'} value={optionEdit} onChange={setOptionEdit}
                     label={'Value'}/>
            </div>
          </div>
        </form>
      </Modal>
    </Fragment>
  )
}

function Text () {
  const style = useRecoilValue(editMemberProperty('style'))
  const suggestion = useRecoilValue(editMemberProperty('suggestion'))
  return (
    <Fragment>
      <div className={'col-12'}>
        <hr/>
        TEXT INTAKE
      </div>
      {style !== 'hidden' && (
        <Fragment>
          <div className={'col-6'}>
            <EditMemberPropertyComponent path={'pattern'}>
              {({
                  value,
                  onChange,
                }) => (<Input label={'PATTERN'} onChange={val => onChange(val)}
                              value={value ? value : ''} id={'pattern'}
                              placeholder={''}
                              type={'text'} isDrop={true}/>
              )}
            </EditMemberPropertyComponent>
          </div>
          <div className={'col-6'}>
            <div className={'form-group'}>
              <label>VALIDATION</label>
              <div className="form-check">
                <EditMemberPropertyComponent path={'allowBreaks'}>
                  {({
                      value,
                      onChange,
                    }) =>
                    (<input className="form-check-input" type="checkbox"
                            id="default"
                            onChange={() => onChange(!value)}
                            checked={!!value}/>)
                  }
                </EditMemberPropertyComponent>
                <label className="form-check-label" htmlFor="default">
                  {InferLabelFromName('textMayContainLineBreaks')}
                </label>
              </div>
              <div className="form-check">
                <EditMemberPropertyComponent path={'processMarkdown'}>
                  {({
                      value,
                      onChange,
                    }) => (
                    <input className="form-check-input" type="checkbox"
                           id="defaultCheck1"
                           onChange={() => onChange(!value)}
                           checked={!!value}/>)
                  }
                </EditMemberPropertyComponent>
                <label className="form-check-label" htmlFor="defaultCheck1">
                  {InferLabelFromName('allowFormatting (markdown)')}
                </label>
              </div>
            </div>
          </div>
          <div className={'col-6'}>
            <EditMemberPropertyComponent path={'suggestion'}>
              {({
                  value,
                  onChange,
                }) => (
                <RadioInputs name={'suggestionsFrom'}
                             label={'ANSWER SUGGESTIONS'}
                             active={value}
                             values={[
                               {
                                 value: 'none',
                                 label: 'None',
                               }, {
                                 value: 'anOptionListedBelow',
                                 label: 'Fixed values defined below',
                               }, {
                                 value: 'userData',
                                 label: 'Values defined somewhere else',
                               },
                             ]}
                             handelChange={val => {
                               onChange(val)
                             }}/>)}
            </EditMemberPropertyComponent>
          </div>
          <div className={'col-6'}>
            <EditMemberPropertyComponent path={'height'}>
              {({
                  value,
                  onChange,
                }) => (
                <Input label={'INPUT HEIGHT (LINES)'}
                       onChange={val => onChange(val)}
                       value={value ? value.toString() : ''}
                       id={'linesText'}
                       placeholder={''}
                       type={'number'}/>)}
            </EditMemberPropertyComponent>
          </div>
          <div className={'col-12'}>
            {suggestion === 'anOptionListedBelow' &&
              (<EditMemberPropertyComponent path={'_options'}>
                {({
                    value,
                    onChange,
                  }) => (<LocalOptions data={value}
                                       change={val => onChange(
                                         val)}/>)}
              </EditMemberPropertyComponent>)
            }
            {suggestion === 'userData' &&
              (<EditMemberPropertyComponent path={'_options'}>
                {({
                    value,
                    onChange,
                  }) => (<ExpressionOptions data={value}
                                            change={val => onChange(
                                              val)}/>)}
              </EditMemberPropertyComponent>)
            }
          </div>
        </Fragment>
      )}
      {style === 'hidden' && (
        <div className={'col-6'}>
          <Select label={'STYLE'} id={'textStyle'} value={style}
                  values={['textbox', 'hidden']}
                  disabled={true}/>
        </div>
      )}
    </Fragment>
  )
}

export { TEXT, Text }
