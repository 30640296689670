export default (str) => {
  console.log(str)
  let el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.classNames = 'invisible'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}