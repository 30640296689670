import React, { Fragment } from 'react'
import { Input, Select } from '../../../../components'
import { InferLabelFromName } from '../../../../utils'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'

const NUMBER = 'number'

function Number () {

  return (
    <Fragment>
      <div className={'col-12'}>
        <hr/>
        NUMBER INTAKE
      </div>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'style'} defaultValue={'textbox'}>
          {({
            value,
            onChange,
          }) => (
            <Select label={'Style'.toUpperCase()} id={'numberStyle'}
                    value={value}
                    values={['textbox', 'spinbox', 'slider', 'hidden']}
                    onChange={onChange}/>)}
        </EditMemberPropertyComponent>
      </div>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'decimalPlaces'} defaultValue={''}>
          {({
            value,
            onChange,
          }) => (
            <Input label={InferLabelFromName('decimalPlaces').toUpperCase()}
                   id={'numberDecimalPlaces'} type={'number'}
                   value={value}
                   onChange={onChange}/>)}
        </EditMemberPropertyComponent>
      </div>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'minimum'} defaultValue={''}>
          {({
            value,
            onChange,
          }) => (
            <Input label={'minimum'.toUpperCase()}
                   id={'numberMinimum'}
                   onChange={onChange}
                   value={value}/>)}
        </EditMemberPropertyComponent>
      </div>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'maximum'} defaultValue={''}>
          {({
            value,
            onChange,
          }) => (
            <Input label={'maximum'.toUpperCase()}
                   id={'numberMaximum'}
                   onChange={onChange}
                   value={value}/>)}
        </EditMemberPropertyComponent>
      </div>

    </Fragment>
  )
}

export { NUMBER, Number }
