import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { Icon, Input, NavButtons, Select, Table } from '../../../components'
import classNames from 'classnames'
import update from 'immutability-helper'
import axios from 'axios'
import LoadingType from '../../../utils/loadingType'
import {
  selector,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  editTypeProperty,
  SettingsTypesSelector,
  TypesSelector,
} from '../state'

const { SubMenu } = Menu

const COLUMNS = [
  {
    type: 'text',
    name: 'name',
    label: 'Name',
  },
  {
    type: 'text',
    name: 'type',
    label: 'Type',
  },
]

const RowFilter = ({
                     filter,
                     isFirst,
                     isLast,
                     onChange,
                     onRemove,
                     onMoveUp,
                     onMoveDown,
                   }) => {
  if (!filter) {
    return null
  }

  const { name } = filter

  const hasEnum = !!filter.enum
  const hasOperator = !!filter.oper
  const hasRequired = filter.selectorType === 'param'

  const styleIcon = {
    verticalAlign: 0,
    fontSize: '1.2rem',
  }
  const styleSpan = {
    paddingLeft: '1.1rem',
  }

  let InputValue
  if (hasEnum && !hasRequired) {
    InputValue = <Select value={filter.valueExpr || ''}
                         onChange={nextValue => onChange('valueExpr',
                           nextValue)}
                         values={filter.enum}
                         className={'m-0'}/>
  } else {
    InputValue =
      <Input value={(hasRequired ? filter.paramName : filter.valueExpr) || ''}
             onChange={nextValue => onChange(
               hasRequired ? 'paramName' : 'valueExpr', nextValue)}
             placeholder={filter.selectorType === 'param' ? filter.name : ''}
             classNameForDiv={'my-0'}/>
  }
  return (<div className={'row my-2 align-items-center'} key={name}>
    <div className={'col-3 text-truncate d-flex justify-content-between'}>
      <strong>{name}</strong>
      <strong>{filter.isList
        ? filter.type + ' list'
        : filter.type}</strong></div>
    <div className={'col-2'}>{hasOperator ? <Select
      value={filter.operator || filter.oper[0]}
      values={filter.oper}
      onChange={nextValue => onChange('operator', nextValue)}
      className={'m-0'}/> : <span
      style={styleSpan}>matches</span>}</div>
    <div className={'col-2'}><Select value={filter.selectorType}
                                     onChange={nextValue => onChange(
                                       'selectorType', nextValue)}
                                     values={[
                                       {
                                         label: 'value',
                                         value: 'value',
                                       },
                                       {
                                         label: 'parameter',
                                         value: 'param',
                                       }]} className={'m-0'}/>
    </div>
    <div className={'col-4'}>
      {hasRequired && (<div className={'row'}>
        <div className={'col-8'}>{InputValue}</div>
        <div className={'col-4'}>
          <div className="form-group form-check my-2">
            <input type="checkbox"
                   className="form-check-input"
                   id={'required' + name}
                   checked={filter.required}
                   onChange={() => onChange('required', !filter.required)}
            />
            <label className="form-check-label"
                   htmlFor={'required' + name}>required</label>
          </div>
        </div>
      </div>)}
      {!hasRequired && InputValue}
    </div>

    <div className={'col-1'}>
      <div className={'d-flex justify-content-around my-2'}>
        <button type="button" className="btn my-0 p-0 mx-1" onClick={onMoveUp}
                disabled={isFirst}><Icon
          type={'icon-arrow-up'}
          style={styleIcon}/>
        </button>
        <button type="button" className="btn my-0 p-0 mx-1" onClick={onMoveDown}
                disabled={isLast}><Icon
          type={'icon-arrow-down'}
          style={styleIcon}/>
        </button>
        <button type="button" className={classNames('btn my-0 p-0 mx-1',
          { 'invisible': filter.defaultRequired })}><Icon
          type={'icon-trash'}
          onClick={onRemove}
          style={styleIcon}/>
        </button>
      </div>
    </div>
  </div>)
}

const transformParams = (selectors, params) => params.map(param => {
  const neededSelector = selectors.find(
    selector => selector.name === param.name || selector.parent + '_' +
      selector.name === param.name)
  if (neededSelector) {
    return ({
      ...neededSelector,
      ...param,
      defaultRequired: neededSelector.required,
    })
  }
  return param
})

const Filters = ({
                   source,
                   selectors,
                   params,
                   onChange,
                 }) => {
  if (!selectors || !selectors.length) {
    return null
  }
  const [filters, setFilters] = useState(transformParams(selectors, params))

  const addFilter = (selectorName) => () => {
    let selector = selectors.find(selector => selector.name === selectorName)
    if (selector.parent) {
      onChange([
        ...filters,
        {
          ...selector,
          name: selector.parent + '_' + selector.name,
        }])
    } else {
      onChange([...filters, selector])
    }

  }
  const onChangeFilter = (filterName) => (key, nextValue) => {
    const indexedFilter = filters.findIndex(
      filter => filter.name === filterName)
    if (indexedFilter > -1) {
      const nextFilters = update(filters,
        { [indexedFilter]: { [key]: { $set: nextValue } } })
      onChange(nextFilters)
    }
  }
  const onRemove = filterName => () => {
    const indexedFilter = filters.findIndex(
      filter => filter.name === filterName)
    if (indexedFilter > -1) {
      const nextFilters = update(filters, { $splice: [[indexedFilter, 1]] })
      onChange(nextFilters)
    }
  }

  const onMoveUp = filterName => () => {
    const indexedFilter = filters.findIndex(
      filter => filter.name === filterName)
    if (indexedFilter > -1) {
      const filter = filters[indexedFilter]
      const nextFilters = update(filters,
        { $splice: [[indexedFilter, 1], [indexedFilter - 1, 0, filter]] })
      onChange(nextFilters)
    }
  }
  const onMoveDown = filterName => () => {
    const indexedFilter = filters.findIndex(
      filter => filter.name === filterName)
    if (indexedFilter > -1) {
      const filter = filters[indexedFilter]
      const nextFilters = update(filters,
        { $splice: [[indexedFilter, 1], [indexedFilter + 1, 0, filter]] })
      onChange(nextFilters)
    }
  }

  useEffect(() => {
    if (params) {
      setFilters(transformParams(selectors, params))
    }
  }, [selectors, params])

  if (!filters) {
    return null
  }

  const SelectorsWithOutParent = selectors.filter(selector => !selector.parent)
  const SelectorsWithParent = selectors.filter(selector => selector.parent)

  const MapParents = new Map()
  SelectorsWithParent.forEach(selectors => {
    if (!MapParents.has(selectors.parent)) {
      MapParents.set(selectors.parent, SelectorsWithParent.filter(
        _selector => _selector.parent === selectors.parent))
    }
  })
  const menu = (
    <Menu>
      {SelectorsWithOutParent.filter(
        selector => !filters.find(filter => filter.name === selector.name)).map(selector => (<Menu.Item key={selector.name}
                                    onClick={addFilter(selector.name)}>
          {selector.name}
        </Menu.Item>))}
      {[...MapParents.keys()].map(parent => (
        <SubMenu title={parent} key={parent}>
          {MapParents.get(parent).filter(selector => !filters.find(
              filter => filter.name === parent + '_' + selector.name)).map(selector => <Menu.Item key={selector.name}
                                       onClick={addFilter(
                                         selector.name)}>{selector.name}</Menu.Item>)}
        </SubMenu>
      ))}

    </Menu>
  )

  const requiredFilters = filters.filter(filter => filter.required)
  const optionalFilters = filters.filter(filter => !filter.required)

  return (
    <div className={'row'}>
      <div className={'col-9'}>
        <label className={'mb-2'}>FILTERS</label>
        <hr className={'my-0'}/>
        {requiredFilters.map(
          (filter, index) => <RowFilter filter={filter}
                                        isFirst={index === 0}
                                        isLast={index ===
                                          (requiredFilters.length - 1)}
                                        onChange={onChangeFilter(filter.name)}
                                        onMoveUp={onMoveUp(filter.name)}
                                        onMoveDown={onMoveDown(filter.name)}
                                        onRemove={onRemove(filter.name)}/>)}
        {optionalFilters.map(
          (filter, index) => <RowFilter filter={filter}
                                        isFirst={index === 0}
                                        isLast={index ===
                                          (optionalFilters.length - 1)}
                                        onChange={onChangeFilter(filter.name)}
                                        onMoveUp={onMoveUp(filter.name)}
                                        onMoveDown={onMoveDown(filter.name)}
                                        onRemove={onRemove(filter.name)}/>)}

        <Dropdown overlay={menu} trigger={['click']}
                  disabled={!selectors.filter(
                    selector => !filters.find(
                      filter => filter.name === selector.name)).length}>
          <button className={'btn btn-secondary btn-theme my-2'}
                  onClick={e => e.preventDefault()}
          ><Icon type="icon-plus2"
                 style={{
                   fontSize: '1rem',
                   verticalAlign: '0.15em',
                 }
                 }/> Add {source} Filter
          </button>
        </Dropdown>
      </div>
      <div className={'col-3'}>
        <Parameters parameters={filters.filter(
          filter => filter.selectorType === 'param')}/>
      </div>
    </div>
  )
}

const RowParameter = ({ parameter }) => {

  const MemoRowParameter = useMemo(
    () => (<div className={'d-flex justify-content-between my-2'}
                key={'Parameter' + parameter.name}>
      <span>{parameter.paramName ? parameter.paramName : parameter.name}</span>
      <span>({parameter.isList
        ? parameter.type + ' list'
        : parameter.type})</span>
    </div>),
    [parameter.name, parameter.type, parameter.isList, parameter.paramName])
  return MemoRowParameter
}
const Parameters = ({ parameters }) => {
  const requiredParameters = parameters.filter(parameter => parameter.required)
  const optionalParameters = parameters.filter(parameter => !parameter.required)
  return <Fragment>
    <label className={'mb-2'}>PARAMETERS</label>
    <hr className={'my-0'}/>
    {requiredParameters.map(
      parameter => <RowParameter parameter={parameter}/>)}
    {!!optionalParameters.length && (
      <div className={'d-flex justify-content-between my-2'}>
        <cite>Optional:</cite>
      </div>
    )}
    {optionalParameters.map(
      parameter => <RowParameter parameter={parameter}/>)}
  </Fragment>
}

const QueryName = () => {
  const [name, setName] = useRecoilState(editTypeProperty('name'))
  return <Input label={'QUERY NAME'}
                value={name || ''}
                onChange={setName}
                type={'text'}
                id={'queryName'}/>
}

const QueryProvider = () => {
  const [providers, setProviders] = useState(['', 'Knackly'])
  const provider = useRecoilValue(editTypeProperty('provider'))
  const types = useRecoilValue(TypesSelector)
  const {
    isFilevine,
    isClio,
  } = useRecoilValue(SettingsTypesSelector)

  useEffect(() => {
    let isFlag = true

    const flagClio = 'clio:'
    const flagFileOne = 'fv:'

    const type = types.find(
      type => type.name.includes(flagClio) || type.name.includes(flagFileOne))
    if (!type) {
      isFlag = false
    }

    if (isFlag) {
      if (isFilevine && isClio) {
        setProviders(['', 'Knackly', 'Clio', 'Filevine'])
        return
      }
      if (isFilevine) {
        setProviders(['', 'Knackly', 'Filevine'])
        return
      }
      if (isClio) {
        setProviders(['', 'Knackly', 'Clio'])
        return
      }
    }
  }, [types])

  const setProvider = useRecoilCallback(({ set }) => (val) => {
    set(editTypeProperty('provider'), val)
    set(editTypeProperty('source'), '')
    set(editTypeProperty('params'), [])
    set(editTypeProperty('writeBack'), false)
  }, [])

  return (<Select label={'PROVIDER'}
                  id={'queryType'}
                  value={provider || providers[0]}
                  values={providers}
                  onChange={setProvider}/>)
}

const QuerySource = () => {
  const provider = useRecoilValue(editTypeProperty('provider'))
  const source = useRecoilValue(editTypeProperty('source'))
  const [sources, setSources] = useState([''])

  const fetchSources = async () => {
    if (!provider) {
      return
    }
    try {
      const Response = await axios.get('/api/queries/types/' + provider)
      if (Response) {
        setSources(['', ...Response.data] || [''])
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (provider) {
      fetchSources()
    }
  }, [provider])

  const setSource = useRecoilCallback(({ set }) => (val) => {
    set(editTypeProperty('source'), val)
    set(editTypeProperty('params'), [])
    set(editTypeProperty('writeBack'), false)
  }, [])

  return (<Select label={'SOURCE'}
                  id={'querySource'}
                  value={provider === '' ? '' : source || ''}
                  values={sources}
                  onChange={setSource}
                  disabled={provider === ''}/>)
}

const CurrentSourceSelector = selector({
  key: 'CurrentSourceSelector',
  get: async ({ get }) => {
    const source = get(editTypeProperty('source'))
    const provider = get(editTypeProperty('provider'))
    if (!source) {
      return {}
    }
    try {
      const Response = await axios.get(
        `/api/queries/types/${provider}/descriptor/${source}`)
      if (Response) {
        if (Response.data) {
          return (Response.data)
        }
      }
    } catch (e) {
      console.log(e)
    }
    return {}
  },
})

const QueryParams = () => {
  const extModelName = useRecoilValue(editTypeProperty('extModelName'))
  const source = useRecoilValue(editTypeProperty('source'))
  const [params, setParams] = useRecoilState(editTypeProperty('params'))
  const currentSource = useRecoilValue(CurrentSourceSelector)
  if (extModelName) {
    return (<Filters source={source}
                     selectors={currentSource.selectors}
                     params={params}
                     onChange={setParams}/>)
  }
  return null
}

const QueryWriteBack = () => {
  const [writeBack, setWriteBack] = useRecoilState(
    editTypeProperty('writeBack'))
  const currentSource = useRecoilValue(CurrentSourceSelector)
  if (currentSource.writeBack || writeBack) {
    return (
      <div className="form-check my-2">
        <input className="form-check-input" type="checkbox" id="writeBack"
               onChange={() => setWriteBack(!writeBack)}
               checked={!!writeBack}/>
        <label className="form-check-label" htmlFor="writeBack">
          Modified data can be saved back to the provider
        </label>
      </div>)
  }
  return null
}

const FieldsSelector = selector({
  key: 'FieldsSelector',
  get: async ({ get }) => {
    const source = get(editTypeProperty('source'))
    const provider = get(editTypeProperty('provider'))
    const types = get(TypesSelector)

    if (!provider || !source) {
      return ({
        fields: [],
        extModelName: '',
        implicitKey: '',
      })
    }

    try {
      const Response = await axios.get(
        `/api/queries/types/${provider}/sources/${source}`)
      if (Response) {
        if (typeof Response.data === 'string') { // string = model name
          // fetch fields from named model
          let m = types.find(t => t.name === Response.data)
          if (m.neededLoading) {
            m = await LoadingType(m._id)
          }
          if (m && m.properties) {
            return ({
              fields: m.properties.map(p => {
                const typeName = p.type === 'object'
                  ? p.typeName + ' (object)'
                  : p.type
                return {
                  name: p.name,
                  type: p.isList ? typeName + ' list' : typeName,
                }
              }),
              extModelName: Response.data,
              implicitKey: m.key,
            })
          }
        } else if (Array.isArray(Response.data)) {
          return ({
            fields: Response.data,
            extModelName: '',
            implicitKey: '',
          })
        } else {
          return ({
            fields: [],
            extModelName: '',
            implicitKey: '',
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
    return ({
      fields: [],
      extModelName: '',
      implicitKey: '',
    })
  },
})

const QueryFields = () => {
  const source = useRecoilValue(editTypeProperty('source'))
  const [key, setKey] = useRecoilState(editTypeProperty('key'))
  const [ExtModelName, setExtModelName] = useRecoilState(
    editTypeProperty('extModelName'))
  const {
    fields,
    implicitKey,
    extModelName,
  } = useRecoilValue(FieldsSelector)

  useEffect(() => {
    if (extModelName !== ExtModelName) {
      setExtModelName(extModelName)
    }
  }, [extModelName, ExtModelName])

  if (source) {
    return (<Table columns={COLUMNS}
                   preData={fields}
                   implicitKey={implicitKey}
                   QueryRowKey={key}
                   setQueryRowKey={setKey}
                   disabled={true}
                   isQuery={true}/>)
  }
  return null
}
const Query = () => {

  return (
    <Fragment>
      <div className={'col-12'}>
        <div className="row">
          <div className="col-3">
            <QueryName/>
          </div>
          <div className="col-3">
            <QueryProvider/>
          </div>
          <div className="col-3">
            <QuerySource/>
          </div>
          <div className={'col-3'}>
            <NavButtons/>
          </div>
        </div>
      </div>
      <div className={'col-12'}>
        <QueryParams/>
      </div>
      <div className={'col-12'}>
        <QueryFields/>
      </div>
      <div className={'col-12'}>
        <QueryWriteBack/>
      </div>
    </Fragment>
  )
}

export default Query
