import React, { useState, useEffect, useRef, useContext } from 'react'
import { OBJECT, SELECTION, GLOBAL } from '../config'
import className from 'classnames'
import Icon from './icon'
import Member from './member'

import Context from '../context'
import _ from 'lodash'

const Nested = ({
  member,
  copyText,
  showMember = false,
  goToSteps = [],
  MaxLevels,
}) => {

  const [showChild, setShowChild] = useState(false)
  const [childMembers, setChildMembers] = useState([])

  const {
    activeType,
    types,

    isVariables,
    isTemplates,
    isFormulas,
    isGlobal,

    serialization,
    clearGoto,
    ActionComponent,
  } = useContext(Context)

  const ref = useRef()

  const fillChildMembers = async () => {
    const nextChildMembers = await serialization(member, activeType, types,
      isVariables, isTemplates, isFormulas, isGlobal)
    if (nextChildMembers) {
      setChildMembers(nextChildMembers)
    }
  }

  useEffect(() => {
    if (goToSteps.length) {
      openMember()
    }
  }, [goToSteps])

  useEffect(() => {
    fillChildMembers()
  }, [showChild, MaxLevels])

  const openMember = () => {
    setShowChild(() => true)
  }
  const hideMember = () => {
    if (goToSteps.length) {
      clearGoto()
    }
    setShowChild(() => false)
  }
  const childrenFields = childMembers.map(childMember => childMember.name)

  if (!showChild) {
    return (<Member
      member={member}
      openMember={openMember}
      copyText={member.type === SELECTION || member.type === OBJECT ||
      member.type === GLOBAL
        ? copyText
        : copyText + '.' + member.name}
      childrenFields={childrenFields}
      ActionComponent={ActionComponent}
      showMember={showMember}
    />)
  }
  return (
    <div
      ref={ref}
      className={className(
        'border rounded px-2 py-1 my-1 d-flex flex-column text-nowrap',
        {
          'knackly-back-light': member.type === OBJECT || member.type ===
            SELECTION || member.type === GLOBAL,
        })}
      onClick={(event) => {
        event.stopPropagation()
        hideMember()
      }}
    >
      <div
        className={'d-flex justify-content-between cursorPointer no-select'}>
        <div className={'text-truncate'}>
          {member.type === GLOBAL && (
            <Icon type={'icon-global'}
                  className={'align-middle pr-1'} style={{ marginTop: -3 }}/>)}
          <Icon type={member.isFormulas
            ? Icon.fromFormulaType(member.type)
            : Icon.fromValueType(member.type)}
                className={'align-middle'}
                style={{ marginTop: -3 }}/> {member.name} <Icon
          type={'icon-return'} className={'align-middle'}
          style={{
            marginTop: -3,
            transform: 'rotate(270deg)',
          }}/>
        </div>
        <div className={'d-flex align-items-center'}>{member.typeName
          ? member.typeName
          : member.type} {member.isList
          ? ' list'
          : ''}
          <ActionComponent
            member={member}
            isObject={true}
            childrenFields={childrenFields}
            copyText={copyText}/>
        </div>
      </div>
      {childMembers && childMembers.map(childMember => {
        if (childMember.type === OBJECT || childMember.type === SELECTION ||
          childMember.type === GLOBAL) {
          return <Nested
            key={copyText + '.' +
              childMember.name}
            member={childMember}
            copyText={member.isList ? childMember.name : copyText + '.' +
              childMember.name}
            goToSteps={childMember.name === goToSteps[0]
              ? _.tail(goToSteps)
              : []}
            showMember={childMember.name === goToSteps[0] &&
              goToSteps.length === 1}
            MaxLevels={MaxLevels - 1}
          />
        } else {
          return (<Member
            key={copyText + '.' + childMember.name}
            member={childMember}
            parentIsList={member.isList}
            showMember={childMember.name === goToSteps[0] &&
              goToSteps.length === 1}
            copyText={member.isList ? childMember.name : copyText + '.' +
              childMember.name}
          />)
        }
      })}
    </div>)
}

export default Nested
