import React, { useMemo, useState } from 'react'
import { MemberList, Search } from '../../../components'
import useSearch from '../../../utils/useSearch'
import AddVariablesModal from '../Variables/modal/add'
import AddFormulasModal from '../Formulas/modalAdd'
import AddTemplatesModal from '../Templates/modal/add'
import AddAppsModal from '../Apps/modal'
import {
  CurrentMembersSelector,
  CurrentTypeSelector,
  ElementsChangesAtom,
  getCurrentTab,
  getKind,
  getMemberKey,
  getMemberName,
  SelectedMembersAtom,
} from '../state'
import produce from 'immer'
import _ from 'lodash'
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Button, Dropdown, Menu } from 'antd'
import Icon from '../../../components/Icon'
import ModalConfirm from '../../../components/ModalConfirm'
import Copy from './copy'
import Move from './move'

const NavigationMembers = () => {

  const [search, setSearch] = useState('')
  const [selectedMembers, setSelectedMembers] = useRecoilState(
    SelectedMembersAtom)
  const history = useHistory()

  const Members = useRecoilValue(CurrentMembersSelector)
  const Type = useRecoilValue(CurrentTypeSelector)

  const MemoSearch = useMemo(() => (<Search onSubmit={nextSearch => {
    setSearch(() => nextSearch)
    if (nextSearch) {
      setSelectedMembers({})
    }
  }} search={search}/>), [])

  const switchActiveMember = (action, member) => {
    if (action === 'click') {
      setSelectedMembers({})
      if (window.needSaveProfileTemplate) {
        return window.confirmProfile(
          `/${window.tenancy}/types/${Type.name}/${getCurrentTab()}/${member.name}`)
      }
      history.push(
        `/${window.tenancy}/types/${Type.name}/${getCurrentTab()}/${member.name}`)
      return
    }

    if (action === 'select') {
      const activeMemberName = getMemberName()
      let activeMembersId
      const activeMember = activeMemberName ? Members.find(
        el => el.name === activeMemberName) : Members[0]
      if (activeMember) {
        activeMembersId = activeMember._id
      }

      setSelectedMembers(produce((draft) => {
        if (draft[member._id] === null) {
          delete draft[member._id]
        } else {
          draft[member._id] = null
          draft[activeMembersId] = null
        }
        if (!Object.entries(draft).length) {
          draft[member._id] = null
          draft[activeMembersId] = null
        }
      }))
    }
    if (action === 'shift') {
      const items = _.orderBy(search ? useSearch(Members, search) : Members,
        ['name'], ['asc'])

      let start = Members[0]
        ? items.findIndex(el => el._id === Members[0]._id)
        : 0

      const activeMemberName = getMemberName()
      if (activeMemberName) {
        start = items.findIndex(el => el.name === activeMemberName)
      }

      let end = items.findIndex(el => el._id === member._id)

      if (end > start) {
        setSelectedMembers(
          items.slice(start, end + 1).reduce((previousValue, currentValue) => {
            previousValue[currentValue._id] = null
            return previousValue
          }, {}))
      }
      if (start > end) {
        setSelectedMembers(
          items.slice(end, start + 1).reduce((previousValue, currentValue) => {
            previousValue[currentValue._id] = null
            return previousValue
          }, {}))
      }
    }
  }

  const memberKey = getMemberKey()
  const kind = getKind(memberKey)

  const deleteMembers = useRecoilCallback(({
                                             snapshot,
                                             set,
                                           }) => async () => {
    const type = await snapshot.getPromise(CurrentTypeSelector)
    const selectedMembers = await snapshot.getPromise(SelectedMembersAtom)
    const iDs = Object.keys(selectedMembers)
    set(ElementsChangesAtom, produce(draft => {
      const kind = getKind(getCurrentTab())
      iDs.forEach(id => {
        const hasElement = draft.find(
          element => element._id === id && element.kind === kind &&
            element.parent === type._id)
        if (hasElement) {
          return draft.filter(
            element => element._id !== id && element.kind !== kind &&
              element.parent !== type._id)
        } else {
          draft.push({
            action: 'remove',
            _id: id,
            kind: kind,
            parent: type._id,
            parentKind: 'model',
          })
        }
      })
    }))

    set(SelectedMembersAtom, {})
    history.push(`/${window.tenancy}/types/${type.name}/${getCurrentTab()}`)
  }, [])

  return (<div className="col-3">
    {Object.keys(selectedMembers).length > 1 ? (<div className="d-flex">
      {MemoSearch}
      <div className="pl-2 mb-2">
        <Dropdown
          overlay={(<Menu>
            <Menu.Item>
              <Copy title={`Copy ${kind}s to other model`}>
               <span>
                 <Icon type="icon-copy" style={{
                   fontSize: '18px',
                   verticalAlign: '0.1em',
                 }}/>
                 <span>Copy {kind}s to other model</span>
               </span>
              </Copy>
            </Menu.Item>
            <Menu.Item>
              <Move title={`Copy ${kind}s to other model`}>
                <span>
                  <Icon type="icon-arrows-alt" style={{
                    fontSize: '18px',
                    verticalAlign: '0.1em',
                  }}/><span>Move {kind}s to other model</span>
                </span>
              </Move>
            </Menu.Item>
            <Menu.Item>
              <ModalConfirm
                title={`Are you sure you want to delete ${kind}s?`}
                submit={deleteMembers}>
    <span>
      <Icon type="icon-trash" style={{
        fontSize: 18,
        verticalAlign: '0.1em',
        marginRight: 8,
      }}/><span>Delete {kind}s</span>
    </span>
              </ModalConfirm>
            </Menu.Item>
          </Menu>)}>
          <Button title="DeleteVariables" shape="circle"
                  className="circle-ant-btn circle-ant-btn-search"><Icon
            type="icon-bars"
            style={{ fontSize: '1.1rem' }}
            className="circle-ant-btn-icon"/>
          </Button>
        </Dropdown>
      </div>
    </div>) : MemoSearch}


    <MemberList members={search ? useSearch(Members, search) : Members}
                changeActive={switchActiveMember}
                typeKey={Type.key}
    />

    <Switch>
      <Route
        path={[
          '/:tenancy/types/:typename/templates',
          '/:tenancy/types/:typename/templates/:membername']}>
        <AddTemplatesModal/>
      </Route>
      <Route
        path={[
          '/:tenancy/types/:typename/formulas',
          '/:tenancy/types/:typename/formulas/:membername']}>
        <AddFormulasModal/>
      </Route>
      <Route
        path={[
          '/:tenancy/types/:typename/apps',
          '/:tenancy/types/:typename/apps/:membername']}>
        <AddAppsModal/>
      </Route>
      <Route
        path={[
          '/:tenancy/types',
          '/:tenancy/types/:typename',
          '/:tenancy/types/:typename/variables',
          '/:tenancy/types/:typename/variables/:membername']}>
        <AddVariablesModal/>
      </Route>
    </Switch>
  </div>)
}

export default NavigationMembers
