import { enUS, enGB, enAU, enCA } from 'date-fns/locale'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/en-au'
import 'moment/locale/en-ca'
import {
  setDefaultLocale as setDefaultDatePickerLocale,
  registerLocale as registerDatePickerLocale,
} from  'react-datepicker'

function setDescription (locale, description) {
  if (!locale.description) {
    locale.description = description
  }
}

setDescription(enUS, 'English (United States)')
setDescription(enAU, 'English (Australia)')
setDescription(enCA, 'English (Canada)')
setDescription(enGB, 'English (United Kingdom)')

export const locales = {
  [enUS.code]: enUS,
  [enAU.code]: enAU,
  [enCA.code]: enCA,
  [enGB.code]: enGB,
}

export function setLocale (code) {
  const localeCode = validateLocale(code)
  registerDatePickerLocale(localeCode, locales[localeCode])
  setDefaultDatePickerLocale(localeCode)
  moment.locale(localeCode)
}

export function validateLocale (code) {
  return (code in locales) ? code : 'en-US' // fall-back locale
}
