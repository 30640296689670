import React, {
  memo,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react'
import className from 'classnames'
import { DND, OBJECT, SELECTION, GLOBAL } from '../config'
import Icon from './icon'
import _ from 'lodash'
import getDateFormatByLocation from '../utils/getDateFormatByLocation'
import Context from '../context'

const Member = ({
  style,
  member,
  copyText,
  openMember,
  childrenFields = [],
  parentIsList = false,
  showMember = false,
  isChild = false,
}) => {
  const memberRef = useRef(null)
  const [isActive, setActive] = useState(
    member.new ? member.new : false)
  const [hover, setHover] = useState(false)

  const {
    isAddin,

    pluginSDK,
    useDrag,
    ActionComponent,
  } = useContext(Context)

  let [, dragRef] = useDrag({
    item: {
      type: DND.TYPES.DNDITEMROOT,
      copyText,
    },
    end: () => {
      if (isAddin) {
        if (member.isList) {
          pluginSDK.VerificationDocument(copyText,
            pluginSDK.TypesOfField.listFull,
            '|punc:"1, 2, and 3"')
          pluginSDK.VerificationDocument('this', pluginSDK.TypesOfField.content)
        } else {
          pluginSDK.VerificationDocument(copyText,
            pluginSDK.TypesOfField.content)
        }
      }
    },
  })

  useEffect(function () {
    dragRef(memberRef)
  }, [memberRef.current])

  useEffect(() => {
    if (showMember) {
      if (memberRef.current) {
        setTimeout(() => {
          if (memberRef.current) {
            if (memberRef.current.scrollIntoView) {
              memberRef.current.scrollIntoView()
            }
          }
          setActive(true)
        }, 0)
      }
    } else {
      if (isActive) {
        setActive(false)
      }
    }
  }, [isActive, showMember, memberRef.current])

  let icon

  if (member.isTemplates) {
    icon = Icon.fromTemplateType(member.type)
  } else if (member.isFormulas) {
    icon = Icon.fromFormulaType(member.type)
  } else {
    icon = Icon.fromValueType(member.type)
  }

  const text = parentIsList ? member.name : copyText

  const MemoIconArrow = useMemo(() => {
    if (member.type === OBJECT || member.type === GLOBAL || member.type ===
      SELECTION) {
      return <Icon type={'icon-return'} className={'align-middle'} style={{
        marginTop: -3,
        transform: 'rotate(180deg)',
      }}/>
    }
    return null
  }, [member.type, hover])

  return (
    <div
      style={style} onClick={(event) => {
      event.stopPropagation()
      if (member.type === OBJECT || member.type === GLOBAL || member.type ===
        SELECTION) {
        openMember()
      }
    }}
      onMouseEnter={() => setHover(() => true)}
      onMouseLeave={() => setHover(() => false)}
    >
      <div
        ref={memberRef}
        id={member.name}
        className={className(
          'border rounded px-2 py-1 my-1 d-flex justify-content-between text-nowrap cursorPointer no-select',
          {
            'bg-light': (member.type === OBJECT || member.type === GLOBAL ||
              isChild) && !isActive,
          },
          {
            'knackly-plain': member.type !== OBJECT && member.type !== GLOBAL &&
              !isChild && !isActive,
          },
          {
            'right-nav-hollow': member.type === OBJECT || member.type ===
              GLOBAL || member.type === SELECTION,
          },
          { 'knackly-panel-active': isActive })}

        onDragStart={isAddin ? event => {
          event.dataTransfer.clearData()
          if (member.isList) {
            event.dataTransfer.setData('text/plain',
              pluginSDK.GenerationField(copyText,
                pluginSDK.TypesOfField.listFull,
                { filter: '|punc:"1, 2, and 3"' }))
          } else {
            event.dataTransfer.setData('text/plain',
              pluginSDK.GenerationField(copyText,
                pluginSDK.TypesOfField.content))
          }
        } : null
        }

        onMouseOver={member.new ? event => {
          if (isAddin) {
            event.preventDefault()
          }
          delete member.new
          setActive(false)
        } : null}

        onDoubleClick={isAddin ? event => {
          event.preventDefault()
          event.stopPropagation()
          pluginSDK.InsertContent(text, false, member.isList, false,
            member.type === 'date' ? getDateFormatByLocation() : [])
        } : null
        }
      >
        <div className={'text-truncate'}>
          {member.type === GLOBAL && (
            <Icon type={'icon-global'}
                  className={'align-middle pr-1'} style={{ marginTop: -3 }}/>)}
          <Icon type={icon} className={'align-middle'}
                style={{ marginTop: -3 }}/> {member.name} {MemoIconArrow}
        </div>
        <div className={'d-flex align-items-center'}>{member.typeName ||
          member.type} {member.isList ? ' list' : ''} <ActionComponent
          member={member}
          copyText={text}
          childrenFields={childrenFields}
        />
        </div>
      </div>
    </div>
  )
}

export default memo(Member, _.isEqual)
