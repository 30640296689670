import React, { Fragment } from 'react'
import { NavigationDark } from './style'
import Card from './Card'
import classnames from 'classnames'
import ItemTypes from './ItemTypes'
import { useDrag } from 'react-dnd'
import { ReactComponent as Note } from '../../../images/icons/sticky-note.svg'

const AddStaticBlock = ({ clearPreview }) => {
  const [, drag, preview] = useDrag({
    item: {
      type: ItemTypes.CARD,
      content: {
        id: 'add',
        class: 'static',
      },
    },
    end: () => {
      clearPreview()
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  return (
    <Fragment>
      <div className={'position-absolute'} style={{
        zIndex: -1,
        left: -125,
        width: 250,
      }}>
        <Card customRef={preview} content={{
          id: 'add',
          class: 'static',
        }} classOfLayout={'static'}/>
      </div>
      <div style={{
        width: 285,
      }} className={'d-flex border rounded'}>
        <NavigationDark ref={drag} className={'px-2 pt-1 rounded-left cursorPointer'}> <Note style={{
          width: 16,
          height: 16,
        }}/>
        </NavigationDark>
        <span className={'text-truncate font-italic w-100 p-2'}>drag to create an information cell...</span>
        <NavigationDark style={{ width: 10 }}
                        className={classnames(' rounded-right')}>
        </NavigationDark>
      </div>
    </Fragment>
  )
}

export default AddStaticBlock
