import React from 'react'
import { Dropdown, Menu } from 'antd'
import Icon from '../../../components/Icon'
import AddTypeModal from './add'
import { useRecoilCallback, useRecoilState } from 'recoil'
import {
  SettingsTypesSelector, VisibleElementModal,
} from '../state'
import SearchComponent from './search'
import ListComponent from './list'
import ImportElementModal from '../../../modals/importElement'
import axios from 'axios'

const Navigation = () => {

  const [settings, setSettings] = useRecoilState(SettingsTypesSelector)

  const updateSettings = (key) => {
    localStorage.setItem(key, settings[key] ? '0' : '1')
    setSettings(prevSettings => {
      return {
        ...prevSettings, [key]: !prevSettings[key],
      }
    })
  }

  const exportElementsAll = async () => {
    console.log('exportElementsAll')
    try {
      const { data } = await axios.get('/api/v2/types/exports',
        { responseType: 'blob' })
      const _url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = _url
      link.setAttribute('download', window.tenancy + '.zip')
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      console.error(e.toString())
    }
  }
  const onOpenImportElement = useRecoilCallback(({ set }) => () => {
    set(VisibleElementModal, true)
  }, [])

  return (<div className={'col-2'}>
    <div className={'d-flex justify-content-between '}>
      <label className="mb-2 elements">ELEMENTS
        <Dropdown overlay={<Menu>
          <Menu.Item onClick={onOpenImportElement}>
                <span><Icon type="icon-Import"
                            style={{
                              fontSize: 14, verticalAlign: '0.1em',
                            }}/><span> Import element</span>
                </span>
          </Menu.Item>
          {/*<Menu.Item onClick={exportElements}>
                <span><Icon type="icon-export"
                            style={{
                              fontSize: 14, verticalAlign: '0.1em',
                            }}/><span> Export  map of  elements</span>
                </span>
          </Menu.Item>*/}
          <Menu.Item onClick={exportElementsAll}>
                <span><Icon type="icon-export"
                            style={{
                              fontSize: 14, verticalAlign: '0.1em',
                            }}/><span> Export All</span>
                </span>
          </Menu.Item>
        </Menu>}
        >
          <Icon type="icon-more" className={'elements-menu'} style={{
            fontSize: 18, verticalAlign: '0',
          }}/>
        </Dropdown>
      </label>

      <Icon type="icon-return"
            onClick={() => updateSettings('isOpenListModels')}
            style={{
              fontSize: '1.3rem', verticalAlign: '0.1em',
            }}/>
    </div>

    <SearchComponent/>
    <React.Suspense fallback={<div>Loading...</div>}>
      <ListComponent/>
      <div className={'my-2'}>
        <AddTypeModal/>
      </div>
    </React.Suspense>
    <ImportElementModal/>
  </div>)

}

export default Navigation
