import React, {
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import classNames from 'classnames'
import { Dropdown } from 'antd'
import { row } from '../menu'
import MenuKey from '../menu/key'
import styled from 'styled-components'
import { Icon } from '../../index'
import { canBeKey } from '../../../config'

const ItemTypes = {
  CARD: 'ROW',
}

const InputOfTable = styled.input`
  background-color: ${props => props.theme.colorTable.plain} !important;
  color: ${props => props.theme.colorTable.text} !important;
  border-color: ${props => props.theme.colorTable.back} !important;

  &.isKey {
    background-color: ${props => props.theme.colorTable.backLight} !important;
    color: ${props => props.theme.colorTable.backLightText} !important;
  }
`
const DivOfTable = styled.div`
  display: table-cell;
  background-color: ${props => props.theme.colorTable.plain} !important;
  color: ${props => props.theme.colorTable.text} !important;
  border-color: ${props => props.theme.colorTable.back} !important;

  &.isKey {
    background-color: ${props => props.theme.colorTable.backLight} !important;
    color: ${props => props.theme.colorTable.backLightText} !important;
  }
`
const Span = styled.span`
  &:after {
    content: " ${props => props.value || 0}";
  }
`
const Item = ({ index, h, nextItem, change, onPaste, data, header }) => {

  const ref = useRef(null)
  const [isActive, setActive] = useState(false)

  const getValue = () => {
    if (h) {
      if (h.path) {
        if (data[h.path]) {
          return data[h.path]
        }
      }
    }
    return ''
  }

  useEffect(() => {
    if (isActive) {
      if (ref.current) {
        ref.current.focus()
      }
    }
  }, [isActive, ref])

  const onCopy = e => {
    const indexStart = header.findIndex(_h => _h.path === h.path)
    const selection = document.getSelection()
    const values = selection.toString().split(/\n/g)
    const emptyArray = new Array(indexStart).fill('')
    const chunkArray = (arr, cnt) => arr.reduce(
      (prev, cur, i, a) => !(i % cnt)
        ? prev.concat([a.slice(i, i + cnt)])
        : prev,
      [])

    e.clipboardData.setData('text/plain',
      chunkArray([...emptyArray, ...values], header.length).
        map(item => item.join(String.fromCharCode(9))).
        join(String.fromCharCode(13)))
    e.preventDefault()
  }
  if (isActive === false) {
    return (<DivOfTable key={'item-' + index + '-' + h.path}
                        data-index={index}
                        data-path={h.path}
                        onCopy={onCopy}
                        onClick={() => setActive(true)}
                        className={classNames('p-2 knacklyBlock', {
                          'isKey': h.isKey,
                        })}
    >
      {data[h.path]}
    </DivOfTable>)
  }

  return (<InputOfTable key={'item-' + index + '-' + h.path}
                        data-index={index}
                        data-path={h.path}
                        ref={ref}
                        className={classNames('p-2 knacklyBlock', {
                          'isKey': h.isKey,
                        })} onKeyDown={nextItem}
                        onChange={e => {
                          change(index, h.path, e.target.value)
                        }}
                        onBlur={() => setActive(false)}
                        onPaste={onPaste(index, h.path)}
                        value={getValue()}/>)
}
const Card = React.forwardRef(
  (
    {
      data,
      header,
      handleDelete,
      nextItem,
      change,
      index,
      isDragging,
      connectDragSource,
      connectDropTarget,
      onPaste,
      connectDragPreview,
      isQuery,
      QueryRowKey,
      handleKey,
    },
    ref) => {
    const elementRef = useRef(null)
    connectDragPreview(elementRef)
    connectDropTarget(elementRef)
    const previewRef = useRef(null)
    connectDragSource(previewRef)
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))
    useImperativeHandle(ref, () => ({
      getNode: () => previewRef.current,
    }))
    let CountByRow = (<Span value={index + 1}/>)

    if (isQuery) {
      if (QueryRowKey) {
        if (data.name === QueryRowKey) {
          CountByRow = (<Span value={' '}><Icon type={'icon-key1'}/></Span>)
        }
      }
    }
    return (
      <Fragment>
        <div className={'d-flex flex-row bd-highlight'} key={'row-' + index}
             ref={elementRef}>
          <div className={classNames('py-2 text-center noselect')}
               style={{ minWidth: 30 }} ref={previewRef}>
            <Dropdown overlay={isQuery ? MenuKey({
              handleKey: canBeKey(data) ? handleKey : undefined,
              isKey: QueryRowKey && (data.name === QueryRowKey),
              index,
            }) : row({
              handleDelete,
              index,
            })} trigger={['click']}>
              {CountByRow}
            </Dropdown>
          </div>
          {header.map(h => {
              if (isQuery) {
                if (QueryRowKey) {
                  if (data.name === QueryRowKey) {
                    return (<Item index={index}
                                  h={{
                                    ...h,
                                    isKey: true,
                                  }}
                                  nextItem={nextItem}
                                  change={change}
                                  onPaste={onPaste}
                                  data={data}
                                  header={header}/>)
                  }
                }
              }
              return (<Item index={index}
                            h={h}
                            nextItem={nextItem}
                            change={change}
                            onPaste={onPaste}
                            data={data}
                            header={header}/>)
            },
          )}
          <div style={{ minWidth: 150 }}/>
        </div>
      </Fragment>
    )
  },
)
export default DropTarget(
  ItemTypes.CARD,
  {
    hover (props, monitor, component) {
      if (!component) {
        return null
      }
      const node = component.getNode()
      if (!node) {
        return null
      }
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = node.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      props.moveRow(dragIndex, hoverIndex)
      monitor.getItem().index = hoverIndex
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: props => {
        return ({
          id: props.id,
          index: props.index,
        })
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    }),
  )(Card),
)
