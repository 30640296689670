import React, { Fragment } from 'react'
import Card from '../Card'
import InsertColumn from '../insert/column'
import { InsertRow } from '../insert'

const RowNavigation = ({ number = 1 }) => {
  /*  return (<div className={'position-absolute ml-1'}
                 style={{ left: 0, width: '2rem' }}
    >{number}</div>)*/

  return (<div className={'position-absolute text-center'}
               style={{ left: 0, width: '2rem' }}
  >{number}</div>)

}

const Row = ({
  row,
  countRows,
  rowIndex,
  position,
  moveCard,
  variables,
  countColumns,
  add,
  changeContent,
  removeCard,
  changeVisibility,
  setSelected,
  changeOffsetAndSpan,
  closePreviewRow,
  previewRow,
  scrolling,
  removeSelected,
  moveRowTo,
}) => {
  const preview = (position) => {
    previewRow(
      position,
      rowIndex)
  }

  if (position.row === rowIndex && position.position > 0) {
    return (
      <Fragment>
        <InsertRow countColumn={countColumns} key={'InsertRow' + rowIndex}
                   closePreviewRow={closePreviewRow} add={add}
                   indexRow={rowIndex}/>

        <div className={'row no-gutters ml-3'} id={'row' + rowIndex}>
          <RowNavigation number={rowIndex + 1}/>
          <Card
            key={row[0].id}
            col={'col-' + Math.round((12 / countColumns) * position.position)}
            classOfLayout={row[0].class}
            index={0}
            countRows={countRows}
            content={row[0]}
            column={row[0]}
            scrolling={scrolling}
            moveCard={moveCard}
            isHovering={true}
            previewRow={preview}
            countColumns={countColumns}
            add={() => add(
              {
                row: rowIndex,
                column: 0,
                type: 'INSERT_TO_ROW',
              })}
            variables={variables}
            rowIndex={rowIndex}
            row={row}
            isSelected={row[0].isSelected || false}
            setSelected={() => setSelected(rowIndex, 0)}
            removeSelected={removeSelected}
            changeVisibility={(key, val) => changeVisibility({
              row: rowIndex,
              id: 'column.id',
            })(key, val)}
            changeOffsetAndSpan={changeOffsetAndSpan}
            changeContent={(val) => {
              changeContent({
                id: row[0].id,
                row: rowIndex,
                column: 0,
              }, val)
            }}
            removeCard={removeCard}
            moveRowTo={moveRowTo}
          />
          {new Array(countColumns - position.position).fill('').
            map((column, index) => <InsertColumn key={index}
                                                 previewRow={preview}
                                                 index={index +
                                                   position.position}
                                                 isHovering={true}
                                                 value={''}
                                                 add={(oldCard) => {
                                                   add(
                                                     {
                                                       row: rowIndex,
                                                       column: index +
                                                         position.position,
                                                       type: 'INSERT_TO_ROW',
                                                     }, oldCard)
                                                 }}
                                                 hasInRow={true}
                                                 hoverFirstItem={() => undefined}
                                                 rollbackAllItems={() => undefined}
                                                 countColumn={countColumns}/>)}
        </div>
      </Fragment>)
  }
  const hasOffSet = !!row.find(column => column.offset > -1)
  if (hasOffSet) {
    let columnsWithEmptyElements = [
      ...new Array(countColumns).fill('').map((column, index) => ({
        isEmpty: true,
        offset: index,
        span: 1,
      }))]
    columnsWithEmptyElements = columnsWithEmptyElements.map(column => {
      const columnFromRow = row.find(
        columnFromRow => columnFromRow.offset === column.offset)
      if (columnFromRow) {
        return ({ ...columnFromRow })
      } else {
        return column
      }
    })
    let instance = []
    let allSize = 0
    columnsWithEmptyElements.forEach((column, index) => {
      if (countColumns === allSize) {
        return
      }
      if (index > 0) {
        const preColumn = instance[instance.length - 1]
        if (preColumn) {
          const preSize = preColumn.offset + preColumn.span
          const Size = column.offset + column.span
          if (Size > preSize) {
            allSize += column.span
            instance = [...instance, column]
          }
        }
      } else {
        allSize += column.span
        instance = [...instance, column]
      }
    })
    columnsWithEmptyElements = [...instance]
    return (
      <Fragment>
        <InsertRow countColumn={countColumns} key={'InsertRow' + rowIndex}
                   add={add}
                   closePreviewRow={closePreviewRow}
                   indexRow={rowIndex}/>
        <div className={'row no-gutters ml-3'} id={'row' + rowIndex}>
          <RowNavigation number={rowIndex + 1}/>
          {columnsWithEmptyElements.map((column, index) => column.isEmpty ? (
              <InsertColumn key={index}
                            index={index}
                            value={''}
                            add={(oldCard) => {
                              add(
                                {
                                  row: rowIndex,
                                  column: column.offset,
                                  type: 'INSERT_TO_ROW',
                                }, oldCard)
                            }}
                            hasInRow={true}
                            hoverFirstItem={() => undefined}
                            rollbackAllItems={() => undefined}
                            countColumn={countColumns}/>
            ) :
            (<Card
              key={column.id}
              column={column}
              countRows={countRows}
              col={column.span > 1 ? 'col-' +
                Math.round(12 / countColumns * column.span) : 'col'}
              classOfLayout={column.class}
              index={index}
              content={column}
              moveCard={moveCard}
              scrolling={scrolling}
              countColumns={countColumns}
              variables={variables}
              rowIndex={rowIndex}
              row={row}
              isSelected={column.isSelected || false}
              setSelected={() => setSelected(rowIndex, index)}
              removeSelected={removeSelected}
              changeOffsetAndSpan={changeOffsetAndSpan}
              changeVisibility={(key, val) => changeVisibility({
                row: rowIndex,
                id: column.id,
              })(key, val)}
              add={(columnIndex) => add(
                {
                  row: rowIndex,
                  column: columnIndex,
                  type: 'INSERT_TO_ROW',
                })}
              changeContent={(val) => changeContent({
                id: column.id,
                row: rowIndex,
                column: index,
              }, val)}
              removeCard={removeCard}
              moveRowTo={moveRowTo}
            />))}
        </div>
      </Fragment>)
  }
  return (
    <Fragment>
      <InsertRow countColumn={countColumns} key={'InsertRow' + rowIndex}
                 add={add}
                 closePreviewRow={closePreviewRow}
                 indexRow={rowIndex}/>

      <div className={'row no-gutters ml-3'}
           id={'row' + rowIndex}>
        <RowNavigation number={rowIndex + 1}/>
        {row.map((column, index) => <Card
          key={column.id}
          col={'col-12'}
          countRows={countRows}
          classOfLayout={column.class}
          index={index}
          content={column}
          column={column}
          scrolling={scrolling}
          moveCard={moveCard}
          previewRow={preview}
          countColumns={countColumns}
          add={(columnIndex) => add(
            {
              row: rowIndex,
              column: columnIndex,
              type: 'INSERT_TO_ROW',
            })}
          variables={variables}
          rowIndex={rowIndex}
          isSelected={column.isSelected || false}
          setSelected={() => setSelected(rowIndex, index)}
          removeSelected={removeSelected}
          row={row}
          changeVisibility={(key, val) => changeVisibility({
            row: rowIndex,
            id: column.id,
          })(key, val)}
          changeOffsetAndSpan={changeOffsetAndSpan}
          changeContent={(val) => {
            changeContent({
              id: column.id,
              row: rowIndex,
              column: index,
            }, val)
          }}
          removeCard={removeCard}
          moveRowTo={moveRowTo}/>)}
      </div>
    </Fragment>
  )
}

export default Row
