import React, { Fragment, useState } from 'react'
import moment from 'moment'
import AsyncSelect from '../../components/AsyncSelect'
import Snapshot from './modal/snapshot'
import Icon from '../../components/Icon'
import { Button, Tooltip } from 'antd'

const style = {
  verticalAlign: '0.1em',
}

const styleButton = {
  boxShadow: 'none',
}

const Version = ({
  snapshots,
  updateSnapshot,
  catalogName,
  changeVersion,
  liveVersion,
  changeIsDisabled,
  isDisabled,
}) => {
  const [currentSnapshot, setCurrentSnapshot] = useState(null)

  const getSnapshot = (id) => {
    return snapshots.find(
      snapshot => snapshot._id === id)
  }
  const onCancel = () => {
    setCurrentSnapshot(null)
  }
  const onSave = (label) => {
    updateSnapshot(currentSnapshot, label)
    setCurrentSnapshot(null)
  }
  return (
    <Fragment>
      <div className={'col-1'}>
        <div className="form-group form-check m-3">
          <label className="form-check-label cursorPointer">
            <input type="checkbox"
                   className="form-check-input cursorPointer"
                   checked={!isDisabled}
                   onChange={() => changeIsDisabled(
                     !isDisabled)}/>
            Enabled</label>
        </div>
      </div>
      <div className={'col-3'}>
        {!liveVersion && (<AsyncSelect isSearchable={true}
                                       isDisabled={isDisabled}
                                       value={liveVersion
                                         ? liveVersion
                                         : 'Latest'}
                                       onChange={selected => {
                                         if (!selected) {
                                           return
                                         }
                                         if (selected.value === 'Latest') {
                                           changeVersion(undefined)
                                         } else {
                                           const currentSnapshot = getSnapshot(
                                             selected.value)
                                           if (!currentSnapshot.label) {
                                             setCurrentSnapshot(
                                               currentSnapshot._id)
                                           }
                                           changeVersion(selected.value)
                                         }
                                       }}
                                       options={[
                                         {
                                           value: 'Latest',
                                           label: 'Latest',
                                         },
                                         ...snapshots.map(snapshot => ({
                                           value: snapshot._id,
                                           label: snapshot.label || moment(
                                             snapshot.version).format('L LTS'),
                                         }))]}
        />)}
        {liveVersion && (<div className="d-flex">
          <div className={'w-100'}>
            <AsyncSelect isSearchable={true}
                         isDisabled={isDisabled}
                         value={liveVersion ? liveVersion : 'Latest'}
                         onChange={selected => {
                           if (!selected) {
                             return
                           }
                           if (selected.value === 'Latest') {
                             changeVersion(undefined)
                           } else {
                             const currentSnapshot = getSnapshot(selected.value)
                             if (!currentSnapshot.label) {
                               setCurrentSnapshot(currentSnapshot._id)
                             }
                             changeVersion(selected.value)
                           }
                         }}
                         options={[
                           {
                             value: 'Latest',
                             label: 'Latest',
                           },
                           ...snapshots.map(snapshot => ({
                             value: snapshot._id,
                             label: snapshot.label || moment(
                               snapshot.version).format('L LTS'),
                           }))]}
            />
          </div>
          <Tooltip placement="bottom"
                   title={'Edit label in the current version'}>
            <Button shape="circle" style={styleButton} size={'large'}
                    className="m-2 border-0 bg-transparent"
                    onClick={() => setCurrentSnapshot(liveVersion)}>
              <Icon type="icon-pencil-alt" style={style}/>
            </Button>
          </Tooltip>
        </div>)}
      </div>
      {currentSnapshot && (
        <Snapshot visible={true}
                  snapshot={getSnapshot(currentSnapshot)}
                  catalogName={catalogName}
                  onCancel={onCancel}
                  onSave={onSave}/>)}
    </Fragment>)
}

export default Version
