import React, { useMemo } from 'react'
import { Button, Tooltip } from 'antd'
import Icon from '../Icon'
import {
  useRecoilValue,
  useRecoilCallback,
} from 'recoil'
import {
  CurrentTypeSelector,
  ElementsChangesAtom,
  NeedSaveElementsChanges,
} from '../../views/types/state'
import axios from 'axios'
import { queryClient } from '../../config'

let style = {
  verticalAlign: '0.1em',
}

let styleButton = {
  boxShadow: 'none',
}
let styleBlock = {
  marginTop: '1.75rem',
}
const NavButtons = ({
                      saveCaption,
                      cancelCaption,
                    }) => {
  const needSave = useRecoilValue(NeedSaveElementsChanges)

  const save = useRecoilCallback(({
                                    snapshot,
                                    set,
                                    refresh,
                                  }) => async () => {
    const Elements = await snapshot.getPromise(ElementsChangesAtom)
    try {
      await axios.post('/api/v2/types', Elements)
      window.typesWereChanged = false
      set(ElementsChangesAtom, [])
      await queryClient.clear()
      refresh(CurrentTypeSelector)
    } catch (e) {
      console.log(e.toString())
    }
  }, [])

  const cancel = useRecoilCallback(({ set }) => () => {
    window.typesWereChanged = false
    set(ElementsChangesAtom, [])
  }, [])

  const MemoBlock = useMemo(() => (<div style={styleBlock}>
    {!!needSave && (<Tooltip placement="bottom" title={saveCaption}>
      <Button id={'saveType'} shape="circle" style={styleButton} size={'large'}
              className="circle-ant-btn mx-1" onClick={save}>
        <Icon type="icon-save" style={style}/>
      </Button>
    </Tooltip>)}
    {!!needSave && (<Tooltip placement="bottom" title={cancelCaption}>
      <Button id={'cancelType'} shape="circle" style={styleButton}
              size={'large'}
              className="mx-1 border-0 bg-transparent"
              onClick={cancel}>
        <Icon type="icon-undo" style={style}/>
      </Button>
    </Tooltip>)}
  </div>), [needSave])

  return MemoBlock
}

export default NavButtons
