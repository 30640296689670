import React from 'react'
import { Menu } from 'antd'

const column = ({ isKey, forceKey, index, handleKey, handleDelete, handleRename, handleSortASC, handleSortDESC, handleRelabel }) => (
  <Menu>
    <Menu.Item disabled={isKey || forceKey} onClick={() => handleKey(index)}>
      <button type="button" disabled={isKey || forceKey} className={'btn btn-link btn-sm ' + ((isKey || forceKey) ? 'text-secondary' : 'text-dark')}>Set key</button>
    </Menu.Item>
    <Menu.Item disabled={isKey && forceKey} onClick={() => handleRename(index)}>
      <button type="button" disabled={isKey && forceKey} className={'btn btn-link btn-sm ' + ((isKey && forceKey) ? 'text-secondary' : 'text-dark')}>Rename</button>
    </Menu.Item>
    <Menu.Item onClick={() => handleSortASC(index)}>
      <button type="button" className="btn btn-link btn-sm text-dark">Sort A to Z</button>
    </Menu.Item>
    <Menu.Item onClick={() => handleSortDESC(index)}>
      <button type="button" className="btn btn-link btn-sm text-dark">Sort Z to A</button>
    </Menu.Item>
    {/*<Menu.Item onClick={() => handleRelabel(index)}>
      <button type="button" className="btn btn-link btn-sm text-dark">Relabel</button>
    </Menu.Item>*/}
    <Menu.Item disabled={isKey} onClick={() => handleDelete(index)}>
      <button type="button" disabled={isKey} className={'btn btn-link btn-sm ' + (isKey ? 'text-secondary' : 'text-dark')}>Delete</button>
    </Menu.Item>
  </Menu>
)
export default column