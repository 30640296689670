import { PUSH_USER } from '../../action/user'

export default function user (state = {}, action) {
  switch (action.type) {
    case PUSH_USER: {
      return { ...action.user }
    }
    default :
      return state
  }
}
