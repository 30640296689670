import { atom, selector } from 'recoil'
import axios from 'axios'
import sortTypes from '../../../utils/sortTypes'
import { TypesSelector } from '../state'

export const SearchAtom = atom({
	key: 'SearchTypeNavigation',
	default: '',
})

export const TypesNavigationSelector = selector({
	key: 'TypesNavigation',
	get: async ({ get }) => {
		const search = get(SearchAtom)
		if (!search) {
			return get(TypesSelector)
		}

		let params = {
			fields: 'name,kind,type,_id',
		}

		if (search) {
			params.search = search
		}

		const { data } = await axios.get('/api/v2/types', {
			params
		})

		if (data) {
			return sortTypes(data)
		}
		return []
	},
})
