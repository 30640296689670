import React from 'react'
import { useRecoilState } from 'recoil'
import { SearchAtom } from './state'
import SearchInput from '../../../components/Search'

const SearchComponent = () => {
	const [search, setSearch] = useRecoilState(SearchAtom)
	return (
		<SearchInput search={search} onSubmit={setSearch} />
	)
}

export default SearchComponent
