import React from 'react'

import { Input, Select } from '../../../components'
import {
  DATE,
  NUMBER,
  OBJECT, QUESTION,
  TABLE,
  TEXT,
  TRUEFALSE,
} from '../../../config'

import ActionsList from '../../../components/ActionsList'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  editMemberProperty,
  editMemberPropertyByPath,
  TypesSelector,
} from '../state'
import EditMemberPropertyComponent from '../EditMemberPropertyComponent'
import Expressions from './expressions'
import Expression from './expression'
import MappingDesigner from './mappingDesigner'
import Notes from '../notes'

const TypePropertyComponent = () => {
  const types = useRecoilValue(TypesSelector)
  const [ref, setRef] = useRecoilState(editMemberProperty('ref'))
  const setWasChanged = useSetRecoilState(editMemberProperty('wasChanged'))
  const [type, setType] = useRecoilState(editMemberProperty('type'))

  const MODEL_NAMES = types.filter(
    ({ type }) => type !== TABLE && type !== QUESTION).map(type => type.name)
  let TYPE_OPTIONS = [TEXT, NUMBER, DATE, TRUEFALSE, 'line', ...MODEL_NAMES]

  if (ref && !MODEL_NAMES.includes(ref)) {
    TYPE_OPTIONS.splice(TYPE_OPTIONS.length, 0, 'line', ref)
  }
  return (<Select values={TYPE_OPTIONS}
                  value={type === OBJECT
                    ? (ref || 'error')
                    : type}
                  className={'form-group'} onChange={val => {
    if (MODEL_NAMES.includes(val)) {
      setType(OBJECT)
      setRef(val)
    } else {
      setType(val)
    }
    setWasChanged(true)
  }}
  />)
}

const WasChangedComponent = () => {
  const wasChanged = useRecoilValue(editMemberProperty('wasChanged'))
  if (wasChanged) {
    return (<div className={'col-12'}><p
      className={'text-danger mb-2, mt-0'}>Changes to a formula name or
      type
      can cause data loss because the changes are not applied to existing
      records.</p>
    </div>)
  }
  return null
}

const Formula = () => {

  const setProperty = useSetRecoilState(editMemberPropertyByPath)
  const isExternal = useRecoilValue(editMemberProperty('isExternal'))
  const isList = useRecoilValue(editMemberProperty('isList'))
  const type = useRecoilValue(editMemberProperty('type'))

  const onChange = (key, value) => {
    setProperty({
      path: key,
      value,
    })
  }

  return (
    <div className={'w-100'}>
      <div className={'row'}>
        <div className={'col-6'}>
          <EditMemberPropertyComponent path={'name'} defaultValue={''}>
            {({
                value,
                onChange,
              }) => (
              <Input type={'test'} label={'FORMULA NAME'} id={'formulaName'}
                     value={value}
                     onChange={(nextName) => {
                       onChange(nextName)
                       setProperty({
                         path: 'wasChanged',
                         value: true,
                       })
                     }} disabled={isExternal} />)}</EditMemberPropertyComponent>
        </div>
        <div className={'col-6'}>
          <label>FORMULA TYPE</label>
          <div className={'d-flex '}>
            <div className={'w-25 d-flex flex-column'} style={{
              height: 38,
              marginTop: -3,
            }}>
              <div className="form-check">
                <input className="form-check-input" type="radio" id={'single'}
                       name={'formula'}
                       checked={!isList}
                       onChange={() => {
                         onChange('isList', false)
                         onChange('expression', (preExpression) => {
                           if (preExpression) {
                             try {
                               return JSON.parse(preExpression)[0]
                             } catch (e) {

                             }
                           }
                           return ''
                         })

                         onChange('wasChanged', true)
                       }}
                />
                <label className="form-check-label" htmlFor={'single'}>
                  Single
                </label>
              </div>
              <div className="form-check" style={{ marginTop: -1 }}>
                <input className="form-check-input" type="radio" id={'ListOf'}
                       name={'formula'}
                       checked={isList}
                       onChange={() => {
                         onChange('isList', true)
                         onChange('expression', (preExpression) => {
                           if (preExpression) {
                             try {
                               return JSON.stringify([preExpression])
                             } catch (e) {

                             }
                           }
                           return '[]'
                         })
                         onChange('wasChanged', true)
                       }}
                />
                <label className="form-check-label" htmlFor={'ListOf'}>
                  List of
                </label>
              </div>
            </div>
            <div className={'w-50 d-flex'}>
              <TypePropertyComponent />
            </div>
            {!isExternal && (<div className={'w-25 px-2 py-1'}>
              <ActionsList />
            </div>)}
          </div>
        </div>
        <WasChangedComponent />
        {isList && (<Expressions />)}
        {(!isList && type !== OBJECT) && (<Expression />)}
        {(!isList && type === OBJECT) && (<MappingDesigner />)}
        <Notes/>
      </div>
    </div>
  )
}

export default Formula
