import React from 'react'
import DocxTemplate from './docx'
import PdfFormTemplate from './pdff'
import TextTemplate from './text'
import FileTemplate from './file'
import ActionsList from '../../../../components/ActionsList'
import { Input, Select } from '../../../../components'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'
import { useRecoilValue } from 'recoil'
import { editMemberProperty } from '../../state'
import Notes from '../../notes'

export let TEMPLATETYPES = [
  {
    value: 'text',
    label: 'text',
  },
  {
    value: 'docx',
    label: 'docx',
  }, {
    value: 'file',
    label: 'file',
  },
]

export const activePDF = () => {
  TEMPLATETYPES.push({
    value: 'pdff',
    label: 'pdf form',
  })
}

export const Types = () => {
  const isExternal = useRecoilValue(editMemberProperty('isExternal'))
  const type = useRecoilValue(editMemberProperty('type'))

  return (<div className={'w-100'}>
    <div className={'row'}>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'name'} defaultValue={''}>
          {({
              value,
              onChange,
            }) => (
            <Input label={'TEMPLATE NAME'} value={value} id={'templateName'}
                   type={'text'} spellCheck={false}
                   onChange={onChange}
                   disabled={isExternal}
            />)}
        </EditMemberPropertyComponent>
      </div>
      <div className={'col-5'}>
        <EditMemberPropertyComponent path={'type'} defaultValue={''}>
          {({
              value,
              onChange,
            }) => (<Select label={'TEMPLATE TYPE'}
                           value={value}
                           id={'templateType'}
                           values={TEMPLATETYPES}
                           onChange={onChange} />)}
        </EditMemberPropertyComponent>
      </div>
      {!isExternal &&
        (<div className="col-1 pt-4">
          <ActionsList />
        </div>)}
      {type === 'text' && (
        <TextTemplate />
      )}
      {type === 'file' && (
        <FileTemplate />
      )}
      {type === 'docx' && (
        <DocxTemplate />
      )}
      {type === 'pdff' && (
        <PdfFormTemplate />
      )}
      <Notes/>
    </div>
  </div>)
}
