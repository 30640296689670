import React, { useState } from 'react'
import { Modal } from 'antd'
import { Input } from '../../../components'
import { IsInvalidIdentifier } from '../../../utils'
import { COLLECTION, OBJECT } from '../../../config'
import axios from 'axios'
import { useQuery } from 'react-query'
import AsyncSelect from '../../../components/AsyncSelect'

const NewCollections = ({
  visible,
  onCancel,
  onSave,
  collections,
}) => {
  const [name, setName] = useState('')
  const [type, setType] = useState('new')
  const [nameTouched, setNameTouched] = useState(false)
  const [typeTouched, setTypeTouched] = useState(false)
  const [isEveryone, setEveryone] = useState(false)
  let {
    data: types,
    isLoading,
  } = useQuery(['types', 'params', 'name,type,_id'], async () => {
    let params = {
      fields: 'name,type,_id',
    }

    const { data } = await axios.get('/api/v2/types', {
      params,
    })
    return data
  }, {
    retry: false,
    refetchOnWindowFocus: false,
  })

  if (!types || isLoading) {
    types = []
  }

  const submit = () => {
    if (!errors.name && !errors.type) {
      onSave({
        name,
        type,
        isEveryone,
      })
      reset()
    }
  }

  const cancel = () => {
    onCancel()
    reset()
  }

  const reset = () => {
    setName('')
    setType('new')
    setNameTouched(false)
    setTypeTouched(false)
  }
  const validate = () => {
    const errors = {}
    if (!type) {
      errors.type = 'You must choose a type for items in the collection.'
    } else {
      errors.type = ''
    }
    let err
    if (!name) {
      errors.name = 'Please provide a name.'
    } else if (name.length > 50) {
      errors.name = 'Collection names cannot exceed 50 characters.'
      errors.nameForce = true
    } else if (name.indexOf('$') >= 0) {
      errors.name = 'Collection names cannot include the \'$\' character.'
      errors.nameForce = true
    } else if (IsInvalidIdentifier(name)) {
      err = IsInvalidIdentifier(name)
      errors.name = `Invalid: ${err}.`
      errors.nameForce = true
    } else if (collections.find(c => c.name === name)) {
      errors.name = 'A collection by this name already exists.'
      errors.nameForce = true
    } else if (types.find(t => t.name === name) && type === 'new') {
      errors.type = 'The new collection by this name already exists.'
      errors.typeForce = true
    } else {
      errors.name = ''
    }
    return errors
  }

  const errors = validate()

  return (
    <Modal
      title="New Catalog"
      visible={visible}
      onCancel={cancel}
      closable={false}
      footer={[
        <button key="submit" className={'btn btn-secondary dart-btn'}
                onClick={submit}>
          Ok
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                onClick={cancel}>Cancel</button>,
      ]}
    >
      <form onSubmit={e => {
        e.preventDefault()
        submit()
      }}>
        <div className={'row'}>
          <div className={'col-6'}>
            <Input type={'text'} value={name} onChange={setName} label={'Name'}
                   id={'modalNewTypeName'}
                   onBlur={() => { if (visible) setNameTouched(true)}}/>
            {errors.name && (nameTouched || errors.nameForce) &&
              <p className={'text-danger'}>
                {errors.name}
              </p>}
          </div>
          <div className={'col-6'}>
            <div className="form-group">
              <AsyncSelect id={'addCatalog'}
                           label={'Type'}
                           onChange={selected => {
                             if (selected) {
                               setType(selected.value)
                             }
                           }
                           }
                           value={type}
                           options={[
                             { value: 'new', label: '(new)' },
                             ...types.filter(
                               t => t.type === COLLECTION || t.type === OBJECT).
                               map(type => ({
                                 value: type._id,
                                 label: type.name,
                               }))]}
                           isInsideModal={true}/>
              {errors.type && (typeTouched || errors.typeForce) &&
                <p className={'text-danger'}>
                  {errors.type}
                </p>}
            </div>
          </div>
          <div className="col-12  py-2">
            <div className="form-group form-check m-0">
              <input type="checkbox" id={'access'} className="form-check-input"
                     value={''} checked={isEveryone}
                     onChange={() => setEveryone(!isEveryone)}/>
              <label className="form-check-label" htmlFor={'access'}>Grant
                access to everyone by default</label>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default NewCollections
