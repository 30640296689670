import axios from 'axios'

export const ADD_NEW_COLLECTION = 'ADD_NEW_COLLECTION'
export const PUSH_COLLECTIONS = 'PUSH_COLLECTIONS'
export const CHANGE_COLLECTIONS = 'CHANGE_COLLECTIONS'
export const ERROR_COLLECTIONS = 'ERROR_COLLECTIONS'
export const REQUEST_COLLECTIONS = 'REQUEST_COLLECTIONS'
export const EDIT_COLLECTION = 'EDIT_COLLECTION'
export const SAVE_COLLECTION = 'SAVE_COLLECTION'
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION'

export const addNewCollection = collection => async dispatch => {
  let { data: { status, obj } } = await axios.post('/api/collections', collection)
  if (status === 'Done') {
    dispatch({
      type: ADD_NEW_COLLECTION,
      obj,
    })
  }
}
export const requestCollections = () => async dispatch => {
  dispatch({ type: REQUEST_COLLECTIONS })
  try {
    let { data } = await axios.get('/api/collections')
    if (data) {
      dispatch({
        type: PUSH_COLLECTIONS,
        data,
      })
    } else {
      dispatch({ type: ERROR_COLLECTIONS })
    }
  } catch (e) {
    console.log(e)
    dispatch({ type: ERROR_COLLECTIONS })
  }
}
export const saveCollection = index => async (dispatch, getState) => {
  let { collections } = getState()
  if (collections[index]) {
    let { data: { collection } } = await axios.put(
      `/api/collections/${collections[index]._id}/`,
      { ...collections[index] })
    if (collection) {
      dispatch({
        type: SAVE_COLLECTION,
        index,
        collection,
      })
    }
  }
}
export const saveCollections = () => async (dispatch, getState) => {
  let { collections } = getState()
  if (collections) {
    let { data } = await axios.put(`/api/collections/`,
      { collections })
    if (data.collections) {
      dispatch({
        type: PUSH_COLLECTIONS,
        data,
      })
    }
  }
}
export const addApp = (name, template, id) => async dispatch => {
  let { data: { status, collection } } = await axios.post(
    `/api/collections/${id}/apps`, {
      name,
      template,
    })
  if (status === 'Done') {
    dispatch({
      type: CHANGE_COLLECTIONS,
      collection,
    })
  }
}

export const changeCollections = (key, val, index) => dispatch => dispatch({
  type: EDIT_COLLECTION,
  key,
  val,
  index,
})
export const updateCollections = (Collections) => dispatch => dispatch({
  type: PUSH_COLLECTIONS,
  data: Collections,
})
export const removeCollection = (_id) => async dispatch => {
  try {
    let { status } = await axios.delete(`/api/collections/${_id}`)
    if (status) {
      dispatch({
        type: REMOVE_COLLECTION,
        _id,
      })
    }
  } catch (e) {
    console.error(e)
  }
}
