import React, { Fragment } from 'react'
import { Select } from '../../../../components'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'

const DATE = 'date'

function Date () {
  return (
    <Fragment>
      <div className={'col-12'}>
        <hr />
        DATE INTAKE
      </div>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'style'} defaultValue={'textbox'}>
          {({
              value,
              onChange,
            }) => (
            <Select label={'Style'.toUpperCase()} id={'dateStyle'}
                    value={value || 'textbox'}
                    values={
                      value === 'hidden'
                        ? ['textbox', 'datepicker', 'hidden']
                        : ['textbox', 'datepicker']
                    }
                    onChange={val => onChange(val)} />)}
        </EditMemberPropertyComponent>
      </div>
    </Fragment>
  )
}

export { DATE, Date }
