import { Engine } from 'yatte'
import getMembers from './getMembers'
import loadingType from '../loadingType'
import evaluateFormula from '../evaluateFormula'
import getMembersIndexDB from '../../config/indexMembers'

export const SELECTION = 'selection'
export const OBJECT = 'object'
export const GLOBAL = 'global'

const serialization = async (
  member,
  activeType,
  types,
  isVariables,
  isTemplates,
  isFormulas,
  isGlobal,
  recurseMaxLevels = 3,
) => {
  const indexes = getMembersIndexDB(window.tenancy, activeType._id)
  const loadDef = async (def) => {
    if (def.neededLoading) {
      def = await loadingType(def._id)
      def.neededLoading = false
    }
    return def
  }
  if (!recurseMaxLevels) {
    return []
  }
  let version = await indexes.getItem('version')
  if (!version) {
    await indexes.setItem('version', activeType.version)
    version = activeType.version
  }
  if (version !== activeType.version) {
    await indexes.clear()
    await indexes.setItem('version', activeType.version)
  }
  const alreadyIndexing = await indexes.getItem(member._id)
  if (alreadyIndexing) {
    return alreadyIndexing
  }
  const job = async () => {
    try {
      if (member.type === OBJECT || member.type === GLOBAL) {
        let def = member.isFormulas
          ? types.find(type => type.name === member.ref)
          : types.find(
            type => type._id === member.typeDef || type.name ===
              member.typeName)
        if (def) {
          return getMembers(await loadDef(def), isVariables, isTemplates,
            isFormulas, isGlobal, member.type === GLOBAL)
        }
      }

      if (member.type === SELECTION) {
        if (!member.options) {
          return
        }
        if (member.typeOfVariable === 'anOptionListedBelow' ||
          member.typeOfVariable === 'userData') {
          try {
            const expr = Engine.compileExpr(member.options)
            if (expr.ast.type === Engine.AST.CallExpression) {
              if (expr.ast.callee.type === Engine.AST.Identifier) {
                const queryName = expr.ast.callee.name
                const findTypeByName = typeName => types.find(
                  t => t.name === typeName)
                let query = findTypeByName(queryName)
                if (query) {
                  query = await loadDef(query)
                  if (query.extModelName) {
                    let def = findTypeByName(query.extModelName)
                    if (def) {
                      return getMembers(await loadDef(def), isVariables,
                        isTemplates, isFormulas, isGlobal)
                    }
                  }
                }
              }
            }

            if (expr.ast.type === Engine.AST.MemberExpression) {
              const refs = member.options.split('.')
              const def = await refs.reduce(async (_def, ref) => {
                const currentType = await _def

                const variable = currentType.properties.find(
                  variable => variable.name === ref)

                if (variable) {
                  if (variable.typeDef) {
                    let def = types.find(type => type._id === variable.typeDef)
                    if (def) {
                      return await loadDef(def)
                    }
                  }
                }

                const formula = currentType.formulas.find(
                  formula => formula.name === ref)

                if (formula) {
                  if (formula.ref) {
                    let def = types.find(type => type.name === formula.ref)
                    if (def) {
                      return await loadDef(def)
                    }
                  }
                }
              }, Promise.resolve(activeType))

              if (def) {
                return getMembers(def, isVariables,
                  isTemplates, isFormulas, isGlobal)
              }
            }

            if (expr.ast.type === Engine.AST.Identifier) {
              const formula = activeType.formulas.find(
                formula => formula.name === expr.ast.name)

              if (formula) {
                if (formula.ref) {
                  let def = types.find(type => type.name === formula.ref)
                  if (def) {
                    return getMembers(await loadDef(def), isVariables,
                      isTemplates, isFormulas, isGlobal)
                  }
                }
              }

              const ref = await evaluateFormula(activeType._id, member._id)
              if (ref) {
                const def = types.find(type => type._id === ref)
                return getMembers(await loadDef(def), isVariables, isTemplates,
                  isFormulas, isGlobal)
              }

              const variable = activeType.properties.find(
                variable => variable.name === expr.ast.name)

              if (variable) {
                if (variable.typeDef) {
                  let def = types.find(type => type._id === variable.typeDef)
                  if (def) {
                    return getMembers(await loadDef(def), isVariables,
                      isTemplates, isFormulas, isGlobal)
                  }
                }
              }
            }

            const resultExpression = expr()
            if (resultExpression) {
              const nextChildMembers = Object.keys(
                resultExpression.reduce((
                    previousValue,
                    currentValue) => ({ ...previousValue, ...currentValue }),
                  {}) ||
                {}).map(variable => ({
                name: variable,
                type: 'text',
              }))

              return nextChildMembers
            }
          } catch (e) {
            return []
          }
        }

        if (member.typeOfVariable === 'aTable') {
          let defName = ''
          const expr = Engine.compileExpr(member.options)
          if (expr.ast.type === Engine.AST.CallExpression) {
            if (expr.ast.callee.type === Engine.AST.Identifier) {
              defName = expr.ast.callee.name
            }
          }
          if (!defName) {
            defName = member.options
          }
          let def = types.find(t => t.name === defName)
          if (def) {
            def = await loadDef(def)
            if (def.extModelName) {
              const extDef = types.find(t => t.name === def.extModelName)
              if (extDef) {
                def = extDef
              }
            }
            return getMembers(await loadDef(def), isVariables, false, false,
              false)
          }
        }
      }
      return []
    } catch (e) {
      console.log(activeType.name, member.name, e)
      return []
    }
  }

  const result = await job()
  await indexes.setItem(member._id, result)
  return result
}

export default serialization
