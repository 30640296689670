import mapMembers from './mapMembers'
import { GLOBAL } from '../../config'

const getMembers = (
  def, isVariables, isTemplates, isFormulas, isGlobal, prevGlobal = false) => {
  let members = []
  if (def.neededLoading) {
    return []
  }
  if (isVariables || prevGlobal) {
    if (!isGlobal) {
      members.push(...mapMembers(
        (def.properties || []).filter(member => member.type !== GLOBAL),
        'isVariables'))
    } else {
      members.push(...mapMembers(def.properties || [], 'isVariables'))
    }
  }
  if (!isVariables && isGlobal) {
    members.push(...mapMembers(
      (def.properties || []).filter(member => member.type === GLOBAL),
      'isVariables'))
  }
  if (isTemplates) {
    members.push(...mapMembers(def.templates || [], 'isTemplates'))
  }
  if (isFormulas) {
    members.push(...mapMembers(def.formulas || [], 'isFormulas'))
  }
  return members
}

export default getMembers
