import React, { Fragment, useEffect, useState } from 'react'
import Markdown from '../../components/Markdown'
import styled from 'styled-components'
import getFile from '../adapter/getFile'
import { Icon } from '../../components'
import axios from 'axios'
import { ReactComponent as Spinner } from '../../images/icons/spinner.svg'
import { InitialCaps } from '../../utils'

const DetailsOfAdapter = styled.div`
  color: ${(props) => props.theme.colorTable.text || '#ffffff'} !important;
  background: ${(props) => props.theme.colorTable.plain};
`
const AppSection = styled.div`
  background: ${(props) => props.theme.colorTable.backLight || '#9B9B9B'};
  color: ${(props) => props.theme.colorTable.backLightText || '#ffffff'};
  `

const LinkIcon = styled.a`
  color: ${(props) => props.theme.colorTable.backLightText || '#ffffff'}!important
 `

const External = ({ location }) => {
  const [instruct, setInstruct] = useState('')
  const [fileUrl, setUrl] = useState('')
  const [app, setApp] = useState(location.state.appName ? { name: location.state.appName } : { name: '' })
  let timerID

  useEffect(() => {
    setInstruct(location.state.instruct)
    if (location.state.showDocs) {
      if (location.state.collectionName && location.state.id && location.state.appName) {
        const getUrl = () => process.env.NODE_ENV === 'development'
          ? `http://localhost:5050/${window.tenancy}/`
          : `/`
        setUrl(`${getUrl()}download/${location.state.collectionName}/${location.state.id}`)
        checkInProgressOfItem(location.state.collectionName, location.state.id, location.state.appName)
      }
    }
  }, [location.state])

  const getItemApp = async (collectionName, id, appName) => {
    let { data: { app } } = await axios.get(`/api/collections/${collectionName}/items/${id}/${appName}`)
    return app
  }

  const checkInProgressOfItem = async (collectionName, id, appName) => {
    clearTimeout(timerID)
    const IN_PROGRESS = 'In Progress'

    const nextApp = await getItemApp(collectionName, id, appName)
    if (nextApp.status === IN_PROGRESS) {
      timerID = setTimeout(() => {
        checkInProgressOfItem(collectionName, id, appName)
      }, 1000)
    } else {
      setApp(nextApp)
    }
  }

  return (
    <div className={'row mt-n2'}>
      <div className="col-8 mt-3 offset-md-2">
        <DetailsOfAdapter className="col-12 border overflow-auto" style={{ maxHeight: 'calc(100vh - 15.5rem)' }}>
          <div className="d-flex justify-content-between m-2">
            <div>
              <Markdown className={'markdown'}>{instruct}</Markdown>
            </div>
          </div>
          {location.state.showDocs && (
            <Fragment>
              {(Object.keys(app).length > 0) &&
              (<AppSection className="row mt-2 mb-3 mx-2 py-2">
                <div className="col-10 py-1"><span className="font-weight-bold">{app.label ||
                InitialCaps(app.name)}</span>
                </div>
                {app.files && (
                  <div className="col-2 py-1">
                    {app.files.length > 1 &&
                    (<button className={'btn btn-secondary btn-block p-1 btn-theme'}
                             onClick={getFile(fileUrl + '/' + app._id.toString() + '/all',
                               app.name + '.zip')}>
                      <Icon type="icon-download1" style={{
                        fontSize: '1rem',
                        verticalAlign: '0.15em',
                      }
                      }/> Download All
                    </button>)}
                    {app.files.length === 1 &&
                    (<button className={'btn btn-secondary btn-block p-1 btn-theme'}
                             onClick={getFile(fileUrl + '/' + app._id.toString() + '/0',
                               app.files[0].base)}>
                      <Icon type="icon-download1" style={{
                        fontSize: '1rem',
                        verticalAlign: '0.15em',
                      }}/> Download
                    </button>)}
                  </div>
                )}
                {!app.files &&
                <div className={'col-12 p-3 text-center'}><Spinner className={'rot'} style={{ width: 50 }}/>
                </div>}
                {app.files && (<Fragment>
                  {app.files.length > 0 && <>
                    <div className={'col-12 py-1'}>

                      {app.files.map((file, index) => {
                        const linkUrl = fileUrl + '/' + app._id.toString() + '/' + index
                        return (
                          <div key={linkUrl} id={app.name.toLowerCase() + '-file-' + index}
                               onClick={getFile(linkUrl, file.base)} className={'d-flex'}>
                            <LinkIcon
                              className={'mb-1 text-truncate w-100'}
                            >
                              <Icon type={Icon.fromFileName(file.base)} style={{
                                fontSize: '18px',
                                verticalAlign: '0.1em',
                              }}
                                    className={'mr-1 mb-1'}/>
                              {file.base}
                            </LinkIcon>
                          </div>
                        )
                      })}

                    </div>
                  </>}
                </Fragment>)}
              </AppSection>)}
            </Fragment>
          )}
        </DetailsOfAdapter>
      </div>
    </div>
  )
}

export default External
