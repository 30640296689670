import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InsertColumn from './column'

const InsertRow = ({
                     countColumn,
                     add,
                     indexRow,
                     closePreviewRow,
                   }) => {
  const [arrayInsertColumn, setArray] = useState(() => countColumn ? new Array(countColumn).fill('') : [])

  useEffect(() => {
    if (countColumn) {
      setArray(prevState => {
        if (countColumn !== prevState.length) {
          return new Array(countColumn).fill('')
        }
        return prevState
      })
    }
  }, [countColumn])

  const hoverFirstItem = useCallback(() => {
    setArray(prevState => {
      if (prevState[0]) {
        if (prevState[0] === 'col-12') {
          return prevState
        }
      }
      return ['col-12']
    })
  }, [])

  const rollbackAllItems = useCallback(() => {
    if (countColumn !== arrayInsertColumn.length) {
      setArray(prevState => {
        if (countColumn !== prevState.length) {
          return new Array(countColumn).fill('')
        }
        return prevState
      })
    }
  }, [countColumn])

  const MemoInsertRow = useMemo(() => (
    <div className={'row no-gutters row-cols-' + countColumn}>
      {arrayInsertColumn.map(
        (arrayInsertColumn, index) =>
          <InsertColumn
            key={index}
            index={index}
            closePreviewRow={closePreviewRow}
            value={arrayInsertColumn}
            add={(oldCard) => add(
              {
                row: indexRow,
                column: index,
                type: 'NEW_ROW',
              }, oldCard)}
            hoverFirstItem={hoverFirstItem}
            rollbackAllItems={rollbackAllItems}
            countColumn={countColumn}/>)}
    </div>
  ), [countColumn, arrayInsertColumn, add, indexRow, closePreviewRow, hoverFirstItem, rollbackAllItems])

  return MemoInsertRow
}

export default InsertRow
