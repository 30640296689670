import React, { useState } from 'react'
import classNames from 'classnames'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as IconArrowLeft } from '../../images/icons/arrow-left.svg'
import styled from 'styled-components'

const LabelSignIn = styled.h1`
  color:${props => props.theme.colorTable.text}
`

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [invalid, setInvalid] = useState(false)
  const [isSend, setIsSend] = useState(false)
  const submit = async e => {
    e.preventDefault()
    if (isEmail(email)) {
      /* let res = */
      await axios.post('/api/users/reset', { email })
      toast.success('You should receive an email with a link to reset your password.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false
      })
      setEmail('')
      setInvalid(false)
      setIsSend(true)
    } else {
      setInvalid(true)
    }
  }

  return (
    <div style={{ paddingTop: '10%' }}>
      <div className={'mx-auto signin text-center d-flex flex-column p-5'}>
        <LabelSignIn className={'mx-0 font-weight-bold'}>Forgot</LabelSignIn>
        <LabelSignIn className={' mb-5 mx-0 font-weight-bold'}>your password?</LabelSignIn>
        <form onSubmit={submit}>
          <input className={classNames('form-control mb-5 rounded-0', { 'is-invalid': invalid })}
                 value={email}
                 onChange={e => setEmail(e.target.value)} id={'password'} type="email"
                 placeholder="Your email" required/>
          {isSend ? <Link to={`/${window.tenancy}/login`}>
            <button className={'btr-dark mt-2 rounded-pill'}><IconArrowLeft style={{
              width: 12,
              marginTop: -2
            }}/> Back
            </button>
          </Link> : <button
            className={'dart-btn h-100 mt-2 rounded-pill'}>Send</button>}
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
