import React, { Fragment, useEffect, useRef, useMemo } from 'react'
import Select from 'react-select/async'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { withTheme } from 'styled-components'
import Label from '../Label'

const getOptionBackground = (theme, state) => {
  if (state.isSelected) {
    return theme.colorTable.listSelection
  }
  if (state.isFocused) {
    return theme.colorTable.backLightHover
  }
  return theme.colorTable.backLight
}

const getOptionColor = (theme, state) => {
  if (state.isSelected) {
    return theme.colorTable.listSelectionText
  }
  return theme.colorTable.backLightText
}

const getControlBorder = (theme, state) => {
  return `1px solid ${state.isFocused
    ? theme.colorTable.hoverBorder // #80bdff
    : theme.colorTable.border      // #ced4da
  }`
}

const styles = (theme) => ({
  option: (provided, state) => ({
    ...provided,
    background: getOptionBackground(theme, state),
    color: getOptionColor(theme, state),
    cursor: 'pointer',
  }),
  control: (provided, state) => {
    return ({
      ...provided,
      background: theme.colorTable.plain,
      border: getControlBorder(theme, state),
      width: 'auto',
      height: 'calc(1.5em + 0.75rem + 2px)',
      color: theme.colorTable.textLight,
      paddingLeft: 14,
      fontSize: '1rem',
      outline: 0,
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 0,
      cursor: 'pointer',
      '&:active': {
        border: getControlBorder(theme, state),
      },
      '&:hover': {
        border: getControlBorder(theme, state),
      },
      '&:after': {
        content: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'12\' height=\'12\' viewBox=\'0 0 12 12\'%3E%3Cg fill=\'%23000000\'%3E%3Cpath d=\'M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z\' fill=\'%23595959\'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")',
        display: 'block',
        position: 'absolute',
        left: 'calc(100% - 0.75rem - 12px);',
      },
    })
  },
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.colorTable.textLight,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.colorTable.textLight,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3
  })
})

const height = 36

const MenuList = (props) => {
  const {
    options,
    children,
    maxHeight,
    getValue,
    focusedOption,
  } = props
  const menuRef = useRef(null)
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  useEffect(() => {
    if (focusedOption) {
      const indexFocusedOption = options.findIndex((option) => option.value === focusedOption.value)
      if (indexFocusedOption > -1) {
        if (menuRef.current) {
          menuRef.current.scrollToItem(indexFocusedOption)
        }
      }
    }
  }, [focusedOption])

  const heightList = useMemo(() => {
    const fullHeight = children.length * height
    if (fullHeight < maxHeight) {
      return fullHeight
    }
    return maxHeight
  }, [children.length, maxHeight])

  return (
    <List
      ref={menuRef}
      height={heightList}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({
          index,
          style,
        }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}

const MenuListLoader = (props) => {
  const {
    options,
    children,
    maxHeight,
    getValue,
    focusedOption,
    loadMoreOptions,
    isOptionsLoaded,
    hasNextOptions,
  } = props
  const menuRef = useRef(null)
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  useEffect(() => {
    if (focusedOption) {
      const indexFocusedOption = options.findIndex((option) => option.value === focusedOption.value)
      if (indexFocusedOption > -1) {
        if (menuRef.current) {
          if (menuRef.current.scrollToItem) {
            menuRef.current.scrollToItem(indexFocusedOption)
          }
        }
      }
    }
  }, [focusedOption])

  const heightList = useMemo(() => {
    const fullHeight = children.length * height
    if (fullHeight < maxHeight) {
      return fullHeight
    }
    return maxHeight
  }, [children.length, maxHeight])

  const Option = ({
                    index,
                    style,
                  }) => {
    if (!isOptionsLoaded(index)) {
      return 'Loading...'
    }

    return (<div style={style}>{children[index]}</div>)
  }
  const itemCount = hasNextOptions ? children.length + 1 : children.length
  return (
    <InfiniteLoader
      itemCount={itemCount}
      isItemLoaded={isOptionsLoaded}
      loadMoreItems={loadMoreOptions}
    >
      {({
          onItemsRendered,
          ref,
        }) => (
        <List
          ref={(list) => {
            ref(list)
            menuRef.current = list
          }}
          height={heightList}
          itemCount={itemCount}
          itemSize={height}
          onItemsRendered={onItemsRendered}
          initialScrollOffset={initialOffset}
        >
          {Option}
        </List>)}
    </InfiniteLoader>
  )
}

const AsyncSelect = ({
                       id,
                       name,
                       label,
                       helpText,
                       value,
                       onChange,
                       isSearchable = true,
                       isDisabled = false,
                       isMulti = false,
                       options = [],
                       loadOptions,
                       loadMoreOptions,
                       isOptionsLoaded,
                       hasNextOptions,
                       theme,
                       isInsideModal,
                       showLabel = true,
                     }) => {
  const loadOptionsDefault = (inputValue, callback) => {
    inputValue = inputValue.toLowerCase()
    callback(options.filter(option => option.label.toLowerCase().includes(inputValue)))
  }
  return (
    <Fragment>
      {showLabel && (<Label name={name} label={label} helpText={helpText} forId={id} className={'d-block'}/>)}
      <Select
        value={options.filter(option =>
          option.value === value)}
        onChange={onChange}
        defaultOptions={options}
        loadOptions={loadOptions || loadOptionsDefault}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        isClearable={true}
        menuPlacement={'auto'}
        isMulti={isMulti}
        styles={styles(theme)}
        placeholder={''}
        menuPortalTarget={isInsideModal ? undefined : document.body}
        components={loadMoreOptions ? ({
          MenuList: (props) => <MenuListLoader isOptionsLoaded={isOptionsLoaded}
                                               hasNextOptions={hasNextOptions}
                                               loadMoreOptions={loadMoreOptions} {...props}/>,
        }) : ({ MenuList })}
      />
    </Fragment>
  )
}

export default withTheme(AsyncSelect)
