import React, { memo, useRef, useMemo, useEffect } from 'react'

import { OBJECT, SELECTION, GLOBAL } from './config'

import ComponentNested from './components/nested'
import ComponentMember from './components/member'

import _ from 'lodash'

import Context from './context'

const MemberRow = memo(({ member, goToSteps }) => {

  if (member.type === OBJECT || member.type === SELECTION || member.type ===
    GLOBAL) {
    return (<ComponentNested
      member={member}
      copyText={member.name}
      goToSteps={member.name === goToSteps[0] ? _.tail(goToSteps) : []}
      showMember={member.name === goToSteps[0] && goToSteps.length === 1}
      MaxLevels={3}
    />)

  } else {
    if (member) {
      return (<ComponentMember
        member={member}
        showMember={member.name === goToSteps[0] && goToSteps.length === 1}
        copyText={member.name}
      />)
    }
    return null
  }
})

function ListMembers ({
  activeType,
  types,
  goToSteps = [],
  clearGoto,
  isVariables,
  isTemplates,
  isFormulas,
  isGlobal,
  isAddin = false,

  pluginSDK,

  evaluateFormula,
  getMembers,
  serialization,
  loadingType,
  useDrag,
  ActionComponent,
}) {

  const ref = useRef(null)

  const members = useMemo(() => {
    return getMembers(activeType,
      isVariables,
      isTemplates,
      isFormulas, isGlobal)
  }, [
    activeType,
    isVariables,
    isTemplates,
    isFormulas, isGlobal])

  useEffect(() => {
    if (goToSteps.length) {
      if (ref.current) {
        const memberIndex = members.findIndex(
          member => member.name === goToSteps[0])
        if (memberIndex) {
          ref.current.scrollToItem(memberIndex, 'center')
        }
      }
    }
  }, [goToSteps, members, ref])

  if (activeType.neededLoading) {
    return 'Loading...'
  }
  return (
    <Context.Provider value={{
      activeType,
      types,
      isVariables,
      isTemplates,
      isFormulas,
      isGlobal,
      isAddin,

      pluginSDK,

      serialization,
      evaluateFormula,
      loadingType,
      useDrag,
      clearGoto,
      ActionComponent,
    }}>
      {members.map(
        member => (<MemberRow member={member} goToSteps={goToSteps}/>))}
    </Context.Provider>
  )
}

export default ListMembers
