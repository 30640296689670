import _ from 'lodash'
import { COLLECTION, OBJECT, QUERY, TABLE } from '../config'

export default types => {
  let sortASC = a => _.orderBy(a, [type => type.name.toLowerCase()])
  if (types) {
    let collections = sortASC(types.filter(({ type }) => type === COLLECTION))
    let objects = sortASC(types.filter(({ type }) => type === OBJECT))
    let tables = sortASC(types.filter(({ type }) => type === TABLE))
    let query = []
    if (window.knacklyAddons) {
      if (window.knacklyAddons.queries) {
        query = sortASC(types.filter(({ type }) => type === QUERY))
      }
    }
    return [...collections, ...objects, ...tables, ...query]
  } else {
    return []
  }
}
