import React, { Fragment } from 'react'
import RowDnD from './dnd/row'

const Row = ({ data, header, handleDelete, nextItem, change, moveRow, onPaste, isQuery,handleKey,QueryRowKey }) => {
  return (
    <Fragment>
      {data.map((d, index) => (
        <RowDnD key={'row-' + index} data={d} id={d.id} index={index} header={header}
                handleDelete={handleDelete}
                handleKey={handleKey}
                nextItem={nextItem}
                isQuery={isQuery}
                QueryRowKey={QueryRowKey}
                onPaste={onPaste}
                change={change} moveRow={moveRow}/>))}
    </Fragment>
  )
}

export default Row
