import React from 'react'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { Ace, Icon } from '../../../components'
import { useRecoilValue } from 'recoil'
import { ErrorExpressionState } from './mappingState'
import EditMemberPropertyComponent from '../EditMemberPropertyComponent'

const Expression = () => {
  const error = useRecoilValue(ErrorExpressionState)

  return (
    <div className={'col-12 form-group'}>
      <label
        className={classNames({ 'text-danger': error })}>EXPRESSION</label>
      {error && (<Tooltip title={error}
                          key={'Tooltip'}
                          placement={'bottom'}
                          overlayClassName={'warning-tooltip'}>
        <Icon type={'icon-exclamation-triangle'} className={'mx-2'} style={{
          color: '#dc3545',
          fontSize: '1.4rem',
          verticalAlign: 0,
        }}/>
      </Tooltip>)}
      <EditMemberPropertyComponent path={'expression'} defaultValue={''}>{({
        value,
        onChange,
      }) => (<Ace height={300}
                  value={value}
                  mode={'kexpr'}
                  className={classNames('form-control rounded-0',
                    { 'border-danger': error })}
                  onChange={onChange}
                  isExpression={true}/>)}
      </EditMemberPropertyComponent>
    </div>)
}

export default Expression
