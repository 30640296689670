import wrapLoadable from '../utils/wrapLoadable'
import _ from 'lodash'

export default [
  {
    path: [
      '/:tenancy/migration/',
      '/:tenancy/migration/:tab',
    ],
    component: wrapLoadable(import('../views/migration')),
    label: 'Migration',
    isNav: () => _.get(window.knacklyAddons, 'migration', false),
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  }, {
    path: [
      '/:tenancy/types/:typename/:tab/:membername',
      '/:tenancy/types/:typename/:tab',
      '/:tenancy/types/:typename',
      '/:tenancy/types/'],
    component: wrapLoadable(import('../views/types')),
    label: 'Designer',
    isNav: true,
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  },
  {
    path: '/:tenancy/collections',
    component: wrapLoadable(import('../views/collections')),
    label: 'Catalog',
    isNav: true,
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  },
  {
    path: '/:tenancy/groups',
    component: wrapLoadable(import('../views/groups')),
    label: 'Groups',
    isNav: false,
    isAdmin: true,
    isMenu: true,
    isExternal: false,
  },
  {
    path: [
      '/:tenancy/:adapter/:application',
      '/:tenancy/:adapter/:record_id/:application'],
    component: wrapLoadable(import('../views/knack')),
    label: 'AppContainer',
    isNav: false,
    isAdmin: false,
    isMenu: false,
    isExternal: true,
  },
  {
    path: '/:tenancy/settings',
    component: wrapLoadable(import('../views/settings/settings')),
    label: 'Settings',
    isNav: false,
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  }, {
    path: '/:tenancy/keys',
    component: wrapLoadable(import('../views/keys')),
    label: 'Keys',
    isNav: false,
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  }, {
    path: '/:tenancy/external/',
    component: wrapLoadable(import('../views/external')),
    label: 'External',
    isNav: false,
    isAdmin: false,
    isMenu: false,
    isExternal: true,
  },
  {
    path: '/:tenancy/config-clio',
    component: wrapLoadable(import('../views/clio')),
    label: 'Clio',
    isNav: false,
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  }, {
    path: '/:tenancy/config-filevine',
    component: wrapLoadable(import('../views/filevine')),
    label: 'Filevine',
    isNav: false,
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  }, {
    path: '/:tenancy/config-netdocuments',
    component: wrapLoadable(import('../views/netdocuments')),
    label: 'NetDocuments',
    isNav: false,
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  }, {
    path: '/:tenancy/config-onedrive',
    component: wrapLoadable(import('../views/onedrive')),
    label: 'OneDrive',
    isNav: false,
    isAdmin: true,
    isMenu: false,
    isExternal: false,
  },
  {
    path: '/:tenancy/:adapter',
    component: wrapLoadable(import('../views/adapter')),
    label: 'Adapter',
    isNav: false,
    isAdmin: false,
    isMenu: false,
    isExternal: false,
  },
]
