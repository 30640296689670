import React from 'react'

const Loading = ({
                   error,
                   retry,
                 }) => {
  if (error) {
    return (<div>Error: {error}
      <button onClick={retry}>Retry</button>
    </div>)
  }
  return (<div>Loading...</div>)
}

export default Loading
