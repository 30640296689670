function objectId () {
  return hex(Date.now() / 1000) +
    ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16))
}

const OIDRE = new RegExp('^[0-9a-fA-F]{23,24}$')

objectId.isValid = (str) => OIDRE.test(str)

function hex (value) {
  return Math.floor(value).toString(16)
}

exports.objectId = objectId
