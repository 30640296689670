import React, { Fragment, useState, useMemo } from 'react'
import { Modal } from 'antd'
import { AddButton, Input, Select } from '../../../../components'
import { TEMPLATETYPES } from '../../../../views/types/Templates/types'
import { validateTypeMember } from '../../../../utils'
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback, useRecoilState,
  useRecoilValue, useSetRecoilState,
} from 'recoil'

import produce from 'immer'
import _ from 'lodash'
import {
  CurrentMembersByKeySelector,
  CurrentTypeSelector,
  ElementsChangesAtom,
} from '../../state'
import { useHistory } from 'react-router-dom'
import { ObjectID } from 'bson'

const DEFAULT_NEW_TEMPLATE_STATE = {
  name: '',
  nameTouched: false,
  showErrors: false,
  type: TEMPLATETYPES[0].value,
}

const NewTemplateState = atom({
  key: 'NewTemplateState',
  default: DEFAULT_NEW_TEMPLATE_STATE,
})

const EditorNewTemplate = selectorFamily({
  key: 'EditorNewTemplate',
  get: path => ({ get }) => {
    return _.get(get(NewTemplateState), path)
  },
  set: path => ({
                  set,
                  get,
                }, newValue) => {
    set(NewTemplateState, produce(draft => {
      _.set(draft, path, newValue)
      if (path === 'name') {
        _.set(draft, 'showErrors', true)
      }
    }))
  },
})

const NewTemplateErrors = selector({
  key: 'NewTemplateErrors',
  get: ({ get }) => {
    const {
      type,
      name,
    } = get(NewTemplateState)

    const variables = get(CurrentMembersByKeySelector('properties'))
    const templates = get(CurrentMembersByKeySelector('templates'))
    const formulas = get(CurrentMembersByKeySelector('formulas'))

    return validateTypeMember('template',
      type,
      null,
      name,
      variables,
      templates,
      formulas)
  },
})

const ErrorAlert = () => {
  const errors = useRecoilValue(NewTemplateErrors)
  const nameTouched = useRecoilValue(EditorNewTemplate('nameTouched'))
  const showErrors = useRecoilValue(EditorNewTemplate('showErrors'))
  if (errors.name && showErrors && (nameTouched || errors.nameForce)) {
    return (<p className={'text-danger'}>
      {errors.name}
    </p>)
  }
  return null
}

const NameProperty = ({ visible }) => {
  const [name, setName] = useRecoilState(EditorNewTemplate('name'))
  const setNameTouched = useSetRecoilState(EditorNewTemplate('nameTouched'))
  return (<Input type={'text'} value={name}
                 onChange={setName}
                 label={'TEMPLATE NAME'}
                 id={'modalNewTypeName'}
                 onBlur={() => {if (visible) setNameTouched(true)}} />)
}

const TypeProperty = () => {
  const [type, setType] = useRecoilState(EditorNewTemplate('type'))
  return (<Select label={'Type'} id={'newType'} value={type}
                  values={TEMPLATETYPES} onChange={setType} />)
}

const NewTemplate = () => {
  let [visible, setVisible] = useState(false)
  const history = useHistory()

  const onOpen = () => setVisible(true)

  const onSubmit = useRecoilCallback(({
                                        snapshot,
                                        set,
                                      }) => async () => {
    const template = await snapshot.getPromise(NewTemplateState)
    const errors = await snapshot.getPromise(NewTemplateErrors)
    const type = await snapshot.getPromise(CurrentTypeSelector)
    if (!errors.name && !errors.type) {
      set(ElementsChangesAtom, produce(draft => {
        draft.push({
          _id: new ObjectID().toString(),
          action: 'add',
          kind: 'template',
          parent: type._id,
          parentKind: 'model',
          name: template.name,
          type: template.type,
        })
      }))

      history.push(
        `/${window.tenancy}/types/${type.name}/templates/${template.name}`)

      onCancel()
    } else {
      set(EditorNewTemplate('showErrors'), true)
    }
  }, [])

  const onCancel = useRecoilCallback(({ set }) => () => {
    setVisible(false)
    set(NewTemplateState, DEFAULT_NEW_TEMPLATE_STATE)
  }, [])

  const MemoAddButton = useMemo(() => (
      <AddButton id={'addTemplate'} label={'Add a new template'}
                 onClick={onOpen} />),
    [])

  return (
    <Fragment>
      <Modal
        title="New template"
        visible={visible}
        onCancel={onSubmit}
        closable={false}
        maskClosable={false}
        width={410}
        footer={[
          <button key="submit" className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }} onClick={onSubmit}>
            Ok
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  style={{ width: 125 }}
                  onClick={onCancel}>Cancel</button>,
        ]}
      >
        <form onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}>
          <div className={'row'}>
            <div className={'col-6'}>
              <NameProperty />
            </div>
            <div className={'col-6'}>
              <TypeProperty />
            </div>
            <div className={'col-12'}>
              <ErrorAlert />
            </div>
          </div>
        </form>
      </Modal>
      {MemoAddButton}
    </Fragment>
  )
}

export default NewTemplate
