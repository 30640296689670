import React, { Fragment, useState, memo } from 'react'
import { Modal } from 'antd'
import { AddButton, Input, RadioInputs } from '../../../components'
import { validateElementName } from '../../../utils'
import { useRecoilState } from 'recoil'
import { TypesSelector } from '../state'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import sortTypes from '../../../utils/sortTypes'

const MemoAddButton = memo(
  ({ onOpen }) => (<AddButton id={'addTypes'} label={'Add a new element'}
                              onClick={onOpen}/>))

const AddTypeModal = () => {

  const [name, setName] = useState('')
  const [active, setActive] = useState(false)
  const [type, setType] = useState('object')
  const [nameTouched, setNameTouched] = useState(false)
  const [types, setTypes] = useRecoilState(TypesSelector)
  const history = useHistory()

  const kind = (type === 'table' || type === 'query') ? type : 'model'

  const onCancel = () => setActive(false)
  const submit = async () => {
    try {
      if (!errors.name && !errors.type) {
        let draft

        if (type === 'table') {
          draft = {
            action: 'add',
            name,
            type,
            properties: [{ type: 'text', name: 'Name', label: 'Name' }],
            data: [{ Name: '' }],
            key: 'Name',
          }
        }

        if (type === 'query') {
          draft = {
            action: 'add',
            name,
            type,
            extModelName: '',
            key: '',
          }
        }

        if (!draft) {
          draft = {
            action: 'add',
            name,
            type,
          }
        }

        const { data } = await axios.post('/api/v2/types', [
          draft])

        if (data) {
          setTypes(sortTypes([...types, ...data]))
          if (data[0]) {
            history.push(`/${window.tenancy}/types/${data[0].name}`)
          }
        }
        onCancel()
        reset()
      }
    } catch (e) {

    }
  }

  const cancel = () => {
    onCancel()
    reset()
  }

  const reset = () => {
    setName('')
    setType('object')
    setNameTouched(false)
  }

  const errors = validateElementName(kind, name, types)

  let typesValues = [
    {
      value: 'object',
      label: 'Model',
    }, {
      value: 'table',
      label: 'Table',
    }]
  if (window.knacklyAddons) {
    if (window.knacklyAddons.queries) {
      typesValues = [
        ...typesValues, {
          value: 'query',
          label: 'Query',
        }]
    }
  }
  return (
    <Fragment>
      <Modal
        title="New Element"
        visible={active}
        onCancel={cancel}
        maskClosable={false}
        closable={false}
        width={600}
        footer={[
          <button key="submit" className={'btn btn-secondary dart-btn'}
                  onClick={submit}>
            Ok
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  onClick={cancel}>Cancel</button>,
        ]}
      >
        <form onSubmit={e => {
          e.preventDefault()
          submit()
        }}>
          <div className={'row'}>
            <div className={'col-8 mt-3'}>
              <Input type={'text'} value={name} onChange={setName}
                     label={'Name'}
                     id={'modalNewTypeName'}
                     onBlur={() => {if (active) setNameTouched(true)}}/>
              {errors.name && (nameTouched || errors.nameForce) &&
                <p className={'text-danger'}>
                  {errors.name}
                </p>}
            </div>
            <div className={'col-4 pt-2'}><RadioInputs values={typesValues}
                                                       active={type}
                                                       handelChange={setType}/>
              {errors.type && <p className={'text-danger'}>
                {errors.type}
              </p>}
            </div>
          </div>
        </form>
      </Modal>
      <MemoAddButton onOpen={() => setActive(true)}/>
    </Fragment>
  )
}

export default AddTypeModal
