import React, { Fragment } from 'react'
import { matchPath } from 'react-router-dom'
import { ObjectID } from 'bson'
import Cart from './cart'
import Icon from '../Icon'
import { InferLabelFromAppName } from '../../utils'

const NavRow = (<div
  className={'d-inline font-weight-light text-uppercase'}
>
  <span className={'py-3'}>
  <Icon type={'icon-return'} style={{
    'transform': 'rotate(180deg)',
    'vertical-align': 1,
  }}/>
    </span>
</div>)

const ActiveApp = ({ external, collections, onNextRoot }) => {
  const searchParam = new URLSearchParams(window.location.search)
  const match = matchPath(window.location.pathname, {
    path: [
      '/:tenancy/:collection/:record_id/:application',
      '/:tenancy/:collection/:application'],
  })

  const migrationMatch = matchPath(window.location.pathname, {
    path: [
      '/:tenancy/migration/import_components',
      '/:tenancy/migration/import_templates',
      '/:tenancy/migration/map_fields',
      '/:tenancy/migration/import_models',
      '/:tenancy/migration/convert_templates'],
  })

  if (migrationMatch || !match) {
    return null
  }

  const collectionNameLower = match.params.collection.toLowerCase()
  const applicationNameLower = decodeURIComponent(match.params.application).toLowerCase()
  const recordId = (match.params.record_id || '')

  const collection = collections.find(
    collection => collection.name.toLowerCase() === collectionNameLower)

  if (!collection) {
    return null
  }

  if (recordId) {
    if (!ObjectID.isValid(recordId)) {
      const appLabel = searchParam.get('appLabel') || ''
      return (
        <Fragment>
          <Cart collection={collection} onNextRoot={onNextRoot}/>
          {NavRow}
          <Cart collection={{ name: recordId }} noLink={true} onNextRoot={() => null}/>
          {NavRow}
          <Cart collection={{
                  name: match.params.application,
                  label: appLabel || InferLabelFromAppName(match.params.application),
                }}
                emphasis={true}
                noLink={true}
                onNextRoot={() => null}/>
        </Fragment>
      )
    }
  }

  const application = collection.type.apps.find(
    app => app.name.toLowerCase() === applicationNameLower)

  if (!application) {
    return null
  }

  if (external) {
    return <Cart collection={application} emphasis={true} noLink={true} onNextRoot={() => null}/>
  }

  return (<Fragment>
    <Cart collection={collection} onNextRoot={onNextRoot}/>
    {NavRow}
    <Cart collection={application} emphasis={true} noLink={true} onNextRoot={() => null}/>
  </Fragment>)
}

export default ActiveApp
