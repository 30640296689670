import React, { Component, Fragment, memo } from 'react'
import Icon from '../Icon'
import { matchPath, withRouter } from 'react-router-dom'
import routes from '../../routes'
import { requestCollections } from '../../action/collections'
import { connect } from 'react-redux'
import knacklyLogo from './knackly.svg'
import {
  ReactComponent as IconHelps,
} from '../../images/icons/question-in-navigation.svg'
import { Dropdown, Tooltip } from 'antd'
import ConfirmModal from '../../modals/confirm'
import styled from 'styled-components'
import classNames from 'classnames'
import Color from 'color'
import queryString from 'query-string'
import AboutModal from './about'
import Collections from './collections'
import { URI } from '../../config'
import ActiveApp from './activeApp'

const MenuLink = styled.a`
    background-color: ${props => props.theme.colorTable.menu} !important;
    color: ${props => props.theme.colorTable.menuText} !important;
    border-color: ${props => props.theme.colorTable.border} !important;
    cursor: pointer !important;

    &:hover {
        background-color: ${props => props.theme.colorTable.menuHover} !important;
        color: ${props => props.theme.colorTable.menuText} !important;
    }
`
const KnacklyLogo = memo(styled.img`
    filter: ${props => getKnacklyLogoFilters(props.theme.colorTable.menu,
            props.theme.colorTable.menuText)};
`)
const MemoHelp = memo(
  () => {
    let helpLink = 'https://knackly.freshdesk.com'
    if (window.location.hostname === 'localhost'
      || window.location.hostname === 'www.actionstep-builder.com'
      || window.location.hostname === 'builder.actionstep.com') {
      helpLink = 'https://actionstep.freshdesk.com/support/solutions/folders/150000540764'
    }
    return (<Tooltip placement="bottom" title={<span>Help</span>}><a
      href={helpLink}
      target="_blank"
      rel="noopener noreferrer"
      className="p-3"
    ><IconHelps style={{
      width: '1rem', verticalAlign: -3,
    }}/></a></Tooltip>)
  })

function getKnacklyLogoFilters (background, text) {
  const menuColor = Color(background)
  const logoColor = Color('#f86e21')
  const contrast = menuColor.contrast(logoColor)
  if (contrast > 6.5 || (menuColor.saturationv() < 7 && contrast > 2)) {
    // logo is probably okay as is
    return 'brightness(1)'
  } // else
  const newLightness = Color(text).lightness().toFixed(0)
  return `brightness(0%) invert(${newLightness}%)`
}

class NavigationBar extends Component {
  state = {
    isConfirmModal: false,
    visibleAbout: false,
  }

  componentDidMount () {
    const { request } = this.props
    request()
  }

  nextRoot = ''

  onOpenConfirm = () => this.setState({ isConfirmModal: true })

  onCancelConfirm = () => this.setState({ isConfirmModal: false })

  onConfirmLeave = () => {
    document.getElementById('cancelType').click()
    this.setState({ isConfirmModal: false })
    this.props.history.push(this.nextRoot)
  }

  onConfirmOk = () => {
    document.getElementById('saveType').click()
    this.setState({ isConfirmModal: false })
    this.props.history.push(this.nextRoot)
  }

  onNextRoot = (next) => {
    if (window.needSaveProfileTemplate) {
      return window.confirmProfile(next)
    } else {
      if (window.typesWereChanged) {
        this.nextRoot = next
        this.onOpenConfirm()
      } else {
        this.props.history.push(next)
      }
    }
  }

  appInf = () => {
    const match = matchPath(window.location.pathname, {
      path: [
        '/:tenancy/:adapter/:record_id/:application',
        '/:tenancy/:adapter/:application',
      ],
    })
    if (match && match.params) {
      const result = match.params
      if (result.adapter === 'types') {
        return null
      }
      const qsParams = queryString.parse(window.location.search)
      if (qsParams && ('hn' in qsParams)) {
        const hn = typeof qsParams.hn === 'string'
          ? qsParams.hn.toLowerCase()
          : ''
        if (hn === '' || hn === 'true' || hn === 'yes' || hn === '1') {
          result.hideNav = true
        }
      } else if (result.record_id && result.application) {
        const appSessKey = `${result.record_id}/${result.application}/`
        result.hideNav = (localStorage.getItem(appSessKey + 'hideNav') ===
          'true')
      }
      return result
    }
  }

  render () {
    const {
      className, collections, user, settings,
    } = this.props

    const isApp = this.appInf()
    if (isApp && isApp.hideNav) {
      return null
    }

    const ROUTES = routes.map(route => {
      if (Array.isArray(route.path)) {
        if (route.label === 'Migration') {
          return ({
            ...route, path: `/${window.tenancy}/migration/`,
          })
        }
        return ({
          ...route, path: `/${window.tenancy}/types`,
        })
      }
      return ({
        ...route, path: route.path.replace(/:tenancy/, window.tenancy),
      })
    })
    const filterByPermissions = (collection) => {
      if (user.permissions) {
        return user.permissions.includes(collection.name)
      } else {
        return false
      }
    }
    const signOut = () => {
      window.signOut()
    }

    const getFile = () => {
      const blob = new Blob([window.rightLogo], { type: 'image/svg+xml' })
      return URL.createObjectURL(blob)
    }
    const caption = settings.label ? settings.label : window.tenancy
    return (<Fragment>
      <div
        className={classNames('navigation-bar container-fluid', className)}>
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="d-flex align-items-center">{/* left-hand grouping on menu bar: logo & collections */}
            <div className={'d-inline mr-3 my-1 font-weight-medium'}>
              {settings.logo
                ? <img src={`${URI}/${window.tenancy}/` + settings.logo}
                       style={{
                         maxWidth: 200, maxHeight: 36,
                       }}
                       className="" alt={caption} title={caption}/>
                : caption.toUpperCase()}
            </div>
            <div className={'my-3'}>
              {isApp && <ActiveApp external={user.isExternal}
                                   collections={collections}
                                   onNextRoot={this.onNextRoot}
              />}
              {!user.isExternal && !isApp && (
                <Collections items={collections.filter(filterByPermissions)}
                             onNextRoot={this.onNextRoot}/>)}
            </div>
          </div>
          <div>{/* right-hand grouping on menu bar: Types menu, help, user, & knackly logo */}
            {ROUTES.filter(route => {
              if (typeof route.isNav === 'function') {
                return route.isNav()
              }
              return route.isNav
            }).
              filter(route => route.isAdmin === user.isAdmin).
              map(route => {
                return (<div key={'route' + route.label}
                             className={'d-inline font-weight-light text-uppercase'}>
                  <a
                    className={classNames('p-3', {
                      activeMenuTop: window.location.pathname.startsWith(
                        route.path),
                    })}
                    href={route.path}
                    onClick={(event) => {
                      event.preventDefault()
                      this.onNextRoot(route.path)
                    }}>{route.label}</a>
                </div>)
              })}
            {user.isAdmin && <MemoHelp/>}
            <div className={'d-inline mr-1 font-weight-light text-uppercase'}>
              <Dropdown
                overlay={<ul className="list-group" style={{ minWidth: 200 }}>
                  <MenuLink className="list-group-item">
                    <p className={'font-weight-bold my-1'}
                       style={{ cursor: 'default' }}>{user.name}</p>
                    <p className={'my-1'}
                       style={{ cursor: 'default' }}>{user.mail}</p>
                  </MenuLink>
                  {ROUTES.filter(route => route.isMenu).
                    filter(route => route.isAdmin === user.isAdmin).
                    map(route => {
                      return (<MenuLink
                        key={'menu' + route.label}
                        className={classNames('list-group-item', {
                          activeMenuTop: window.location.pathname ===
                            route.path,
                        })}
                        href={route.path}
                        onClick={(event) => {
                          event.preventDefault()
                          this.onNextRoot(route.path)
                        }}>
                        {route.label}</MenuLink>)
                    })}

                  {user.isAdmin && (<>
                    <MenuLink
                      className={classNames('list-group-item', {
                        activeMenuTop: window.location.pathname ===
                          `/${window.tenancy}/users`,
                      })}
                      href={`/${window.tenancy}/users`}
                      onClick={(event) => {
                        event.preventDefault()
                        this.onNextRoot(`/${window.tenancy}/users`)
                      }}>
                      {'Users'}
                    </MenuLink>
                    <MenuLink
                      className={classNames('list-group-item', {
                        activeMenuTop: window.location.pathname ===
                          `/${window.tenancy}/keys`,
                      })}
                      href={`/${window.tenancy}/keys`}
                      onClick={(event) => {
                        event.preventDefault()
                        this.onNextRoot(`/${window.tenancy}/keys`)
                      }}>
                      {'API keys'}
                    </MenuLink>
                    <MenuLink
                      className={classNames('list-group-item', {
                        activeMenuTop: window.location.pathname ===
                          `/${window.tenancy}/settings`,
                      })}
                      style={{ cursor: 'default' }}
                      href={`/${window.tenancy}/settings`}
                      onClick={(event) => {
                        event.preventDefault()
                        this.onNextRoot(`/${window.tenancy}/settings`)
                      }}>
                      {'Settings'}
                    </MenuLink>
                    {window.knacklyAddons.clio && (<MenuLink
                      className={classNames('list-group-item', {
                        activeMenuTop: window.location.pathname ===
                          `/${window.tenancy}/config-clio`,
                      })}
                      style={{ cursor: 'default' }}
                      href={`/${window.tenancy}/config-clio`}
                      onClick={(event) => {
                        event.preventDefault()
                        this.onNextRoot(`/${window.tenancy}/config-clio`)
                      }}>
                      {'Clio'}
                    </MenuLink>)}
                    {window.knacklyAddons.filevine && (<MenuLink
                      className={classNames('list-group-item', {
                        activeMenuTop: window.location.pathname ===
                          `/${window.tenancy}/config-filevine`,
                      })}
                      style={{ cursor: 'default' }}
                      href={`/${window.tenancy}/config-filevine`}
                      onClick={(event) => {
                        event.preventDefault()
                        this.onNextRoot(
                          `/${window.tenancy}/config-filevine`)
                      }}>
                      {'Filevine'}
                    </MenuLink>)}
                    {window.knacklyAddons.netdocuments && (<MenuLink
                      className={classNames('list-group-item', {
                        activeMenuTop: window.location.pathname ===
                          `/${window.tenancy}/config-netdocuments`,
                      })}
                      style={{ cursor: 'default' }}
                      href={`/${window.tenancy}/config-netdocuments`}
                      onClick={(event) => {
                        event.preventDefault()
                        this.onNextRoot(
                          `/${window.tenancy}/config-netdocuments`)
                      }}>
                      {'NetDocuments'}
                    </MenuLink>)}
                    {window.knacklyAddons.onedrive && (<MenuLink
                      className={classNames('list-group-item', {
                        activeMenuTop: window.location.pathname ===
                          `/${window.tenancy}/config-onedrive`,
                      })}
                      style={{ cursor: 'default' }}
                      href={`/${window.tenancy}/config-netdocuments`}
                      onClick={(event) => {
                        event.preventDefault()
                        this.onNextRoot(
                          `/${window.tenancy}/config-onedrive`)
                      }}>
                      {'OneDrive'}
                    </MenuLink>)}
                  </>)}
                  <MenuLink className="list-group-item list-group-item-action"
                            style={{ cursor: 'default' }}
                            onClick={() => {
                              this.setState({ visibleAbout: true })
                            }}>{window.knacklyAddons.whiteLabel
                    ? 'About Builder'
                    : 'About Knackly'}
                  </MenuLink>
                  <MenuLink className="list-group-item list-group-item-action"
                            style={{ cursor: 'default' }}
                            onClick={() => {
                              signOut()
                            }}>{'Log Out'}
                  </MenuLink>
                </ul>}>
                <Icon id="usermenu"
                      className="p-3"
                      style={{
                        fontSize: '18px', verticalAlign: '1px',
                      }}
                      type="icon-user"/>
              </Dropdown>
            </div>
            {(!window.knacklyAddons || !window.rightLogo ||
              (!window.knacklyAddons.whiteLabel && !window.rightLogo.length)
            ) && (
              <KnacklyLogo src={knacklyLogo} alt="Knackly"
                           title="Powered by Knackly" height="22"/>)}
            {(window.rightLogo && !!window.rightLogo.length) &&
              (<img src={getFile()}
                    height="22"/>)}
          </div>
        </div>
      </div>
      <ConfirmModal active={this.state.isConfirmModal}
                    cancel={this.onCancelConfirm}
                    leave={this.onConfirmLeave}
                    ok={this.onConfirmOk}/>
      <AboutModal visible={this.state.visibleAbout}
                  onInvisible={() => this.setState({ visibleAbout: false })}
      />
    </Fragment>)
  }
}

const mapStateToProps = state => {
  const {
    collections, user, settings,
  } = state
  return {
    collections, user, settings,
  }
}

const mapDispatchToProps = dispatch => ({
  request: () => {
    dispatch(requestCollections())
  },
})

// eslint-disable-next-line no-class-assign
NavigationBar = styled(NavigationBar)`
    background-color: ${props => props.theme.colorTable.menu};
    color: ${props => props.theme.colorTable.menuText}
`

NavigationBar.defaultProps = {
  theme: {
    colorTable: {
      menu: 'white', menuText: 'rgba(0, 0, 0, 0.5)',
    },
  },
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBar))
