import React, { useState } from 'react'
import classNames from 'classnames'

import { Input, Select } from '../../components'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import { Modal } from 'antd'

const ModalUser = ({
                     active,
                     onCancel,
                     onSubmit,
                     listEmail,
                   }) => {
  let [name, setName] = useState('')
  let [mail, setMail] = useState('')
  let [type, setType] = useState(
    window.knacklyAddons.ms_auth_int ? 'microsoft' : 'default')
  let [errorMail, setErrorMail] = useState('')
  let [errorName, setErrorName] = useState('')
  let [role, setRole] = useState('User')
  let submit = () => {
    let checkEmail = isEmail(mail)
    let checkName = type === 'microsoft' ? false : isEmpty(name)
    let checkListEmail = true
    let isAdmin = role === 'Admin'
    if (checkEmail) {
      checkListEmail = listEmail.includes(mail)
    }

    if (!checkName && checkEmail && !checkListEmail) {

      onSubmit({
        name,
        mail,
        isAdmin,
        pass: '',
        type,
      })
      setName('')
      setMail('')
      setErrorMail('')
    }
    if (!checkEmail) {
      setErrorMail('From is not a valid email')
    }
    if (checkListEmail) {
      setErrorMail('That email is taken. Try another.')
    }
    if (checkName) {
      setErrorName('That name is empty')
    }
  }
  return (
    <Modal
      title="New User"
      visible={active}
      onCancel={onCancel}
      closable={false}
      width={400}
      footer={[
        <button key="submit" className={'btn btn-secondary dart-btn '}
                style={{ width: 125 }} onClick={submit}>
          Ok
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                style={{ width: 125 }}
                onClick={onCancel}>Cancel</button>,
      ]}
    >
      <form onSubmit={e => {
        e.preventDefault()
        submit()
      }}>
        <div className={'row'}>
          {!window.knacklyAddons.ms_auth_int && (<div className={'col-12'}>
            <div className="d-flex">
              <button className={classNames('btn w-100 mb-3 mr-1',
                { 'dart-btn': type === 'default' },
                { 'dart-btn-outline': type !== 'default' })}
                 onClick={() => setType('default')}>
                DEFAULT
              </button>
              <button className={classNames(
                'btn btn-outline-secondary w-100 mb-3 ml-1',
                { 'dart-btn': type === 'microsoft' },
                { 'dart-btn-outline': type !== 'microsoft' })}
                 onClick={() => setType('microsoft')}>MICROSOFT
              </button>
            </div>
          </div>)}
          {type === 'default' && (
            <div className={'col-12'}>
              <Input type={'text'} value={name} onChange={setName}
                     error={errorName} label={'Name'}
                     id={'modalNewUserName'}/>
            </div>
          )}
          <div className={'col-12'}>
            <Input type={'email'}
                   error={errorMail}
                   value={mail}
                   onChange={val => setMail(val.replace(/\s+/g, ''))}
                   label={'Email'}
                   id={'modalNewUserEmail'}/>
          </div>
          <div className={'col-12'}>
            <Select label={'Assign role'} value={role} onChange={setRole}
                    values={['User', 'Admin']}/>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ModalUser
