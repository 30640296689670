import React from 'react'
import classNames from 'classnames'
import { Ace } from '../../../components'
import { useRecoilState, useRecoilValue } from 'recoil'
import { editMemberProperty } from '../state'
import { ErrorExpressionState } from './mappingState'

const MappingExpression = () => {
  const [expression, setExpression] = useRecoilState(
    editMemberProperty('expression'))
  const error = useRecoilValue(ErrorExpressionState)

  return (
    <Ace height={300}
         value={expression}
         mode={'kexpr'}
         className={classNames('form-control rounded-0',
           { 'border-danger': error })}
         onChange={setExpression}
         isExpression={true}
    />)
}

export default MappingExpression
