import React from 'react'
import styled from 'styled-components'
import { Icon } from '../../components'
import getFile from './getFile'
import { getAttachmentName } from '../../utils/attachmentName'

const AppSection = styled.div`
  background: ${(props) => props.theme.colorTable.backLight || '#9B9B9B'};
  color: ${(props) => props.theme.colorTable.backLightText || '#ffffff'}`

const LinkIcon = styled.a`
  color: ${(props) => props.theme.colorTable.backLightText || '#ffffff'}!important
`
const Attachments = ({ attachments, link, itemName }) => {
  return (
    <AppSection className="row my-2 py-2">
      <div className="col-5 py-1">
        <span className="font-weight-bold">Attachments</span>
      </div>
      <div className="col-3 py-1">{/* May 19, 2020 6:21 PM */}</div>
      <div className="col-2 py-1"/>
      <div className="col-2 text-center">
        {attachments.length > 1 &&
        (<button className={'btn btn-secondary btn-block p-1 btn-theme'}
                 onClick={getFile(link + '/attachments/all', itemName + ' - Attachments.zip')}>
          <Icon type="icon-download1" style={{ fontSize: '1rem', verticalAlign: '0.15em' }
          }/> Download All
        </button>)}
        {attachments.length === 1 &&
        (<button className={'btn btn-secondary btn-block p-1 btn-theme'}
                 onClick={getFile(link + '/attachments/' + attachments[0], getAttachmentName(attachments[0]))}>
          <Icon type="icon-download1" style={{
            fontSize: '1rem',
            verticalAlign: '0.15em',
          }}/> Download
        </button>)}
      </div>

      {attachments.length > 0 && <>
        <div className={'col-10 py-1'}>
          {attachments.map((file, index) => {
            return (
              <div key={'linkUrl'} id={file + index}
                   onClick={
                     getFile(link + '/attachments/' + file, getAttachmentName(file))
                   } className={'d-flex'}>
                <LinkIcon
                  className={'mb-1 text-truncate w-100'}
                >
                  <Icon type={Icon.fromFileName(file)} style={{
                    fontSize: '18px',
                    verticalAlign: '0.1em',
                  }}
                        className={'mr-1 mb-1'}/>
                  {getAttachmentName(file)}
                </LinkIcon>
              </div>
            )
          })}
        </div>
        <div className="col-2 py-1"/>
      </>}
    </AppSection>
  )
}

export default Attachments
