import React from 'react'
import EditMemberPropertyComponent from './EditMemberPropertyComponent'
import { Ace } from '../../components'

const Notes = () => {
  return (
    <div className={'col-12'}>
      <label>NOTES</label>
      <div
        className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>
        <EditMemberPropertyComponent path={'metadata.notes'}
                                     defaultValue={''}>
          {({
            value,
            onChange,
          }) => (<Ace
            name={'notes'}
            placeholder={''}
            className={'border-0'}
            value={value || ''}
            onChange={onChange}
            maxLines={Infinity}/>)}
        </EditMemberPropertyComponent>
      </div>
    </div>
  )
}

export default Notes
