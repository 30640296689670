import React, { Fragment } from 'react'
import Input from '../../../components/Input'
import { InferLabelFromAppName } from '../../../utils'
import ActionsList from '../../../components/ActionsList'
import { Ace, Select } from '../../../components'
import BuildLinkExpr from '../../../utils/BuildLinkExpr'
import RequiredRow from '../Variables/types/parameters/RequiredRow'
import ModalParameters from '../Variables/types/parameters/ModalParameters'
import styled from 'styled-components'
import useQuerySelect from '../Variables/types/parameters/useQuerySelect'
import { shallowEqual, useSelector } from 'react-redux'
import EditMemberPropertyComponent from '../EditMemberPropertyComponent'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  CurrentTypeSelector,
  editMemberProperty,
  globalListsCurrentType,
  TypesSelector,
} from '../state'
import Notes from '../notes'

const TemplateWrapper = styled.div`
    height: auto !important;
`

const typeLabel = (param) => {
  return param.type + (param.isList ? ' list' : '')
}

const QueryComponent = () => {

  const globalList = useRecoilValue(globalListsCurrentType)

  const [linkedQuery, setLinkedQuery] = useRecoilState(
    editMemberProperty('linkedQuery'))

  const [selectFrom, setSelectFrom] = useRecoilState(
    editMemberProperty('selectFrom'))

  const [selectOnce, setSelectOnce] = useRecoilState(
    editMemberProperty('selectOnce'))

  const [autoSelect, setAutoSelect] = useRecoilState(
    editMemberProperty('autoSelect'))

  const [autoLink, setAutoLink] = useRecoilState(
    editMemberProperty('autoLink'))

  const id = useRecoilValue(editMemberProperty('_id'))
  const [isBidirectional, setBidirectional] = useRecoilState(
    editMemberProperty('isLink'))
  const types = useRecoilValue(TypesSelector)
  const { name: typeName } = useRecoilValue(CurrentTypeSelector)
  const LinkedQuery = useQuerySelect(id, linkedQuery, isBidirectional,
    undefined,
    typeName, types, setLinkedQuery)
  const SelectFromQuery = useQuerySelect(id, selectFrom, false, undefined,
    typeName, types, setSelectFrom)

  if (SelectFromQuery.queryVisible || LinkedQuery.queryVisible ||
    globalList.length) {
    let selectFromValues = ['']
    if (SelectFromQuery.availableOptions.length) {
      selectFromValues = SelectFromQuery.availableOptions
    }
    if (globalList.length) {
      selectFromValues = [...new Set(selectFromValues.concat(globalList))]
    }

    const showSelectFrom = typeof SelectFromQuery.selectedQuery === 'string'
    const showLinkedQuery = typeof LinkedQuery.selectedQuery === 'string'

    return (<div className="row">
        <div className={'col-4'}>
          <div className={'form-group mb-2'}>
            <div className="form-check">
              <input className="form-check-input cursorPointer"
                     type="checkbox"
                     name="selectFrom"
                     id="useSelectFrom"
                     value=""
                     checked={showSelectFrom}
                     onChange={() => {
                       setSelectFrom(showSelectFrom ? null : '')
                     }}/>
              <label htmlFor={'useSelectFrom'}
                     className={'cursorPointer'}>{'SELECT data FROM...'}</label>
            </div>
            {showSelectFrom && (
              <Select id={'selectFrom'}
                      value={SelectFromQuery.selectedQuery}
                      values={selectFromValues}
                      className={'mb-3 ml-3'}
                      errElement={SelectFromQuery.queryInvalid &&
                        !globalList.includes(SelectFromQuery.selectedQuery) &&
                        <span
                          className={'text-danger'}>(ERROR - change!)</span>}
                      onChange={val => {
                        if (globalList.includes(
                          val)) {
                          setSelectFrom(val)
                        } else {
                          setSelectFrom(
                            BuildLinkExpr(val,
                              SelectFromQuery.requiredParams,
                              SelectFromQuery.optionalParams))
                        }
                      }}/>
            )}
          </div>
        </div>
        <div className={'col-6'}>
          {(!!SelectFromQuery.optionalParams.length ||
              !!SelectFromQuery.requiredParams.length) &&
            <Fragment>
              <div className={'form-group mb-2'}>
                <label>{'PARAMETERS'}</label>
                {SelectFromQuery.requiredParams.map(
                  param => (<RequiredRow name={param.name} key={id + param.name}
                                         type={typeLabel(param)}
                                         value={param.value}
                                         onChange={param.onChange}/>))}
              </div>
              {(!!SelectFromQuery.optionalParams.length) &&
                (<ModalParameters params={SelectFromQuery.optionalParams}
                                  onUpdate={SelectFromQuery.onUpdateOptionalParams}/>)}
            </Fragment>}
        </div>
        {showSelectFrom && <Fragment>
          <div className={'col-4'}>
            <div className="form-check ml-3">
              <input className="form-check-input"
                     type="checkbox"
                     name="exampleRadios"
                     id="selectOnce"
                     value=""
                     checked={!!selectOnce}
                     onChange={() => setSelectOnce(!selectOnce)}/>
              <label className="form-check-label" htmlFor="selectOnce">
                Only once (prevent re-selection)
              </label>
            </div>
          </div>
          <div className={'col-6'}>
            <div className="form-check">
              <input className="form-check-input cursorPointer"
                     type="checkbox"
                     name="selectFrom"
                     id="autoSelect"
                     value=""
                     checked={!!autoSelect}
                     onChange={() => {
                       setAutoSelect(!autoSelect)
                     }}/>
              <label htmlFor={'autoSelect'}
                     className={'cursorPointer'}>{'Auto-select when initializing new records'}</label>
            </div>
          </div>
        </Fragment>}
        <div className={'col-4'}>
          {LinkedQuery.queryVisible && (<div className={'form-group mb-2'}>
            <div className="form-check">
              <input className="form-check-input cursorPointer"
                     type="checkbox"
                     name="selectFrom"
                     id="useLinkedQuery"
                     value=""
                     checked={showLinkedQuery}
                     onChange={() => {
                       setLinkedQuery(showLinkedQuery ? null : '')
                     }}/>
              <label htmlFor={'useLinkedQuery'}
                     className={'cursorPointer'}>{'LINK record TO...'}</label>
            </div>
            {showLinkedQuery && (
              <Select id={'linkTo'} value={LinkedQuery.selectedQuery}
                      values={LinkedQuery.availableOptions}
                      className={'mb-3 ml-3'}
                      errElement={LinkedQuery.queryInvalid &&
                        <span
                          className={'text-danger'}> (ERROR - change!)</span>}
                      onChange={val => setLinkedQuery(
                        BuildLinkExpr(val,
                          LinkedQuery.requiredParams,
                          LinkedQuery.optionalParams),
                      )}/>
            )}
          </div>)}
        </div>
        <div className={'col-6'}>
          {(!!LinkedQuery.optionalParams.length ||
              !!LinkedQuery.requiredParams.length) &&
            (<Fragment>
              <div className={'form-group mb-2'}>
                <label>{'PARAMETERS'}</label>
                {LinkedQuery.requiredParams.map(
                  param => (<RequiredRow name={param.name} key={id + param.name}
                                         type={typeLabel(param)}
                                         value={param.value}
                                         onChange={param.onChange}/>))}
              </div>
              {(!!LinkedQuery.optionalParams.length) &&
                (<ModalParameters params={LinkedQuery.optionalParams}
                                  onUpdate={LinkedQuery.onUpdateOptionalParams}/>)}
            </Fragment>)}
        </div>
        {showLinkedQuery && (<Fragment>
          <div className="col-4">
            <div className="form-check ml-3">
              <input className="form-check-input"
                     type="checkbox"
                     name="exampleRadios"
                     id="bidirectional"
                     value=""
                     disabled={!LinkedQuery.canChangeLinkType}
                     checked={!!isBidirectional}
                     onChange={() => setBidirectional(!isBidirectional)}/>
              <label className="form-check-label" htmlFor="bidirectional">
                Bidirectional link
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-check">
              <input className="form-check-input"
                     type="checkbox"
                     name="exampleRadios"
                     id="autoLink"
                     value=""
                     checked={!!autoLink}
                     onChange={() => setAutoLink(!autoLink)}/>
              <label className="form-check-label" htmlFor="autoLink">
                Auto-link when initializing new records
              </label>
            </div>
          </div>
        </Fragment>)}
      </div>
    )
  }

  return null

}

const App = () => {
  const settings = useSelector(state => state.settings, shallowEqual)

  const name = useRecoilValue(editMemberProperty('name'))
  const extUsers = useRecoilValue(editMemberProperty('extUsers'))

  return (<div className="col-9 px-3 py-2 knackly-plain overflow-auto"
               style={{ maxHeight: 'calc(100vh - 15rem)' }}>
    <div className={'row'}>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'name'} defaultValue={''}>
          {({
            value, onChange,
          }) => (<Input label={'NAME'} value={value} onChange={onChange}/>)}
        </EditMemberPropertyComponent>
      </div>
      <div className={'col-6 d-flex'}>
        <div className="flex-grow-1">
          <EditMemberPropertyComponent path={'label'} defaultValue={''}>
            {({
              value, onChange,
            }) => (<Input label={'LABEL'}
                          placeholder={InferLabelFromAppName(name)}
                          value={value}
                          onChange={onChange}/>)}
          </EditMemberPropertyComponent>
        </div>
        <div className="flex-grow-0 ml-3 my-2">
          <ActionsList/>
        </div>
      </div>
    </div>
    <div className={'row'}>
      <div className={'col-12'}>
        <label htmlFor="appTemplate">APP TEMPLATE</label>
        <TemplateWrapper
          className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3'}>
          <EditMemberPropertyComponent path={'templates'} defaultValue={''}>
            {({
              value, onChange,
            }) => {
              return (<Ace name={'appTemplate'}
                           value={value
                             ? (value.length > 0) ? value[0] : ''
                             : ''}
                           className={'border-0'}
                           onChange={(value) => onChange([value])}
                           maxLines={Infinity}/>)
            }}
          </EditMemberPropertyComponent>
        </TemplateWrapper>
      </div>
    </div>
    <div className={'row'}>
      <div className={'col-12 form-group'}>
        <label className={'mb-2'}>OPTIONS</label>
        <div className="form-check">
          <EditMemberPropertyComponent path={'generateDocs'}
                                       defaultValue={''}>
            {({
              value, onChange,
            }) => (<input type="checkbox"
                          id="generateDocs"
                          className="form-check-input"
                          checked={!!value}
                          onChange={event => {
                            onChange(event.target.checked)
                          }}/>)}
          </EditMemberPropertyComponent>
          <label htmlFor="generateDocs"
                 className="form-check-label">App <span
            style={{ fontWeight: 'bold' }}>generates a document</span> for
            each template referenced above</label>
        </div>
        {settings.isRegistration && (<div className="form-check">
          <EditMemberPropertyComponent path={'extUsers'} defaultValue={''}>
            {({
              value, onChange,
            }) => (<input type="checkbox" id="externalUsers"
                          className="form-check-input"
                          checked={value}
                          onChange={event => {
                            onChange(event.target.checked)
                          }}/>)}
          </EditMemberPropertyComponent>
          <label htmlFor="externalUsers" className="form-check-label">App is
            designed for access by <span
              style={{ fontWeight: 'bold' }}>external users</span></label>
        </div>)}
      </div>
      {settings.isRegistration && extUsers && (<div className={'col-12 mb-4'}>
        <label>UPON COMPLETION BY EXTERNAL USER:</label>
        <div className="form-check">
          <EditMemberPropertyComponent path={'extAutoClose'}>
            {({
              value, onChange,
            }) => (<input type="checkbox"
                          id="autoClose"
                          className="form-check-input"
                          checked={value}
                          onChange={event => {
                            onChange(event.target.checked)
                          }}/>)}</EditMemberPropertyComponent>
          <label htmlFor="autoClose" className="form-check-label"><span
            style={{ fontWeight: 'bold' }}>Automatically close</span> external
            access when user completes app</label>
        </div>
        <div className="form-check">
          <EditMemberPropertyComponent path={'showDocs'}>
            {({
              value, onChange,
            }) => (<input type="checkbox"
                          id="showDocs"
                          className="form-check-input"
                          checked={value}
                          onChange={event => {
                            onChange(event.target.checked)
                          }}/>)}</EditMemberPropertyComponent>
          <label htmlFor="showDocs" className="form-check-label">Produce
            Documents at End of
            Interview</label>
        </div>
      </div>)}

      {settings.isRegistration && extUsers && (<Fragment>
        <EditMemberPropertyComponent path={'extUseURLs'}>
          {({
            value, onChange,
          }) => (<div className={'col-5 form-group'}>
            <div className="form-check">
              <input className="form-check-input" type="radio"
                     name="exampleRadios" id="exampleRadios1"
                     value="option1" checked={!value}
                     onChange={() => onChange(false)}/>
              <label className="form-check-label" htmlFor="exampleRadios1">
                Show further <span
                style={{ fontWeight: 'bold' }}>instructions</span>/messages
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio"
                     name="exampleRadios" id="exampleRadios2"
                     value="option2" checked={value}
                     onChange={() => onChange(true)}/>
              <label className="form-check-label" htmlFor="exampleRadios2">
                <span style={{ fontWeight: 'bold' }}>Navigate</span> to
                another
                URL
              </label>
            </div>
          </div>)}
        </EditMemberPropertyComponent>
        <EditMemberPropertyComponent path={'extUseURLs'}>
          {({
            value: extUseURLs,
          }) => (<div className={'col-7 form-group'}>
            {!extUseURLs && (<Fragment>
              <label htmlFor="externalInstructions">INSTRUCTIONS FOR
                EXTERNAL
                USERS (when app is
                completed)</label>
              <TemplateWrapper
                className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3'}>
                <EditMemberPropertyComponent path={'extInstruct'}>
                  {({
                    value, onChange,
                  }) => (<Ace name={'externalInstructions'}
                              value={value}
                              className={'border-0'}
                              onChange={onChange}
                              maxLines={Infinity}/>)}
                </EditMemberPropertyComponent>
              </TemplateWrapper>
            </Fragment>)}
            {extUseURLs && (<Fragment>
              <EditMemberPropertyComponent path={'extCompletionURL'}>
                {({
                  value, onChange,
                }) => (<Input label={'COMPLETION URL'}
                              value={value}
                              onChange={onChange}/>)}</EditMemberPropertyComponent>
              <EditMemberPropertyComponent path={'extExitURL'}>
                {({
                  value, onChange,
                }) => (<Input label={'EXIT URL'}
                              value={value}
                              onChange={onChange}/>)}
              </EditMemberPropertyComponent>
            </Fragment>)}
          </div>)}
        </EditMemberPropertyComponent>
      </Fragment>)}
    </div>
    <QueryComponent/>
    <div className={'row'}>
      <Notes/>
    </div>
  </div>)
}

export default App
