import React, { Fragment, useEffect, useRef } from 'react'
import { Ace, Input, NavButtons } from '../../../components'
import {
  Switch,
  Route,
  useParams,
  useHistory, useLocation,
} from 'react-router-dom'
import Variables from '../Variables'
import Formulas from '../Formulas'
import Templates from '../Templates'
import Apps from '../Apps'
import { COLLECTION, OBJECT, QUERY, TABLE } from '../../../config'
import styled from 'styled-components'
import Layouts from '../Layouts'
import Query from './query'
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import { CurrentTypeSelector, editTypeProperty } from '../state'
import classNames from 'classnames'
import TableComponent from './table'

const BlockOfTabs = styled.div`
  background-color: ${props => props.theme.colorTable.back}
  border-color: ${props => props.theme.colorTable.back}
`

const NavigationTabLink = ({
  tab,
}) => {
  const history = useHistory()
  const params = useParams()
  const type = useRecoilValue(CurrentTypeSelector)
  return (<li key={tab} className="nav-item mr-2 cursorPointer"
              onClick={() => {
                const nextPath = `/${window.tenancy}/types/${type.name}/${tab}`
                if (window.needSaveProfileTemplate) {
                  return window.confirmProfile(nextPath)
                }
                history.push(nextPath)
              }}>
    <div className={classNames('nav-link',
      { active: params.tab ? params.tab === tab : tab === 'variables' },
      'border-0 h-100')} role="tab"
         aria-selected="true">{tab}</div>
  </li>)
}

const ModelName = () => {
  const [name, setName] = useRecoilState(editTypeProperty('name'))
  const isExternal = useRecoilValue(editTypeProperty('isExternal'))
  const preName = useRecoilValue(editTypeProperty('preName'))
  return (<div className="col-3">
    <Input label={'MODEL NAME'} value={name}
           onChange={setName}
           type={'text'}
           onBlur={() => {
             if (preName !== name) {
               /* updateNameModel(name, preName)*/
             }
           }}
           disabled={isExternal}
           id={'objType'}/>
  </div>)
}

const ModelSummary = () => {
  const [summary, setSummary] = useRecoilState(editTypeProperty('summary'))
  const ref = useRef(null)

  const onBlur = () => {
    if (ref.current) {
      ref.current.style.position = null
      ref.current.style.zIndex = null
      ref.current.style.width = null
      ref.current.style.maxHeight = 38 + 'px'
    }
  }

  const onFocus = () => {
    if (ref.current) {
      const boundingClientRect = ref.current.getBoundingClientRect()
      ref.current.style.position = 'absolute'
      ref.current.style.zIndex = 100
      ref.current.style.width = boundingClientRect.width + 'px'
      ref.current.style.maxHeight = null
    }
  }

  return (<div className="flex-grow-1">
    <label>SUMMARY TEMPLATE</label>
    <div ref={ref}
         style={{ maxHeight: 38, overflowY: 'auto' }}
         className={'knackly-plain rounded-0 py-2 px-2 form-control TemplateWrapper'}>
      <Ace name={'summary'} value={summary || ''}
           className={'border-0'}
           onChange={setSummary}
           onBlur={onBlur}
           onFocus={onFocus}
           maxLines={Infinity}/>
    </div>
  </div>)
}

function Type () {
  const location = useLocation()
  const current = useRecoilValue(CurrentTypeSelector)
  const refreshCurrentType = useRecoilRefresher_UNSTABLE(CurrentTypeSelector)

  useEffect(() => {
    refreshCurrentType()
  }, [location.pathname])

  if (current) {
    switch (current.type) {
      case COLLECTION:
      case OBJECT: {
        return (<Fragment>
            <div className={'col-12'}>
              <div className="row">
                <ModelName/>
               {/* <ModelLabel/>*/}
                <div className="col-9 d-flex">
                  <ModelSummary/>
                  <div className={'flex-grow-0 ml-3'}>
                    <NavButtons
                      saveCaption={'Save changes to types'}
                      cancelCaption={'Revert changes'}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-12'}>
              <ul className="nav nav-tabs text-uppercase" role="tablist">
                <NavigationTabLink tab={'variables'}/>
                <NavigationTabLink tab={'templates'}/>
                <NavigationTabLink tab={'formulas'}/>
                <NavigationTabLink tab={'layouts'}/>
                {current.type === COLLECTION && (
                  <NavigationTabLink typeName={current.name} tab={'apps'}/>)}
              </ul>
              <div className="tab-content">
                <BlockOfTabs
                  className="tab-pane fade show active p-2 pr-3 mb-2">

                  <Switch>
                    <Route
                      path={[
                        '/:tenancy/types/:typename/templates',
                        '/:tenancy/types/:typename/templates/:membername']}>
                      <Templates/>
                    </Route>
                    <Route
                      path={[
                        '/:tenancy/types/:typename/formulas',
                        '/:tenancy/types/:typename/formulas/:membername']}>
                      <Formulas/>
                    </Route>
                    <Route
                      path={[
                        '/:tenancy/types/:typename/layouts',
                        '/:tenancy/types/:typename/layouts/:membername']}>
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <Layouts/>
                      </React.Suspense>
                    </Route>
                    {current.type === COLLECTION && (
                      <Route
                        path={[
                          '/:tenancy/types/:typename/apps',
                          '/:tenancy/types/:typename/apps/:membername']}>
                        <React.Suspense fallback={<div>Loading...</div>}>
                          <Apps/>
                        </React.Suspense>
                      </Route>
                    )}
                    <Route
                      path={[
                        '/:tenancy/types',
                        '/:tenancy/types/:typename',
                        '/:tenancy/types/:typename/variables',
                        '/:tenancy/types/:typename/variables/:membername']}>
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <Variables/>
                      </React.Suspense>
                    </Route>
                  </Switch>
                </BlockOfTabs>
              </div>
            </div>
          </Fragment>
        )
      }
      case TABLE: {
        return (<TableComponent/>)
      }
      case QUERY: {
        return (<Query/>)
      }
      default: {
        return null
      }
    }
  }
  return null
}

export default Type
