import React, { Fragment, useEffect, useState } from 'react'
import { Engine } from 'yatte'
import update from 'immutability-helper'
import { ERRORTOKEN } from '../../../../../utils/tokens'
import { Icon } from '../../../../../components'
import classNames from 'classnames'
import { Modal } from 'antd'
import RowParameter from './RowParameter'
import { LabelForAddButton } from './style'

const typeLabel = (param) => {
  return param.type + (param.isList ? ' list' : '')
}

const AST = Engine.AST

const ModalParameters = ({
                           params,
                           onUpdate,
                         }) => {
  const [localParams, setLocalParams] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setLocalParams([...params])
  }, [params])
  const onCancel = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)
  const onChange = (paramName, nextValue, hasError) => {
    const indexParam = localParams.findIndex(param => param.name === paramName)
    try {
      Engine.compileExpr(nextValue || '""')
      hasError('')
      setLocalParams(preParams => update(preParams, { [indexParam]: { value: { $set: nextValue } } }))
    } catch (e) {
      hasError(e.toString())
      setLocalParams(
        preParams => update(preParams, { [indexParam]: { value: { $set: `"${ERRORTOKEN}${nextValue}"` } } }))
    } finally {
    }
  }
  const onSubmit = () => {
    try {
      const properties = localParams.filter(param => param.value).map(param => ({
        type: AST.Property,
        key: {
          type: AST.Identifier,
          name: param.name,
        },
        value: Engine.compileExpr(param.value).ast,
      }))

      onUpdate(AST.serialize({
        properties,
        type: AST.ObjectExpression,
      }, true))

      onCancel()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Fragment>
      <div className={'my-2'}>
        <button id={'MoreParameters'}
                type="button"
                onClick={onOpen}
                className="btn dart-btn text-white rounded-circle circle-btn mr-2">
          <Icon type="icon-bars" className={'circle-btn-icon'}/>
        </button>
        <LabelForAddButton htmlFor={'MoreParameters'}
                           className={classNames('mt-1 mb-0')}>
          Options...
        </LabelForAddButton>
      </div>
      <Modal
        title={'Parameters'}
        visible={isOpen}
        onCancel={onCancel}
        closable={false}
        width={'40%'}
        footer={[
          <button key="submit"
                  className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }}
                  onClick={
                    onSubmit
                  }>
            Ok
          </button>,
          <button key="back" className={'btn btn-outline-secondary dart-btn-outline'} style={{ width: 125 }}
                  onClick={onCancel}>Cancel</button>,
        ]}
      >
        <div className={'row'}>
          <div className="col-6"><p className={'pt-2'}>PARAMETER</p></div>
          <div className="col-6"><p className={'pt-2'}>EXPRESSION</p></div>
        </div>
        {localParams.map((param) => (<RowParameter type={typeLabel(param)} name={param.name} value={param.value}
                                                   onChange={(value, hasError) => onChange(param.name, value,
                                                     hasError)}/>))}
      </Modal>
    </Fragment>
  )
}

export default ModalParameters
