import axios from 'axios'

export default async tenancy => {
	if (!tenancy) {
		return {
			name: tenancy,
			error: 'Tenancy is empty',
			isNotResponding: false
		}
	}
	try {
		let { data } = await axios.get('/api/isTenancy/' + tenancy)
		return {
			...data,
			isNotResponding: false
		}
	} catch (e) {
		return {
			name: tenancy,
			error: e,
			isNotResponding: true,
		}
	}
}
