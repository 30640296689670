import React from 'react'
import { Button, Modal } from 'antd'

const ConfirmModal = ({ active, ok, cancel, leave }) => {
  return (<Modal visible={active}
                 onOk={ok}
                 onCancel={cancel}
                 title={'Save changes?'}
                 footer={[
                   <Button key="submit" onClick={ok}>
                     Save and continue
                   </Button>,
                   <Button key="leave" onClick={leave}>
                     Leave without saving
                   </Button>,
                   <Button key="cancel" onClick={cancel}>
                     Cancel and stay
                   </Button>,
                 ]}/>)
}
ConfirmModal.defaultProps = {
  active: true,
  width: 600,
  ok: () => console.log(1),
  cancel: () => console.log(2),
  leave: () => console.log(3)
}
export default ConfirmModal
