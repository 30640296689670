import React, { useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { DND } from '../../../config'
import { TextArea } from './style'
import classNames from 'classnames'
import useDebounce from '../../../utils/useDebounce'

function createDrop (onChange, value) {
  const [, drop] = useDrop({
    accept: DND.TYPES.DNDITEMROOT,
    drop (item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      onChange(value + ` {[${item.copyText}]}`)
    },
  })
  return drop
}

const TextAreaWithDrop = ({
                            changeContent,
                            value,
                          }) => {
  const [localValue, setLocalValue] = useState(value)
  let ref = useRef(null)
  const [height, setHeight] = useState(46)
  const drop = createDrop(changeContent, value)

  const resize = () => {
    if (ref.current) {
      if (ref.current.scrollHeight) {
        setHeight(ref.current.scrollHeight)
      }
    }
  }
  const debouncedValue = useDebounce(localValue, 300)

  useEffect(() => {
    if (value !== debouncedValue) {
      changeContent(debouncedValue)
    }
  }, [debouncedValue])

  useEffect(() => {
    resize()
  }, [localValue])

  ref = drop(ref)
  return (
    <TextArea ref={ref}
              className={classNames('form-control border-0 m-0 p-0 shadow-none')}
              style={{
                minHeight: 46,
                height,
              }}
              onChange={event => {
                setLocalValue(event.target.value)
              }} value={localValue}/>
  )
}

export default TextAreaWithDrop
