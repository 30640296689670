import { Engine } from 'yatte'
import { oneLineTrim } from 'common-tags'

export function VerifyKeyProps (
  objExprAst, sourceModelName, sourceKeyName, destModelName, destKeyName, destKeyType, funcName
) {
  let keyMapExpr, key2MapExpr
  if (destKeyName === 'id$') {
    // mapping to user-defined object native to Knackly
    // so we can automatically derive the mapped key value from whatever the source key is
    const suffix = '/' + sourceModelName
    const suffixLen = suffix.length
    const expr = oneLineTrim`
      ${sourceKeyName}.toString().endsWith('${suffix}')
        ?this._link||${sourceKeyName}.toString().slice(0,-${suffixLen})
        :${sourceKeyName}.toString().includes('/')||!this._link
          ?${sourceKeyName}.toString()+'/${destModelName}'
          :this._link
    `
    // old value of expr was: `${sourceKeyName}.toString()+':${funcName || ''}'`
    keyMapExpr = Engine.compileExpr(expr).ast
  } else {
    if (destKeyType === 'number') {
      keyMapExpr = Engine.compileExpr('number.parseInt(this._link)').ast
      key2MapExpr = Engine.compileExpr('this._link.toString()').ast
    } else {
      key2MapExpr = null // what to do here? Not sure.
    }
  }
  if (key2MapExpr) {
    SetAstProperty(objExprAst, 'id$', key2MapExpr, false)
  }
  if (keyMapExpr) {
    SetAstProperty(objExprAst, destKeyName, keyMapExpr, false)
  }
  if (sourceKeyName) {
    // set "link origin" id, if it's not already set
    SetAstProperty(objExprAst, '_link', {
      type: Engine.AST.Identifier,
      name: sourceKeyName,
    }, false)
  }
}

export function SetAstProperty (objExprAst, keyName, valAst, replaceExisting = true) {
  const indexProperty = objExprAst.properties.findIndex(property => property.key.name === keyName)
  if (indexProperty > -1) {
    if (replaceExisting) {
      objExprAst.properties[indexProperty].value = valAst
    }
  } else {
    objExprAst.properties.push({
      type: Engine.AST.Property,
      key: {
        type: Engine.AST.Identifier,
        name: keyName,
      },
      value: valAst,
    })
  }
}

export function RemoveAstAstProperty (objExprAst, keyName) {
  objExprAst.properties = objExprAst.properties.filter(property => property.key.name !== keyName)
}
