import React, { Component } from 'react'

class NoMatch extends Component {
  render () {
    return (
      <div className={'text-center my-5'}>
        <h1>404. That’s an error.</h1>
      </div>
    )
  }
}

export default NoMatch