import React, { Fragment } from 'react'
import { Input, NavButtons, Table } from '../../../components'
import { useRecoilState } from 'recoil'
import { editTypeProperty } from '../state'
import _ from 'lodash'

const TableName = () => {
  const [name, setName] = useRecoilState(editTypeProperty('name'))

  return (<Input label={'TABLE NAME'}
                 value={name}
                 onChange={setName}
                 type={'text'}
                 id={'tableName'}/>)
}

const BodeTableComponent = () => {
  const [variables, setVariables] = useRecoilState(
    editTypeProperty('properties'))
  const [data, setData] = useRecoilState(editTypeProperty('data'))
  const [key, setKey] = useRecoilState(editTypeProperty('key'))

  const saveTable = (next) => {
    const properties = next.header.map(o => {
      if (o.isKey) {
        if (key !== o.path) {
          setKey(o.path)
        }
      }
      return {
        type: 'text',
        name: o.path,
        label: o.label,
      }
    })
    if (!_.isEqual(properties, variables)) {
      setVariables(properties)
    }
    if (!_.isEqual(next.data, data)) {
      setData(next.data)
    }
  }
  return (<Table columns={variables === ''
    ? [{ type: 'text', name: 'Name', label: 'Name' }]
    : variables}
                 preData={data === '' ? [{ Name: '' }] : data}
                 keyRow={key}
                 getTable={saveTable}
                 isQuery={false}
  />)
}
const TableType = () => {
  return (<Fragment>
    <div className={'col-12'}>
      <div className="row">
        <div className="col-3">
          <TableName/>
        </div>
        <div className={'col-3'}>
          <NavButtons/>
        </div>
      </div>
    </div>
    <div className={'col-12'}>
      <label>CONTENT</label>
      <BodeTableComponent/>
    </div>
  </Fragment>)
}

export default TableType
