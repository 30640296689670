function BuildLinkExpr (queryMapping, requiredParams, optionalParams) {
  const [queryName, mapTo, mapBack] = queryMapping.split('-->')
  if (!queryName) {
    return ''
  }
  const reqParams = requiredParams.map(param => param.value).join(',')
  const optParams = optionalParams.filter(param => param.value).map(param => `${param.name}:${param.value}`).join(',')
  let expr = `${queryName}(${reqParams}${reqParams && optParams ? ',' : ''}${optParams ? `{${optParams}}` : ''})`
  if (mapTo) {
    expr += '|map:' + mapTo
  }
  if (mapBack) {
    expr += '|map:' + mapBack
  }
  return expr
}

export default BuildLinkExpr
