import React from 'react'

const CheckInput = ({ id, label, checked, onChange, className }) => {
  return (<div className={className}>
    <input id={id} className="form-check-input cursorPointer" type="checkbox" onChange={() => onChange(!checked)} checked={checked}/>
    <label className="form-check-label cursorPointer" htmlFor={id}>
      {label}
    </label>
  </div>)
}
export default CheckInput
