import React, { useRef, useState } from 'react'
import { Modal } from 'antd'
import { FilePond } from 'react-filepond'
import mime from 'mime-types'
import axios from 'axios'
import getWorkspaceURL from '../../../config/workspaceURL'

const ImportGlobal = ({
  appName,
  elementName,
  catalogName,
  visible,
  onCancel,
}) => {
  const filePondRef = useRef(null)
  const [file, setFile] = useState(undefined)
  const [disabledOk, setDisabledOk] = useState(true)

  const onRemoveFile = async (error, removeFile) => {
    if (error) {
      console.log(error)
    }
    try {
      await axios.delete(`/upload/importModel`, {}, { url: removeFile.url })
    } catch (e) {
      console.error(e)
    } finally {
      setFile(null)
    }
  }
  const process = (
    fieldName, selectedFile, metadata, load, error, progress, abort, transfer,
    options,
  ) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const formData = new FormData()
    formData.append('file', selectedFile)
    setDisabledOk(true)
    const config = {
      baseURL: getWorkspaceURL(),
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
          progressEvent.target.getResponseHeader(
            'x-decompressed-content-length')
        if (totalLength !== null) {
          progress(progressEvent.lengthComputable, progressEvent.loaded,
            totalLength)
        }
      },
      cancelToken: source.token,
    }

    axios.post(`/upload/importModel`, formData, config).then((response) => {
      console.log('response.data', response.data)
      load({
        _contentType: response.data.type || selectedFile.type,
        key: response.data.url,
      })

      setFile({
        source: selectedFile,
        url: response.data.url,
        options: {
          type: 'limbo',
        },
      })
    }).catch(thrown => {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message)
      } else {
        error(thrown)
      }
    }).finally(() => {
      setDisabledOk(false)
    })
    return {
      abort: () => {
        source.cancel('Operation canceled by the user.')
        abort()
      },
    }
  }

  const onSubmit = async () => {
    try {
      await axios.post(
        `/api/collections/${catalogName}/globals/import`,
        { elementName, appName, url: file.url })
    } catch (e) {
      console.error(e)
    } finally {
      onCancel()
    }
  }

  return (
    <Modal
      title={'Import global data to ' + elementName}
      visible={visible}
      onCancel={onCancel}
      closable={false}
      footer={[
        <button key="submit"
                className={'btn btn-secondary dart-btn'}
                onClick={onSubmit}
                disabled={disabledOk}
        >
          Import
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                onClick={onCancel}>Cancel</button>,
      ]}
    >
      <FilePond
        ref={filePondRef}
        allowMultiple={false}
        name={'importModel'}
        file={file ? [file] : []}
        onremovefile={onRemoveFile}
        server={{
          process,
        }}
        acceptedFileTypes={['application/json']}
        fileValidateTypeLabelExpectedTypesMap={{
          'application/json': '.json',
        }}
        fileValidateTypeDetectType={async (source, type) => mime.lookup(
          source.name)}
        labelIdle='Drag &amp; Drop your file or <span class="filepond--label-action">Browse</span>'
      />
    </Modal>
  )
}

export default ImportGlobal
