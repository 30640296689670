import { COLLECTION, OBJECT, queryClient } from '../config'
import _ from 'lodash'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const generateId = value => {
  value.id = uuidv4()
  return value
}

const mapMember = type => {
  if (type.type === COLLECTION || type.type === OBJECT) {
    type.properties = (type.properties && type.properties.map(generateId)) || []
    type.formulas = (type.formulas &&
      _.orderBy(type.formulas.map(generateId), ['name'], 'asc')) || []
    type.templates = (type.templates &&
      _.orderBy(type.templates.map(generateId), ['name'], 'asc')) || []
    if (type.type === COLLECTION) {
      type.apps = (type.apps && type.apps.map(generateId)) || []
    }
  }
  return type
}

const LoadingType = async (typeId) => {
  const URL = '/api/types/' + typeId
  const type = await queryClient.fetchQuery(URL, async () => {
    const { data: { obj } } = await axios.get(URL)
    return obj
  })
  return mapMember(type)
}

export default LoadingType
