import { selector } from 'recoil'
import _ from 'lodash'
import { Engine } from 'yatte'
import { editMemberProperty, TypesSelector } from '../state'
import changeErrorMessage from './changeErrorMessage'
import axios from 'axios'

export const isObjectSelector = selector({
  key: 'isObjectSelector',
  get: ({ get }) => {
    try {
      const expression = get(editMemberProperty('expression'))
      const exprFunc = Engine.compileExpr(expression || '{}')
      return exprFunc && exprFunc.ast && exprFunc.ast.type ===
        Engine.AST.ObjectExpression
    } catch (e) {
      return false
    }
  },
})

export const DestModelSelector = selector({
  key: 'DestModelSelector',
  get: async ({ get }) => {
    const ref = get(editMemberProperty('ref'))
    const types = get(TypesSelector)
    const model = _.find(types, (type) => type.name === ref)
    if (model) {
      const { data } = await axios.get(
        `/api/v2/types/${model.kind || 'model'}/${model._id}`)
      return data
    } else {
      console.log(`Bad model ref '${ref}'`)
    }
  },
})

export const ErrorExpressionState = selector({
  key: 'ErrorExpressionState',
  get: ({ get }) => {
    const expression = get(editMemberProperty('expression'))
    try {
      Engine.compileExpr(expression || '{}')
      return ''
    } catch (e) {
      return changeErrorMessage(e.toString())
    }
  },
})
export const ExprASTsSelector = selector({
  key: 'ExprASTsSelector',
  get: ({ get }) => {
    const expression = get(editMemberProperty('expression'))
    try {
      const compiledExpr = Engine.compileExpr(expression || '{}')
      if (compiledExpr) {
        return compiledExpr.ast.properties.reduce((prev, current) => ({
          ...prev,
          [current.key.name]: current.value,
        }), {})
      }
    } catch (e) {
      return {}
    }
  },
})
