import React from 'react'
import { Modal } from 'antd'
import Markdown from '../../components/Markdown/mdtojsx' // 'markdown-to-jsx'

const ModalTermsOfService = ({ isActive, handleOk, handleCancel, text }) => {
  return (
    <Modal
      title={'Terms of Service'}
      visible={isActive}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <button key="OK" className={'btn btn-secondary dart-btn'} onClick={handleOk}>ACCEPT</button>,
        <button key="OK" className={'btn btn-secondary dart-btn'} onClick={handleCancel}>REJECT</button>,
      ]}>
      <div className={'overflow-auto'} style={{ maxHeight: '40vh' }}>
        <Markdown>{text}</Markdown>
      </div>
    </Modal>
  )
}

export default ModalTermsOfService
