import React, { Component, Fragment } from 'react'
import { connect, Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { GA, queryClient, URI } from './config'
import store from './createStore'
import routes from './routes'
import { Container } from './components'
import isTenancy from './utils/isTenancy'
import isAuth from './utils/isAuth'
import activeExternalToken from './utils/activeExternalToken'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { Confirm, Links, Signin } from './views'
import axios from 'axios'
import { Icon, Spin } from 'antd'
import NoMatch from './views/noMatch'
import ResetPassword from './views/resetPassword'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Users from './views/users'
import { pushUser } from './action/user'
import { change } from './action/settings'
import { changeColors } from './action/colors'
import { ThemeProvider } from 'styled-components'
import queryString from 'query-string'
import { customTheme, GlobalStyle } from './theme'
import ModalTermsOfService from './modals/terms'
import moment from 'moment'
import { setLocale } from './utils/locales'
import ReactGA from 'react-ga'
import Inactivating from './components/Inactivating'
import { activePDF } from './views/types/Templates/types'
import NotResponding from './components/NotResponding'
import Welcome from './views/welcome'
import getVisitorId from './utils/getVisitorId'
import _ from 'lodash'

const redirectToTenancy = () => {
  const locations = window.location.pathname.split('/')
  let tenancy = locations[1]
  if (!tenancy) {
    const keys = Object.keys(
      JSON.parse(window.localStorage.getItem('tokens') || '{}'))
    if (keys[0]) {
      window.location.replace('/' + keys[0])
    }
  }
}
if (process.env.NODE_ENV === 'development' && false) {
  redirectToTenancy()
}

class App extends Component {
  state = {
    isLogin: false,
    isCheck: false,
    isLoad: true,
    isTOS: false,
    isActive: true,
    isAfterTOS: true,
    isClio: false,
    isNotResponding: false,
  }

  login = async (USER, nextHistory) => {
    try {
      const visitorId = await getVisitorId()
      let {
        data: {
          token,
          user,
        },
      } = await axios.post('/api/auth/signin', { user: USER, visitorId })
      if (token) {
        this.props.pushUser(user)
        let tokens = localStorage.getItem('tokens')

        let newInstansTokens = {}
        if (tokens) {
          tokens = JSON.parse(tokens)
          newInstansTokens = { ...tokens }
        }
        localStorage.removeItem('tokens')
        tokens = JSON.stringify({
          ...newInstansTokens,
          [window.tenancy]: token,
        })
        localStorage.setItem('tokens', tokens)
        localStorage.setItem('lastWorkspace', window.tenancy)
        axios.defaults.headers.common['Authorization'] = token

        if (nextHistory) {
          this.props.history.push(nextHistory)
        }

        const getsParameters = queryString.parse(window.location.search)
        if (getsParameters) {
          if (getsParameters.t) {
            this.props.history.push(`/${window.tenancy}/`)
          }
        }
        this.setState({ isLogin: true })
      } else {
        toast.error('Authentication invalid', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  registration = async (newUser, goBack) => {
    try {
      let { data: { error } } = await axios.post('/api/auth/registration',
        { user: newUser })
      if (error) {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        })
      } else {
        let app = newUser.backLink.split('/')
        app = app[app.length - 1]
        goBack()
        toast.success(
          `A confirmation message has been sent to ${newUser.email}. Please click the confirm link to set a password and continue the ${decodeURI(
            app)}.`,
          {
            position: toast.POSITION.TOP_CENTER,
            className: 't-success',
            autoClose: false,
          })
      }
    } catch (e) {
      console.error(e)
    }
  }

  activeIsLogin = () => {
    this.setState({ isLogin: true })
  }

  async componentDidMount () {
    const getsParameters = queryString.parse(window.location.search)
    let tokenFromServer = getsParameters ? getsParameters.t
      ? getsParameters.t
      : undefined : undefined
    let external_token = getsParameters ? getsParameters.external
      ? getsParameters.external
      : undefined : undefined
    if (tokenFromServer) {
      if (!window.location.pathname.includes(`/confirm`)) {
        tokenFromServer = (JSON.parse(
          decodeURIComponent(escape(window.atob((tokenFromServer))))))
      }
      tokenFromServer = tokenFromServer ? tokenFromServer.token
        ? tokenFromServer.token
        : undefined : undefined
    }
    const locations = window.location.pathname.split('/')
    let tenancy = locations[1]
    let hasLink = locations[2] === 'links'
    tenancy = tenancy.toLowerCase()
    let data = await isTenancy(tenancy)
    let {
      name,
      label,
      settings,
      isActive,
      isNotResponding,
      countApps,
      addons,
      rightLogo,
    } = data
    window.knacklyAddons = addons
    window.rightLogo = rightLogo
    window.countApps = countApps
    if (addons) {
      if (addons['pdf']) {
        activePDF()
      }
      if (addons['clio']) {
        this.setState({ isClio: !!addons['clio'] })
      }
      if (addons['filevine']) {
        this.setState({ isFilevine: !!addons['filevine'] })
      }
    }
    this.setState({
      isActive,
      isNotResponding,
    })
    if (settings) {
      if (settings.locale) {
        setLocale(settings.locale)
      }
      this.props.changeColors(settings.colors.length ? settings.colors : [
        window.location.host === 'www.actionstep-builder.com'
          ? '#024638'
          : '#F26B2F',
        '#CBCBCB',
        '#FFFFFF'])
      this.props.settingsPush(settings)
    }
    if (name && !data.error) {

      axios.defaults.baseURL = `${URI}/${tenancy}/`

      window.tenancy = tenancy
      window.signOut = () => {
        this.setState({ isLogin: false })
        let tokens = JSON.parse(localStorage.getItem('tokens'))
        if (tokens) {
          delete tokens[tenancy]
          localStorage.removeItem('tokens')
          localStorage.setItem('tokens', JSON.stringify(tokens))
          localStorage.removeItem('lastWorkspace')
        }
      }
      if (label) {
        window.tenancyLabel = label
      }
      const docTitle = label ? label.toUpperCase() : tenancy.toUpperCase()
      if (addons) {
        if (addons.whiteLabel || rightLogo) {
          document.title = docTitle
        } else {
          document.title = `${docTitle} - Knackly`
        }
      } else {
        document.title = `${docTitle} - Knackly`
      }

      if (window.location.pathname.includes(`/${tenancy}/confirm`)) {
        this.setState({ isLoad: false })
        return null
      }

      let tokens = JSON.parse(localStorage.getItem('tokens'))
      let tokenOfTenancy = tokens && tokens[tenancy]

      if (external_token) {
        external_token = await activeExternalToken(external_token)
      }
      if (tokenOfTenancy || tokenFromServer || external_token) {
        let token = tokenFromServer || tokenOfTenancy || external_token
        if (token) {
          try {
            let {
              auth,
              user,
              refreshToken,
            } = await isAuth(token)
            if (auth) {
              if (tokenFromServer) {
                let newInstansTokens = {}
                if (tokens) {
                  newInstansTokens = { ...tokens }
                }
                localStorage.removeItem('tokens')
                tokens = JSON.stringify({
                  ...newInstansTokens,
                  [window.tenancy]: refreshToken || token,
                })
                localStorage.setItem('tokens', tokens)
                localStorage.setItem('lastWorkspace', window.tenancy)
              }
              this.props.pushUser(user)
              axios.defaults.headers.common['Authorization'] = refreshToken ||
                token
              ReactGA.initialize(GA, {
                gaOptions: {
                  userId: user._id,
                },
              })
              ReactGA.set({ tenancy: window.tenancy })

              this.setState({
                isCheck: true,
                isLogin: true,
                isLoad: false,
              })
              if (hasLink) {
                this.goByLink()
              }
            } else {
              this.setState({
                isCheck: true,
                isLoad: false,
              })
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.setState({
            isCheck: true,
            isLoad: false,
          })
        }
      } else {
        this.setState({
          isCheck: true,
          isLoad: false,
        })
      }
    } else {
      if (window.location.pathname !== '/') {
        if (tenancy) {
          this.props.history.push('/', { massage: tenancy })
        }
      }
      this.setState({
        isCheck: false,
        isLoad: false,
        isNotResponding: false,
      })
    }
  }

  goByLink = async () => {
    try {
      const { data: { link } } = await axios.get(
        '/api/links/' + window.location.pathname.split('/')[3])
      if (link) {
        const { data: { item } } = await axios.get(
          `/api/collections/${link.catalogName}/items/${link.recordId}`)
        if (item) {
          this.props.history.push(
            `/${window.tenancy}/${link.catalogName}/${link.recordId}/${link.appName}?external`)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  componentDidUpdate (prevProps, presState) {
    if ((this.props.settings.terms || this.props.settings.termsForExternal) &&
      presState.isAfterTOS) {
      if (!presState.isTOS) {
        if (Object.keys(prevProps.user).length > 0) {
          if (!prevProps.user.lastAccepted) {
            this.setState({ isTOS: true })
          } else {
            if (Object.keys(this.props.settings).length > 0) {
              const chackedDate = prevProps.user.isExternal
                ? prevProps.settings.dateUpdateTermsForExternal
                : prevProps.settings.dateUpdateTerms
              if (moment(chackedDate).
                isAfter(moment(prevProps.user.lastAccepted))) {
                this.setState({ isTOS: true })
              }
            }
          }
        }
      }
    }
  }

  handelAcceptTOC = async () => {
    let { user } = this.props
    const nextUser = {
      ...user,
      lastAccepted: Date.now(),
    }
    let { data } = await axios.put('/api/users/' + user._id, nextUser)
    if (data) {
      this.props.pushUser(nextUser)
      this.setState({
        isTOS: false,
        isAfterTOS: false,
      })
    }
  }

  handelRejectTOC = () => {
    this.setState({ isTOS: false })
    window.signOut()
  }

  redirectTenancy = async (tenancy) => {
    let {
      name,
      label,
      settings,
      isActive,
      isNotResponding,
      addons,
    } = tenancy
    window.knacklyAddons = addons
    if (addons) {
      if (addons['pdf']) {
        activePDF()
      }
      if (addons['clio']) {
        this.setState({ isClio: !!addons['clio'] })
      }
      if (addons['filevine']) {
        this.setState({ isFilevine: !!addons['filevine'] })
      }
    }
    this.setState({
      isActive,
      isNotResponding,
    })
    if (settings) {
      if (settings.locale) {
        setLocale(settings.locale)
      }
      this.props.changeColors(settings.colors.length ? settings.colors : [
        window.location.host === 'www.actionstep-builder.com'
          ? '#024638'
          : '#F26B2F',
        '#CBCBCB',
        '#FFFFFF'])
      this.props.settingsPush(settings)
    }
    if (name) {

      axios.defaults.baseURL = `${URI}/${tenancy.name}/`

      window.tenancy = name
      localStorage.setItem('lastWorkspace', name)

      window.signOut = () => {
        this.setState({ isLogin: false })
        let tokens = JSON.parse(localStorage.getItem('tokens'))
        if (tokens) {
          delete tokens[tenancy.name]
          localStorage.removeItem('tokens')
          localStorage.setItem('tokens', JSON.stringify(tokens))
          localStorage.removeItem('lastWorkspace')
        }
      }

      if (label) {
        window.tenancyLabel = label
      }

      const docTitle = label ? label.toUpperCase() : tenancy.toUpperCase()
      document.title = addons.whiteLabel ? docTitle : `${docTitle} - Knackly`

      let tokens = JSON.parse(localStorage.getItem('tokens'))
      let tokenOfTenancy = tokens && tokens[tenancy.name]

      if (tokenOfTenancy) {
        let token = tokenOfTenancy
        if (token) {
          try {
            let {
              auth,
              user,
            } = await isAuth(token)
            if (auth) {
              this.props.pushUser(user)
              axios.defaults.headers.common['Authorization'] = token
              ReactGA.initialize(GA, {
                gaOptions: {
                  userId: user._id,
                },
              })
              ReactGA.set({ tenancy: window.tenancy })
              this.props.history.push('/' + tenancy.name)
              this.setState({
                isCheck: true,
                isLogin: true,
                isLoad: false,
              })
            } else {
              this.setState({
                isCheck: true,
                isLoad: false,
              })
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.setState({
            isCheck: true,
            isLoad: false,
          })
        }
      } else {
        this.setState({
          isCheck: true,
          isLoad: false,
        })
      }
    } else {
      this.setState({
        isCheck: false,
        isLoad: false,
      })
    }
  }

  antIcon = <Icon type="loading" className={'w-100'} style={{
    fontSize: 86,
    color: 'rgba(0, 0, 0, 0.65)',
  }} spin/>

  render () {
    let {
      colors,
      settings,
      collections,
    } = this.props
    let {
      isLogin,
      isCheck,
      isLoad,
      isTOS,
      isActive,
      isClio,
      isFilevine,
      isNotResponding,
    } = this.state
    let { user } = this.props
    const theme = customTheme(colors, settings.size)
    if (isLoad) {
      return (<div className={'text-center mt-5'}>
        <Spin className={'w-100'} indicator={this.antIcon}/>
        <h3 className={'mt-5'}>Loading...</h3>
      </div>)
    }

    const filterByPermissions = (collection) => {
      if (user.permissions) {
        return user.permissions.includes(collection.name)
      } else {
        return false
      }
    }
    if (collections.length > 0) {
      collections = collections.filter(filterByPermissions)
    }
    if (isNotResponding) {
      return (<ThemeProvider theme={theme}>
        <div>
          <NotResponding settings={settings}/>
          <GlobalStyle/>
        </div>
      </ThemeProvider>)
    }

    if (!isNotResponding && !window.tenancy) {
      return (<ThemeProvider theme={theme}>
        <div>
          <Welcome redirectTenancy={this.redirectTenancy}/>
          <GlobalStyle/>
        </div>
      </ThemeProvider>)
    }

    if (!isActive) {
      return (<ThemeProvider theme={theme}>
        <div>
          <Inactivating settings={settings}/>
          <GlobalStyle/>
        </div>
      </ThemeProvider>)
    }
    if (isTOS) {
      let isAllExternal = _.get(window.knacklyAddons, 'allExternal', false) &&
        user.isExternal
      if (!isAllExternal) {
        return (
          <ThemeProvider theme={theme}>
            <ModalTermsOfService isActive={isTOS}
                                 text={user.isExternal
                                   ? settings.termsForExternal
                                   : (settings.terms || '')}
                                 handleOk={this.handelAcceptTOC}
                                 handleCancel={this.handelRejectTOC}/>
          </ThemeProvider>)
      }
    }
    return (
      <Fragment>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <div>
              {isLogin && <Container colors={colors}>
                <DndProvider backend={HTML5Backend}>
                  <Switch>
                    <Route path={'/:tenancy/links/:IdLink'}
                           render={() => (<div>Loading...</div>)}/>
                    <Redirect from={'/:tenancy/login'} to={'/:tenancy/'}/>
                    {user.isAdmin && <Route exact component={Users}
                                            path={'/:tenancy/users'}/>}
                    {!isClio && <Redirect from={'/:tenancy/config-clio'}
                                          to={'/:tenancy/'}/>}
                    {routes.map(route => {
                      if (route.isAdmin) {
                        return (<Route key={'route' + route.path} exact
                                       path={route.path}
                                       render={props => user.isAdmin
                                         ? (
                                           <route.component isClio={isClio}
                                                            isFilevine={isFilevine} {...props} />)
                                         : (
                                           <Redirect to={{
                                             pathname: '/',
                                             state: { from: props.location },
                                           }}/>)}/>)
                      } else {
                        if (user.isExternal) {
                          return route.isExternal
                            ? (<Route key={'route' + route.path} exact
                                      path={route.path}
                                      render={props => <route.component
                                        user={user} isClio={isClio}
                                        isFilevine={isFilevine} {...props} />}/>)
                            : (<Redirect to={{
                              pathname: '/',
                            }}/>)
                        } else {
                          return (
                            <Route key={'route' + route.path} exact
                                   path={route.path}
                                   render={props => <route.component user={user}
                                                                     isClio={isClio}
                                                                     isFilevine={isFilevine}{...props} />}/>)

                        }
                      }
                    })}
                    {collections[0] &&
                      <Redirect from={'/:tenancy/'}
                                to={`/:tenancy/${collections[0].name}${collections[0].myRecords
                                  ? '?f=%7B"myrecords"%3A%7B%7D%7D'
                                  : ''}`}/>}
                  </Switch>
                </DndProvider>
              </Container>}
              {(!isLogin) && <Switch>
                <Route path={'/:tenancy/confirm'}
                       render={() => <Confirm login={this.login}/>}/>
                <Route path={'/:tenancy/resetPassword'}
                       component={ResetPassword}/>
                <Route path={'/:tenancy/links/:IdLink'}
                       render={() => (
                         <Links settings={settings}
                                registration={this.registration}
                                pushUser={this.props.pushUser}
                                activeIsLogin={this.activeIsLogin}
                         />)}/>
                {/* <Route path={'/:tenancy/register'}
                     render={(routeProps) => <Register settings={settings}
                                                       registration={this.registration} {...routeProps} />}/>*/}
                {isCheck &&
                  <Route to={'/:tenancy/login'}
                         render={(routeProps) => <Signin settings={settings}
                                                         registration={this.registration}
                                                         login={this.login} {...routeProps} />}/>}
                {isCheck &&
                  <Route to={'/:tenancy/:adapter/:application?external'}
                         render={(routeProps) => <Signin settings={settings}
                                                         registration={this.registration}
                                                         login={this.login} {...routeProps} />}/>}
                {isCheck && <Redirect to={`/${window.tenancy}/login`}/>}
                {isLogin && <Redirect to={`/${window.tenancy}/`}/>}
                {!isCheck && <Route component={NoMatch}/>}
              </Switch>}
              <ToastContainer toastClassName={'d-block'} closeButton={false}
                              bodyClassName={'d-block m-2'}
                              style={{
                                width: '500px',
                                marginLeft: '-250px',
                              }}/>
              <GlobalStyle/>
            </div>
          </ThemeProvider>
        </QueryClientProvider>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  let {
    user,
    colors,
    settings,
    collections,
  } = state
  return {
    user,
    colors,
    settings,
    collections,
  }
}

const mapDispatchToProps = (dispatch) => ({
  pushUser: (user) => dispatch(pushUser(user)),
  settingsPush: (newSettings) => {
    dispatch(change(newSettings))

  },
  changeColors: (colors) => dispatch(changeColors(colors)),
})

App = withRouter(connect(mapStateToProps, mapDispatchToProps)(App))

export default () => (
  <Provider store={store}> <Router><App/></Router></Provider>)
