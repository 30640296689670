import React from 'react'

const Select = ({
                  id,
                  label,
                  value,
                  onChange,
                  values,
                  errElement,
                  disabled = false,
                  className,
                }) =>
  (
    <div className={className ? className : 'form-group mb-2'}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select className="form-control" id={id ? id : null}
              value={value}
              onChange={e => onChange(e.target.value)}
              disabled={disabled}>
        {values.map(val => {
          switch (typeof val) {
            case 'object':
              return (<option key={(id ? id : '') + val.value}
                              value={val.value}>{val.formatting
                ? val.formatting()
                : val.label}</option>)
            case 'string':
              if (val === 'line')
                return (<option value="" key={(id ? id : '') + 'disabled'}
                                disabled="disabled">────────────────</option>)
              return (<option key={(id ? id : '') + val}>{val}</option>)
            default:
              console.log(
                `Unexpected value type in Select options: ${JSON.stringify(
                  val)}`)
              return null
          }
        })
        }
      </select>
      {errElement || null}
    </div>
  )

export default Select
