import React, { useMemo } from 'react'
import { Card, NavigationDark, TextArea } from '../style'
import { ReactComponent as Question } from '../../../../images/icons/question-circle.svg'

const PreViewCard = () => {
  const MemoPreViewCard = useMemo(
    () => (<Card className={'border w-100 rounded d-flex cardLayout'} style={{ opacity: '0.2' }}>
      <NavigationDark className={'px-2 pt-1 rounded-left'}><Question style={{
        width: 16,
        height: 16,
      }}/></NavigationDark>
      <div className={'w-100 knackly-plain px-2 py-1'}>
        <div className={'d-flex flex-column'}>
          <TextArea className={'w-100 form-control border-0 m-0 p-0'}
                    value={''}/>
        </div>
      </div>
      <NavigationDark style={{ width: '25%' }}
                      className={'pt-1 rounded-right'}>
      </NavigationDark>
    </Card>),[])
  return MemoPreViewCard

}

export default PreViewCard
