import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import '../signin/index.css'
import validateEmail from '../../utils/validateEmail'
import styled from 'styled-components'
import { ReactComponent as ArrowBack } from '../../images/icons/ArrowBack.svg'

const ButtonLogin = styled.button`
  background: white !important;
  border-color: ${props => props.theme.colorTable.widget} !important;
  color: ${props => props.theme.colorTable.text} !important;
  font-weight: 500 !important;
  padding: 12px !important;
`
const ButtonLoginDark = styled.button`
  background: ${props => props.theme.colorTable.widget} !important;
  border-color: ${props => props.theme.colorTable.widget} !important;
  color: ${props => props.theme.colorTable.widgetText} !important;
  font-weight: 500 !important;
  padding: 12px !important;
`
const Register = ({
                    registration,
                    goBack,
                    location,
                  }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidName, setInvalidName] = useState(false)
  const submit = () => {
    let instanceEmail = email.toLowerCase()
    if (validateEmail(email) && name.length > 1) {
      setInvalidEmail(false)
      setInvalidName(false)
      let backLink = window.location.pathname
        ? window.location.pathname
        : undefined

      registration({
        email: instanceEmail,
        name,
        backLink,
      }, goBack)
    }
  }

  return (
    <Fragment>
      <form className={'text-left'} onSubmit={event => {
        event.preventDefault()
        submit()
      }}>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Full name</label>
          <input
            className={classNames('form-control rounded-0',
              { 'is-invalid': invalidName })}
            id={'name'} type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Full name" required /></div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email</label>
          <input
            className={classNames('form-control rounded-0',
              { 'is-invalid': invalidEmail })}
            value={email}
            onChange={e => setEmail(e.target.value)}
            id={'email'} type="email"
            placeholder="Email" required />
        </div>
        <input className={'position-absolute'} style={{
          left: -2000,
          zIndex: -1,
        }} type={'submit'} />
      </form>
      <div className={'row'}>
        {!window.knacklyAddons.no3p_auth_ext && (<div className={'col-4'}><ButtonLogin
          className={'my-2 btn-block d-flex rounded'}
          onClick={goBack}><ArrowBack
          style={{ width: '1rem' }} /> BACK</ButtonLogin>
        </div>)}
        <div className={!window.knacklyAddons.no3p_auth_ext
          ? 'col-8'
          : 'col-12'}><ButtonLoginDark className={'my-2 btn-block rounded'}
                                       onClick={() => submit()}>REGISTER
          &
          CONFIRM</ButtonLoginDark></div>
      </div>
    </Fragment>)
  /* return (<Fragment>
       {settings.logo && <BlockLogo className={'w-100 text-center py-3'}>
         <img src={settings.logo} style={{
           maxWidth: '50%',
           maxHeight: 100,
         }} alt={settings.label ? settings.label : settings.name}/>
       </BlockLogo>}
       <div className={settings.logo ? 'pt-5' : ''} style={!settings.logo ? { paddingTop: '10%' } : {}}>
         <div className={'mx-auto signin text-center d-flex flex-column p-5'}>
           <LabelSignIn
             className={'mx-0 mb-5 font-weight-bold'}>Registration</LabelSignIn>
           <form onSubmit={event => {
             event.preventDefault()
             submit()
           }}>
             <input
               className={classNames('form-control mt-1 mb-3 rounded-0', { 'is-invalid': invalidName })}
               value={name}
               onChange={e => setName(e.target.value)}
               id={'name'} type="text"
               placeholder="Name"
               required/>
             <input
               className={classNames('form-control mt-1 mb-3 rounded-0', { 'is-invalid': invalidEmail })}
               value={email}
               onChange={e => setEmail(e.target.value)}
               id={'email'} type="email"
               placeholder="Email" required/>
             <button className={'dart-btn h-100 my-2 rounded-pill '}>Next</button>
           </form>

         </div>
       </div>
     </Fragment>
   )*/
}

export default Register
