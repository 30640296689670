import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import rootReducer from './reducers'

let store
if (process.env.NODE_ENV !== 'development' || true) {
  store = createStore(
    rootReducer,
    applyMiddleware(
      thunkMiddleware,
    ),
  )
} else {
  store = createStore(
    rootReducer,
    applyMiddleware(
      thunkMiddleware,
    ),
  )

}

export default store
