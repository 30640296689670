import React from 'react'
import { Ace } from '../../../../components'

const Onedrive = ({
                    folder,
                    onChange,
                  }) => (
  <div className={'col-12 py-3'}>
    <label>FOLDER PATH (separate folder names with "/")</label>
    <div
      className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>
      <Ace className={'border-0'} value={folder || ''}
           id={'folders'}
           placeholder={'/'}
           onChange={onChange}
           type={'text'} maxLines={Infinity} />
    </div>

  </div>
)

export default Onedrive
