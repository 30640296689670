const getDateFormatByLocation = () => {
  if (!window.settings) {
    return []
  }
  if (window.settings.locale === 'en-US' || window.settings.locale ===
    'en-CA') {
    return ['|format:"MMMM D, YYYY"']
  }
  if (window.settings.locale === 'en-GB' || window.settings.locale ===
    'en-AU') {
    return ['|format:"D MMMM YYYY"']
  }
  return []
}
export default getDateFormatByLocation
