export const SELECTION = 'selection'
export const OBJECT = 'object'
export const GLOBAL = 'global'

export const DND = {
  TYPES: {
    DNDITEMROOT: 'DNDITEMROOT'
  },
  FORMULAS: {
    DNDFORMULAS: 'DNDITEMFORMULAS'
  },
  TEXT: {
    DNDSUGGESTIONS: 'DNDSUGGESTIONS'
  }
}
