import React from 'react'
import Ace from '../Ace'
import { useDrop } from 'react-dnd'
import { DND } from '../../config'

const SingleAce = ({ name, placeholder, value, onChange }) => {
  const [, drop] = useDrop({
    accept: DND.TYPES.DNDITEMROOT,
    drop (item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      onChange(value + ` {[${item.copyText}]}`)
    },
  })
  return (
    <div ref={drop} className={'form-control rounded-0'} style={{ padding: '10px 12px 2px 8px' }}>
      <Ace name={name || null}
           placeholder={placeholder || null}
           value={value}
           onChange={(val) => onChange(val.replace(/[\n\r]+/g, ' '))}
           height={'26px'}
           className={'single-ace'}
           wrapEnabled={false}
           setOptions={{
             enableBasicAutocompletion: false,
             enableLiveAutocompletion: false,
             selectionStyle: 'text',
             wrapBehavioursEnabled: false,
             cursorStyle: 'slim',
           }}
           otherProps={{
             showPrintMargin: false,
             showGutter: false,
             tabSize: 0,
             useSoftTabs: false,
             wrap: false,
             cursorStart: -1
           }}/>
    </div>
  )
}

export default SingleAce
