import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Icon } from 'antd'

import useDebounce from '../../utils/useDebounce'

function Search ({
  onSubmit,
  disabled,
  style,
  className,
}) {
  const [value, setValue] = useState(null)

  const debouncedValue = useDebounce(value, 400)

  const submit = () => {
    if (value) {
      setValue('')
    }
  }

  useEffect(() => {
    const SearchParams = new URLSearchParams(window.location.search)
    if (SearchParams.has('s')) {
      setValue(SearchParams.get('s'))
    }
  }, [])

  useEffect(() => {
    onSubmit(debouncedValue)
  }, [debouncedValue])

  const onChange = useCallback(e => {
    setValue(() => e.target.value)
  }, [])

  const MemoInput = useMemo(() => (
      <input type="text"
             value={value}
             onChange={onChange}
             className="form-control rounded-0"
             placeholder="Search..."
             disabled={disabled}/>),
    [value, disabled])

  const MemoIcon = useMemo(() => {
    return value ? (<Icon
      className={'align-middle'}
      type="close"/>) : (<Icon
      className={'align-middle'}
      type="search" disabled={disabled}/>)
  }, [value, disabled])

  const MemoButton = useMemo(() => (<div className="input-group-append">
    <button className="btn btn-outline-secondary dart-btn rounded-0 w-100 h-100"
            onClick={submit} type="button">
      {MemoIcon}
    </button>
  </div>), [submit])
  return (
    <div className={className ? className : 'input-group mb-2'}
         style={style ? style : {}}>
      {MemoInput}
      {MemoButton}
    </div>)
}

export default Search
