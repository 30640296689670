import React, { Fragment, useState } from 'react'
import { Modal } from 'antd'
import { COLLECTION, OBJECT, queryClient } from '../../../config'
import Select from '../../../components/Select'
import CheckInput from '../../../components/CheckInput'
import { useHistory } from 'react-router-dom'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import {
  CurrentTypeSelector,
  ElementsChangesAtom,
  getKind,
  getMemberKey,
  getTypeName,
  SelectedMembersAtom,
  TypesSelector,
} from '../state'

import axios from 'axios'
import produce from 'immer'
import { ObjectID } from 'bson'

const getTypes = (types, typeName, memberKey) => {
  if (memberKey === 'apps') {
    return types.filter(
      member => member.type === COLLECTION && member.name !== typeName)
  }
  return types.filter(
    member => (member.type === OBJECT || member.type === COLLECTION) &&
      member.name !== typeName)
}

const ModalMove = ({
                     title,
                     children,
                   }) => {
  const history = useHistory()
  const types = useRecoilValue(TypesSelector)
  let [active, setActive] = useState(false)
  const [favorMoved, setFavorMoved] = useState(true)
  const [redirect, setRedirect] = useState(true)

  const typeName = getTypeName() || types[0] ? types[0].name : ''
  const memberKey = getMemberKey()

  const types1 = getTypes(types, typeName, memberKey)
  let [way, setWay] = useState(() => {
    if (types1[0]) {
      return types1[0].name
    }
    return ''
  })
  const handleWay = (nextWay) => {
    setWay(() => nextWay)
  }
  const handleRedirect = () => {
    setRedirect((preRedirect) => !preRedirect)
  }

  const clearModal = () => {
    setActive(false)
    setFavorMoved(true)
    setRedirect(true)
    setWay(() => {
      if (types[0]) {
        return types[0].name
      }
      return ''
    })
  }

  let onCancel = () => clearModal()
  let onActive = () => setActive(true)
  const onSubmit = useRecoilCallback(({
                                        set,
                                        snapshot,
                                        refresh,
                                      }) => async () => {
    const types = await snapshot.getPromise(TypesSelector)
    const changes = await snapshot.getPromise(ElementsChangesAtom)
    const currentType = await snapshot.getPromise(CurrentTypeSelector)

    const selectedMembers = await snapshot.getPromise(SelectedMembersAtom)

    const currentMembers = currentType[memberKey]
    const members = Object.keys(selectedMembers).map(memberId => {
      const member = currentMembers.find(member => member._id === memberId)
      return produce(member, draft => {})
    })

    const directType = types.find(t => t.name === way)

    const type = await queryClient.fetchQuery(
      `/api/v2/types/${directType.kind}/${directType._id}`, async () => {
        const { data } = await axios.get(
          `/api/v2/types/${directType.kind}/${directType._id}`)
        return data
      })

    let directMembers = type[memberKey]

    //Add added members
    changes.filter(
      element => (element.action === 'add') &&
        element.parent === directType._id && element.kind ===
        getKind(memberKey)).forEach(_member => {
      directMembers.push(_member)
    })

    //Update updated members
    changes.filter(
      element => (element.action === 'update') &&
        element.parent === directType._id && element.kind ===
        getKind(memberKey)).forEach(_member => {
      directMembers = directMembers.map(_directMember => {
        if (_member._id === _directMember._id) {
          return ({ ..._member, ..._directMember })
        }
        return _directMember
      })
    })
    members.forEach(member => {

      const existingMember = directMembers.find(
        directMember => directMember.name === member.name)

      const memberChanges = changes.find(
        el => el.action === 'update' && el._id === member._id) || {}

      set(ElementsChangesAtom, produce(draft => {
        if (!existingMember) {
          draft.push({
            ...member,
            ...memberChanges,
            action: 'add',
            kind: getKind(memberKey),
            parent: type._id,
            parentKind: 'model',
            _id: new ObjectID().toString(),
          })
        } else {
          if (!favorMoved) {
            draft.push({
              ...member,
              ...memberChanges,
              action: 'update',
              kind: getKind(memberKey),
              parent: type._id,
              parentKind: 'model',
              _id: existingMember._id,
            })
          }
        }

        draft.push({
          action: 'remove',
          kind: getKind(memberKey),
          parent: currentType._id,
          parentKind: 'model',
          _id: member._id,
        })
      }))
    })
    if (redirect) {
      history.push(
        `/${window.tenancy}/types/${way}/${memberKey === 'properties'
          ? 'variables'
          : memberKey}/`)
      refresh(CurrentTypeSelector)
    }
    set(SelectedMembersAtom, {})
    clearModal()
  }, [
    typeName,
    memberKey,
    way,
    favorMoved,
    redirect])

  const label = memberKey === 'properties'
    ? 'variables'
    : memberKey

  return (<Fragment>
      <div onClick={onActive}>
        {children}
      </div>

      <Modal title={title}
             visible={active}
             onCancel={onCancel}
             closable={false}
             width={410}
             footer={[
               <button key="submit" className={'btn btn-secondary dart-btn'}
                       style={{ width: 125 }} onClick={onSubmit}>
                 Copy
               </button>,
               <button key="back"
                       className={'btn btn-outline-secondary dart-btn-outline'}
                       style={{ width: 125 }}
                       onClick={onCancel}>Cancel</button>,
             ]}
      >
        <form onSubmit={onSubmit}>
          <Select values={types.map(type => type.name)} value={way}
                  onChange={handleWay} id={'copySelect'}
                  label={'Where to move variable:'}/>

          <label>In case of conflicts, attempt to preserve functionality
            of</label>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="favorMoved"
                   id="favorMovedVariable1"
                   checked={favorMoved}
                   onClick={() => setFavorMoved(true)}
            />
            <label className="form-check-label" htmlFor="favorMovedVariable1">
              existing {label}
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="favorMoved"
                   id="favorMovedVariable2"
                   checked={!favorMoved}
                   onClick={() => setFavorMoved(false)}
            />
            <label className="form-check-label" htmlFor="favorMovedVariable2">
              moved {label}
            </label>
          </div>
          <CheckInput id={'redirect'}
                      label={'Go to new version after move ' + label}
                      className={'form-group form-check mt-2'}
                      checked={redirect} onChange={handleRedirect}/>
        </form>
      </Modal>
    </Fragment>
  )
}

export default ModalMove
