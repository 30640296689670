import LoadingType from './loadingType'

async function fetchDef (def) {
  if (def.neededLoading) {
    // console.log(`loading element ${def.name}`)
    return await LoadingType(def._id)
  }
  return def
}

function modelCanSupplyModel (modelDef, modelName, exceptMemberName = '') {
  const mappings = []
  // look through modelDef's potential mappings looking for obvious ways to get modelName output from it
  if (modelDef.properties || modelDef.variables) {
    (modelDef.properties || modelDef.variables)
      .filter(v => v.type === 'object' && !v.isList && v.typeName === modelName && v.name !== exceptMemberName)
      .forEach(v => mappings.push(v.name))
  }
  if (modelDef.formulas) {
    modelDef.formulas
      .filter(f => f.type === 'object' && !f.isList && f.ref === modelName && f.name !== exceptMemberName)
      .forEach(f => mappings.push(f.name))
  }
  if (mappings.length) {
    // console.log('mappings found')
    return mappings
  } else {
    return false
  }
}

export async function queryCanSupplyModel (queryDef, modelName, allModels, exceptMemberName = '') {
  queryDef = await fetchDef(queryDef)
  const mappings = []
  const links = []
  // should return true if queryDef produces objects of type modelName
  // OR objects that can be mapped to type modelName
  if (queryDef.extModelName === modelName) {
    // query DIRECTLY produces queryDef.extModelName objects
    mappings.push(queryDef.name)
    if (queryDef.writeBack) {
      links.push(queryDef.name)
    }
  }
  // look through this object's potential mappings looking for obvious ways to get modelName output from it
  let model = allModels.find(t => t.name === queryDef.extModelName)
  if (model) {
    model = await fetchDef(model)
    const mapped1way = modelCanSupplyModel(model, modelName, exceptMemberName)
    if (mapped1way) {
      let modelDef = allModels.find(t => t.name === modelName)
      if (modelDef) {
        modelDef = await fetchDef(modelDef)
        mapped1way.forEach(ident => {
          mappings.push(`${queryDef.name}-->${ident}`)
          if (queryDef.writeBack) {
            const mapped2way = modelCanSupplyModel(modelDef, queryDef.extModelName, ident)
            if (mapped2way) {
              mapped2way.forEach(ident2 => {
                links.push(`${queryDef.name}-->${ident}-->${ident2}`)
              })
            }
          }
        })
      } else {
        const msg = `Model '${modelName}' not found; unable to look up potential query links`
        // throw new Error(msg)
        console.error(msg)
      }
    }
  }
  if (mappings.length) {
    if (links.length) {
      mappings.links = links
    }
    return mappings
  }
  return false
}

export async function getMappingsForModel (modelName, allModels, exceptMemberName = '') {
  // called by the designer UI to find out what queries will result in objects of this type/model
  // (or objects which can be MAPPED to this type/model )
  if (!modelName) {
    throw new TypeError('modelName must be defined and cannot be empty')
  }
  const mappings = []
  const links = []
  if (window.knacklyAddons) {
    if (window.knacklyAddons.queries) {
      const Queries = await Promise.all(
        allModels
          .filter(model => model.type === 'query')
          .map(async query => {
            return await queryCanSupplyModel(query, modelName, allModels, exceptMemberName)
          })
      )
      Queries.forEach(model => {
        if (model) {
          if (model.links) {
            model.links.forEach(link => links.push(link))
          }
          model.forEach(mapping => mappings.push(mapping))
        }
      })
    }
  }
  if (mappings.length) {
    if (links.length) {
      mappings.links = links
    }
    return mappings
  }
}
