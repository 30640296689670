import { Icon as AntIcon } from 'antd'

// our custom iconfont is at:
// https://www.iconfont.cn/manage/index?manage_type=myprojects&projectId=1054754&sortType=unicode

const Icon = AntIcon.createFromIconfontCN({
  scriptUrl: '/font_1054754_20221117.js',
})

Icon.fromValueType = function (typename) {
  switch (typename) {
    case 'text':
      return 'icon-font'
    case 'number':
      return 'icon-hashtag'
    case 'date':
      return 'icon-calendar'
    case 'true/false':
      return 'icon-check2'
    case 'selection':
      return 'icon-select3'
    case 'file':
      return 'icon-file'
    case 'object':
    case 'global':
      return 'icon-object'
    default:
      return 'icon-warning'
  }
}

Icon.fromTemplateType = function (typename) {
  switch (typename) {
    case 'text':
      return 'icon-text1'
    case 'docx':
      return 'icon-file-word'
    case 'file':
      return 'icon-file'
    case 'pdf':
    case 'pdff':
      return 'icon-file-pdf'
    default:
      return 'icon-warning'
  }
}

Icon.fromFormulaType = function (typename) {
  switch (typename) {
    default:
      return 'icon-formula'
  }
}

Icon.fromFileName = function (filename) {
  const pos = filename.lastIndexOf('.')
  const ext = filename.substring(pos)
  switch (ext) {
    case '.docx':
      return 'icon-file-word'
    case '.txt':
      return 'icon-file-text'
    case '.pdf':
      return 'icon-file-pdf'
    case '.png':
    case '.jpg':
    case '.jpeg':
    case '.gif':
    case '.bmp':
    case '.svg':
    case '.ico':
      return 'icon-file-image'
    default:
      return 'icon-file'
  }
}

Icon.fromStatus = function (status) {
  switch (status) {
    case 'Ok':
      return 'icon-check'
    case 'In Progress':
      return 'icon-hourglass-half'
    case 'Out of date':
      return 'icon-exclamation-triangle'
    default:
      return 'icon-warning'
  }
}
Icon.fromLayoutType = function (typelayout) {
  switch (typelayout) {
    case 'layout':
    case 'default':
      return 'icon-newspaper'
    default:
      return 'icon-warning'
  }
}
Icon.fromAppType = function (type) {
  switch (type) {
    default:
      return 'icon-app1'
  }
}

Icon.fromUser = function (user) {
  if (user.isAdmin) return 'icon-user-shield'
  if (user.isExternal) return 'icon-user-circle'
  // if (!user.isActive)
  //   return 'icon-user-tag'
  // default:
  return 'icon-user'
}

Icon.fromType = typename => {
  switch (typename) {
    case 'object':
      return 'icon-model'
    case 'table':
      return 'icon-table1'
    case 'query':
      return 'icon-query'
    case 'collection':
      return 'icon-cabinet'
    default:
      return 'icon-warning'
  }
}

export default Icon
