import React, { useState } from 'react'
import { Modal } from 'antd'
import { Select } from '../../../components'
import AsyncSelect from '../../../components/AsyncSelect'

const ModalMove = ({
  isModal,
  onCancel,
  onSubmit,
  currentCell,
  currentColumn,
  currentPosition,
  countColumns,
  countRows,
}) => {
  const [positionByRow, setRow] = useState(currentPosition + 1)
  const [action, setAction] = useState('AboveRow')
  const submit = () => {
    onSubmit({
      positionByRow: positionByRow - 1,
      action,
      currentCell,
      currentPosition,
      currentColumn,
    })
  }

  return (
    <Modal
      title="Where do you want to move this cell to?"
      visible={isModal}
      onCancel={onCancel}
      footer={[
        <button key="submit" className={'btn btn-secondary dart-btn'}
                onClick={submit}>
          Ok
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                onClick={onCancel}>Cancel</button>,
      ]}
    >
      <form onSubmit={submit}>
        <div className={'row'}>
          <div className={'col-6'}>
            <Select id={'positionByRow'}
                    values={countColumns > 1 ? [
                      { value: 'AboveRow', label: 'Above row' },
                      { value: 'OnRow', label: 'On row' },
                      { value: 'BelowRow', label: 'Below row' }] : [
                      { value: 'AboveRow', label: 'Above row' },
                      { value: 'BelowRow', label: 'Below row' }]}
                    label={''}
                    value={action}
                    onChange={setAction}/>
          </div>
          <div className={'col-6'}>
            <AsyncSelect id={'positionByRow'}
                         options={Array.from(Array(countRows).keys()).
                           map(item => ({
                             value: item + 1,
                             label: item === currentPosition ? item + 1 +
                               ' (current)' : item + 1,
                           }))}
                         value={positionByRow}
                         showLabel={false}
                         isInsideModal={true}
                         onChange={(action) => {
                           if (action) {
                             if ('value' in action) {
                               setRow(action.value)
                             }
                           }
                         }}
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ModalMove
