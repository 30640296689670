import React, { useMemo } from 'react'
import { Button, Tooltip } from 'antd'
import Icon from '../Icon'

let style = {
  /*  fontSize: 20,*/
  verticalAlign: '0.1em',
}

let styleButton = {
  boxShadow: 'none',
}
let styleBlock = {
  marginTop: '1.75rem',
}
const NavButtons = ({
                      isActive,
                      save,
                      saveCaption,
                      cancel,
                      cancelCaption,
                    }) => {

  const MemoSave = useMemo(
    () => (<Tooltip placement="bottom" title={saveCaption}>
      <Button shape="circle" style={styleButton} size={'large'}
              className="circle-ant-btn mx-1" onClick={save}>
        <Icon type="icon-save" style={style} />
      </Button>
    </Tooltip>), [])

  const MemoCancel = useMemo(
    () => (<Tooltip placement="bottom" title={cancelCaption}>
      <Button shape="circle" style={styleButton} size={'large'}
              className="mx-1 border-0 bg-transparent"
              onClick={cancel}>
        <Icon type="icon-undo" style={style} />
      </Button>
    </Tooltip>), [])

  const MemoBlock = useMemo(() => (<div style={styleBlock}>
    {isActive && MemoSave}
    {isActive && MemoCancel}
  </div>), [isActive])

  return MemoBlock
}

export default NavButtons
