import { ACTIVE_MODAL, DEACTIVATE_MODAL } from '../../action/modal'
import { Map } from 'immutable'

let initialState = {
  newCollections: false,
}

function modal (state = initialState, action) {
  switch (action.type) {
    case ACTIVE_MODAL: {
      return Map(initialState).set(action.key, true).set('other', action.other).toJS()
    }
    case DEACTIVATE_MODAL: {
      return Map(initialState).set(action.key, false).toJS()
    }
    default: {
      return state
    }
  }
}

export default modal
