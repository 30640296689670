import React, { Fragment, useState } from 'react'
import { Modal } from 'antd'
import { produce } from 'immer'
import axios from 'axios'
import { requestCollections } from '../../../action/collections'
import { connect } from 'react-redux'

const Synchronize = ({
  visible,
  catalog,
  model,
  collections,
  request,
  onCancel,
}) => {
  const [range, setRange] = useState([])
  const [skipDisabledAppOptions, setSkipDisabledAppOptions] = useState(false)

  const assortment = collections.filter(
    collection => collection.type._id === model._id).
    filter(collection => collection.name !== catalog.name)

  const onSubmit = async () => {
    try {
      let syncList
      if (assortment.length === 1) {
        syncList = [assortment[0]._id]
      } else {
        syncList = range
      }
      const catalogName = catalog && catalog.name
      await axios.post(
        `/api/collections/${catalogName}/synchronize`,
        { parentCatalog: catalog._id, syncList, skipDisabledAppOptions })
    } catch (e) {

    } finally {
      request()
      onCancel()
    }
  }

  const onSelect = () => {
    if (assortment.length === range.length) {
      setRange([])
    } else {
      setRange(assortment.map(item => item._id))
    }
  }

  const onChange = (id) => () => setRange(produce(draft => {
    const index = draft.findIndex(value => value === id)
    if (index !== -1) {
      draft.splice(index, 1)
      return
    }
    draft.push(id)
  }))

  return (
    <Modal
      title={`Changes saved for catalog '${catalog && catalog.name}'.`}
      visible={visible}
      onCancel={onCancel}
      closable={false}
      footer={[
        <button key="submit"
                className={'btn btn-secondary dart-btn'}
                disabled={assortment.length !== 1 && !range.length}
                onClick={onSubmit}>
          Apply
        </button>,
        <button key="back"
                className={'btn btn-outline-secondary dart-btn-outline'}
                onClick={onCancel}>No thanks</button>,
      ]}
    >
      {assortment.length === 1 && (
        <Fragment>
          <p>Synchronize settings to '{assortment[0].name}' catalog based on
            model
            '{model &&
              model.name}'?</p>
          <div className="form-check mb-2">
            <input className="form-check-input" type="checkbox"
                   id="skipDisabledAppOptions"
                   onChange={() => setSkipDisabledAppOptions(
                     !skipDisabledAppOptions)}
                   checked={!!skipDisabledAppOptions}/>
            <label className="form-check-label"
                   htmlFor="skipDisabledAppOptions">
              Apply app enabled status across other catalog too
            </label>
          </div>
        </Fragment>)}
      {assortment.length > 1 && (
        <Fragment>
          <p className={'mb-2'}>Synchronize settings to other catalogs based on
            model '{model &&
              model.name}'?</p>
          <div className="form-check mb-2">
            <input className="form-check-input" type="checkbox"
                   id="skipDisabledAppOptions"
                   onChange={() => setSkipDisabledAppOptions(
                     !skipDisabledAppOptions)}
                   checked={!!skipDisabledAppOptions}/>
            <label className="form-check-label"
                   htmlFor="skipDisabledAppOptions">
              Apply app enabled status across these catalogs too
            </label>
          </div>
          <button key="selectAll"
                  className={'btn btn-secondary dart-btn'}
                  onClick={onSelect}>
            Select {assortment.length === range.length ? 'none' : 'all'}
          </button>
          <div style={{ maxHeight: 200, overflow: 'auto' }}>
            {assortment.map(
              item => (<div key={item._id} className="form-check my-2">
                <input className="form-check-input" type="checkbox"
                       checked={range.some(v => v === item._id)}
                       id={item._id} onChange={onChange(item._id)}/>
                <label className="form-check-label" htmlFor={item._id}>
                  {item.name}{item.label && ` (${item.label})`}
                </label>
              </div>))}
          </div>
        </Fragment>)}
    </Modal>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => ({
  request: () => {
    dispatch(requestCollections())
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(Synchronize)
