import React from 'react'
import classNames from 'classnames'
import RemoveMarkdown from 'remove-markdown'
import moment from 'moment'
import Icon from '../../components/Icon'
import styled from 'styled-components'

const ItemOfCollection = styled.div`
  color: ${(props) => props.theme.colorTable.text};
  background: ${(props) => props.theme.colorTable.plain};

  &:hover {
    background: ${(props) => props.theme.colorTable.backLightHover};
  }

  &.accent {
    color: ${(props) => props.theme.colorTable.listSelectionText};
    background: ${(props) => props.theme.colorTable.listSelection};
  }
`
const Loader = ({ style }) => (
  <ItemOfCollection style={style} className={'cursorPointer border border-bottom-0 py-3 px-2 text-center'}>
    Loading...
  </ItemOfCollection>
)
const Item = ({
                item,
                index,
                active,
                setActive,
                style,
              }) => {
  return (item &&
    <ItemOfCollection key={'collection' + item.date} onClick={(event) => {
      const e = event || window.event
      const selectedKey = e.metaKey || e.ctrlKey

      const type = selectedKey ? 'select' : 'click'
      setActive(type, index)
    }
    }
                      style={style}
                      className={classNames('cursorPointer border border-bottom-0 py-3 px-2',
                        { accent: index === active })}>
      <div className={'row'}>
        <div className="col-6 text-truncate">{item.name && RemoveMarkdown(item.name)}</div>
        <div className="col-4">{moment(item.date).format('ll')}</div>
        <div className="col-2"><Icon type={Icon.fromStatus(item.status)}
                                     style={{
                                       fontSize: '18px',
                                       verticalAlign: '0.05rem',
                                     }}
                                     className="mx-2"/></div>
      </div>
    </ItemOfCollection>
  )
}
const RowComponent = ({
                        style,
                        loading,
                        item,
                        active,
                        index,
                        setActive,
                      }) => {
  return loading ? <Loader style={style}/> : <Item style={style} item={item} index={index} active={active}
                                                   setActive={setActive}
                                                   loading={loading}/>
}
export default RowComponent
