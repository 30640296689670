import React, { useEffect, useState } from 'react'
import NetDocuments from './netdocuments'
import Onedrive from './onedrive'
import { Select } from '../../../../components'
import { Button } from 'antd'
import Icon from '../../../../components/Icon'
import axios from 'axios'
import { useQuery } from 'react-query'
import { original, produce } from 'immer'
import { ConfirmProfileModal } from './confirm'

const styleButton = {
  boxShadow: 'none',
  width: 26,
  height: 26,
}

const style = {
  verticalAlign: '0.1em',
}
const ND_PREFIX = 'nd:'

const Profile = ({
                   typeName,
                   templateName,
                 }) => {
  const [hasChanges, setHasChanges] = useState(false)
  const [profile, setProfile] = useState('')
  const [def, setDef] = useState(null)

  const {
    data,
    isLoading,
    refetch,
  } = useQuery([
    'Profile', typeName,
    templateName], async () => {
    const { data } = await axios.get(
      `/api/types/${typeName}/templates/${templateName}/profile`)
    return data
  }, {
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    data: profiles,
  } = useQuery([
    'Profiles', typeName,
    templateName], async () => {
    const { data } = await axios.get(
      `/api/types/${typeName}/templates/${templateName}/profiles`)
    return data
  }, {
    retry: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    setProfile('')
    setHasChanges(false)
  }, [templateName])

  useEffect(() => {
    if (data) {
      if (data.profile) {
        setProfile(data.profile)
      }
      if (data.def) {
        setDef(data.def)
      }
    }
  }, [isLoading, data])

  const onSave = async () => {
    console.log('onSave')
    await axios.post(`/api/types/${typeName}/templates/${templateName}/profile`,
      {
        profile,
        def,
      })
    await onCancel()
  }

  const onCancel = async () => {
    const result = await refetch()
    if (result.data) {
      if (result.data.profile) {
        setProfile(result.data.profile)
      }
      if (result.data.def) {
        setDef(result.data.def)
      }
    }
    setHasChanges(false)
    window.needSaveProfileTemplate = false
  }

  const onChange = (key) => (value) => {
    setDef(produce(draft => {
      if (!original(draft)) {
        return ({ [key]: value })
      }
      draft[key] = value
    }))
    setHasChanges(true)
    window.needSaveProfileTemplate = true
  }

  if (isLoading || !profiles) {
    return null
  }

  return (
    <div className={'col-12'}>
      <div className={'row'}>
        <div className="col-4">
          <Select label={'PROFILE'} value={profile}
                  id={'Profile'}
                  values={profiles}
                  onChange={val => {
                    setProfile(val)
                    setDef(null)
                    setHasChanges(true)
                    window.needSaveProfileTemplate = true
                  }} />
        </div>
        <div className={'col-4 offset-4'}>
          {hasChanges && (<div
            className={'d-flex h-100 align-items-center justify-content-end'}>
            <Button shape="circle" style={styleButton}
                    id={'saveProfileTemplate'}
                    size={'large'}
                    className="circle-ant-btn mx-2"
                    onClick={onSave}>
              <Icon type="icon-save" style={style} />
            </Button>
            <Button shape="circle" style={styleButton}
                    id={'cancelProfileTemplate'}
                    size={'large'}
                    className="mx-2 border-0 bg-transparent"
                    onClick={onCancel}>
              <Icon type="icon-undo" style={style} />
            </Button>
          </div>)}
        </div>

        {profile.includes(ND_PREFIX) &&
          (<NetDocuments profile={profile}
                         def={def}
                         onChange={onChange} />)}

        {profile === 'OneDrive' &&
          (<Onedrive folder={def ? def.folder || '' : ''}
                     onChange={onChange('folder')} />)}
      </div>
      <ConfirmProfileModal ok={onSave} cancel={onCancel} />
    </div>
  )
}

export default Profile
