import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import validatePassword from '../../utils/validatePassword'
import axios from 'axios'
import { toast } from 'react-toastify'
import { URI } from '../../config'

const LabelSignIn = styled.h1`
  color:${props => props.theme.colorTable.text}
`

const BlockLogo = styled.div`
 background-color: ${props => props.theme.colorTable.menu};
 color:${props => props.theme.colorTable.menuText}
`

const ButtonLoginDark = styled.button`
 background: ${props => props.theme.colorTable.widget}!important;
 border-color:${props => props.theme.colorTable.widget}!important;
 color: ${props => props.theme.colorTable.widgetText}!important;
 font-weight: 500!important;
 padding: 12px!important;
`

const NewUser = ({ settings, pushUser, onConfirm, email, fullName }) => {
  const [name, setName] = useState(fullName)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [invalidPassword, setInvalidPassword] = useState(false)

  const submit = async () => {
    if (password.length > 5 && password === confirmPassword) {
      setInvalidPassword(false)
      let { data: { error, token, user } } = await axios.post('/api/auth/registrationByLink', {
        user: {
          email,
          name,
          pass: password,
        },
      })
      if (error) {
        toast.error(
          error,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          })
      } else {
        pushUser(user)
        let tokens = localStorage.getItem('tokens')

        let newInstansTokens = {}
        if (tokens) {
          tokens = JSON.parse(tokens)
          newInstansTokens = { ...tokens }
        }
        localStorage.removeItem('tokens')
        tokens = JSON.stringify({
          ...newInstansTokens,
          [window.tenancy]: token,
        })
        localStorage.setItem('tokens', tokens)
        axios.defaults.headers.common['Authorization'] = token
        onConfirm()
      }
    } else {
      if (!validatePassword(password) && password !== confirmPassword) {
        toast.error(
          'Please verify both password and try again.',
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          })
        setInvalidPassword(true)
      } else {
        validatePassword(false)
      }
    }
  }
  return (
    <Fragment>
      {settings.logo && <BlockLogo className={'w-100 text-center py-3'}>
        <img src={`${URI}/${window.tenancy}/` + settings.logo} style={{
          maxWidth: '50%',
          maxHeight: 100,
        }} alt={settings.label ? settings.label : settings.name}/>
      </BlockLogo>}

      <div className={settings.logo ? 'pt-5' : ''} style={!settings.logo ? { paddingTop: '10%' } : {}}>
        <div className={'mx-auto signin text-center d-flex flex-column p-5 '}>
          <LabelSignIn
            className={'mb-5 font-weight-bold'}>{settings.label ? settings.label : settings.name}</LabelSignIn>
          <p className={'mb-5'}>Please sign in to access the {settings.label ? settings.label : settings.name}</p>
          <form className={'text-left'} onSubmit={event => {
            event.preventDefault()
            submit()
          }}>
            <div className="form-group">
              <label htmlFor="emailForReturningUser">Email</label>
              <div className={'px-2'}>{email.replace(/^(.)(.*)(.@.*)$/,
                (_, a, b, c) => a + b.replace(/./g, '*') + c,
              )}</div>
            </div>
            <div className="form-group">
              <label htmlFor="emailForReturningUser">Full name</label>
              <input
                className={classNames('form-control rounded-0')}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="PasswordForReturningUser">Set password</label>
              <input className={classNames('form-control rounded-0', { 'is-invalid': invalidPassword })}
                     value={password}
                     onChange={e => setPassword(e.target.value)}
                     type={'password'}/>
            </div>
            <div className="form-group">
              <label htmlFor="PasswordForReturningUser">Confirm password</label>
              <input className={classNames('form-control rounded-0', { 'is-invalid': invalidPassword })}
                     value={confirmPassword}
                     onChange={e => setConfirmPassword(e.target.value)}
                     type={'password'}/>
            </div>
            <input className={'position-absolute'} style={{
              left: -2000,
              zIndex: -1,
            }} type={'submit'}/>
          </form>
          <div className={'row'}>
            <div className={'col-4'}/>
            <div className={'col-8'}><ButtonLoginDark className={'my-2  btn-block rounded'}
                                                      onClick={() => submit()}>REGISTER
              &
              CONFIRM</ButtonLoginDark></div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

export default NewUser
