import React, { useImperativeHandle, useRef } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { Icon } from '../../index'
import { Dropdown } from 'antd'
import { column } from '../menu'
import styled from 'styled-components'

const ItemTypes = {
  HEADER: 'HEADER',
}

const TitleOfColumn = styled.div`
  border-color: ${props => props.theme.colorTable.back} !important;
`

let style = { verticalAlign: 2 }
const Header = React.forwardRef(
  ({
     header,
     handleDelete,
     handleKey,
     handleRename,
     handleRelabel,
     index,
     isDragging,
     connectDragSource,
     connectDropTarget,
     handleSortASC,
     handleSortDESC,
   }, ref) => {
    const elementRef = useRef(null)
    connectDragSource(elementRef)
    connectDropTarget(elementRef)
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))
    return (
      <TitleOfColumn key={'props-' + index}
                     className="py-2 pl-2 knacklyBlock d-flex justify-content-between"
                     ref={elementRef}>
          <span>
            <Icon style={style} className={'mr-1'}
                  type={Icon.fromValueType('text')} />
            {header.label}
          </span><span>
          {header.isKey &&
            <Icon style={style} className={'mr-1'} type={'icon-key1'} />}
        <Dropdown overlay={column({
          isKey: header.isKey,
          forceKey: header.forceKey,
          index,
          handleDelete,
          handleKey,
          handleRename,
          handleRelabel,
          handleSortASC,
          handleSortDESC,
        })}>
            <Icon style={{
              ...style,
              transform: 'rotate(90deg)',
            }} type={'icon-more'} />
            </Dropdown>
          </span>
      </TitleOfColumn>
    )
  },
)
export default DropTarget(
  ItemTypes.HEADER,
  {
    hover (props, monitor, component) {
      if (!component) {
        return null
      }
      const node = component.getNode()
      if (!node) {
        return null
      }
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = node.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      props.moveHeader(dragIndex, hoverIndex)
      monitor.getItem().index = hoverIndex
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.HEADER,
    {
      beginDrag: props => {
        return ({
          index: props.index,
        })
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(Header),
)
