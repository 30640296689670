import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { Icon } from '../../../components'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import MappingExpression from './mappingExpression'
import Mapping from './mapping'
import { useRecoilValue } from 'recoil'
import { ErrorExpressionState, isObjectSelector } from './mappingState'

const ButtonSwitch = styled.button`
  background-color: ${props => props.theme.colorTable.plain} !important;
  border-color: ${props => props.theme.colorTable.widget} !important;

  &.active {
    color: ${props => props.theme.colorTable.widgetText} !important;
    background: ${props => props.theme.colorTable.widget} !important;
  }

  &:disabled {
    border-color: #6c757d !important;
  }
`

const InlineCheck = ({
                       id,
                       label,
                       checked,
                       onChange,
                     }) => {
  return (
    <span className="ml-4">
      <input id={id} type="checkbox" style={{ marginTop: '0.5rem' }}
             onChange={() => onChange(!checked)} checked={checked} />
      <label className="form-check-label font-weight-normal ml-1"
             htmlFor={id}>{label}</label>
    </span>
  )
}

const MappingDesigner = () => {
  const [editAsExpr, setEditAsExpr] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const isObject = useRecoilValue(isObjectSelector)
  const error = useRecoilValue(ErrorExpressionState)

  const onMapping = useCallback((event) => {
    event.preventDefault()
    setEditAsExpr(() => false)
  }, [])

  const onExpression = useCallback((event) => {
    event.preventDefault()
    setEditAsExpr(() => true)
  }, [])

  return (<div className={'col-12'}>
    <div className="btn-group btn-group-sm py-2" role="group">
      <ButtonSwitch type="button"
                    className={classNames('btn rounded-0',
                      { active: !isObject || editAsExpr })}
                    onClick={onExpression}
      >EXPRESSION</ButtonSwitch>
      <ButtonSwitch type="button"
                    disabled={!isObject}
                    className={classNames('btn rounded-0',
                      { active: isObject && !editAsExpr })}
                    onClick={onMapping}
      >MAPPING</ButtonSwitch>
      {isObject && !editAsExpr && (
        <InlineCheck id={'showAll'} label={'show advanced'}
              onChange={setShowAll}
              checked={showAll} />
      )}
      {error &&
        (<Tooltip title={error} key={'Tooltip'} placement={'bottom'}
                  overlayClassName={'warning-tooltip'}>
          <Icon type={'icon-exclamation-triangle'} className={'mx-2'} style={{
            color: '#dc3545',
            fontSize: '1.6rem',
            verticalAlign: 0,
            marginTop: '0.1rem',
          }} />
        </Tooltip>)}
    </div>
    {isObject && !editAsExpr ? (<Mapping showAll={showAll} />) : (<MappingExpression />)}
  </div>)
}

export default MappingDesigner
