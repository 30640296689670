import axios from 'axios'

const redirectNetDocuments = async (documentId, shiftKey) => {
  let redirUrl = ''
  try {
    if (shiftKey) {
      redirUrl = `https://vault.netvoyage.com/neWeb2/goId.aspx?id=${documentId}&open=Y`
      // EXPERIMENTAL. TODO: finalize... work with different locale URLs, etc.
    } else {
      const { data } = await axios.get('/api/netdocuments/documents/' + documentId + '/createSecuredLink')
      redirUrl = data.redirectUrl
    }
    window.open(redirUrl, '_blank').focus()
  } catch (e) {

  }
}

export default redirectNetDocuments
