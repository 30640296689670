import isDone from './isDone'
import XRegExp from 'xregexp'
import {
  TEXT, NUMBER, DATE, TRUEFALSE, SELECTION, OBJECT, ID,
  TEXTBOX, DATEPICKER, CHECKBOX, CHECKBOXES, DROPDOWN, POPUP, HIDDEN,
} from '../config'
const { objectId } = require('./objectid')
export const {
  IsInvalidIdentifier,
  validateTypeMember,
  validateElementName,
} = require('./validateIdent')

export function InferLabelFromName (name, retainFirstCap = false, retainOtherCaps = false) {
  if (!name) return ''
  const rest = name.slice(1)
    .replace(inferredWordBreak, '$1 $2')
    .replace(inferredWordBreak2, '$1 $2')
    .replace(inferredWordBreak2, '$1 $2')
    .replace(inferredBlank, ' $1 ')
    .replace(inferredWordBegin, ' $1$2')
    .replace(/ +/g, ' ')
  return (
    (retainFirstCap ? name[0] : name[0].toUpperCase()) + (retainOtherCaps ? rest : rest.toLowerCase())
  )
}

export function InferLabelFromAppName (name) {
  if (!name) return ''
  if (name.includes(' ')) return InitialCaps(name)
  return InferLabelFromName(name, false, true)
}

/**
 * Determine whether the given `input` is iterable.
 *
 * @returns {Boolean}
 */
export function IsIterable(input) {
  if (input === null || input === undefined) {
    return false
  }
  return typeof input[Symbol.iterator] === 'function'
}

export { isDone, objectId }

export const InitialCap = str => str ? str[0].toUpperCase() + str.substring(1) : ''

export const InitialCaps = str => str ? str.replace(/\b\w/g, l => l.toUpperCase()) : ''
// export function InitialCaps(str) {
//   return (typeof str === 'string')
//     ? str.split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
//     : str
// }

export const PluralizeStyle = name => {
  if (name.endsWith('x') || name.endsWith('sh') || name.endsWith('ch')) {
    return name + 'es'
  }
  if (!name.endsWith('s')) {
    return name + 's'
  }
  // else punt
  return name
}

export const GetDefaultSelectionStyle = (isList) => isList ? CHECKBOXES : DROPDOWN

export const GetDefaultControlStyle = (varType, isList) => {
  switch (varType) {
    case TEXT:
    case NUMBER:
      return TEXTBOX
    case DATE:
      return DATEPICKER
    case TRUEFALSE:
      return CHECKBOX
    case SELECTION:
      return GetDefaultSelectionStyle(isList)
    case OBJECT:
      return POPUP
    case ID:
      return HIDDEN
    default:
  }
}

const inferredWordBreak = XRegExp('(\\p{Ll})(\\p{Lu}|\\p{Lt}|\\p{N})', 'g')
const inferredWordBreak2 = XRegExp('([^_])_([^_])', 'g')
const inferredBlank = XRegExp('(_{2,})', 'g')
const inferredWordBegin = XRegExp('(\\p{Lu}|\\p{Lt})(\\p{Ll})', 'g')
