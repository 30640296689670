import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Label from '../Label'
import MaskedInput from 'react-maskedinput'
import { patternChars } from './patternChars'

AppInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onFormat: PropTypes.func,
  spellcheck: PropTypes.bool,
  pattern: PropTypes.string
}

function AppInput ({
  id, name, label, type, index, value, helpText, onChange, onFormat, placeholder, spellcheck, pattern, activate, list
}) {
  const [isEditing, setIsEditing] = useState(false)
  const inList = (typeof index === 'number' && index >= 0)
  return (
    <div className={inList ? 'input-group mb-1' : null}>
      {inList
        ? <div className="input-group-prepend"><span className="input-group-text">{(index + 1) + '.'}</span></div>
        : <Label name={name} label={label} helpText={helpText} forId={id}/>}
      {pattern
        ? <MaskedInput  id={id}
                        type={type}
                        value={value}
                        mask={pattern}
                        formatCharacters={patternChars}
                        onFocus={() => isEditing ? null : setIsEditing(true)}
                        onChange={event => onChange(event.target.value)}
                        onBlur={() => isEditing ? setIsEditing(false) : null}
                        required={false}
                        className={'form-control rounded-2'}
                        placeholder={placeholder}
                        spellCheck="false"
                        autoComplete="off"
                        autoFocus={activate || null}
                        list={list}/>
        : <input  id={id}
                  type={type}
                  value={(isEditing || !onFormat) ? value : onFormat(value)}
                  onFocus={() => isEditing ? null : setIsEditing(true)}
                  onChange={event => onChange(event.target.value)}
                  onBlur={() => isEditing ? setIsEditing(false) : null}
                  className={'form-control rounded-2'}
                  placeholder={placeholder}
                  spellCheck={typeof spellcheck === 'boolean' ? String(spellcheck) : null}
                  autoComplete="off"
                  autoFocus={activate || null}
                  list={list}/>
      }
    </div>
  )
}

AppInput.defaultProps = {
  onChange: val => (val)
}

export default AppInput
