import React, { useState } from 'react'
import changeErrorMessage from '../../../Formulas/changeErrorMessage'
import { ERRORTOKEN } from '../../../../../utils/tokens'
import classNames from 'classnames'
import { Ace, Icon } from '../../../../../components'
import { Tooltip } from 'antd'
import { Row } from './style'

const RowParameter = ({ name, type, value, onChange }) => {
  const [error, setError] = useState('')

  const hasError = nextError => {
    setError(changeErrorMessage(nextError))
  }

  if (value.startsWith('"' + ERRORTOKEN) && value.endsWith('"')) {
    value = value.slice(ERRORTOKEN.length + 1, -1)
  }
  return (<Row className={'row'} key={'RowParameter' + name}>
    <div className="col-6">
      <div className={'d-flex align-items-center justify-content-between h-100'}>
        <span>{name}</span>
        <span>{type}</span>
      </div>
    </div>
    <div className="col-6">
      <div className={classNames('knackly-plain rounded-0 py-2 form-control TemplateWrapper my-1',
        { 'border-danger': error })}>
        <Ace mode={'kexpr'}
             key={'RowParameterAce' + name}
             value={value === '""' ? '' : value}
             onChange={(nextValue) => onChange(nextValue, hasError)}
             className={'border-0'}
             maxLines={Infinity}
             isExpression={true}/>
      </div>
      {error && (
        <Tooltip title={error} key={'Tooltip' + name} placement={'bottom'}
                 overlayClassName={'warning-tooltip'}>
          <Icon type={'icon-exclamation-triangle'} style={{
            color: '#dc3545',
            fontSize: '1.2rem',
            verticalAlign: 0,
            left: 'calc(100% - 2.6rem)',
            top: '0.9rem',
            position: 'absolute',
          }}/>
        </Tooltip>)}
    </div>
  </Row>)
}

export default RowParameter
