import React from 'react'
import className from 'classnames'
import { useDrop } from 'react-dnd'
import { DND } from '../../config'

function createDrop (onChange, value) {
  const [, drop] = useDrop({
    accept: DND.TYPES.DNDITEMROOT,
    drop (item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      onChange(value + ` {[${item.copyText}]}`)
    },
  })
  return drop
}

const Input = ({
                 type,
                 value,
                 id,
                 onChange,
                 onBlur,
                 label,
                 placeholder,
                 spellcheck,
                 pattern,
                 myref,
                 error,
                 isDrop,
                 classNameForDiv,
               }) => {

  const dropRef = isDrop ? createDrop(onChange, value) : null

  return (<div ref={dropRef} className={classNameForDiv
    ? classNameForDiv
    : 'form-group mb-3'}>
    <label htmlFor={id}>{label}</label>
    <input type={type}
           onBlur={event => onBlur ? onBlur(event.target.value) : 0}
           className={className('form-control rounded-0',
             { 'is-invalid': error })}
           id={id} placeholder={placeholder}
           spellCheck={typeof spellcheck === 'boolean'
             ? String(spellcheck)
             : null}
           autoComplete="off"
           pattern={pattern}
           ref={myref}
           value={value}
           onChange={event => onChange(event.target.value)}
    />
    <div className="invalid-feedback">
      {error}
    </div>
  </div>)
}

Input.defaultProps = {
  onChange: val => console.log(val),
  isDrop: false,
}
export default Input
