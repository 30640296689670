import axios from 'axios'

const getJSON = (File) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = async () => {
    try {
      const { data } = await axios.get(reader.result, {
        url: '',
        baseURL: '',
      })
      resolve(data)
    } catch (e) {
      reject(e)
    }
  }
  reader.onerror = error => reject(error)
  reader.readAsDataURL(File)
})

export default getJSON
